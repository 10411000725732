import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import {Grid} from '@mui/material';
import {ALL_TEXT, ENUMS, images} from '../../common';
import {GetBadge} from '../../utils/helpers/users';
import {
  AttachmentsDetails,
  GenericButton,
  Loader,
  ModalUpdateClaim,
} from '../../components';
import './styles.scss';
import {getClaimDetails} from '../../utils/rest-services';
import {useLocation} from 'react-router';
import {NumberToText} from '../../utils/helpers';

function ClaimDetails() {
  const location = useLocation();

  const getParamsFromUrl = () => {
    return new URLSearchParams(location.search);
  };
  let query = getParamsFromUrl();
  const id = query.get('claimId');

  const [isUpdateClaimModal, setIsUpdateClaimModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [claimDetailData, setClaimDetailData] = useState([]);
  const [adminData, setAdminData] = useState({
    docs: [],
    notes: '',
  });

  const [claimDoc, setClaimDoc] = useState({
    driverLisense: [],
    registration: [],
    insuranceDeclaration: [],
    claimInfo: [],
    incidentReport: [],
    other: [],
  });

  const getClaimDetailHandler = async () => {
    setLoader(true);
    let response = await getClaimDetails(id);
    const {
      content: {claim, claimDocs, adminDocs, adminNote},
    } = response;
    if (response && response.success) {
      setClaimDetailData(claim);
      setAdminData({
        docs: adminDocs,
        notes: adminNote,
      });
      const groupedArrays = _.groupBy(claimDocs, 'documentType');
      setClaimDoc({
        driverLisense: (groupedArrays[1] || []).map(item => item),
        registration: (groupedArrays[2] || []).map(item => item),
        insuranceDeclaration: (groupedArrays[3] || []).map(item => item),
        claimInfo: (groupedArrays[4] || []).map(item => item),
        incidentReport: (groupedArrays[5] || []).map(item => item),
        other: (groupedArrays[6] || []).map(item => item),
      });
    }
    setLoader(false);
  };
  useEffect(() => {
    getClaimDetailHandler();
  }, []);
  const claimInfo = [
    {
      title: ALL_TEXT.DATE_SETTLED,
      value: claimDetailData.dateSettled,
    },
    {
      title: ALL_TEXT.STATUS,
      value: claimDetailData?.claimStatus,
    },
  ];
  const claimDetailsHeader = [
    {title: claimDetailData?.fullName},
    {title: claimDetailData?.email},
    {title: ALL_TEXT.CLAIM_NUMBER_, info: claimDetailData?.claimId},
  ];

  const claimContent = [
    {
      title: ALL_TEXT.PROVIDER_NUMBER,
      value:
        claimDetailData.claimNumber == ''
          ? ALL_TEXT.NOT_AVAILABLE
          : claimDetailData.claimNumber,
    },
    {
      title: ALL_TEXT?.PROVIDER_NAME,
      value: claimDetailData.providerName,
    },
    {
      title: ALL_TEXT?.CLAIM_TYPE,
      value: NumberToText(claimDetailData.serviceTypeId),
    },
    {
      title: ALL_TEXT?.DATE_LOSS,
      value: claimDetailData.dateLoss,
    },
    {
      title: ALL_TEXT?.DATE_FILED,
      value: claimDetailData.dateFiled,
    },
    {
      title: ALL_TEXT?.CLAIM_AMOUNT,
      value: claimDetailData.claimAmount,
    },
    {
      title: `Date ${claimDetailData?.claimStatus}`,
      value:
        claimDetailData.dateSettled == ''
          ? ALL_TEXT.NOT_AVAILABLE
          : claimDetailData.dateSettled,
    },
    {
      title: ALL_TEXT?.STATUS,
      value: GetBadge(claimDetailData?.claimStatus),
    },
  ];

  const attachments = [
    {
      title: ALL_TEXT.DRIVER_LICENSE,
      file: claimDoc.driverLisense,
    },
    {
      title: ALL_TEXT.REGISTRATION_DOC,
      file: claimDoc.registration,
    },
    {
      title: ALL_TEXT.INSURANCE_DECLARATION,
      file: claimDoc.insuranceDeclaration,
    },
    {
      title: ALL_TEXT.CLAIM_INFO,
      file: claimDoc.claimInfo,
    },
    {
      title: ALL_TEXT.INCIDENT_REPORT,
      file: claimDoc.incidentReport,
    },
    {
      title: ALL_TEXT.OTHER_MISC,
      file: claimDoc.other,
    },
  ];

  const handleDownload = i => {
    fetch(i).then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = i.split('\\').pop();
        a.click();
      });
      //window.location.href = response.url;
    });
  };

  return (
    <>
      {loader ? (
        <div className="loader-container-dasboard">
          <Loader />
        </div>
      ) : (
        <div className="claimDetails-main-container">
          <div className="title-container-claimDetails">
            <span>{ALL_TEXT.CLAIMS_DETAILS}</span>
            <div className="claim-box">
              {claimDetailsHeader.map((item, index) => {
                return (
                  <div className="claim-container" key={index}>
                    <span>
                      {item.title}
                      <span className="claim-number-style">{item.info}</span>
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="updgrade-details-button">
              <GenericButton
                buttonText={ALL_TEXT.BUTTON_TEXT.UPDATE}
                onPress={() => {
                  setIsUpdateClaimModal(true);
                }}
              />
            </div>
          </div>
          <div className="claim-detail-container">
            <Grid container spacing={2}>
              {claimContent.map((item, index) => {
                const {title, value} = item;
                return (
                  value != ALL_TEXT.NOT_AVAILABLE && (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={index}>
                      <div className="claim-content">
                        <span>{title}</span>
                        <span>{value}</span>
                      </div>
                    </Grid>
                  )
                );
              })}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="claim-notes">
                  <h5>{ALL_TEXT.USER_NOTES}</h5>
                  <p>{claimDetailData.userNotes}</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="calim-attachments">
                  <p>{ALL_TEXT.ATTACHMENTS}</p>
                  <Grid container spacing={1}>
                    {attachments.map((item, index) => {
                      const {title, file} = item;
                      const hasFiles = file.length > 0;
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          xl={6}
                          key={index}
                          className={`attachment-grid ${
                            hasFiles ? '' : 'empty'
                          }`}>
                          <div className="attachment-content">
                            <div className="attachment-header">
                              <img
                                src={images.attachment}
                                alt={ALL_TEXT.ICON}
                              />
                              <h6>{title}</h6>
                            </div>

                            {file.map((i, index) => (
                              <AttachmentsDetails
                                key={index}
                                onPressDownload={() => {
                                  handleDownload(i.documentPath);
                                }}
                                fileName={i.documentPath.split('\\').pop()}
                                onPressView={() => {
                                  const link = document.createElement('a');
                                  link.href = i.documentPath;
                                  link.target = '_blank';
                                  link.rel = 'noopener noreferrer';
                                  link.click();
                                }}
                              />
                            ))}
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              </Grid>
              {claimDetailData?.claimStatus?.toLowerCase() !=
                ENUMS.CLAIMS.STAND.PENDING.LABEL?.toLowerCase() && (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="claim-admin-agent">
                      <p>{ALL_TEXT.ADMIN_AGENT}</p>

                      <Grid container spacing={2}>
                        {claimInfo.map((item, index) => {
                          const {title, value} = item;
                          return (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={6}
                              lg={6}
                              xl={6}
                              key={index}>
                              <div className="claim-info">
                                <span>{title}</span>
                                <span>
                                  {/* {value} */}
                                  {title == ALL_TEXT.STATUS
                                    ? GetBadge(value)
                                    : value}
                                </span>
                              </div>
                            </Grid>
                          );
                        })}
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {adminData?.notes && (
                      <div className="claim-notes">
                        <h5>{ALL_TEXT.NOTES}</h5>
                        <p>{`${adminData?.notes}`}</p>
                      </div>
                    )}
                  </Grid>
                  {adminData.docs && adminData.docs.length > 0 && (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className="calim-attachments">
                        <p>{ALL_TEXT.ATTACHMENTS}</p>
                        <Grid container spacing={1}>
                          <div className="attachment-files">
                            {adminData.docs?.map((i, index) => (
                              <AttachmentsDetails
                                key={index}
                                fileName={i.documentPath.split('\\').pop()}
                                onPressView={() => {
                                  const link = document.createElement('a');
                                  link.href = i.documentPath;
                                  link.target = '_blank';
                                  link.rel = 'noopener noreferrer';
                                  link.click();
                                }}
                                onPressDownload={() =>
                                  handleDownload(i.documentPath)
                                }
                              />
                            ))}
                          </div>
                        </Grid>
                      </div>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </div>
          {isUpdateClaimModal && (
            <ModalUpdateClaim
              dateField={claimDetailData?.dateFiled}
              claimFileDate={claimDetailData?.dateSettled}
              status={claimDetailData?.claimStatus}
              id={id}
              show={isUpdateClaimModal}
              handleClose={isSuccess => {
                setIsUpdateClaimModal(false);
                if (isSuccess == true) {
                  getClaimDetailHandler();
                }
              }}
            />
          )}
        </div>
      )}
    </>
  );
}

export default ClaimDetails;
