import React, {useState, useEffect} from 'react';
import './styles.scss';
import {ALL_TEXT, ENUMS, colors} from '../../../common';
import {CustomizedSearch, GenericTable} from '../../../components';
import {TableHeading} from './table.js';
import Pagination from '@mui/material/Pagination';
import {useDispatch, useSelector} from 'react-redux';
import {
  setIsDeviceTokenPopUp,
  setNotificationPopUp,
} from '../../../redux/slice/logs';
import LogsPopup from '../../../components/logs-popup';
import {
  getEmailLogStatusIcon,
  getValuesFromEnumsObj,
} from '../../../utils/helpers';
import {
  usePostApiAuditLogsGetAllNotificationLogsMutation,
  NodukAdmnApi,
} from '../../../redux/slice/nodukAdmin.ts';
import {Tooltip} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import {MdContentCopy} from 'react-icons/md';

function NofificationLogs() {
  const {useLazyGetApiAuditLogsGetNotificationTokensQuery} = NodukAdmnApi;
  const [getNotificationsLogsAPI, getNotificationsLogsResponse] =
    usePostApiAuditLogsGetAllNotificationLogsMutation();
  const [getNotificationsTokensAPI, getNotificationsTokensResponse] =
    useLazyGetApiAuditLogsGetNotificationTokensQuery();
  const notificationData = useSelector(
    state => state.logsSlice.selectedNotificationData,
  );
  const isNotificationPopUp = useSelector(
    state => state.logsSlice.isNotificationPopUp,
  );

  const isDeviceTokenPopup = useSelector(
    state => state.logsSlice.isDeviceTokenPopUp,
  );

  const fetchNotificationsLogs = async payload => {
    await getNotificationsLogsAPI(payload);
  };
  const fetchNotificationTokenList = async logId => {
    await getNotificationsTokensAPI({logId: logId});
  };

  useEffect(() => {
    if (isDeviceTokenPopup) {
      fetchNotificationTokenList(notificationData?.logId);
    }
  }, [isDeviceTokenPopup]);

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedModule, setSelectedModule] = useState('');
  const [selectedEmail, setSelectedEmail] = useState('');

  useEffect(() => {
    const payload = {
      logPageDto: {
        pageNo: currentPage,
        pageSize: 10,
        email: selectedEmail,
        status: selectedStatus,
        moduleAction: selectedModule,
      },
    };

    fetchNotificationsLogs(payload);
  }, [currentPage]);

  let totalPages = getNotificationsLogsResponse?.data?.content?.totalPages ?? 0;
  let tokensList = getNotificationsTokensResponse?.data?.content ?? {};
  const [btnLoader, setBtnLoader] = useState(false);

  const dispatch = useDispatch();
  const notificationList = getNotificationsLogsResponse?.data?.content?.list;

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const notificationSearchHandler = async (
    email,
    moduleDropDown,
    statusDropDown,
    clear,
  ) => {
    let payload;
    if (clear) {
      payload = {
        logPageDto: {
          pageNo: currentPage,
          pageSize: 10,
          email: '',
          status: '',
          moduleAction: '',
        },
      };
    } else {
      const status = getValuesFromEnumsObj(ENUMS.MODULE.STATUS, statusDropDown);
      payload = {
        logPageDto: {
          pageNo: currentPage,
          pageSize: 10,
          email: email,
          status: status,
          moduleAction: moduleDropDown == 'All' ? '' : moduleDropDown,
        },
      };
      setSelectedEmail(email);
      setSelectedModule(moduleDropDown === 'All' ? '' : moduleDropDown);
      setSelectedStatus(status);
    }
    setCurrentPage(1);
    setBtnLoader(true);
    await fetchNotificationsLogs(payload);
    setBtnLoader(false);
  };
  const {
    MODULE: {
      ACTION: {ALL, INVOICE, CLAIM, SUBSCRIPTION, CUSTOMER, DISASTER, CARRIER},
    },
  } = ENUMS;

  const deviceTokenDetails = () => {
    const TokenItem = ({tok, index}) => {
      return (
        <div className="token-content">
          <div className="token-count">{index + 1}</div>
          <div className="device-token">
            <span>{tok}</span>
          </div>
          <Tooltip id={tok} classNameArrow="arrow" />
          <MdContentCopy
            data-tip={tok}
            data-tooltip-id={tok}
            data-tooltip-content={'Click to Copy'}
            onClick={() => {
              navigator.clipboard.writeText(tok);
            }}
            color={colors.black2}
            style={{cursor: 'pointer', outline: 'none'}}
            size={20}
          />
        </div>
      );
    };

    return (
      <div className="device-token-content">
        <div>
          <h6>{'Web Device Token'}</h6>
          {tokensList?.webTokens?.length === 0 ? (
            <div className="no-tokens-available">
              {ALL_TEXT.NOTIFICATION_LOGS.NO_TOKEN_AVAILABLE}
            </div>
          ) : (
            tokensList?.webTokens?.map((i, index) => (
              <TokenItem key={index} tok={i} index={index} />
            ))
          )}
        </div>
        <div>
          <h6>{'Android Device Token'}</h6>
          {tokensList?.androidTokens?.length === 0 ? (
            <div className="no-tokens-available">
              {ALL_TEXT.NOTIFICATION_LOGS.NO_TOKEN_AVAILABLE}
            </div>
          ) : (
            tokensList?.androidTokens?.map((i, index) => (
              <TokenItem key={index} tok={i} index={index} />
            ))
          )}
        </div>
        <div>
          <h6>{'Ios Device Token'}</h6>
          {tokensList?.iosTokens?.length === 0 ? (
            <div className="no-tokens-available">
              {ALL_TEXT.NOTIFICATION_LOGS.NO_TOKEN_AVAILABLE}
            </div>
          ) : (
            tokensList?.iosTokens?.map((i, index) => (
              <TokenItem key={index} tok={i} index={index} />
            ))
          )}
        </div>
      </div>
    );
  };

  const notificationContent = () => {
    return (
      <div>
        <div className="notification-content-container">
          <div className="notify-image">
            <span
              className={
                notificationData?.notifyType == 'refund'
                  ? 'icon-refund iconFont'
                  : notificationData?.notifyType == 'invoice'
                  ? 'icon-invoices iconFont'
                  : notificationData?.notifyType == 'subscription'
                  ? 'icon-subscription iconFont'
                  : notificationData?.notifyType == 'claim'
                  ? 'icon-claims iconFont'
                  : 'icon-notification iconFont'
              }></span>
          </div>
          <div className="notification-claim-failed">
            <h5>{notificationData?.title}</h5>
            <p>{notificationData?.body}</p>
          </div>
        </div>
        {notificationData?.failedReason && (
          <div className="failed-reason-container">
            <div className="upper-failed-reason">
              <span className="failed-title">{ALL_TEXT.FAILED_REASON}</span>
              <div className="reason-icon-container">
                <div className="fail-reason">
                  <span>{ALL_TEXT.WEB}</span>
                  <div>
                    {getEmailLogStatusIcon(notificationData?.sendToWeb)}
                  </div>
                </div>
                <div className="fail-reason">
                  <span>{ALL_TEXT.MOB}</span>
                  <div>
                    {getEmailLogStatusIcon(notificationData?.sendToMobile)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {notificationData?.failedReason && (
          <div className="failed-content">
            <p>{notificationData?.failedReason}</p>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="notification-logs-main-container">
      <div className="title-container-notification-logs">
        <div>
          <span>{ALL_TEXT.NOTIFICATION_LOGS.NOTIFICATION_LOGS_TITLE} </span>
        </div>
      </div>
      <div className="notification-logs-search-bar">
        <CustomizedSearch
          title={ALL_TEXT.NOTIFICATION_LOGS.SEARCH_NOTIFICATION_LOGS_BY}
          firstLabel={ALL_TEXT.EMAIL}
          firstPlaceholder={ALL_TEXT.ENTER_EMAIL}
          firstDropdown
          firstDropdownLabel={ALL_TEXT.EMAIL_LOG.MODULE_ACTION}
          firstDropdownList={[
            ALL.LABEL,
            INVOICE.LABEL,
            CLAIM.LABEL,
            SUBSCRIPTION.LABEL,
            CUSTOMER.LABEL,
            DISASTER.LABEL,
            CARRIER.LABEL,
          ]}
          secondDropdown
          secondDropdownLabel={ALL_TEXT.STATUS}
          seconddropdownlist={[
            ENUMS.MODULE.EMAIL_STATUS.ACTIVE.LABEL,
            ENUMS.MODULE.EMAIL_STATUS.INACTIVE.LABEL,
            ENUMS.MODULE.EMAIL_STATUS.ALL.LABEL,
          ]}
          onClearFilterHandler={() => {
            notificationSearchHandler('', '', '', true);
          }}
          btnLoader={btnLoader}
          onSearchBtnPress={(email, _, __, moduleDropDown, statusDropDown) => {
            notificationSearchHandler(
              email,
              moduleDropDown,
              statusDropDown,
              false,
            );
          }}
        />
      </div>
      <div className="table-notification-logs-container">
        <GenericTable
          headings={TableHeading()}
          data={notificationList}
          loader={getNotificationsLogsResponse.isLoading}
        />
      </div>
      {totalPages > 1 && (
        <div className="pagination-container">
          <div className="inner-pagination">
            <Pagination
              page={currentPage}
              color={'secondary'}
              onChange={handlePageChange}
              count={totalPages}
            />
          </div>
        </div>
      )}
      {isNotificationPopUp && (
        <LogsPopup
          show={isNotificationPopUp}
          size={'md'}
          title={ALL_TEXT.NOTIFICATION_LOGS.NOTIFICATION_CONTENT}
          body={notificationContent()}
          handleClose={() => dispatch(setNotificationPopUp(false))}
        />
      )}
      {isDeviceTokenPopup && (
        <LogsPopup
          size={'md'}
          show={isDeviceTokenPopup}
          title={ALL_TEXT.NOTIFICATION_LOGS.DEVICE_TOKEN}
          body={deviceTokenDetails()}
          handleClose={() => dispatch(setIsDeviceTokenPopUp(false))}
          loading={getNotificationsTokensResponse.isFetching}
        />
      )}
    </div>
  );
}

export default NofificationLogs;
