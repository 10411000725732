import React from 'react';
import { Formik } from 'formik';
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import { Grid } from '@mui/material';
import { ALL_TEXT, ENUMS } from './../../common/constants';
import { GenericButton } from './../generic-button/index';
//import { InputField } from '../input-field';
import { TextAreaFeild } from '../textarea';
import './style.scss';
import { couponEditSchema } from '../../common/schemas';
//import { toast } from 'react-toastify';
import { NodukAdmnApi } from '../../redux/slice/nodukAdmin.ts';
import { useSelector, useDispatch } from 'react-redux';
import { openModal, setModalDetails } from '../../redux/slice/popup-modal';

export function CouponEditDetail({ show, handleClose }) {
  const { useLazyGetApiAdminUpdateCouponQuery } = NodukAdmnApi
  const dispatch = useDispatch();
  const selectedData = useSelector((state) =>
    state.coupon.selectedCoupon
  );

  const [editCouponAPI, editCouponResponse] =
    useLazyGetApiAdminUpdateCouponQuery();

  const editCouponCode = async values => {
    var result = await editCouponAPI({
      couponId: values.couponId.trim(),
      description: values.description.trim()
    });
    if (result) {
      result?.data?.success ? handleClose(true) : null;
      dispatch(
        setModalDetails({
          type: result?.data?.success ? ENUMS.POPUP_ENUMS.SUCCESS : ENUMS.POPUP_ENUMS.ERROR,
          details: result?.data?.description,
        }),
      );
      dispatch(openModal());
    }
  };
  return (
    <>
      <Formik
        initialValues={{ description: selectedData?.description ?? '', couponName: selectedData?.name ?? '', couponId: selectedData?.stripeCouponId ?? '' }}
        enableReinitialize
        validateOnMount={true}
        validationSchema={couponEditSchema}
        onSubmit={(values, formik) => {
          editCouponCode(values, formik);
        }}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          values,
          touched,
          errors,
        }) => (
          <Modal show={show} onHide={handleClose} size="md" centered>
            <Modal.Header closeButton={handleClose}>
              <h5><b>{ALL_TEXT.EDIT_COUPON_DETAIL}</b></h5>
            </Modal.Header>
            <Modal.Body>
              <div className="edit-coupon-madal">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <label htmlFor="/">{ALL_TEXT.COUPON_CODE_}</label>
                    <h5 className='couponText'>{values.couponName}</h5>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <label htmlFor="/">
                      {ALL_TEXT.USER_DETAIL.DESCRIPTION_}
                    </label>

                    <div className="textArea-add-policy">
                      <TextAreaFeild
                        id="description"
                        name="description"
                        value={values.description}
                        setValue={handleChange('description')}
                        handleBlur={handleBlur}
                        error={touched.description && errors.description}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="edit-coupon-detail">
                <GenericButton
                  grayButton
                  buttonText={ALL_TEXT.CANCEL}
                  onPress={handleClose}
                />
                <GenericButton
                  buttonText={ALL_TEXT.UPDATE}
                  onPress={handleSubmit}
                  loading={editCouponResponse.isFetching}
                />
              </div>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
}
