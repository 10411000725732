import React, { useState } from 'react';
import ApplicationContext from './context';
const ContextProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedPlanId, setSelectedPlanId] = useState(1);
  const [existingCards, setExistingCards] = useState([]);
  const [plansCost, setPlansCost] = useState(null);
  const [cartItems, setCartItems] = useState(null);
  const [notificationCount, setNotificationCount] = useState(0);
  return (
    <ApplicationContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        selectedCard,
        setSelectedCard,
        selectedPlanId,
        setSelectedPlanId,
        existingCards,
        setExistingCards,
        plansCost,
        setPlansCost,
        cartItems,
        setCartItems,
        notificationCount,
        setNotificationCount
      }}>
      {children}
    </ApplicationContext.Provider>
  );
};

export default ContextProvider;
