import React, {useState} from 'react';
import {
  CommissionSeachWithEmailAndDate,
  GenericTable,
} from '../../../../components';
import {Pagination} from '@mui/material';
import {tableHeading} from './table';
import {ViewDetails} from './view-details-popup';
import {useDispatch, useSelector} from 'react-redux';
import {
  setAddEditCommissionPopup,
  setViewDetails,
} from '../../../../redux/slice/commission-calculator';
import {AddEdiCommission} from './add-commission';

export function AgencyAgentSalesCalculator({
  data,
  dateRange,
  setDateRange,
  onDateChangeFilter,
  loader,
}) {
  const {list, currentPage: currentPageNumber, totalPages} = data || {};
  const [currentPage, setCurrentPage] = useState(currentPageNumber);
  const [email, setEmail] = useState('');

  const commissionPopup = useSelector(
    state => state?.commission?.viewDetailsPopup,
  );
  const commissionDetails = useSelector(
    state => state?.commission?.commissionDetails,
  );
  const addEditCommissionPopup = useSelector(
    state => state?.commission?.addEditCommissionPopup,
  );
  const dispatch = useDispatch();
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    onDateChangeFilter(null, null, null, value);
  };

  return (
    <div>
      <div className="filter-container">
        <CommissionSeachWithEmailAndDate
          fieldValue={email}
          setFieldValue={setEmail}
          dateRange={dateRange}
          setDateRange={setDateRange}
          onSubmitBtnPress={(email, date) => {
            onDateChangeFilter(email, date[0], date[1]);
          }}
        />
      </div>
      <div>
        <GenericTable loader={loader} headings={tableHeading()} data={list} />
      </div>
      {totalPages > 1 && (
        <div className="pagination-container">
          <div className="inner-pagination">
            <Pagination
              page={currentPage}
              color={'secondary'}
              onChange={handlePageChange}
              count={totalPages}
            />
          </div>
        </div>
      )}
      {commissionPopup && (
        <ViewDetails
          show={commissionPopup}
          data={commissionDetails}
          startDate={dateRange[0]}
          endDate={dateRange[1]}
          handleClose={() => {
            dispatch(setViewDetails(false));
          }}
        />
      )}
      {addEditCommissionPopup && (
        <AddEdiCommission
          handleClose={() => {
            dispatch(setAddEditCommissionPopup(false));
          }}
          show={addEditCommissionPopup}
          data={commissionDetails}
        />
      )}
    </div>
  );
}
