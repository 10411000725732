import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {ALL_TEXT, ENUMS} from '../../common/constants';
import {GrClose} from 'react-icons/gr';
import {colors, images} from '../../common';
import {Loader} from '../loader';
import {
  getInvoiceDetails,
  getSusbcriptionDetails,
} from '../../utils/rest-services';
import './styles.scss';
import 'bootstrap/dist/css/bootstrap.css';
import {Tooltip} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import {planCategory} from '../../utils/helpers';
import {TbInfoCircle} from 'react-icons/tb';

const InvoiceItem = ({
  name,
  deductible,
  cost,
  serviceTypeId,
  make,
  model,
  address,
}) => {
  return (
    <div className="invoice-item-view">
      <div className="plan-icon">
        <img src={planSelectorIcon(serviceTypeId)} alt="" />
      </div>
      <div className="invoice-detail-card">
        <div className="primary-card-container-invoice ">
          <span>{name}</span>
          {make && <span>{`Make: ${make}`} </span>}
          {model && <span>{`Model: ${model}`} </span>}
          {address && <span>{`Address: ${address}`} </span>}
        </div>
        <div className="secondary-card-container-invoice">
          <span>{`${
            ALL_TEXT.ERROR_PLACEHOLDERS.DEDUCTIBLES.charAt(0).toUpperCase() +
            ALL_TEXT.ERROR_PLACEHOLDERS.DEDUCTIBLES.slice(1)
          }: ${deductible}`}</span>
          <div>
            <span> {`${ALL_TEXT.COST}: ${cost}`} </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const planSelectorIcon = id => {
  switch (id) {
    case ENUMS.SERVICE_TYPE.VEHICLE:
      return images.vehicleV;
    case ENUMS.SERVICE_TYPE.HOME:
      return images.homeV;
    case ENUMS.SERVICE_TYPE.DEVICE:
      return images.devicesV;
    case ENUMS.SERVICE_TYPE.COMMERCIAL:
      return images.commercialV;
    case ENUMS.COVERAGE_TYPES.DISASTER:
      return images.disasterV;
  }
};

export function InvoiceAndSubscriptionDetails({
  show,
  handelClose,
  invoiceId,
  refundId,
  subscriptionId,
  signUpId,
}) {
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [invoiceItemList, setInvoiceItemList] = useState([]);
  // const [refundDetails, setRefundDetails] = useState({});
  // const [refundItemList, setRefundItemList] = useState([]);
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (invoiceId) {
      fetchInvoiceDetails();
    } else if (subscriptionId) {
      fetchSubscriptionDetails();
    } else {
      // fetchRefundDetails();
    }
  }, []);

  const fetchInvoiceDetails = async () => {
    setLoader(true);
    let response = await getInvoiceDetails(invoiceId, signUpId);
    if (response && response.success) {
      const {content} = response;
      setInvoiceDetails(content.invoice);
      setInvoiceItemList(content.invoiceItems);
      setLoader(false);
    }
  };
  const fetchSubscriptionDetails = async () => {
    setLoader(true);
    let response = await getSusbcriptionDetails(subscriptionId, signUpId);
    if (response && response.success) {
      const {content} = response;
      setSubscriptionDetails(content);
      setLoader(false);
    }
  };

  // const fetchRefundDetails = async () => {
  //   setLoader(true);
  //   let response = await getRefundById(refundId.split('_')[1]);
  //   if (response && response.data.success) {
  //     const {
  //       data: {
  //         content: {refund, items},
  //       },
  //     } = response;
  //     setLoader(false);
  //     setRefundDetails(refund);
  //     setRefundItemList(items);
  //   }
  // };

  const ItemText = ({keyy, value, isBadge, onClick, isCoppiedable}) => {
    return (
      <div
        onClick={isCoppiedable ? onClick : null}
        className={`balance-details`}>
        <div>
          <span>{keyy} </span>
          <span
            style={{cursor: isCoppiedable && 'pointer'}}
            data-tooltip-id={isCoppiedable && `id-tooltip-${keyy}`}
            data-tooltip-content={isCoppiedable && `Copy to Clipboard`}
            className={`${
              isBadge ? `${planCategory(value)} invoice-plan-type-badge` : ''
            }`}>
            {value}
            {isCoppiedable && <Tooltip id={`id-tooltip-${keyy}`} />}
          </span>
        </div>
      </div>
    );
  };
  const getInvoiceStatus = (status, text) => {
    if (status === 'False') {
      return (
        <span class="badge rounded-pill bg-warning text-light">
          {ENUMS.INVOICES.UPCOMING.LABEL}
        </span>
      );
    } else if (status === false) {
      return (
        <span class="badge rounded-pill bg-danger text-light">{text}</span>
      );
    } else if (status === 'succeeded') {
      return <span class="badge rounded-pill bg-success">{text}</span>;
    } else if (status === 'failed') {
      return (
        <span class="badge rounded-pill bg-danger text-light">
          {ENUMS.INVOICES.FAILED.LABEL}
        </span>
      );
    } else if (status === 'pending') {
      return (
        <span class="badge rounded-pill bg-warning">
          {ENUMS.CLAIMS.STAND.PENDING.LABEL}
        </span>
      );
    } else if (status === true) {
      return (
        <span class="badge rounded-pill bg-success center-align">{text}</span>
      );
    } else {
      return <span class="badge rounded-pill bg-success">{text}</span>;
    }
  };

  const getPopupTitle = () => {
    if (invoiceId) {
      return ALL_TEXT.INVOICE_DETAILS;
    } else if (subscriptionId) {
      return ALL_TEXT.SUBSCRIPTION_DETAILS;
    } else return '';
  };

  const getDiscountValue = () => {
    if (invoiceDetails?.discountAmount) {
      return `$${parseFloat(invoiceDetails?.discountAmount).toFixed(2)}`;
    } else if (invoiceDetails?.discountPercent) {
      return `${invoiceDetails?.discountPercent}%`;
    } else {
      return 'No Discount';
    }
  };

  invoiceDetails.taxAmount;
  const invoiceDetailsItems = [
    {
      key: ALL_TEXT.SUBSCRIPTION_ID,
      value: invoiceDetails?.viewSubId,
    },
    {
      key: ALL_TEXT.INVOICE_ID,
      value: invoiceDetails?.viewInvoiceId,
    },
    // {
    //   key: ALL_TEXT.PREVIOUSE_BALANCE,
    //   value: invoiceDetails?.previousBalance,
    // },
    // {
    //   key: ALL_TEXT.CURRENT_BALANCE,
    //   value: invoiceDetails?.currentBalance,
    // },
    {
      key: ALL_TEXT.DUE_DATE,
      value: invoiceDetails.dueOn?.split(' ')[0],
    },
    {
      key: ALL_TEXT.PAID_DATE,
      value: invoiceDetails.datePaid?.split(' ')[0],
    },
    {
      key: (
        <span>
          <Tooltip id={`subtotal`} />
          {ALL_TEXT.SUBTOTAL}
          <TbInfoCircle
            data-tooltip-id={`subtotal`}
            data-tooltip-content={`$1 Processing Fee`}
          />
        </span>
      ),
      value: `$${parseFloat(invoiceDetails.subTotal).toFixed(2)}`,
    },
    {
      key: 'Discount',
      value: getDiscountValue(),
    },
    {
      key: ALL_TEXT.SUBTOTAL_AFTER_DISCOUNT,
      value: `$${parseFloat(invoiceDetails?.subtotalAfterDiscount).toFixed(2)}`,
    },
    {
      key: (
        <span>
          <Tooltip id={`taxes-and-otherFee`} />
          {ALL_TEXT.TAXES_AND_OTHER_FEE}
          <TbInfoCircle
            data-tooltip-id={`taxes-and-otherFee`}
            data-tooltip-content={` ${
              invoiceDetails.taxPercent === 0 ? 2.9 : invoiceDetails.taxPercent
            }% Sales Tax`}
          />
        </span>
      ),
      value: `$${invoiceDetails.taxAmount}`,
    },
  ];

  const subscriptionDetailsItem = [
    {
      key: ALL_TEXT.SUBSCRIPTION_ID,
      value: subscriptionDetails.viewSubscriptionId,
    },
    {
      key: ALL_TEXT.SUBSCRIPTION_TYPE,
      value: subscriptionDetails.subscriptionType,
    },
    {
      key: ALL_TEXT.CREATED_DATE,
      value: subscriptionDetails.createdDate?.split('T')[0],
    },
    {
      key: (
        <span>
          <Tooltip id={`subtotal`} />
          {ALL_TEXT.SUBSCRIPTION_DETAILS_.SUBTOTAL}
          <TbInfoCircle
            data-tooltip-id={`subtotal`}
            data-tooltip-content={`$1 Processing Fee`}
          />
        </span>
      ),

      value: `$${parseFloat(subscriptionDetails.subscriptionSubtotal).toFixed(
        2,
      )}`,
    },

    {
      key: ALL_TEXT.SUBSCRIPTION_DETAILS_.DISCOUNT,

      value: `${subscriptionDetails.discount}`,
    },
    {
      key: ALL_TEXT.SUBSCRIPTION_DETAILS_.SUBTOTAL_AFTER_DISCOUNT,

      value: `$${parseFloat(subscriptionDetails.subtotalAfterDiscount).toFixed(
        2,
      )}`,
    },
    {
      key: (
        <span>
          <Tooltip id={`taxes-and-otherFee`} />
          {ALL_TEXT.TAXES_AND_OTHER_FEE}
          <TbInfoCircle
            data-tooltip-id={`taxes-and-otherFee`}
            data-tooltip-content={` ${
              subscriptionDetails.taxPercent === 0
                ? 2.9
                : subscriptionDetails.taxPercent
            }% Sales Tax`}
          />
        </span>
      ),
      value: `$${parseFloat(subscriptionDetails.taxAmount).toFixed(2)}`,
    },
    {
      key: ALL_TEXT.AMOUNT,
      value: `$${parseFloat(subscriptionDetails.subscriptionTotal).toFixed(2)}`,
    },
  ];

  // const refundDetailsItem = [
  //   {
  //     key: allCommonText.labels.subscriptionId,
  //     value: refundDetails.viewSubscriptionId,
  //   },
  //   {
  //     key: allCommonText.labels.refundID,
  //     value: refundDetails.refundId,
  //   },
  //   {
  //     key: allCommonText.labels.refundDate,
  //     value: refundDetails.refundDate,
  //   },
  //   {
  //     key: allCommonText.labels.subscriptionType,
  //     value: refundDetails.subscriptionType,
  //   },
  //   {
  //     key: allCommonText.labels.refundType,
  //     value: refundDetails.refundType,
  //   },
  // ];
  return (
    <Modal centered show={show} size={'lg'} onHide={handelClose}>
      <Modal.Body className="modal-body-invoice">
        {loader ? (
          <div className="loader-container-invoice">
            <Loader />
          </div>
        ) : (
          <>
            <div className="top-add-payment-container">
              <span>{getPopupTitle()}</span>
              <GrClose
                className="add-payment-close-btn"
                onClick={handelClose}
                size={18}
                color={colors.primary}
              />
            </div>
            <div className="invoice-details-container">
              <div
                className={`invoice-top-detail-title ${
                  (subscriptionId && 'badge-center') ||
                  (refundId && 'badge-center')
                }`}>
                {invoiceId && (
                  <span>{`${invoiceDetails.firstName} ${invoiceDetails.lastName}`}</span>
                )}
                {invoiceId &&
                  getInvoiceStatus(
                    invoiceDetails.isPaid,
                    invoiceDetails.isPaid
                      ? ENUMS.INVOICES.PAID.LABEL
                      : ENUMS.INVOICES.UPCOMING.LABEL,
                  )}
                {subscriptionId &&
                  getInvoiceStatus(
                    subscriptionDetails.isActive,
                    subscriptionDetails.isActive
                      ? ALL_TEXT.ACTIVE
                      : ALL_TEXT.CANCELLED,
                  )}
                {/*  {refundId &&
                  getInvoiceStatus(
                    refundDetails.refundStatus,
                    refundDetails.refundStatus,
                  )} */}
              </div>

              {invoiceId &&
                invoiceDetailsItems.length > 0 &&
                invoiceDetailsItems.map(i => (
                  <ItemText
                    key={i}
                    keyy={i.key}
                    isCoppiedable={
                      i.key == ALL_TEXT.INVOICE_ID ||
                      i.key == ALL_TEXT.SUBSCRIPTION_ID
                    }
                    onClick={() => {
                      navigator.clipboard.writeText(i.value);
                    }}
                    isBadge={
                      subscriptionDetails.subscriptionType ? true : false
                    }
                    value={i.value}
                  />
                ))}
              {subscriptionId &&
                subscriptionDetailsItem.map(i => (
                  <ItemText
                    key={i}
                    keyy={i.key}
                    isCoppiedable={i.key == ALL_TEXT.SUBSCRIPTION_ID}
                    onClick={() => {
                      navigator.clipboard.writeText(i.value);
                    }}
                    isBadge={
                      i.key === ALL_TEXT.SUBSCRIPTION_TYPE &&
                      subscriptionDetails.subscriptionType
                        ? true
                        : false
                    }
                    value={i.value}
                  />
                ))}
              {/* {refundId &&
                refundDetailsItem.map(i => (
                  <ItemText
                    key={i}
                    keyy={i.key}
                    isCoppiedable={
                      i.key == allCommonText.labels.subscriptionId ||
                      i.key == allCommonText.labels.refundID
                    }
                    onClick={() => {
                      navigator.clipboard.writeText(i.value);
                    }}
                    isBadge={
                      i.key === allCommonText.labels.subscriptionType &&
                      refundDetails.subscriptionType
                        ? true
                        : false
                    }
                    value={i.value}
                  />
                ))} */}
              {invoiceId && (
                <div className="total-balance-invoice">
                  <div>
                    <span>{ALL_TEXT.AMOUNT} </span>
                    <span>{invoiceDetails.cost} </span>
                  </div>
                </div>
              )}
              {/* {refundId && (
                <div className="total-balance-invoice">
                  <div>
                    <span>{allCommonText.labels.amount} </span>
                    <span>{refundDetails.refundAmount} </span>
                  </div>
                </div>
              )} */}
            </div>
            {subscriptionDetails?.items?.length > 0 && subscriptionId && (
              <div className="invoice-container-v2">
                <span className="invoice-title">
                  {ALL_TEXT.SUBSCRIPTION_ITEM}
                </span>
                <div className="invoice-item-details">
                  {subscriptionDetails?.items?.length > 0 &&
                    subscriptionDetails?.items?.map(i => (
                      <InvoiceItem
                        key={i}
                        make={i?.make}
                        model={i?.model}
                        serviceTypeId={i.serviceTypeId}
                        address={i?.address}
                        cost={`$${parseFloat(i.cost).toFixed(2)}`}
                        deductible={`$${parseFloat(i.deductibleAmount).toFixed(
                          2,
                        )}`}
                        name={i.providerName}
                      />
                    ))}
                </div>
              </div>
            )}
            {invoiceId && invoiceItemList.length > 0 && (
              <div className="invoice-container-v2">
                <span className="invoice-title">{ALL_TEXT.INVOICE_ITEMS}</span>
                <div className="invoice-item-details">
                  {invoiceItemList?.map(i => (
                    <InvoiceItem
                      key={i}
                      make={i?.make}
                      model={i?.model}
                      serviceTypeId={i.serviceTypeId}
                      address={i?.address}
                      cost={`$${parseFloat(i.cost).toFixed(2)}`}
                      deductible={`$${parseFloat(i.deductibleAmount).toFixed(
                        2,
                      )}`}
                      name={i.providerName}
                    />
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
