import React from 'react';
import './styles.scss';
import {Modal} from 'react-bootstrap';
import {ALL_TEXT} from '../../common';
import {GenericButton} from '../generic-button';
import {ChooseFile} from '../choose-file';
import {Loader} from '../loader';

export function ImportDisasterAreaModal({
  show,
  handleClose,
  onChangeHandler,
  file,
  onUploadButtonPress,
  btnLoading,
  disbaleButton,
}) {
  return (
    <>
      <Modal
        show={show}
        size="md"
        centered
        className="import-disaster-area"
        onHide={handleClose}>
        <Modal.Header closeButton>
          <h5>{ALL_TEXT.DISASTER.IMPORT_DISASTER_AREAS}</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="select-file-container">
            {btnLoading ? (
              <div className="select-file-loader">
                <Loader size={30} />
                <p>{ALL_TEXT.DISASTER.FETCHING}</p>
              </div>
            ) : (
              <ChooseFile
                files={file}
                onRemoveFileHandler={() => {
                  onChangeHandler([]);
                }}
                onChangeHandler={file => {
                  onChangeHandler([file]);
                }}
                customTitle={ALL_TEXT.DISASTER.SELECT_FILE}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <GenericButton
            buttonText={ALL_TEXT.DISASTER.IMPORT}
            onPress={onUploadButtonPress}
            loading={btnLoading}
            disable={disbaleButton}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}
