import React from 'react';
import {CellValue} from '../../../components';
import {getEmailLogStatusIcon} from '../../../utils/helpers';
import {QUERYPARAM, images} from '../../../common';
import {useDispatch} from 'react-redux';
import {
  setIsDeviceTokenPopUp,
  setNotificationPopUp,
  setSetlectedNotificationData,
} from '../../../redux/slice/logs';
import {useNavigate} from 'react-router';
import {path} from '../../../common/routesNames';

export function TableHeading() {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleRedirect = row => {
    const isTargetPage = window.location.pathname === path.notificationLogs;
    if (row.roleId !== 1) {
      if (isTargetPage) {
        if (row.signupId === 0 && row.employeeId !== 0) {
          navigate({
            pathname: path.staffMaintainance,
            search: `?${QUERYPARAM.SIGNUPID}=${row.userEmail}`,
          });
        }
        else if (row.signupId !== 0 && row.employeeId === 0) {
          navigate({
            pathname: path.customerProfile,
            search: `?${QUERYPARAM.SIGNUPID}=${row.userEmail}`,
          });
        }
        else if (row.signupId === 0 && row.employeeId === 0) {
          navigate({
          });
        }
      }
    }
  };
  return [
    {
      title: 'User',
      fieldName: 'userEmail',
      dataformat: (cell, row) => {
        return (
          <div className="email-logs-email">
            <p onClick={() => handleRedirect(row)}>{row.userName}</p>
            <CellValue cell={cell} row={row} />
          </div>
        );
      },
    },
    {
      title: 'Module',
      fieldName: 'moduleAction',
      dataformat: (cell, row) => (
        <CellValue cell={cell !== null ? cell : 'N/A'} row={row} />
      ),
    },
    {
      title: 'Sent by',
      fieldName: 'sentBy',
      dataformat: (cell, row) => (
        <CellValue cell={cell !== null ? cell : 'N/A'} row={row} />
      ),
    },
    {
      title: 'Sent to Web',
      fieldName: 'sendToWeb',
      dataformat: cell => {
        const status = cell;
        const icon = getEmailLogStatusIcon(status);
        return <div>{icon}</div>;
      },
    },
    {
      title: 'Send to Mobile',
      fieldName: 'sendToMobile',
      dataformat: cell => {
        const status = cell;
        const icon = getEmailLogStatusIcon(status);

        return <div>{icon}</div>;
      },
    },
    {
      title: 'Sent Date',
      fieldName: 'sentDate',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Sent Time',
      fieldName: 'sentTime',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Last Login',
      fieldName: 'lastLogin',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
      // dataformat: (cell, row) => {
      //   const [datePart, timePart] = cell.split('');
      //   const formattedDate = datePart.replace(/\//g, '-');
      //   const formattedDateTime = `${formattedDate} ${timePart}`;
      //   return <CellValue cell={formattedDateTime} row={row} />;
      // },
    },
    {
      title: 'Actions',
      fieldName: 'firstName',
      dataformat: (cell, row) => (
        <div className="notfication-icon-action">
          <span className="action-icons-staff">
            <span
              className="search-icon-"
              onClick={() => {
                dispatch(setIsDeviceTokenPopUp(true));
                dispatch(setSetlectedNotificationData(row));
              }}>
              <img src={images.mobileToken} />
            </span>
          </span>
          <span className="action-icons-staff">
            <span
              className="search-icon-"
              onClick={() => {
                dispatch(setNotificationPopUp(true));
                dispatch(setSetlectedNotificationData(row));
              }}>
              <img src={images.searchIcon} />
            </span>
          </span>
        </div>
      ),
    },
  ];
}
