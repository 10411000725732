import React, {useState, useEffect} from 'react';
import './styles.scss';
import {ALL_TEXT, ENUMS} from '../../common';
import {CustomizedSearch, GenericTable} from '../../components';
import {TableSubscription} from './table.js';
import Pagination from '@mui/material/Pagination';
import {usePostApiAdminGetAllSubscriptionsMutation} from '../../redux/slice/nodukAdmin.ts';
import {getValuesFromEnumsObj} from '../../utils/helpers';
// import {getValuesFromEnumsObj} from '../../utils/helpers';

function Susbcriptions() {
  const [btnLoader, setBtnLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedValue, setSelectedValue] = useState({
    type: 0,
    status: 0,
    email: '',
  });
  // const [selectedType, setSelectedType] = useState(0);
  // const [selectedStatus, setSelectedStatus] = useState(0);
  const [getAllSubscriptionsAPI, getAllSubscriptionsResponse] =
    usePostApiAdminGetAllSubscriptionsMutation();

  useEffect(() => {
    fetchSubscriptionList({
      adminSubscriptions: {
        email: selectedValue.email,
        pageNo: currentPage,
        pageSize: 10,
        type: selectedValue.type,
        subsId: 0,
        status: selectedValue.status,
      },
    });
  }, [currentPage]);

  const fetchSubscriptionList = async payload => {
    let result = await getAllSubscriptionsAPI(payload);
    if (result && result.data.success)
      setTotalPages(result?.data?.content?.totalPages);
  };

  const subscriptionList = getAllSubscriptionsResponse?.data?.content.list;

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const subscriptionSearchhandler = async (
    number,
    email,
    type,
    status,
    clear,
  ) => {
    let payload;
    if (clear) {
      payload = {
        adminSubscriptions: {
          email: '',
          pageNo: 1,
          pageSize: 10,
          type: 0,
          subsId: 0,
          status: 0,
        },
      };
      setSelectedValue({
        type: 0,
        status: 0,
        email: '',
      });
    } else {
      setSelectedValue({
        type: getValuesFromEnumsObj(ENUMS.SUBSCRIPTION_TYPES, type),
        status: getValuesFromEnumsObj(ENUMS.SUBSCRIPTION, status),
        email: email,
      });
      payload = {
        adminSubscriptions: {
          email: email,
          subsId: number || 0,
          pageNo: 1,
          pageSize: 10,
          type: getValuesFromEnumsObj(ENUMS.SUBSCRIPTION_TYPES, type),
          status: getValuesFromEnumsObj(ENUMS.SUBSCRIPTION, status),
        },
      };
    }
    setCurrentPage(1);
    setBtnLoader(true);
    await fetchSubscriptionList(payload);
    setBtnLoader(false);
  };
  return (
    <div className="subscriptions-main-container">
      <div className="title-container-subscriptions">
        <span>{ALL_TEXT.SUBSCRIPTIONS}</span>
      </div>
      <div className="subscriptions-search-bar">
        <CustomizedSearch
          btnLoader={btnLoader}
          title={'Search Subscription By'}
          firstLabel={'Subscription Id'}
          firstPlaceholder={'Enter Id'}
          isFirstInputNumeric
          firstPrefixerValue={'sub_'}
          secondInput
          secondLabel={'Customer Email'}
          secondPlaceholder={'Enter Customer Email'}
          firstDropdown
          firstDropdownLabel={'Subscription Type'}
          firstDropdownList={[
            ENUMS.SUBSCRIPTION_TYPES.ALL.LABEL,
            ENUMS.SUBSCRIPTION_TYPES.MONTHLY.LABEL,
            ENUMS.SUBSCRIPTION_TYPES.BIANNUAL.LABEL,
            ENUMS.SUBSCRIPTION_TYPES.ANNUAL.LABEL,
          ]}
          secondDropdown
          secondDropdownLabel={'Status'}
          seconddropdownlist={[
            ENUMS.SUBSCRIPTION.ACTIVE.LABEL,
            ENUMS.SUBSCRIPTION.CANCELLED.LABEL,
            ENUMS.SUBSCRIPTION.ALL.LABEL,
          ]}
          onClearFilterHandler={() => {
            subscriptionSearchhandler('', '', '', '', true);
          }}
          onSearchBtnPress={(
            subscriptionId,
            email,
            nothing,
            subscriptionType,
            status,
          ) => {
            subscriptionSearchhandler(
              subscriptionId,
              email,
              subscriptionType,
              status,
            );
          }}
        />
      </div>
      <div className="table-subscriptions-container">
        <GenericTable
          loader={getAllSubscriptionsResponse.isLoading}
          headings={TableSubscription()}
          data={subscriptionList}
        />
      </div>
      {totalPages > 1 && (
        <div className="pagination-container">
          <div className="inner-pagination">
            <Pagination
              page={currentPage}
              color={'secondary'}
              onChange={handlePageChange}
              count={totalPages}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Susbcriptions;
