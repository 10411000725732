import React, {useState, useEffect} from 'react';
import '../add-policy/styles.scss';
import {
  GenericButton,
  GenericModal,
  InputField,
  Loader,
  PlanCard,
} from '../../components';
import {Grid} from '@mui/material';
import {ALL_TEXT, ENUMS, QUERYPARAM, colors} from '../../common';
import {path} from '../../common/routesNames';
import {useNavigate} from 'react-router';
import {AddPaymentMethod} from '../../components/add-payment-method';
// import {useStripe} from '@stripe/react-stripe-js';
import {AiFillCheckCircle} from 'react-icons/ai';
// import {AddStripeSubId, CreateSubscription} from '../../utils/rest-services';
import AddPolicyMainLayout from '../add-policy-main-layout';
import {
  getBillingCards,
  getCheckoutCompleteObject,
} from '../../utils/rest-services';
import {useDispatch, useSelector} from 'react-redux';
import {HiOutlineInformationCircle} from 'react-icons/hi2';
import {Tooltip} from 'react-tooltip';
import {openModal, setModalDetails} from '../../utils/store/slices/popup-modal';
import {
  setCouponCodeText,
  setDiscounts,
  setPromoCode,
  setSelectedPlanID,
  setPaymentdetails,
  setDuration,
  setTaxPercentage,
  setProcessingFee,
  setCartItems,
  setMonthlyPlanData,
  setBiannualPlanData,
  setAnnualPlanData,
  setSelectedPlanRecords,
  setTotalDeductibles,
} from '../../redux/slice/checkout';
import './style.scss';
import {NodukAdmnApi} from '../../redux/slice/nodukAdmin.ts';
import {CgCloseO} from 'react-icons/cg';
import _ from 'lodash';

const MONTHLY_PLAN_ID = 1;
const BIANUAL_PLAN_ID = 2;
const ANNUAL_PLAN_ID = 3;

const SelectPaymentMethod = () => {
  const {useLazyGetApiAdminCustomerApplyCouponCodeQuery} = NodukAdmnApi;
  const [addPaymentMethodPopUp, setAddPaymentMethodPopUp] = useState(false);
  const [existingCards, setExistingCards] = useState([]);
  const [btnLoader] = useState(false);
  const [loader, setLoader] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [popUpResponse, setPopupResponse] = useState({
    type: 1,
    detail: '',
    title: '',
  });
  const COUPON_ERROR = 'Coupon Error';
  // const {setSelectedPlanId} = useContext(ApplicationContext);
  const [getPromoInfoAPI, getPromoInfoResponse] =
    useLazyGetApiAdminCustomerApplyCouponCodeQuery();
  // const selectedPlan = useSelector(state => {
  //   return state.checkout;
  // });
  const promoCode = useSelector(state => {
    return state.checkout.promoCode;
  });
  const couponCodeText = useSelector(state => {
    return state.checkout.couponCodeText;
  });
  // const discounts = useSelector(state => {
  //   return state.checkout.discounts;
  // });
  const selectedCard = useSelector(state => {
    return state.checkout.selectedCard;
  });
  const selectedPlanId = useSelector(state => {
    return state.checkout.selectedPlanId;
  });
  const paymentdetails = useSelector(state => {
    return state.checkout.paymentdetails;
  });
  // const minimumDiscountAmount = useSelector(state => {
  //   return state.checkout.minimumDiscountAmount;
  // });
  // const duration = useSelector(state => {
  //   return state.checkout.duration;
  // });
  const signupId = useSelector(
    state => state.checkout.selectedUser?.signupId ?? null,
  );

  // New States
  const monthlyPlanData = useSelector(
    state => state.checkout?.monthlyPlanData ?? null,
  );
  const biAnnualPlanData = useSelector(
    state => state.checkout?.biAnnualPlanData ?? null,
  );
  const annualPlanData = useSelector(
    state => state.checkout.annualPlanData ?? null,
  );
  const processingFee = useSelector(
    state => state.checkout.processingFee ?? null,
  );
  const selectedPlanRecords = useSelector(
    state => state.checkout.selectedPlanRecords ?? null,
  );

  // const taxPercent = useSelector(state => state.checkout.taxPercent ?? null);

  const getParamFromUrl = () => {
    return new URLSearchParams(location.search);
  };
  let query = getParamFromUrl();
  const id = query.get(QUERYPARAM.SIGNUPID);
  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (signupId || id) {
      getPaymentDetails(signupId || id);
      getBillingCardsHandler(signupId || id);
    } else {
      navigate(
        {pathname: path.addPolicy, search: location.search},
        {replace: true},
      ); // if user refresh the page it redirect to add-policy page
    }
  }, []);

  const setPlanAmountsHandler = (result, firstTimeRender) => {
    var monthlyPlan;
    var biannualPlan;
    var annualPlan;
    const plans = result?.content?.plans || [];
    if (plans && plans.length > 0) {
      monthlyPlan = _.find(plans, {planType: MONTHLY_PLAN_ID});
      biannualPlan = _.find(plans, {planType: BIANUAL_PLAN_ID});
      annualPlan = _.find(plans, {planType: ANNUAL_PLAN_ID});
    }
    var selectedPlan;
    switch (selectedPlanId) {
      case MONTHLY_PLAN_ID:
        selectedPlan = monthlyPlan;
        break;
      case BIANUAL_PLAN_ID:
        selectedPlan = biannualPlan;
        break;
      case ANNUAL_PLAN_ID:
        selectedPlan = annualPlan;
        break;
    }
    if (result) {
      const {content} = result || {};
      const {processingFee, salesTax, cartItems, totals} = content || {};

      if (!firstTimeRender) {
        const minimumDiscountAmount =
          selectedPlan?.appliedDiscount?.minimumDiscountAmount;
        const planAmount = selectedPlanRecords?.totalTaxedInvoiceAmount;
        if (planAmount < minimumDiscountAmount) {
          setPopupResponse({
            type: ENUMS.MODAL_TYPES.ERROR,
            detail: `Can not apply coupon minimum payable amount is $${minimumDiscountAmount}`,
          });
          setShowModal(true);

          return;
        }
      }
      firstTimeRender && dispatch(setPaymentdetails(content));
      !firstTimeRender && dispatch(setCouponCodeText(promoCode));

      dispatch(setTaxPercentage(salesTax));
      dispatch(setProcessingFee(processingFee));
      dispatch(setTotalDeductibles(totals?.totalDeductibles));
      if (cartItems) {
        dispatch(setCartItems(cartItems));
      }
      if (plans && plans?.length > 0) {
        dispatch(setMonthlyPlanData(monthlyPlan));
        dispatch(setBiannualPlanData(biannualPlan));
        dispatch(setAnnualPlanData(annualPlan));
        // set Default Plan Monthly as selected

        if (firstTimeRender) {
          dispatch(setSelectedPlanRecords(monthlyPlan));
        } else {
          dispatch(setSelectedPlanRecords(selectedPlan));
        }
      }
    }
  };

  const getPaymentDetails = async signupId => {
    setLoader(true);
    let response = await getCheckoutCompleteObject(signupId); // will be handel in future
    setLoader(false);
    if (response && !response?.data?.success) {
      setPlanAmountsHandler(response, true);
      dispatch(setPromoCode(''));
      dispatch(setDuration(''));
      dispatch(setSelectedPlanID({id: MONTHLY_PLAN_ID}));
      dispatch(setCouponCodeText(''));
      dispatch(
        setDiscounts({
          amount: 0,
          percentage: 0,
        }),
      );
    } else {
      setPopupResponse({
        type: ENUMS.MODAL_TYPES.ERROR,
        detail: ALL_TEXT.CHECKOUT_ERROR,
      });
      setShowModal(true);
    }

    // Commented OLD LOGIC
    // if (response && response.success) {
    //   dispatch(setPaymentdetails(response.content));
    //   if (selectedPlan?.totalCost == '' || !selectedPlan?.totalCost) {
    //     dispatch(setTotalCost(response?.content?.monthlyCost));
    //     dispatch(
    //       setSelectedPlanID({
    //         id: ENUMS.PLANS_ID.MONTHLY,
    //         cost: response?.content?.monthlyCost,
    //       }),
    //     );
    //   } else {
    //     const currentRecurring = selectDefaultPlanHandler(response.content);
    //     onCardChangeDiscountHandler(currentRecurring);
    //   }
    // }
  };

  // const selectDefaultPlanHandler = planCost => {
  //   if (selectedPlan.selectedPlanId == ENUMS.PLANS_ID.MONTHLY) {
  //     dispatch(
  //       setSelectedPlanID({
  //         id: ENUMS.PLANS_ID.MONTHLY,
  //         cost: planCost?.monthlyCost,
  //       }),
  //     );
  //     return planCost?.monthlyCost;
  //   } else if (selectedPlan.selectedPlanId == ENUMS.PLANS_ID.BIANNUAL) {
  //     dispatch(
  //       setSelectedPlanID({
  //         id: ENUMS.PLANS_ID.BIANNUAL,
  //         cost: planCost?.biAnnualCost,
  //       }),
  //     );
  //     return planCost?.biAnnualCost;
  //   } else {
  //     dispatch(
  //       setSelectedPlanID({
  //         id: ENUMS.PLANS_ID.ANNUAL,
  //         cost: planCost?.annualCost,
  //       }),
  //     );
  //   }
  //   return planCost?.annualCost;
  // };

  const getBillingCardsHandler = async signupId => {
    let response = await getBillingCards(signupId); // will be hjandle in future
    if (response && response.success) {
      setExistingCards(response.content);
    } else {
      dispatch(setModalDetails({type: ENUMS.MODAL_TYPES.ERROR}));
      dispatch(openModal());
    }
  };
  // const applyCouponCode_ = async () => {
  //   let response = await getPromoInfoAPI({
  //     signupId: signupId || id,
  //     couponCode: promoCode,
  //   });
  //   if (response && response?.data?.success) {
  //     const {
  //       data: {
  //         content: {
  //           discountPercent,
  //           discountAmount,
  //           minimumDiscountAmount,
  //           duration,
  //           promoCode,
  //           couponType,
  //         },
  //       },
  //     } = response || {};

  //     // remove percentage coipon code
  //     if (
  //       selectedPlan.selectedPlanId !== ENUMS.PLANS_ID.MONTHLY &&
  //       discountPercent
  //     ) {
  //       removeCouponIfPercentageDiscountOtherThenMonthlyPlan();
  //       return;
  //     }
  //     dispatch(setCouponType(couponType));
  //     dispatch(setMinimumDiscountAmount(minimumDiscountAmount));
  //     const totalAmount = parseFloat(
  //       selectedPlan?.totalCost?.replace(/\$|,/g, ''),
  //     );
  //     if (totalAmount < parseFloat(minimumDiscountAmount)) {
  //       dispatch(setPromoCode(''));
  //       dispatch(setDuration(''));
  //       dispatch(
  //         setModalDetails({
  //           type: ENUMS.POPUP_ENUMS.ERROR,
  //           details: ALL_TEXT.COUPON_NOT_VALID,
  //         }),
  //       );
  //       dispatch(openModal());
  //       return;
  //     } else {
  //       const totalCost = parseFloat();
  //       // getTotalCost(parseFloat(discountPercent) / 100, discountAmount),
  //       if (totalCost < 1) {
  //         setPopupResponse({
  //           type: ENUMS.POPUP_ENUMS.ERROR,
  //           detail: ALL_TEXT.COUPON_GREATER_THAN,
  //           title: COUPON_ERROR,
  //         });
  //         setShowModal(true);
  //         dispatch(setPromoCode(''));
  //         return;
  //       }
  //       dispatch(setDuration(duration));
  //     }
  //     dispatch(setCouponCodeText(promoCode));
  //     if (discountPercent) {
  //       // handel percentage of discount with total amount
  //       const percentage = parseFloat(discountPercent) / 100;
  //       dispatch(
  //         setDiscounts({
  //           percentage: percentage,
  //         }),
  //       );
  //     } else {
  //       // handel discount amount with total amount
  //       dispatch(
  //         setDiscounts({
  //           amount: discountAmount,
  //         }),
  //       );
  //     }
  //   } else {
  //     dispatch(
  //       setModalDetails({
  //         type: ENUMS.POPUP_ENUMS.ERROR,
  //         details: response?.data?.description,
  //       }),
  //     );
  //     dispatch(openModal());
  //   }
  // };

  // const getTotalCost = (percentage, amount) => {
  //   // const totalCost = parseFloat(selectedPlan?.totalCost.split('$')[1]);
  //   const totalCost = getPlansCostWithoutDiscount(
  //     selectedPlan?.selectedPlanId,
  //   )?.replace(/\$|,/g, '');
  //   if (percentage) {
  //     return `${(totalCost - parseFloat(percentage) * totalCost).toFixed(2)}`;
  //   } else if (amount) {
  //     return `${(totalCost - parseFloat(amount)).toFixed(2)}`;
  //   } else {
  //     return selectedPlan?.totalCost;
  //   }
  // };
  // const removeCouponIfPercentageDiscountOtherThenMonthlyPlan = () => {
  //   removeCouponHandler();
  //   dispatch(
  //     setModalDetails({
  //       type: ENUMS.POPUP_ENUMS.ALERT,
  //       details: ALL_TEXT.COUPON_DISCOUNT_MONTHLY,
  //       title: COUPON_ERROR,
  //     }),
  //   );
  //   dispatch(openModal());
  // };
  // const getTotalVisible = incre => {
  //   // const totalCost = parseFloat(selectedPlan?.totalCost.split('$')[1]);
  //   const totalCost = getPlansCostWithoutDiscount(
  //     selectedPlan?.selectedPlanId,
  //   )?.replace(/\$|,/g, '');
  //   let monthlyCost = getPlansCostWithoutDiscount(ENUMS.PLANS_ID.MONTHLY);
  //   monthlyCost = monthlyCost?.replace(/\$|,/g, '');
  //   if (discounts?.percentage) {
  //     return `$${(totalCost - discounts?.percentage * totalCost).toFixed(2)}`;
  //   } else if (discounts?.amount) {
  //     return `$${(totalCost - discounts?.amount).toFixed(2)}`;
  //   } else if (duration === FIRST_INVOICE) {
  //     if (incre) {
  //       return `$${(totalCost - monthlyCost + 1).toFixed(2)}`;
  //     } else {
  //       return `$${(totalCost - monthlyCost).toFixed(2)}`;
  //     }
  //   } else {
  //     return selectedPlan?.totalCost;
  //   }
  // };

  // const incrementByOneTotalVisible = () => {
  //   // const totalCost = parseFloat(selectedPlan?.totalCost.split('$')[1]);
  //   let totalCost = getPlansCostWithoutDiscount(selectedPlan?.selectedPlanId);
  //   totalCost = totalCost?.replace(/\$|,/g, '');
  //   if (discounts?.percentage) {
  //     return `$${(totalCost - discounts?.percentage * totalCost + 1).toFixed(
  //       2,
  //     )}`;
  //   } else if (discounts?.amount) {
  //     return `$${(totalCost - discounts?.amount + 1).toFixed(2)}`;
  //   } else if (duration === FIRST_INVOICE) {
  //     return getTotalVisible(true);
  //   } else {
  //     let cost = (
  //       parseFloat(selectedPlan?.totalCost?.replace(/\$|,/g, '')) + 1
  //     ).toFixed(2);
  //     return `$${cost}`;
  //   }
  // };
  // const getDiscountVisible = () => {
  //   if (discounts?.percentage) {
  //     return `${discounts?.percentage * 100}%`;
  //   } else if (discounts?.amount) {
  //     return `$${discounts?.amount}`;
  //   } else if (duration === FIRST_INVOICE) return `100%  (first month only)`;
  //   else {
  //     return getDiscountOnPlans();
  //   }
  // };
  // const getDiscountOnPlans = () => {
  //   if (selectedPlanId == ENUMS.PLANS_ID.MONTHLY) {
  //     return ALL_TEXT.DISCOUNTS_PLANS.MONTHLY;
  //   } else if (selectedPlanId == ENUMS.PLANS_ID.BIANNUAL) {
  //     return ALL_TEXT.DISCOUNTS_PLANS.BIANNUAL;
  //   } else {
  //     return ALL_TEXT.DISCOUNTS_PLANS.ANNUALLY;
  //   }
  // };
  // const getPlansCostWithoutDiscount = selectedPlanId => {
  //   if (selectedPlanId == ENUMS.PLANS_ID.MONTHLY) {
  //     return paymentdetails?.monthlyCost;
  //   } else if (selectedPlanId == ENUMS.PLANS_ID.BIANNUAL) {
  //     return `$${(
  //       parseFloat(paymentdetails?.monthlyCost?.replace(/\$|,/g, '')) * 6
  //     ).toFixed(2)}`;
  //   } else {
  //     return `$${(
  //       parseFloat(paymentdetails?.monthlyCost?.replace(/\$|,/g, '')) * 12
  //     ).toFixed(2)}`;
  //   }
  // };
  const removeCouponHandler = () => {
    dispatch(setPromoCode(''));
    dispatch(setDuration(''));
    setPlanAmountsHandler({content: paymentdetails});
    dispatch(setSelectedPlanID({id: MONTHLY_PLAN_ID}));
    dispatch(setCouponCodeText(''));
    dispatch(
      setDiscounts({
        amount: 0,
        percentage: 0,
      }),
    );
  };

  // const onCardChangeDiscountHandler = (
  //   totalRecurrings,
  //   onRecurringChange,
  //   sameCardClicks,
  // ) => {
  //   if (!sameCardClicks && discounts?.percentage) {
  //     removeCouponIfPercentageDiscountOtherThenMonthlyPlan();
  //     return;
  //   }

  //   totalRecurrings = parseFloat(totalRecurrings.split('$')[1]);
  //   if (
  //     (totalRecurrings <= parseFloat(minimumDiscountAmount) &&
  //       couponCodeText != '') ||
  //     (onRecurringChange &&
  //       totalRecurrings <= parseFloat(minimumDiscountAmount))
  //   ) {
  //     removeCouponHandler();
  //     dispatch(
  //       setModalDetails({
  //         type: ENUMS.POPUP_ENUMS.ALERT,
  //         details: ALL_TEXT.COUPON_REMOVED,
  //       }),
  //     );
  //     dispatch(openModal());
  //     return;
  //   }
  // };

  //New implemented Code Functions
  useEffect(() => {
    planChangeHandler();
  }, [selectedPlanId]);
  const planChangeHandler = () => {
    if (selectedPlanId == MONTHLY_PLAN_ID) {
      return dispatch(setSelectedPlanRecords(monthlyPlanData));
    } else if (selectedPlanId == BIANUAL_PLAN_ID) {
      return dispatch(setSelectedPlanRecords(biAnnualPlanData));
    } else {
      return dispatch(setSelectedPlanRecords(annualPlanData));
    }
  };
  const getDisplayCost = value => `$${parseFloat(value).toFixed(2)}`;
  const checkPercentageOrDollarChange = isPercentageDiscount => {
    if (isPercentageDiscount) {
      return `${selectedPlanRecords?.appliedDiscount?.discountPercentage}%`;
    } else {
      return getDisplayCost(
        selectedPlanRecords?.appliedDiscount?.discountedAmount,
      );
    }
  };
  const applyCouponCode_ = async () => {
    let response = await getPromoInfoAPI({
      signupId: signupId || id,
      couponCode: promoCode,
    });
    const {success, description} = response?.data || {};
    if (response && response?.data?.success) {
      if (success) {
        setPlanAmountsHandler(response.data);
      }
    } else {
      setPopupResponse({
        type: ENUMS.MODAL_TYPES.ERROR,
        detail: description,
      });
      setShowModal(true);
    }
  };
  const checkMinimumDiscountAmount = selectedPlan => {
    const minimumDiscountAmount =
      selectedPlanRecords?.appliedDiscount?.minimumDiscountAmount;
    const planAmount = selectedPlan?.totalTaxedInvoiceAmount;
    if (planAmount < minimumDiscountAmount) {
      removeCouponHandler();
      setPopupResponse({
        type: ENUMS.MODAL_TYPES.ERROR,
        detail: `Can not apply coupon minimum payable amount is $${minimumDiscountAmount}`,
      });
      setShowModal(true);

      return;
    }
  };

  return (
    <AddPolicyMainLayout selectedId={1}>
      {loader ? (
        <div className="loader-container-payment">
          <Loader />
        </div>
      ) : (
        // <div className="invoices-main-container px-2">
        <div className="add-policy-container">
          <Grid container spacing={3}>
            <Grid item xl={4} lg={4} md={4} xs={12}>
              <div className="card-container ">
                <PlanCard
                  title={ALL_TEXT.PlANS_TYPE.MONTHLY}
                  infoOne={
                    monthlyPlanData?.planDescription || ALL_TEXT.RENEWS.MONTHLY
                  }
                  planAmount={monthlyPlanData?.planAmount - processingFee}
                  processingFee={processingFee}
                  infoSecond={
                    monthlyPlanData?.appliedDiscount
                      ? `${monthlyPlanData?.appliedDiscount?.discountText}`
                      : ALL_TEXT.DISCOUNTS_PLANS.MONTHLY
                  }
                  price={getDisplayCost(monthlyPlanData?.discountedPlanAmount)}
                  type={MONTHLY_PLAN_ID}
                  key={MONTHLY_PLAN_ID}
                  planType={MONTHLY_PLAN_ID}
                  isOfferDiscount={
                    monthlyPlanData?.appliedDiscount?.discountType != 0
                      ? true
                      : false
                  }
                  onCardPress={() => {
                    dispatch(setSelectedPlanID({id: MONTHLY_PLAN_ID}));
                    checkMinimumDiscountAmount(
                      paymentdetails?.plans?.find(
                        i => i.planType === MONTHLY_PLAN_ID,
                      ),
                    );
                  }}
                  check={selectedPlanId === MONTHLY_PLAN_ID}
                />
              </div>
            </Grid>
            <Grid item xl={4} lg={4} md={4} xs={12}>
              <div className="card-container ">
                <PlanCard
                  title={ALL_TEXT.PlANS_TYPE.BIANNUAL}
                  infoOne={
                    biAnnualPlanData?.planDescription ||
                    ALL_TEXT.RENEWS.BIANNUAL
                  }
                  planAmount={biAnnualPlanData?.planAmount - processingFee}
                  processingFee={processingFee}
                  infoSecond={
                    biAnnualPlanData?.appliedDiscount
                      ? `${biAnnualPlanData?.appliedDiscount?.discountText}`
                      : ALL_TEXT.DISCOUNTS_PLANS.BIANNUAL
                  }
                  price={getDisplayCost(biAnnualPlanData?.discountedPlanAmount)}
                  type={BIANUAL_PLAN_ID}
                  key={BIANUAL_PLAN_ID}
                  planType={BIANUAL_PLAN_ID}
                  isOfferDiscount={
                    biAnnualPlanData?.appliedDiscount?.discountType != 0
                      ? true
                      : false
                  }
                  onCardPress={() => {
                    dispatch(setSelectedPlanID({id: BIANUAL_PLAN_ID}));
                    checkMinimumDiscountAmount(
                      paymentdetails?.plans?.find(
                        i => i.planType === BIANUAL_PLAN_ID,
                      ),
                    );
                  }}
                  check={selectedPlanId === BIANUAL_PLAN_ID}
                />
              </div>
            </Grid>
            <Grid item xl={4} lg={4} md={4} xs={12}>
              <div className="card-container ">
                <PlanCard
                  title={ALL_TEXT.PlANS_TYPE.ANNUAL}
                  infoOne={
                    annualPlanData?.planDescription || ALL_TEXT.RENEWS.YEARLY
                  }
                  planAmount={annualPlanData?.planAmount - processingFee}
                  processingFee={processingFee}
                  infoSecond={
                    annualPlanData?.appliedDiscount
                      ? `${annualPlanData?.appliedDiscount?.discountText}`
                      : ALL_TEXT.DISCOUNTS_PLANS.ANNUALLY
                  }
                  price={getDisplayCost(annualPlanData?.discountedPlanAmount)}
                  type={ANNUAL_PLAN_ID}
                  key={ANNUAL_PLAN_ID}
                  planType={ANNUAL_PLAN_ID}
                  isOfferDiscount={
                    annualPlanData?.appliedDiscount?.discountType != 0
                      ? true
                      : false
                  }
                  onCardPress={() => {
                    dispatch(setSelectedPlanID({id: ANNUAL_PLAN_ID}));
                    checkMinimumDiscountAmount(
                      paymentdetails?.plans?.find(
                        i => i.planType === ANNUAL_PLAN_ID,
                      ),
                    );
                  }}
                  check={selectedPlanId === ANNUAL_PLAN_ID}
                />
              </div>
            </Grid>
            <div className="coupon-code-container">
              <Grid container spacing={0}>
                <Grid item xl={6} lg={6} md={6} xs={12}>
                  <div className="promo-text">
                    <span>Coupon code</span>
                    <span>
                      Adding promo code will remove other plan discounts.
                    </span>
                  </div>
                  <div className="promo-child-container">
                    <div className="promo-child-container-1">
                      <InputField
                        value={promoCode}
                        handleChange={({target: {value}}) => {
                          dispatch(setPromoCode(value));
                        }}
                        placeHolder={'Enter Code'}
                        name={'promocode'}
                        disabled={couponCodeText !== ''}
                      />
                    </div>
                    <div className="promo-child-container-2">
                      <GenericButton
                        buttonText={'Apply'}
                        disable={couponCodeText !== ''}
                        onPress={() => {
                          if (promoCode !== '') {
                            applyCouponCode_(promoCode);
                          }
                        }}
                        loading={getPromoInfoResponse?.isFetching}
                      />
                    </div>
                    {couponCodeText != '' && (
                      <div className="promo-child-container-2">
                        <div className="promo-code">
                          <span>{couponCodeText} </span>
                          <CgCloseO
                            size={20}
                            color={colors.white}
                            onClick={removeCouponHandler}
                            style={{
                              cursor: 'pointer',
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item xl={6} lg={6} md={6} xs={12}>
                  <div className="subscription-container">
                    <ItemInfoSelectPaymentScreen
                      text={
                        <span>
                          {'Current Invoice'}
                          <HiOutlineInformationCircle
                            data-tooltip-id={'tax-tooltip-1'}
                            data-tooltip-content={`Including Processing Fee of $${processingFee}`}
                            style={{
                              color: 'black',
                              marginLeft: '10px',
                              fontWeight: 'bolder',
                              height: '24px',
                              width: '24px',
                            }}
                          />
                          <Tooltip
                            id={'tax-tooltip-1'}
                            style={{width: '120px'}}
                          />
                        </span>
                      }
                      price={getDisplayCost(selectedPlanRecords?.planAmount)}
                      text2={'Discount'}
                      price2={
                        selectedPlanRecords?.appliedDiscount ? (
                          <span className="bold-black">
                            {/* {`${selectedPlanRecords?.appliedDiscount?.discountName}  `} */}
                            <span className="bold-red">
                              {`${checkPercentageOrDollarChange(
                                selectedPlanRecords?.appliedDiscount
                                  .isPercentageDiscount,
                              )}`}
                            </span>
                          </span>
                        ) : (
                          ALL_TEXT.DISCOUNTS_PLANS.MONTHLY
                        )
                      }
                      text3={'Invoice After Discount'}
                      price3={getDisplayCost(
                        selectedPlanRecords?.discountedPlanAmount,
                      )}
                    />

                    {/* <ItemInfo
                      text={'Current Invoice'}
                      // price={getTotalVisible()}
                    /> */}

                    {/* <div className="once-invoice-container">
                      <ItemInfo
                        text={'Current Invoice'}
                        price={getTotalVisible()}
                      />
                      <ItemInfo text={'Platform Fee'} price={`$1.00`} />
                      <ItemInfo
                        text={'Total Current Invoice'}
                        price={incrementByOneTotalVisible()}
                      />
                    </div>
                    <ItemInfo
                      text={'Future Invoice'}
                      price={getFutureInvoice()}
                    /> */}
                    {/* {duration !== 'once' ? (
                      <ItemInfo
                        text={'Total Recurring'}
                        price={getTotalVisible()}
                      />
                    ) : (
                      <div className="once-invoice-container">
                        <ItemInfo
                          text={'Current Invoice'}
                          price={getTotalVisible()}
                        />
                        <ItemInfo
                          text={'Future Invoice'}
                          price={getPlansCostWithoutDiscount(
                            selectedPlan.selectedPlanId,
                          )}
                        />
                      </div>
                    )} */}
                  </div>
                </Grid>
              </Grid>
            </div>
            <Grid item xl={12} lg={12} md={12} xs={12}>
              <div className="add-payment-method">
                <span className="payment-card-type">
                  {selectedCard && (
                    <i
                      class={`fa-brands fa-cc-${selectedCard.cardType}`}
                      style={{color: colors.primary, fontSize: '50px'}}></i>
                  )}
                  <span className="card-payment-info">
                    {selectedCard
                      ? `*** *** *** ${selectedCard.last4}`
                      : ALL_TEXT.SELECT_PAYMENT.SELECT_PAYMENT_METHOD}
                  </span>
                </span>
                {selectedCard?.isCardSave && (
                  <div className="default-card-check">
                    <AiFillCheckCircle color={colors.green} size={20} />
                    <span>{ALL_TEXT.SELECT_PAYMENT.CARD_SAVE_AS_DEFAULT}</span>
                  </div>
                )}
                <div
                  onClick={() => {
                    setAddPaymentMethodPopUp(true);
                  }}
                  className="add-payment-method">
                  <span>
                    {selectedCard
                      ? ALL_TEXT.BUTTON_TEXT.CHANGE
                      : ALL_TEXT.BUTTON_TEXT.ADD}
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item xl={12} lg={12} md={12} xs={12}>
              {selectedPlanRecords?.totalTaxedInvoiceAmount < 1 && (
                <div className="minimim-invoice">{`Current Invoice should be equals or greater than $1.00`}</div>
              )}
              <div className="navgate-controller mt-5">
                <GenericButton
                  buttonText={ALL_TEXT.BUTTON_TEXT.BACK}
                  grayButton
                  onPress={() => {
                    navigate(
                      {pathname: path.addPolicy, search: location.search},
                      {replace: true},
                    );
                  }}
                />
                <GenericButton
                  buttonText={ALL_TEXT.BUTTON_TEXT.NEXT}
                  onPress={() => {
                    navigate(
                      {pathname: path.reviewAndPay, search: location.search},
                      {replace: true},
                    );
                  }}
                  loading={btnLoader}
                  disable={
                    selectedCard == null ||
                    selectedPlanRecords?.totalTaxedInvoiceAmount < 1
                  }
                />
              </div>
            </Grid>
          </Grid>
          {/* </div> */}
          {addPaymentMethodPopUp && (
            <AddPaymentMethod
              handleClose={() => {
                setAddPaymentMethodPopUp(false);
              }}
              show={addPaymentMethodPopUp}
              existingCards={existingCards}
            />
          )}
          <GenericModal
            show={showModal}
            title={popUpResponse?.title}
            type={popUpResponse.type}
            body={
              popUpResponse.type === ENUMS.POPUP_ENUMS.SUCCESS ? (
                <div className="payment-success-popup">
                  <div> {ALL_TEXT.SUCCESSFULLY_PAYMENT.PAYMENT_RECEIVED}</div>
                  {ALL_TEXT.SUCCESSFULLY_PAYMENT.DESCRIPTION}
                  <a href={`mailto:${ALL_TEXT.URLS.INFO}`}>
                    {ALL_TEXT.URLS.INFO}
                  </a>
                </div>
              ) : (
                popUpResponse.detail
              )
            }
            buttonText={ALL_TEXT.BUTTON_TEXT.OK}
            primaryBtnPress={() => {
              if (popUpResponse.title == COUPON_ERROR) {
                setShowModal(false);
                return;
              } else if (popUpResponse.type == ENUMS.MODAL_TYPES.ERROR)
                setShowModal(false);
              // navigate(path.addPolicy);
            }}
          />
        </div>
      )}
    </AddPolicyMainLayout>
  );
};
export default SelectPaymentMethod;

export const ItemInfoSelectPaymentScreen = ({
  text,
  price,
  text2,
  price2,
  text3,
  price3,
}) => {
  return (
    <div className="total-amount-payment-screen">
      <span className="container">
        <span>{text}</span>
        <span className="red-color">{price}</span>
      </span>
      <span className="container">
        <span>{text2}</span>
        <span className="red-color">{price2}</span>
      </span>
      <span className="container">
        <span>{text3}</span>
        <span className="red-color">{price3}</span>
      </span>
    </div>
  );
};
