import {React} from 'react';
import {ActionDropDownPopUp, CellValue} from '../../../../components';
import {ALL_TEXT} from '../../../../common';
import {BsThreeDotsVertical} from 'react-icons/bs';
import {TbEdit} from 'react-icons/tb';
import {useDispatch} from 'react-redux';
import {
  setAddEditCommissionPopup,
  setCommissionDetails,
  setViewDetails,
} from '../../../../redux/slice/commission-calculator';

export function tableHeading() {
  const dispatch = useDispatch();
  const getMenuItems = row => {
    return [
      {
        title: ALL_TEXT.VIEW_DETAILS,
        icon: <span class="icon-search popup-menu-icon gray-popup-icon"></span>,
        onItemPress: () => {
          // setUserObject(row);
          dispatch(setViewDetails(true));
          dispatch(setCommissionDetails(row));
        },
      },
      {
        title: 'Add/Edit Commission Rate',
        icon: (
          <span className="edit-icon-staff">
            <TbEdit />
          </span>
        ),
        onItemPress: () => {
          dispatch(setAddEditCommissionPopup(true));
          dispatch(setCommissionDetails(row));

          // dispatch(setEditModal(true));
          // dispatch(setSelectedEmployeeData(row));
        },
      },
    ];
  };

  return [
    {
      title: 'Name',
      fieldName: 'fullName',
      dataformat: (cell, row) => {
        if (!cell) {
          return 'N/A';
        }
        return <CellValue disableTooltip cell={cell} row={row} />;
      },
    },
    {
      title: 'Email',
      fieldName: 'email',
      dataformat: (cell, row) => (
        <CellValue
          disableTooltip
          customClassName={'commission-value'}
          cell={cell}
          row={row}
        />
      ),
    },
    {
      title: 'Total Sales',
      fieldName: 'deductibles',
      dataformat: (cell, row) => (
        <CellValue
          disableTooltip
          customClassName={'commission-value'}
          cell={cell}
          row={row}
        />
      ),
    },
    {
      title: 'Total Subscriptions',
      fieldName: 'subscriptions',
      dataformat: (cell, row) => (
        <CellValue
          disableTooltip
          customClassName={'commission-value'}
          cell={cell}
          row={row}
        />
      ),
    },
    {
      title: 'Total Customers',
      fieldName: 'customers',
      dataformat: (cell, row) => (
        <CellValue
          disableTooltip
          customClassName={'commission-value'}
          cell={cell}
          row={row}
        />
      ),
    },
    {
      title: 'Registrations',
      fieldName: 'registrations',
      dataformat: (cell, row) => (
        <CellValue
          disableTooltip
          customClassName={'commission-value'}
          cell={cell}
          row={row}
        />
      ),
    },
    {
      title: 'Actions',
      fieldName: 'action',
      dataformat: (cell, row) => {
        return (
          <ActionDropDownPopUp
            visibleIcon={<BsThreeDotsVertical />}
            items={getMenuItems(row)}
            userObj={row}
          />
        );
      },
    },
  ];
}
