import React, {useState, useEffect} from 'react';
import './styles.scss';
import {ALL_TEXT, ENUMS} from '../../common';
import {GenericButton} from './../../components/generic-button/index';
import {CustomizedSearch} from './../../components/customized-search/index';
import {GenericModal, GenericTable} from '../../components';
import {TableCarrier} from './table';
import Pagination from '@mui/material/Pagination';
import {getValuesFromEnumsObj} from '../../utils/helpers';
import CarrierModal from '../../components/carrier-modal';
import {useDispatch, useSelector} from 'react-redux';
import {
  addCarrier,
  carrierCloseModal,
  carrierOpenModal,
  deleteCloseModal,
} from '../../redux/slice/carriers';
import {
  usePostApiAdminGetAllCarriersMutation,
  usePostApiAdminActivateDeactivateCarrierMutation,
} from '../../redux/slice/nodukAdmin.ts';
import {setModalDetails} from '../../utils/store/slices/popup-modal';
import {openModal} from '../../redux/slice/popup-modal';
import {toast} from 'react-toastify';

function ManageCarriers() {
  const removecarrierItems = useSelector(state => state.carrier.removecarrier);
  const isAddCarrier = useSelector(state => state.carrier.isAddCarrier);
  const isDeleteModal = useSelector(state => state.carrier.isDeleteOpenModal);
  const isCarrierModalOpens = useSelector(
    state => state.carrier.isCarrierOpenModal,
  );

  const {
    POLICY_TYPES: {HOME, VEHICLE, COMMERCIAL, DEVICE, ALL},
  } = ENUMS;
  const dispatch = useDispatch();
  const [btnLoader, setBtnLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedType, setSelectedType] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [selectedCarrierName, setSelectedCarrierName] = useState('');
  const [activateOrDeactivateCarrierAPI, activateOrDeactivateCarrierResponse] =
    usePostApiAdminActivateDeactivateCarrierMutation();

  var carrierlist = [];
  let totalPages = 1;

  const [
    getAllCarriers,
    {isLoading, isError, isSuccess, data: CarrierData, error},
  ] = usePostApiAdminGetAllCarriersMutation();

  if (isSuccess && CarrierData && CarrierData.content) {
    carrierlist = CarrierData?.content?.list ?? [];
    totalPages = CarrierData?.content?.totalPages;
  } else if (isError) {
    dispatch(
      setModalDetails({
        type: ENUMS.POPUP_ENUMS.ERROR,
        details: error.exceptionMessage,
      }),
    );
    dispatch(openModal());
  }

  const fetchCarrierHandler = () => {
    const payload = {
      carrierResponse: {
        providerName: selectedCarrierName,
        serviceTypeId: selectedType,
        status: selectedStatus,
        pageNumber: currentPage,
        pageSize: ENUMS.PAGE_SIZE,
      },
    };
    fetchAllCarrier(payload);
  };

  useEffect(() => {
    fetchCarrierHandler();
  }, [currentPage]);

  const fetchAllCarrier = async payload => {
    try {
      await getAllCarriers(payload);
    } catch (error) {
      dispatch(
        setModalDetails({
          type: ENUMS.POPUP_ENUMS.ERROR,
          details: error,
        }),
      );
      dispatch(openModal());
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const userSearchhandler = async (
    name,
    typeDropDown,
    statusDropDown,
    clear,
  ) => {
    let payload;
    if (clear) {
      payload = {
        carrierResponse: {
          providerName: '',
          serviceTypeId: 0,
          status: 0,
          pageNumber: 1,
          pageSize: ENUMS.PAGE_SIZE,
        },
      };
      setSelectedCarrierName('');
      setSelectedType(0);
      setSelectedStatus(0);
    } else {
      const claimType = getValuesFromEnumsObj(ENUMS.POLICY_TYPES, typeDropDown);
      const userStatus = getValuesFromEnumsObj(
        ENUMS.USERS.STATUS,
        statusDropDown,
      );
      payload = {
        carrierResponse: {
          providerName: name,
          serviceTypeId: claimType,
          status: userStatus,
          pageNumber: 1,
          pagesize: ENUMS.PAGE_SIZE,
        },
      };
      setSelectedCarrierName(name);
      setSelectedType(claimType);
      setSelectedStatus(userStatus);
    }
    setCurrentPage(1);
    setBtnLoader(true);
    await fetchAllCarrier(payload);

    setBtnLoader(false);
  };

  const removeCarrierItem = async () => {
    let response = await activateOrDeactivateCarrierAPI({
      id: removecarrierItems.id,
      status: !removecarrierItems.status,
    });
    if (response && response?.data?.success) {
      toast.success(response?.data?.description);
      dispatch(deleteCloseModal());
      fetchCarrierHandler();
    }
  };

  return (
    <div className="claimlist-main-container">
      <div className="title-container-claimlist">
        <span>{ALL_TEXT.MANAGE_CARRIERS}</span>
        <GenericButton
          customStyle={'custom-register-btn'}
          buttonText={`+${ALL_TEXT.ADD_NEW_CARRIER}`}
          onPress={() => {
            dispatch(addCarrier());
            dispatch(carrierOpenModal());
          }}
        />
      </div>
      <div className="manageCarrierList-search-bar">
        <CustomizedSearch
          title={ALL_TEXT.SEARCH_MANAGE_CARRIERS_BY}
          firstLabel={ALL_TEXT.CARRIER_NAME}
          firstPlaceholder={ALL_TEXT.CARRIER_NAME}
          firstDropdown
          firstDropdownLabel={ALL_TEXT.CARRIER_TYPE}
          firstDropdownList={[
            HOME.LABEL,
            VEHICLE.LABEL,
            COMMERCIAL.LABEL,
            DEVICE.LABEL,
            ALL.LABEL,
          ]}
          secondDropdown
          secondDropdownLabel={ALL_TEXT.STATUS}
          seconddropdownlist={[
            ENUMS.USERS.STATUS.ACTIVE.LABEL,
            ENUMS.USERS.STATUS.INACTIVE.LABEL,
            ENUMS.USERS.STATUS.ALL.LABEL,
          ]}
          onClearFilterHandler={() => {
            userSearchhandler('', '', '', true);
          }}
          btnLoader={btnLoader}
          onSearchBtnPress={(name, _, __, typeDropDown, statusDropDown) => {
            userSearchhandler(name, typeDropDown, statusDropDown);
          }}
        />
      </div>
      <div className="table-manageCarrierList-container">
        <GenericTable
          headings={TableCarrier()}
          data={carrierlist}
          loader={isLoading}
        />
      </div>
      {totalPages > 1 && (
        <div className="pagination-container">
          <div className="inner-pagination">
            <Pagination
              page={currentPage}
              color={'secondary'}
              onChange={handlePageChange}
              count={totalPages}
            />
          </div>
        </div>
      )}
      {isCarrierModalOpens && (
        <CarrierModal
          show={isCarrierModalOpens}
          addCarrier={isAddCarrier}
          handleClose={isAdd => {
            dispatch(carrierCloseModal());

            isAdd == true && fetchCarrierHandler();
          }}
        />
      )}
      {isDeleteModal && (
        <GenericModal
          show={isDeleteModal}
          btnLoader={activateOrDeactivateCarrierResponse.isLoading}
          type={ENUMS.POPUP_ENUMS.ALERT}
          title={
            removecarrierItems.status
              ? ALL_TEXT.DISASTER.DEACTIVATE
              : ALL_TEXT.DISASTER.ACTIVATE
          }
          buttonText={ALL_TEXT.BUTTON_TEXT.YES}
          buttonText2={ALL_TEXT.BUTTON_TEXT.NO}
          body={
            removecarrierItems.status
              ? ALL_TEXT.DEACTIVATE_CARRIER
              : ALL_TEXT.ACTIVATE_CARRIER
          }
          button2
          grayButton2
          primaryBtnPress={() => {
            removeCarrierItem();
          }}
          secondaryBtnPress={() => {
            dispatch(deleteCloseModal());
          }}
        />
      )}
    </div>
  );
}

export default ManageCarriers;
