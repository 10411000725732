import React from 'react';
import './styles.scss';
import 'bootstrap/dist/css/bootstrap.css';
import {Modal} from 'react-bootstrap';
import {ALL_TEXT} from '../../common';
import {InputField} from '../input-field';
import {GenericButton} from '../generic-button';
import {Formik} from 'formik';
import {editRiskLevelSchema} from '../../common/schemas';
import {usePostApiDisasterAddEditDisasterRiskMutation} from '../../redux/slice/nodukAdmin.ts';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {setIsRiskLevelOpenMoadal} from '../../redux/slice/disaster-management';

export function AddRiskModal({show, handleClose, updatelist}) {
  const dispatch = useDispatch();
  const selectedRiskLevel = useSelector(
    state => state.disaster.selectedRiskLevel,
  );
  const [updateRiskLevelAPI, updateRiskLevelResponse] =
    usePostApiDisasterAddEditDisasterRiskMutation();

  const editRiskLevel = async values => {
    let payload = {
      addDisasterRisk: {
        riskId: selectedRiskLevel.id,
        riskLevel: values.riskLevel,
        percentage: values.riskDeductibles,
      },
    };

    let response = await updateRiskLevelAPI(payload);

    if (response && response.data.success) {
      updatelist();
      toast.success(response.data.description);
      dispatch(setIsRiskLevelOpenMoadal(false));
    } else {
      toast.error(response.data.description);
    }
  };
  return (
    <>
      <Formik
        enableReinitialize
        validateOnMount={true}
        initialValues={{
          riskLevel: selectedRiskLevel.riskLevel,
          riskDeductibles: selectedRiskLevel.deductiblePercent,
        }}
        validationSchema={editRiskLevelSchema}
        onSubmit={(values, formik) => {
          editRiskLevel(values, formik);
        }}>
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleSubmit,
          setFieldValue,
          handleChange,
        }) => (
          <Modal
            show={show}
            onHide={handleClose}
            size="md"
            centered
            className="risk-modal">
            <Modal.Header>
              <h5>{ALL_TEXT.DISASTER.EDIT_RISK_LEVEL_}</h5>
            </Modal.Header>
            <Modal.Body>
              <div className="risk-modal-body">
                <div className="select-risk-level">
                  <label htmlFor="/">{ALL_TEXT.DISASTER.RISK_LEVEL}</label>
                  <InputField
                    disabled={true}
                    id={'riskLevel'}
                    name={'riskLevel'}
                    value={values.riskLevel}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    error={touched.riskLevel && errors.riskLevel}
                  />
                </div>
                <div className="deductibles-section">
                  <label htmlFor="/">{ALL_TEXT.DISASTER.DEDUCTIBLES}</label>
                  <InputField
                    id={'riskDeductibles'}
                    name={'riskDeductibles'}
                    value={values.riskDeductibles}
                    handleChange={e => {
                      const numericValue = e.target.value.replace(
                        /[^0-9.]+/g,
                        '',
                      );
                      const restrictedValue = numericValue.substring(0, 6);
                      setFieldValue('riskDeductibles', restrictedValue);
                    }}
                    handleBlur={handleBlur}
                    error={touched.riskDeductibles && errors.riskDeductibles}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="risk-level-action">
                <GenericButton
                  grayButton
                  buttonText={ALL_TEXT.CANCEL}
                  onPress={handleClose}
                />
                <GenericButton
                  loading={updateRiskLevelResponse.isLoading}
                  buttonText={ALL_TEXT.BUTTON_TEXT.UPDATE}
                  onPress={() => handleSubmit()}
                />
              </div>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
}
