import React, {useState, useEffect} from 'react';
import {Modal} from 'react-bootstrap';
import {ALL_TEXT, ENUMS, colors} from '../../../../common';
import {GrClose} from 'react-icons/gr';
import {GenericButton, InputField, Loader} from '../../../../components';
import {Grid} from '@mui/material';
import {
  NodukAdmnApi,
  usePostApiAdminAddEditCommissionRateMutation,
} from '../../../../redux/slice/nodukAdmin.ts';
import {toast} from 'react-toastify';

export function AddEdiCommission({show, handleClose, data}) {
  const {employeeId} = data || {};
  const [addCommissionRateAPI, addCommissionRateResponse] =
    usePostApiAdminAddEditCommissionRateMutation();
  const {useLazyGetApiAdminGetAgentCommissionRateQuery} = NodukAdmnApi;

  const [getCommissionRateAPI, getCommissionRateResponse] =
    useLazyGetApiAdminGetAgentCommissionRateQuery();

  useEffect(() => {
    getCommissionRateAPI({
      empId: employeeId,
      roleId: ENUMS.STAFF.ROLE.AGENT.VALUE,
    });
  }, []);

  useEffect(() => {
    if (
      getCommissionRateResponse?.data?.success === false &&
      getCommissionRateResponse?.status === 'fulfilled'
    ) {
      toast.error(getCommissionRateResponse?.data?.description);
    }
  }, [getCommissionRateResponse]);

  const commissionData = getCommissionRateResponse?.data?.content || [];

  const {
    autoRate,
    commercialRate,
    commissionRateId,
    deviceRate,
    disasterRate,
    homeRate,
  } = commissionData || {};

  const [deductibles, setDeductibles] = useState({
    auto: autoRate || 0,
    device: deviceRate || 0,
    home: homeRate || 0,
    commercial: commercialRate || 0,
    disaster: disasterRate || 0,
  });
  useEffect(() => {
    setDeductibles({
      auto: autoRate || 0,
      device: deviceRate || 0,
      home: homeRate || 0,
      commercial: commercialRate || 0,
      disaster: disasterRate || 0,
    });
  }, [getCommissionRateResponse]);

  return (
    <>
      <>
        <Modal
          className="email-share-QR-wrapper"
          centered
          size="lg"
          show={show}
          onHide={handleClose}>
          <Modal.Header>
            <div className="top-add-payment-container">
              <span>{ALL_TEXT.ADD_EDIT_COMMISSION} </span>
              <GrClose
                className="add-payment-close-btn"
                onClick={handleClose}
                size={18}
                color={colors.primary}
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            {getCommissionRateResponse.isFetching ? (
              <Loader />
            ) : (
              <div className="add-edit-deductibles-container">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <label htmlFor="/">{'Auto Deductibles'}</label>
                    <InputField
                      search
                      prefixerValue={'$'}
                      placeHolder={'Enter your Auto Deductibles'}
                      value={deductibles.auto}
                      handleChange={({target: {value}}) => {
                        setDeductibles(e => ({...e, auto: value}));
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <label htmlFor="/">{'Device Deductibles'}</label>
                    <InputField
                      search
                      prefixerValue={'$'}
                      placeHolder={'Enter your Device Deductibles'}
                      value={deductibles.device}
                      handleChange={({target: {value}}) => {
                        setDeductibles(e => ({...e, device: value}));
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <label htmlFor="/">{'Home Deductibles'}</label>
                    <InputField
                      search
                      prefixerValue={'$'}
                      placeHolder={'Enter your Home Deductibles'}
                      value={deductibles.home}
                      handleChange={({target: {value}}) => {
                        setDeductibles(e => ({...e, home: value}));
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <label htmlFor="/">{'Commercial Deductibles'}</label>
                    <InputField
                      search
                      prefixerValue={'$'}
                      placeHolder={'Enter your Commercial Deductibles'}
                      value={deductibles.commercial}
                      handleChange={({target: {value}}) => {
                        setDeductibles(e => ({...e, commercial: value}));
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <label htmlFor="/">{'Disaster Deductibles'}</label>
                    <InputField
                      search
                      prefixerValue={'$'}
                      placeHolder={'Enter your Deductibles'}
                      value={deductibles.disaster}
                      handleChange={({target: {value}}) => {
                        setDeductibles(e => ({...e, disaster: value}));
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <GenericButton
              onPress={async () => {
                await addCommissionRateAPI({
                  agentCommissionRateDto: {
                    employeeId: employeeId,
                    roleId: ENUMS.STAFF.ROLE.AGENT.VALUE,
                    commissionRateId: commissionRateId,
                    autoRate: deductibles.auto || 0,
                    homeRate: deductibles.home || 0,
                    commercialRate: deductibles.commercial || 0,
                    deviceRate: deductibles.device || 0,
                    disasterRate: deductibles.disaster || 0,
                  },
                });
                handleClose();
                toast.success(
                  addCommissionRateResponse?.data?.description ||
                    'Commission rate addedd sccessfully',
                );
              }}
              loading={addCommissionRateResponse.isLoading}
              buttonText={'Save'}
            />
          </Modal.Footer>
        </Modal>
      </>
    </>
  );
}
