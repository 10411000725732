import React, {useState, useEffect} from 'react';
import {ALL_TEXT, ENUMS} from '../../common';
import {
  GenericTable,
  CustomizedSearch,
  GenericButton,
  AddNewUser,
  GenericModal,
  Loader,
  CellValue,
} from '../../components';
import './style.scss';
import {tableHeading} from './table';
import Pagination from '@mui/material/Pagination';
import {getAllAgents, gettAllUsers} from '../../utils/rest-services';
import {useDispatch, useSelector} from 'react-redux';
import {
  setCouponCodeText,
  setDiscounts,
  setDuration,
  setPromoCode,
  setTotalCost,
  setUserList,
} from '../../redux/slice/checkout';
import {getValuesFromEnumsObj} from '../../utils/helpers';
import {
  setActivationLinkPopup,
  setActiveUser,
  setChangePasswordModal,
} from '../../redux/slice/user';
import {NodukAdmnApi} from '../../redux/slice/nodukAdmin.ts';
import {toast} from 'react-toastify';
import {openModal, setModalDetails} from '../../redux/slice/popup-modal';
import {getProfile, getToken} from '../../utils/localstorage';

function Users() {
  const {
    useLazyGetApiAdminActivateDeactivateUserQuery,
    useLazyGetApiAdminGenerateForgetPasswordLinkQuery,
  } = NodukAdmnApi;
  const [addNewUser, setAddNewUser] = useState(false);
  const [getAllUsers, setgetAllUsers] = useState([]);
  const [agentsList, setAgentsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [btnLoader, setBtnLoader] = useState(false);
  const [loader, setLoader] = useState(true);
  const [userRole, setUserRole] = useState(-1);
  const isLoggedIn = getToken() !== '';
  const [selectedUser, setSelectedUser] = useState({
    role: 0,
    status: 0,
    name: '',
    email: '',
    agent: 0,
  });
  const dispatch = useDispatch();
  const isDeActivateModalOpen = useSelector(state => state.User.isActiveUser);
  const isActivationLinkPopup = useSelector(
    state => state.User.isActivationLinkPopUp,
  );
  const selectedUserData = useSelector(state => state.User.selectredUser);
  const isChangePasswordModalOpens = useSelector(
    state => state.User.isChangePasswordModalOpens,
  );

  // QueryParamsFromURL
  const getParamFromUrl = () => {
    return new URLSearchParams(location.search);
  };
  let query = getParamFromUrl();
  const dashboardRole = query.get('role');

  // RTK Query
  const [activateOrDeactivateAccountAPI, activateOrDeactivateAccountResponse] =
    useLazyGetApiAdminActivateDeactivateUserQuery();
  const [generatePasswordAPI, generatePasswordResponse] =
    useLazyGetApiAdminGenerateForgetPasswordLinkQuery();

  // it is used for show user  change password modal
  useEffect(() => {
    if (isChangePasswordModalOpens === true) {
      generatePasswordAPI({email: selectedUserData?.email});
    }
  }, [isChangePasswordModalOpens]);

  useEffect(() => {
    if (isLoggedIn) {
      let {roleId} = getProfile();
      setUserRole(roleId);
    }
  }, [isLoggedIn]);
  useEffect(() => {
    fetchUserListHandler();
    removeCouponHandler();
  }, [currentPage]);

  const removeCouponHandler = () => {
    dispatch(setCouponCodeText(''));
    dispatch(setPromoCode(''));
    dispatch(setDuration(''));
    dispatch(setTotalCost(''));
    dispatch(
      setDiscounts({
        amount: 0,
        percentage: 0,
      }),
    );
  };
  const fetchUserListHandler = () => {
    const payload = {
      name: selectedUser.name,
      email: selectedUser.email,
      role: dashboardRole || selectedUser.role,
      status: selectedUser.status,
      agentid: selectedUser.agent,
      pagenumber: currentPage,
      pagesize: ENUMS.PAGE_SIZE,
    };
    getAllUsersList(payload);
    fetchAgentList();
  };

  const getAllUsersList = async payload => {
    setLoader(true);
    let response = await gettAllUsers(payload);
    setLoader(false);
    const {
      content: {list, totalPages},
    } = response;
    if (response && response.success && list.length > 0) {
      dispatch(setUserList(list));
      setgetAllUsers(list);
      setTotalPage(totalPages);
    } else {
      setBtnLoader(false);
      setgetAllUsers([]);
      setTotalPage(1);
    }
  };

  const fetchAgentList = async () => {
    let response = await getAllAgents();
    const {content} = response;
    if (response && response.success) {
      if (content.length > 0) {
        content.map(i => (i.value = i.id));
      }
      setAgentsList(content);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const userSearchhandler = async (
    name,
    email,
    statusDropDown,
    roleDropDown,
    agent,
    clear,
  ) => {
    let payload;

    if (clear) {
      payload = {
        name: '',
        email: '',
        role: 0,
        status: 0,
        agentid: 0,
        pagenumber: 1,
        pagesize: ENUMS.PAGE_SIZE,
      };
      setSelectedUser({
        role: 0,
        status: 0,
        name: '',
        email: '',
        agent: '',
      });
    } else {
      setSelectedUser({
        status: getValuesFromEnumsObj(ENUMS.USERS.STATUS, statusDropDown),
        role: getValuesFromEnumsObj(ENUMS.USERS.ROLE, roleDropDown),
        name: name,
        email: email,
        agent: agent?.value || 0,
      });
      payload = {
        name: name,
        email: email,
        role: getValuesFromEnumsObj(ENUMS.USERS.ROLE, roleDropDown),
        status: getValuesFromEnumsObj(ENUMS.USERS.STATUS, statusDropDown),
        agentid: agent?.value || 0,
        pagenumber: 1,
        pagesize: ENUMS.PAGE_SIZE,
      };
    }
    setCurrentPage(1);
    setBtnLoader(true);
    await getAllUsersList(payload);
    setBtnLoader(false);
  };

  const activeOrDeactivateUser = async () => {
    let response = await activateOrDeactivateAccountAPI({
      signupId: selectedUserData?.signupId,
      status: !selectedUserData?.isActive,
    });
    if (response && response?.data?.success) {
      toast.success(response?.data?.description);
      dispatch(setActiveUser(false));
      fetchUserListHandler();
    } else {
      dispatch(
        setModalDetails({
          type: ENUMS.POPUP_ENUMS.ERROR,
          details: response?.data?.description,
        }),
      );
      dispatch(openModal());
    }
  };
  const getModalBody = () => {
    if (isChangePasswordModalOpens) {
      if (generatePasswordResponse.isFetching) {
        return <Loader />;
      } else {
        return (
          <CellValue
            row={generatePasswordResponse?.data?.content}
            cell={generatePasswordResponse?.data?.content}
          />
        );
      }
    } else if (isActivationLinkPopup) {
      return (
        <CellValue
          row={selectedUserData?.activationLink}
          cell={selectedUserData?.activationLink}
        />
      );
    } else {
      return selectedUserData.isActive
        ? ALL_TEXT.DEACTIVATE_ACCOUNT
        : ALL_TEXT.ACTIVATE_ACCOUNT;
    }
  };

  const getModalTitle = () => {
    if (isChangePasswordModalOpens) return ALL_TEXT.CHANGE_PASSWORD_LINK_MODAL;
    else if (isActivationLinkPopup) return ALL_TEXT.CREATE_ACCOUNT_SETUP_LINK;
    else if (isDeActivateModalOpen) {
      if (selectedUserData?.isActive) {
        return ALL_TEXT.DISASTER.DEACTIVATE;
      } else {
        return ALL_TEXT.DISASTER.ACTIVATE;
      }
    }
  };

  return (
    <div className="userlist-main-container">
      <div className="title-container-userlist">
        <span>{ALL_TEXT.USERS}</span>
        <GenericButton
          customStyle={'custom-register-btn'}
          buttonText={`+${ALL_TEXT.REGISTER_USER}`}
          onPress={() => {
            setAddNewUser(true);
          }}
        />
      </div>
      <div className="user-search-bar">
        <CustomizedSearch
          agent
          title={'Search Customer By'}
          firstLabel={'Name'}
          firstPlaceholder={'Enter Name'}
          secondInput
          secondLabel={'Email'}
          secondPlaceholder={'Enter Email'}
          // thirdLabel={'Search By Agent'}
          // thirdPlaceholder="Enter Agent Name"
          firstDropdown
          firstDropdownLabel={'Status'}
          firstDropdownList={[
            ENUMS.USERS.STATUS.ACTIVE.LABEL,
            ENUMS.USERS.STATUS.INACTIVE.LABEL,
            ENUMS.USERS.STATUS.ALL.LABEL,
          ]}
          secondDropdown
          secondDropdownLabel={'Role'}
          seconddropdownlist={[
            ENUMS.USERS.ROLE.CUSTOMER.LABEL,
            ENUMS.USERS.ROLE.REGISTERED.LABEL,
            ENUMS.USERS.ROLE.ALL.LABEL,
          ]}
          dashboardRole={
            dashboardRole == 1
              ? ENUMS.USERS.ROLE.CUSTOMER.LABEL
              : dashboardRole == 2
              ? ENUMS.USERS.ROLE.REGISTERED.LABEL
              : ENUMS.STAFF.ROLE.ALL.LABEL
          }
          thirdDropdown={userRole !== ENUMS.STAFF.ROLE.AGENT.VALUE}
          thirdDropdownLabel={'Agent'}
          thirddropdownlist={agentsList}
          onClearFilterHandler={() => {
            userSearchhandler('', '', '', '', '', true);
          }}
          btnLoader={btnLoader}
          onSearchBtnPress={(
            name,
            email,
            _,
            statusDropDown,
            roleDropDown,
            agent,
          ) => {
            userSearchhandler(name, email, statusDropDown, roleDropDown, agent);
          }}
        />
      </div>
      <div className="table-userlist-container">
        <GenericTable
          headings={tableHeading()}
          data={getAllUsers}
          loader={loader}
        />
      </div>
      {addNewUser && (
        <AddNewUser
          show={addNewUser}
          buttonText={'Add'}
          handleClose={isSuccess => {
            setAddNewUser(false);
            if (isSuccess === true) fetchUserListHandler();
          }}
        />
      )}
      {totalPage > 1 && (
        <div className="pagination-container">
          <div className="inner-pagination">
            <Pagination
              page={currentPage}
              color={'secondary'}
              onChange={handlePageChange}
              count={totalPage}
            />
          </div>
        </div>
      )}
      {/* THIS MODAL IS BEING USED TO SHOW CHANGE PASSWORD MODAL AND DEACTIVATE ACCOUNT */}
      {(isDeActivateModalOpen ||
        isChangePasswordModalOpens ||
        isActivationLinkPopup) && (
        <GenericModal
          show={
            isDeActivateModalOpen ||
            isChangePasswordModalOpens ||
            isActivationLinkPopup
          }
          btnLoader={activateOrDeactivateAccountResponse?.isFetching}
          type={
            isDeActivateModalOpen
              ? ENUMS.POPUP_ENUMS.DELETE
              : ENUMS.POPUP_ENUMS.ALERT
          }
          title={getModalTitle()}
          buttonText={ALL_TEXT.BUTTON_TEXT.YES}
          buttonText2={ALL_TEXT.BUTTON_TEXT.NO}
          body={getModalBody()}
          button2={!isChangePasswordModalOpens && !isActivationLinkPopup}
          grayButton2
          primaryBtnPress={() => {
            if (!isChangePasswordModalOpens && !isActivationLinkPopup)
              activeOrDeactivateUser();
            else {
              dispatch(setChangePasswordModal(false));
              dispatch(setActivationLinkPopup(false));
            }
          }}
          secondaryBtnPress={() => {
            dispatch(setActiveUser(false));
          }}
        />
      )}
    </div>
  );
}

export default Users;
