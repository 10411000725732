import React from 'react';
import {ALL_TEXT, colors, images} from '../../../common';
import {Grid} from '@mui/material';
import ProgressBar from '@ramonak/react-progress-bar';

export function DashboardClaim({data}) {
  const claimTileData = [
    {
      image: images.roundAutoIcon,
      serviceType: ALL_TEXT.SERVICE_TYPE.VEHICLE,
      count: data?.autoClaims,
    },
    {
      image: images.roundDeviceIcon,
      serviceType: ALL_TEXT.SERVICE_TYPE.DEVICE,
      count: data?.deviceClaims,
    },
    {
      image: images.roundHomeIcon,
      serviceType: ALL_TEXT.SERVICE_TYPE.HOME,
      count: data?.homeClaims,
    },
    {
      image: images.roundCommercialIcon,
      serviceType: ALL_TEXT.SERVICE_TYPE.COMMERCIAL,
      count: data?.commercialClaims,
    },
    {
      image: images.roundDisasterIcon,
      serviceType: ALL_TEXT.SERVICE_TYPE.DISASTER_,
      count: data?.disasterClaims,
    },
  ];

  const ClaimCardData = [
    {
      claimType: images.openClaims,
      claimTitle: ALL_TEXT.OPENED_CLAIMS,
      claimCount: data?.openClaims,
      totalClaims: data?.totalClaims,
      progressBar: data?.openClaims,
      color: colors.primary,
      claimAmount: `$${data?.openClaimsAmount.toFixed(2)}`,
    },
    {
      claimType: images.settledClaims,
      claimTitle: ALL_TEXT.SETTLED_CLAIMS,
      claimCount: data?.settledClaims,
      totalClaims: data?.totalClaims,
      progressBar: data?.settledClaims,
      color: colors.primary,
      claimAmount: `$${data?.settledClaimsAmount.toFixed(2)}`,
    },
    {
      claimType: images.rejectedClaims,
      claimTitle: ALL_TEXT.CLOSED_CLAIMS,
      claimCount: data?.cancelledClaims,
      totalClaims: data?.totalClaims,
      progressBar: data?.cancelledClaims,
      color: colors.primary,
      claimAmount: `$${data?.rejectedClaimsAmount.toFixed(2)}`,
    },
  ];

  return (
    <div className="dashboard-claim">
      <h5>{ALL_TEXT.CLAIMS}</h5>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Grid container spacing={1.1}>
            {claimTileData.map((item, index) => {
              return (
                <Grid key={index} item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <ClaimTile
                    typeIcon={item.image}
                    title={item.serviceType}
                    count={item.count}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Grid container spacing={1.1}>
            {ClaimCardData.map((item, index) => {
              const {
                claimType,
                claimTitle,
                claimCount,
                totalClaims,
                progressBar,
                color,
                claimAmount,
              } = item;

              return (
                <Grid key={index} item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ClaimCard
                    claimType={claimType}
                    claimTitle={claimTitle}
                    claimCount={claimCount}
                    totalClaims={totalClaims}
                    progressBar={
                      <ProgressBar
                        completed={(Number(progressBar) / Math.max(Number(totalClaims), 1)) * 100}
                        isLabelVisible={false}
                        bgColor={color}
                      />
                    }
                    claimAmount={claimAmount}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
const ClaimTile = ({typeIcon, title, count}) => {
  return (
    <div className="claim-tile">
      <div className="claim-type">
        <img src={typeIcon} alt="icon" />
        <span>{title}</span>
      </div>
      <div className="claim-count">
        <span>{count}</span>
      </div>
    </div>
  );
};
const ClaimCard = ({
  claimType,
  claimTitle,
  claimCount,
  progressBar,
  claimAmount,
  totalClaims,
}) => {
  return (
    <div className="claim-card">
      <img src={claimType} alt="icon" />
      <span>{claimTitle}</span>
      <div className={claimHandler(claimTitle)}>
        <span>{`${claimCount} / ${totalClaims}`}</span>
      </div>
      <div className="progress-bar">{progressBar}</div>

      <div className="claim-amount">{claimAmount}</div>
    </div>
  );
};
const claimHandler = text => {
  if (text === ALL_TEXT.OPENED_CLAIMS) {
    return 'open-claim';
  } else if (text === ALL_TEXT.SETTLED_CLAIMS) {
    return 'settled-claim';
  } else {
    return 'rejected-claim';
  }
};
