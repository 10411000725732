import {createSlice} from '@reduxjs/toolkit';

export const couponSlice = createSlice({
  name: 'couponSlice',
  initialState: {
    isDeactivateCoupon: false,
    selectedCoupon: {},
    isCouponEditModal: false,
  },
  reducers: {
    setDeactivateCoupon(state, {payload}) {
      state.isDeactivateCoupon = payload;
    },
    setSelectedCouponData(state, {payload}) {
      state.selectedCoupon = payload;
    },
    setCouponEditModal(state, {payload}) {
      state.isCouponEditModal = payload;
    },
  },
});

export const {setDeactivateCoupon, setSelectedCouponData, setCouponEditModal} =
  couponSlice.actions;
