import React, {useEffect, useState} from 'react';
import {ALL_TEXT, ENUMS, QUERYPARAM} from '../../../common';
import {
  ActionDropDownPopUp,
  ConfirmCancellationPopup,
  CustomizedSearch,
  GenericModal,
  Loader,
} from '../../../components';
import {PolicyCard} from '../../../components/policy-card';
import CustomerProfileLayout from '../main-layout';
import './style.scss';
import {BsThreeDotsVertical} from 'react-icons/bs';
import {Tooltip} from 'react-tooltip';
import {
  cancelCustomerPolicy,
  getCustomerPolicies,
} from '../../../utils/rest-services';
import {useDispatch, useSelector} from 'react-redux';
import {setModalDetails, openModal} from '../../../redux/slice/popup-modal';
import Pagination from '@mui/material/Pagination';
import {planCategory, serviceTypeIcon} from '../../../utils/helpers';
import {useLocation, useNavigate} from 'react-router';
import {getValuesFromEnumsObj} from '../../../utils/helpers/index';
import {path} from '../../../common/routesNames';

function CustomerPolicy() {
  const {
    POLICY_TYPES: {HOME, DEVICE, COMMERCIAL, VEHICLE, ALL},
  } = ENUMS;
  const menuItems = [
    {
      title: ALL_TEXT.ADD_CLAIM,
      icon: <span class="icon-plus popup-menu-icon green-popup-icon"></span>,
    },
    {
      title: ALL_TEXT.CANCEL,
      icon: <span class="icon-close popup-menu-icon red-popup-icon"></span>,
    },
  ];
  const [policyList, setPolicyList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [btnLoader, setBtnLoader] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [policyType, setPolicyType] = useState(0);
  const [ispolicySuccess, setIspolicySuccess] = useState(false);
  const [successResponse, setSuccessResponse] = useState(false);
  const [ispolicyResponse, setIspolicyResponse] = useState({
    type: '',
    title: '',
    description: '',
  });
  const [cancelablePolicy, setCancelablePolicy] = useState({
    isOpen: false,
    reason: '',
    cartItemId: 0,
    signupId: 0,
  });
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const isSelectedUser = useSelector(
    state => state.checkout.isUserChange ?? null,
  );
  const getParamFromUrl = () => {
    return new URLSearchParams(location.search);
  };
  let query = getParamFromUrl();
  const signupId = query.get(QUERYPARAM.SIGNUPID);

  const signupIdState = useSelector(
    state => state.checkout.selectedUser?.signupId ?? null,
  );
  useEffect(() => {
    const payload = {
      signupId: signupIdState || signupId,
      pageNo: currentPage,
      rowsPerPage: 10,
      status: 0,
      id: 0,
      policyType: policyType,
    };
    getCustomerPolicyHandler(payload);
  }, [currentPage, signupIdState, isSelectedUser]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const getCustomerPolicyHandler = async payload => {
    setLoader(true);
    let result = await getCustomerPolicies(payload);
    if (result && result.success) {
      const {
        content: {list, totalPages},
      } = result;
      setLoader(false);
      setTotalPage(totalPages);
      setPolicyList(list);
    } else {
      setPolicyList([]);
    }
  };

  const policySearchhandler = async (id, type, clear) => {
    let payload;
    const coverageType = getValuesFromEnumsObj(ENUMS.POLICY_TYPES, type);
    if (clear) {
      payload = {
        signupId: signupId,
        pageNo: 1,
        rowsPerPage: ENUMS.PAGE_SIZE,
        id: 0,
        policyType: 0,
      };
      setPolicyType(0);
    } else {
      setPolicyType(coverageType);
      payload = {
        signupId: signupId,
        pageNo: 1,
        rowsPerPage: ENUMS.PAGE_SIZE,
        id: id ? id?.split('_')[1] || id : 0,
        policyType: coverageType,
      };
    }
    setCurrentPage(1);
    setBtnLoader(true);
    await getCustomerPolicyHandler(payload);
    setBtnLoader(false);
  };
  const confirmCancelSubscriptionHandler = async () => {
    setBtnLoader(true);
    const payload = {
      signupId: cancelablePolicy.signupId,
      cartItemId: cancelablePolicy.cartItemId,
      cancelReason: cancelReason,
    };
    let response = await cancelCustomerPolicy(payload);

    setBtnLoader(false);
    if (response) {
      setCancelablePolicy({isOpen: false});
      setIspolicySuccess(true);
      setSuccessResponse(response.data.success);
      let type = response.data.success
        ? ENUMS.POPUP_ENUMS.SUCCESS
        : ENUMS.POPUP_ENUMS.ERROR;
      let title = response.data.success
        ? ALL_TEXT.GENERIC_MODAL.SUCCESS
        : ALL_TEXT.GENERIC_MODAL.ERROR;
      setIspolicyResponse({
        type: type,
        title: title,
        description: response?.data?.description,
      });
    }
    // let result = await cancelSubscription(payload);
  };

  return (
    <CustomerProfileLayout selectedId={ENUMS.CUSTOMER_TAB_INDEX.POLICY}>
      <div className="customer-policy">
        <CustomizedSearch
          title={'Search Plan By'}
          firstLabel="Plan Id"
          firstPrefixerValue={'pln_'}
          isFirstInputNumeric
          firstPlaceholder={'Enter Plan Id'}
          firstDropdown
          firstDropdownLabel={'Plan Type'}
          firstDropdownList={[
            HOME.LABEL,
            DEVICE.LABEL,
            COMMERCIAL.LABEL,
            VEHICLE.LABEL,
            ALL.LABEL,
          ]}
          onClearFilterHandler={() => {
            policySearchhandler('', '', true);
          }}
          onSearchBtnPress={(policyId, _, __, policyType) => {
            policySearchhandler(policyId, policyType);
          }}
          btnLoader={btnLoader}
        />
        {!loader && policyList.length === 0 && (
          <div className="loader-container-dasboard flex-center">
            <span> {ALL_TEXT.NO_POLICY}</span>
          </div>
        )}
        {loader ? (
          <div className="loader-container-dasboard">
            <Loader />
          </div>
        ) : (
          policyList?.map((i, index) => {
            const {
              serviceProviderType,
              subscriptionType,
              serviceProvider,
              cost,
              policyId,
              make,
              model,
              deductibleAmount,
              serviceTypeId,
              isCancelable,
              isClaimable,
              claimDescription,
              cartItemId,
              serviceProviderId,
              brand,
              planCost,
              address,
            } = i || {};

            return (
              <PolicyCard
                key={index}
                cardType={ENUMS.VIEW_CARD_TYPE.POLICY}
                planIconTitle={
                  <div className="plan-name">
                    {serviceTypeIcon(serviceTypeId)}
                    <span className="description-title">
                      {serviceProviderType}
                    </span>
                  </div>
                }
                badge={
                  <span class={planCategory(subscriptionType)}>
                    {subscriptionType}
                  </span>
                }
                cost={
                  <>
                    <span className="fw-bold">
                      <span>
                        {`${ALL_TEXT.MONTHLY_COST} $${parseFloat(cost).toFixed(
                          2,
                        )}`}
                      </span>
                      <span className="d-inline-block vertical-dots-policy">
                        <ActionDropDownPopUp
                          items={menuItems}
                          visibleIcon={<BsThreeDotsVertical />}
                          addPolicy={() => {
                            if (!isClaimable) {
                              // temporary removing ! from here
                              dispatch(
                                setModalDetails({
                                  title: ALL_TEXT.CANT_ADD_CLAIM,
                                  details: claimDescription,
                                  type: ENUMS.MODAL_TYPES.ALERT,
                                }),
                              );
                              dispatch(openModal());
                            } else {
                              navigate({
                                pathname: path.addNewClaim,
                                search: `?${
                                  QUERYPARAM.SERVICE_PROVIDER
                                }=${serviceProvider}&${
                                  QUERYPARAM.SERVICE_TYPE
                                }=${subscriptionType}&${
                                  QUERYPARAM.AMOUNT
                                }=${parseFloat(deductibleAmount).toFixed(2)}&${
                                  QUERYPARAM.CART_ITEM_ID
                                }=${cartItemId}&${
                                  QUERYPARAM.SERVICE_PROVIDER_ID
                                }=${serviceProviderId}&${QUERYPARAM.SIGNUPID}=${
                                  signupIdState || signupId
                                }&${
                                  QUERYPARAM.SERVICE_PROVIDER_TYPE
                                }=${serviceProviderType}`,
                              });
                            }
                          }}
                          cancelPolicy={() => {
                            if (!isCancelable) {
                              // temporary removing ! from here

                              dispatch(
                                setModalDetails({
                                  title: ALL_TEXT.CANT_CANCEL_POLICY,
                                  details: ALL_TEXT.CANT_CANCEL_POLICY_DETAILS,
                                  type: ENUMS.MODAL_TYPES.ALERT,
                                }),
                              );
                              dispatch(openModal());
                            } else {
                              setCancelablePolicy({
                                isOpen: true,
                                cartItemId: cartItemId,
                                signupId: signupId,
                                reason: cancelReason,
                              });
                            }
                          }}
                        />
                      </span>
                    </span>
                  </>
                }
                LeftTopDetails={
                  <div className="details-item">
                    <span>{ALL_TEXT.POLICY_ID}</span>
                    <span
                      data-tooltip-id={`policy-id-tooltip`}
                      data-tooltip-content={`Copy to Clipboard`}
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        navigator.clipboard.writeText(policyId);
                      }}>
                      {policyId}
                    </span>
                    <Tooltip id={`policy-id-tooltip`} />
                  </div>
                }
                rightTopDetails={
                  serviceTypeId === ENUMS.COVERAGE_TYPES.HOME ||
                  serviceTypeId === ENUMS.COVERAGE_TYPES.DISASTER ? (
                    <div className="details-item">
                      <span>{ALL_TEXT.PROVIDER}</span>
                      <span>{serviceProvider}</span>
                    </div>
                  ) : serviceTypeId === ENUMS.COVERAGE_TYPES.DEVICE ? (
                    <div className="details-item">
                      <span>{ALL_TEXT.ENTER_BRAND.slice(5)}</span>
                      <span>{brand}</span>
                    </div>
                  ) : (
                    <div className="details-item">
                      <span>{ALL_TEXT.ENTER_Maker.slice(5)}</span>
                      <span>{make}</span>
                    </div>
                  )
                }
                rigthBottomDetails={
                  serviceTypeId === ENUMS.COVERAGE_TYPES.HOME ||
                  serviceTypeId === ENUMS.COVERAGE_TYPES.DISASTER ? (
                    <div className="details-item">
                      <span>{ALL_TEXT.PLAN_COST}</span>
                      <span>{`$${parseFloat(planCost).toFixed(2)}`}</span>
                    </div>
                  ) : (
                    <div className="details-item">
                      <span>{ALL_TEXT.SELECT_DEDUCTIBLES.slice(7)}</span>
                      <span>{`$${parseFloat(deductibleAmount).toFixed(
                        2,
                      )}`}</span>
                    </div>
                  )
                }
                LeftBottomDetails={
                  serviceTypeId === ENUMS.COVERAGE_TYPES.HOME ||
                  serviceTypeId === ENUMS.COVERAGE_TYPES.DISASTER ? (
                    <div className="details-item">
                      <span>{ALL_TEXT.SELECT_DEDUCTIBLES.slice(7)}</span>
                      <span>{`$${parseFloat(deductibleAmount).toFixed(
                        2,
                      )}`}</span>
                    </div>
                  ) : (
                    <div className="details-item">
                      <span>{ALL_TEXT.ENTER_MODEL.slice(5)}</span>
                      <span>{model}</span>
                    </div>
                  )
                }
                lastLeftBottomDetails={
                  serviceTypeId === ENUMS.COVERAGE_TYPES.HOME ||
                  serviceTypeId === ENUMS.COVERAGE_TYPES.DISASTER ? (
                    <div className="details-item address">
                      <span>{ALL_TEXT.ENTER_ADDRESS.slice(5)}</span>
                      <span>{address}</span>
                    </div>
                  ) : (
                    <div className="details-item">
                      <span>{ALL_TEXT.PROVIDER}</span>
                      <span>{serviceProvider}</span>
                    </div>
                  )
                }
                lastBottomDetails={
                  serviceTypeId === ENUMS.COVERAGE_TYPES.HOME ||
                  serviceTypeId === ENUMS.COVERAGE_TYPES.DISASTER ? null : (
                    <div className="details-item">
                      <span>{ALL_TEXT.PLAN_COST}</span>
                      <span>{`$${parseFloat(planCost).toFixed(2)}`}</span>
                    </div>
                  )
                }
              />
            );
          })
        )}

        {ispolicySuccess && (
          <GenericModal
            show={ispolicySuccess}
            type={ispolicyResponse.type}
            title={ispolicyResponse.title}
            body={ispolicyResponse.description}
            buttonText={ALL_TEXT.OK}
            handleClose={() => {
              setIspolicySuccess(false);
              if (successResponse) {
                const payload = {
                  signupId: signupIdState || signupId,
                  pageNo: 1,
                  rowsPerPage: 10,
                  status: 0,
                  id: 0,
                  policyType: policyType,
                };
                getCustomerPolicyHandler(payload);
              }
            }}
          />
        )}
      </div>
      {totalPage > 1 && (
        <div className="pagination-container">
          <div className="inner-pagination">
            <Pagination
              page={currentPage}
              color={'secondary'}
              onChange={handlePageChange}
              count={totalPage}
            />
          </div>
        </div>
      )}

      {cancelablePolicy.isOpen && (
        <ConfirmCancellationPopup
          show={cancelablePolicy.isOpen}
          reason={cancelReason}
          setReason={setCancelReason}
          loader={btnLoader}
          handelSubmit={data => {
            confirmCancelSubscriptionHandler(data);
          }}
          handleClose={() => {
            setBtnLoader(false);
            setCancelablePolicy({
              isOpen: false,
            });
          }}
        />
      )}
    </CustomerProfileLayout>
  );
}

export default CustomerPolicy;
