import React, {useState} from 'react';
import {Formik} from 'formik';
import 'react-datepicker/dist/react-datepicker.css';
import {Grid} from '@mui/material';
import {Tooltip} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import {AiFillCheckCircle} from 'react-icons/ai';
import {BsSearch} from 'react-icons/bs';
import './style.scss';
import {ALL_TEXT, ENUMS, colors} from '../../../../common';
import {
  CriteriaDropdown,
  GenericButton,
  GenericModal,
  Loader,
  TextAreaFeild,
} from '../../../../components';
import {InputField} from './../../../../components/input-field/index';
import {IoMdCloseCircle} from 'react-icons/io';
import {
  addCouponDiscountAmountSchema,
  addCouponFirstMonthSchema,
  addCouponFixedAmountSchema,
} from '../../../../common/schemas';
import {
  NodukAdmnApi,
  usePostApiAdminAddCouponCodeMutation,
} from '../../../../redux/slice/nodukAdmin.ts';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router';
import {path} from '../../../../common/routesNames';

function AddCouponCode() {
  const FIRST_AMOUNT_TEXT = 'First';
  const {useLazyGetApiAdminVerifyPromotionCodeQuery} = NodukAdmnApi;
  const [isFirstMonthSchema, setIsFirstMonthSchema] = useState(false);
  const [isDisabled, setisDisabled] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [modalDetails, setModalDetails] = useState({
    type: ENUMS.POPUP_ENUMS.SUCCESS,
    details: '',
  });
  const [couponCode, setCouponCode] = useState('');
  const [verifyCouponCodeAPI, verifyCouponCodeResponse] =
    useLazyGetApiAdminVerifyPromotionCodeQuery();
  const [addCouponCodeAPI, addCouponCodeResponse] =
    usePostApiAdminAddCouponCodeMutation();
  const navigate = useNavigate();

  const [selectedCouponDiscount, setSelectedCouponDiscount] = useState(
    ENUMS.COUPON_DISCOUNT.FIXED_AMOUNT,
  );
  const [showMinimumPayableMessage, setShowMinimumPayableMessage] =
    useState(false);
  // const handleNotesChange = event => {
  //   setNotes(event.target.value);
  // };

  const handleInputChange = event => {
    setCouponCode(event.target.value);
  };

  const cancelCoupon = resetForm => {
    setisDisabled(true);
    setShowMinimumPayableMessage(false);
    resetForm();
  };
  const options = [
    {value: 'Once', label: 'Once (Single Invoice)'},
    {value: 'Forever', label: 'Forever (All Invoices)'},
    {value: FIRST_AMOUNT_TEXT, label: 'First month 100% (first month invoice)'},
  ];
  const startDatePlaceholder = 'ALL_TEXT.ADD_COUPON.START_DATE';

  const getEndDate = date => {
    if (date != '') {
      const currentDate = new Date(date);
      const nextDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000); // Add 24 hours in milliseconds
      return nextDate.toISOString().split('T')[0];
    }
  };
  // const verifyCouponCodeHandler = async promocode => {
  //   let response = await verifyCouponCodeAPI({promotionCode: promocode});
  //   if (response && response.data.success) {
  //     setisDisabled(false);
  //   } else {
  //     toast.error(response.data.description);
  //   }
  // };

  const verifyCouponCodeHandler = async promocode => {
    setIsVerifying(true); // Start verifying
    try {
      let response = await verifyCouponCodeAPI({promotionCode: promocode});
      if (response && response.data.success) {
        setisDisabled(false); // Coupon is verified
      } else {
        toast.error(response.data.description);
      }
    } catch (error) {
      toast.error('An error occurred while verifying the coupon.');
    } finally {
      setIsVerifying(false); // Verification done
    }
  };

  const addCouponHandler = async value => {
    let couponType = 0;
    switch (value.couponType.value.toLowerCase()) {
      case 'once':
        couponType = 1;
        break;
      case 'forever':
        couponType = 2;
        break;
      default:
        couponType = 3;
        break;
    }

    const payload = {
      agentCouponCode: {
        couponDuration: value.couponType.value.toLowerCase(),
        amountOff: couponType === 3 ? null : parseFloat(value.fixedAmount ?? 0),
        percentOff:
          value.discountAmount != ''
            ? parseFloat(value.discountAmount ?? 0)
            : null,
        minimumCouponAmount: parseFloat(value.minimumDiscountAmount ?? 0),
        validTill: value.couponExpiryDate,
        startDate: value.couponStartDate,
        redeemLimit: parseInt(value.redemptions),
        description: value.notes,
        promotionCode: value.couponCode,
        couponName: value.couponCode,
        couponType: couponType,
      },
    };

    let response = await addCouponCodeAPI(payload);
    if (response && response.data.success) {
      setModalDetails({
        type: ENUMS.POPUP_ENUMS.SUCCESS,
        details: response.data.description,
      });
      setShowModal(true);
    } else {
      setModalDetails({
        type: ENUMS.POPUP_ENUMS.ERROR,
        details: response.data.description,
      });
      setShowModal(true);
    }
  };

  const getValidationSchema = () => {
    if (!isDisabled) {
      if (isFirstMonthSchema) {
        return addCouponFirstMonthSchema;
      }
      if (selectedCouponDiscount === ENUMS.COUPON_DISCOUNT.FIXED_AMOUNT) {
        return addCouponFixedAmountSchema;
      } else {
        return addCouponDiscountAmountSchema;
      }
    } else {
      return null;
    }
  };

  return (
    <Formik
      enableReinitialize
      validateOnMount={true}
      onSubmit={values => {
        addCouponHandler(values);
      }}
      validationSchema={getValidationSchema()}
      initialValues={{
        couponCode: '',
        couponType: null,
        fixedAmount: '',
        discountAmount: '',
        redemptions: '',
        couponStartDate: null,
        couponExpiryDate: null,
        minimumDiscountAmount: '',
        notes: '',
      }}>
      {({
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        values,
        touched,
        errors,
        resetForm,
      }) => (
        <div className="add-coupon-main-container">
          <div className="title-container-add-coupon">
            <div>
              <span>{ALL_TEXT.ADMINISTRATION} / </span>
              <span>{ALL_TEXT.ADD_COUPON_CODE}</span>
            </div>
          </div>
          <div className="add-coupon-container">
            <label htmlFor="/">{ALL_TEXT.ADD_COUPON.COUPON_CODE}</label>
            <div className="add-coupon-box">
              <div className="coupon-field">
                <InputField
                  disabled={!isDisabled || isVerifying}
                  id="couponCode"
                  name="couponCode"
                  value={values.couponCode}
                  handleBlur={handleBlur}
                  error={touched.couponCode && errors.couponCode}
                  placeholder={ALL_TEXT.ADD_COUPON.ENTER_COUPON_CODE}
                  onKeyPress={e => {
                    if (e.charCode == 13) {
                      if (values.couponCode) {
                        verifyCouponCodeHandler(values.couponCode);
                      }
                    }
                  }}
                  handleChange={event => {
                    const regexPattern = /^[a-zA-Z0-9_]+$/;
                    if (
                      regexPattern.test(event.target.value) ||
                      event.target.value == ''
                    ) {
                      handleChange(event);
                      handleInputChange(event);
                    }
                  }}
                  searchIcon={
                    verifyCouponCodeResponse.isFetching ? (
                      <span className="search-icon">
                        <Loader size={20} color={colors.white} />
                      </span>
                    ) : (
                      <span
                        className={
                          !isDisabled && !isVerifying
                            ? 'search-icon disabled-search-icon'
                            : 'search-icon'
                        }
                        onClick={() => {
                          if (values.couponCode) {
                            verifyCouponCodeHandler(values.couponCode);
                          }
                        }}>
                        <BsSearch />
                      </span>
                    )
                  }
                />
              </div>
              {!isDisabled && (
                <CouponCode
                  cancelCoupon={() => cancelCoupon(resetForm)}
                  couponCode={couponCode}
                />
              )}
            </div>
          </div>
          <div className="add-coupon-detail">
            <Grid container spacing={2}>
              {/* First Layer  */}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="coupon-input-container">
                  <div className="coupon-input-field ">
                    <label htmlFor="/">{ALL_TEXT.COUPON_TYPE_}</label>
                    <CriteriaDropdown
                      name="couponType"
                      handleBlur={handleBlur}
                      value={values.couponType}
                      onChangeOption={event => {
                        setFieldValue('couponType', event);
                        handleChange('couponType');
                        if (event.value == FIRST_AMOUNT_TEXT) {
                          setIsFirstMonthSchema(true);
                        } else {
                          setIsFirstMonthSchema(false);
                        }
                      }}
                      error={touched.couponType && errors.couponType}
                      dropdowntitle={`Select ${ALL_TEXT.COUPON_TYPE_}`}
                      isDisable={isDisabled}
                      className={isDisabled ? 'isDisabled' : 'isEnable'}
                      options={options}
                    />

                    <div className="coupon-note my-3">
                      <p className="coupon-note-text">
                        <span>{ALL_TEXT.SUBSCRIPTION.NOTE}</span>
                        {ALL_TEXT.COUPON_NOTE}
                        <a
                          href="https://noduk.com/ "
                          target="_blank"
                          rel="noopener noreferrer">
                          {ALL_TEXT.SUBSCRIPTION.LEARN_MORE}
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="coupon-input-field ">
                    <label htmlFor="/">{ALL_TEXT.COUPON_DISCOUNT}</label>
                    <div className="coupon-CoverageItem">
                      <div className="">
                        <CoverageItem
                          onClick={() => {
                            if (!isDisabled) {
                              setFieldValue('discountAmount', '');
                              handleChange('discountAmount', '');
                              setSelectedCouponDiscount(
                                ENUMS.COUPON_DISCOUNT.FIXED_AMOUNT,
                              );
                            }
                          }}
                          disabled={
                            isDisabled ||
                            values?.couponType?.value === FIRST_AMOUNT_TEXT
                          }
                          text={ALL_TEXT.ADD_COUPON.FIXED_AMOUNT}
                          check={
                            selectedCouponDiscount ===
                            ENUMS.COUPON_DISCOUNT.FIXED_AMOUNT
                          }
                          selectedTab={
                            selectedCouponDiscount ===
                            ENUMS.COUPON_DISCOUNT.FIXED_AMOUNT
                          }
                        />
                      </div>
                      <div className="coupon-amount">
                        <InputField
                          id="fixedAmount"
                          name="fixedAmount"
                          value={values.fixedAmount}
                          handleBlur={handleBlur}
                          handleChange={e => {
                            const numericValue = e.target.value.replace(
                              /[^0-9.]+/g,
                              '',
                            );
                            const restrictedValue = numericValue.substring(
                              0,
                              6,
                            );
                            setFieldValue('fixedAmount', restrictedValue);
                          }}
                          error={
                            selectedCouponDiscount ===
                              ENUMS.COUPON_DISCOUNT.FIXED_AMOUNT &&
                            touched.fixedAmount &&
                            errors.fixedAmount
                          }
                          disabled={
                            isDisabled ||
                            selectedCouponDiscount ===
                              ENUMS.COUPON_DISCOUNT.DISCOUNT_AMOUNT ||
                            values?.couponType?.value === FIRST_AMOUNT_TEXT
                          }
                          customSign={'$'}
                          placeholder={ALL_TEXT.ADD_COUPON.ENTER_AMOUNT}
                        />
                      </div>
                    </div>
                    <div className="coupon-CoverageItem">
                      <div className="">
                        <CoverageItem
                          onClick={() => {
                            if (!isDisabled) {
                              setFieldValue('fixedAmount', '');
                              handleChange('fixedAmount', '');
                              setSelectedCouponDiscount(
                                ENUMS.COUPON_DISCOUNT.DISCOUNT_AMOUNT,
                              );
                            }
                          }}
                          disabled={
                            isDisabled ||
                            values?.couponType?.value === FIRST_AMOUNT_TEXT
                          }
                          text={ALL_TEXT.ADD_COUPON.DISCOUNT_AMOUNT}
                          check={
                            selectedCouponDiscount ===
                            ENUMS.COUPON_DISCOUNT.DISCOUNT_AMOUNT
                          }
                          selectedTab={
                            selectedCouponDiscount ===
                            ENUMS.COUPON_DISCOUNT.DISCOUNT_AMOUNT
                          }
                        />
                      </div>
                      <div className="coupon-amount">
                        <InputField
                          id="discountAmount"
                          name="discountAmount"
                          handleBlur={handleBlur}
                          value={values.discountAmount}
                          handleChange={e => {
                            const numericValue = e.target.value.replace(
                              /[^0-9.]+/g,
                              '',
                            );
                            const restrictedValue = numericValue.substring(
                              0,
                              6,
                            );
                            setFieldValue('discountAmount', restrictedValue);

                            // Check if the entered value is between 51 and 89
                            const discountValue = parseFloat(restrictedValue);
                            setShowMinimumPayableMessage(
                              discountValue >= 51 && discountValue <= 90,
                            );
                          }}
                          error={
                            selectedCouponDiscount ===
                              ENUMS.COUPON_DISCOUNT.DISCOUNT_AMOUNT &&
                            touched.discountAmount &&
                            errors.discountAmount
                          }
                          disabled={
                            isDisabled ||
                            selectedCouponDiscount ===
                              ENUMS.COUPON_DISCOUNT.FIXED_AMOUNT ||
                            values?.couponType?.value === FIRST_AMOUNT_TEXT
                          }
                          customSign={'%'}
                          placeholder={ALL_TEXT.ADD_COUPON.ENTER_PERCENTAGE}
                        />
                      </div>
                    </div>
                    {showMinimumPayableMessage && (
                      <span style={{color: '#f39c12'}}>
                        {ALL_TEXT.COUPON_CODE_VALUE}
                      </span>
                    )}
                  </div>
                </div>
              </Grid>
              {/* Second Layer  */}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="coupon-input-container">
                  <div className="coupon-input-field text-container">
                    <label htmlFor="/">{ALL_TEXT.ADD_COUPON.DESCRIPTION}</label>
                    <div className="texarea-box">
                      <TextAreaFeild
                        disabled={isDisabled}
                        id={'notes'}
                        name={'notes'}
                        value={values.notes}
                        setValue={e => {
                          setFieldValue('notes', e.target.value);
                        }}
                        placeholder={ALL_TEXT.ADD_COUPON.DESCPTION_PLACE_HOLDER}
                      />
                    </div>
                  </div>
                  <div className="coupon-input-field text-container ">
                    <div className=" field-range my-1">
                      <label htmlFor="/">
                        {ALL_TEXT.ADD_COUPON.MAXIMUM_REDEMPTIONS}
                      </label>
                      <InputField
                        id="redemptions"
                        name="redemptions"
                        value={values.redemptions}
                        handleChange={e => {
                          const numericValue = e.target.value.replace(
                            /[^0-9+]/g,
                            '',
                          );
                          const restrictedValue = numericValue.substring(0, 6);
                          setFieldValue('redemptions', restrictedValue);
                        }}
                        handleBlur={handleBlur}
                        error={touched.redemptions && errors.redemptions}
                        placeholder={ALL_TEXT.ADD_COUPON.ENTER_COUNT}
                        disabled={isDisabled}
                      />
                    </div>
                    <div className=" discount-amount field-range my-1">
                      <label htmlFor="/">
                        {ALL_TEXT.ADD_COUPON.MINIMUM_DISCOUNT_AMOUNT}
                      </label>
                      <InputField
                        disabled={isDisabled}
                        customSign={'$'}
                        placeholder={ALL_TEXT.ADD_COUPON.ENTER_AMOUNT}
                        id="minimumDiscountAmount"
                        name="minimumDiscountAmount"
                        value={values.minimumDiscountAmount}
                        handleChange={e => {
                          const numericValue = e.target.value.replace(
                            /[^0-9.]+/g,
                            '',
                          );
                          const restrictedValue = numericValue.substring(0, 6);
                          setFieldValue(
                            'minimumDiscountAmount',
                            restrictedValue,
                          );
                        }}
                        handleBlur={e => {
                          const numericValue = e.target.value.replace(
                            /[^0-9.]+/g,
                            '',
                          );
                          const restrictedValue =
                            parseFloat(numericValue) < 1 ? '1' : numericValue;
                          setFieldValue(
                            'minimumDiscountAmount',
                            restrictedValue,
                          );
                        }}
                        error={
                          touched.minimumDiscountAmount &&
                          errors.minimumDiscountAmount
                        }
                      />
                    </div>
                  </div>
                </div>
              </Grid>
              {/* Third Layer */}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="coupon-input-container">
                  <div className="coupon-input-field ">
                    <label htmlFor="/">
                      {ALL_TEXT.ADD_COUPON.COUPON_START_DATE}
                    </label>
                    <InputField
                      id="couponStartDate"
                      name="couponStartDate"
                      value={values?.couponStartDate}
                      handleBlur={handleBlur}
                      onKeyDown={e => e.preventDefault()}
                      handleChange={e => {
                        setFieldValue('couponExpiryDate', '');
                        setFieldValue('couponStartDate', e.target.value);
                      }}
                      error={touched.couponStartDate && errors.couponStartDate}
                      disabled={isDisabled}
                      placeholder={startDatePlaceholder}
                      type="date"
                      min={new Date().toISOString().split('T')[0]}
                    />
                  </div>
                  <div className="coupon-input-field">
                    <label htmlFor="/">
                      {ALL_TEXT.ADD_COUPON.COUPON_EXPIRY_DATE}
                    </label>
                    <InputField
                      id="couponExpiryDate"
                      name="couponExpiryDate"
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      value={values.couponExpiryDate}
                      onKeyDown={e => e.preventDefault()}
                      error={
                        touched.couponExpiryDate && errors.couponExpiryDate
                      }
                      min={
                        getEndDate(values.couponStartDate) ||
                        new Date().toISOString().split('T')[0]
                      }
                      disabled={isDisabled}
                      placeholder={ALL_TEXT.ADD_COUPON.EXPIRY_DATE}
                      type="date"
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="add-coupon-action">
                  <GenericButton
                    buttonText={ALL_TEXT.BUTTON_TEXT.ADD}
                    onPress={handleSubmit}
                    disable={isDisabled}
                    loading={addCouponCodeResponse.isLoading}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          {showModal && (
            <GenericModal
              show={showModal}
              type={modalDetails.type}
              buttonText={ALL_TEXT.BUTTON_TEXT.OK}
              body={modalDetails.details}
              primaryBtnPress={() => {
                if (modalDetails.type == ENUMS.POPUP_ENUMS.SUCCESS) {
                  navigate(path.couponCode, {replace: true});
                } else {
                  setShowModal(false);
                }
              }}
            />
          )}
        </div>
      )}
    </Formik>
  );
}

const CoverageItem = ({text, check, onClick, selectedTab, disabled}) => {
  return (
    <div
      onClick={onClick}
      className={
        selectedTab ? 'coverage-item coverage-item-selected' : 'coverage-item'
      }>
      <input
        disabled={disabled}
        class="form-check-input"
        type="radio"
        id="flexRadioDefault2"
        name="radio-group-2"
        value={`name-Vehicle`}
        checked={check}></input>
      <span className="coupon-discount-text">{text}</span>
    </div>
  );
};

const CouponCode = ({cancelCoupon, couponCode}) => {
  return (
    <div className="coupon-container">
      <div className="coupon-number">
        <span>{couponCode}</span>
        <span className="coupon-cancel-icon" onClick={cancelCoupon}>
          <IoMdCloseCircle color={colors.dropDownBackground} size={20} />
        </span>
      </div>
      <AiFillCheckCircle
        style={{cursor: 'pointer'}}
        color={colors.green}
        size={20}
        data-tip={'Coupon Code is Verified.'}
        data-tooltip-id={'Coupon Code is Verified.'}
        data-tooltip-content={'Coupon Code is Verified..'}
      />
      <Tooltip id={'Coupon Code is Verified.'} classNameArrow="arrow" />
    </div>
  );
};

export default AddCouponCode;
