import React from 'react';
import {CellValue, ToggleButton} from '../../../../components';
import {GetBadge} from '../../../../utils/helpers/users';
import {ENUMS} from '../../../../common';
import {TbEdit} from 'react-icons/tb';
import {useDispatch} from 'react-redux';
import {
  setAreaModalOpen,
  setEditDisasterArea,
  setIsUpdateStatusModal,
} from '../../../../redux/slice/disaster-areas';

const {
  USERS: {STATUS},
} = ENUMS;
export function DisasterAreasTable() {
  const dispatch = useDispatch();
  return [
    {
      title: 'Zip Code',
      fieldName: 'zipCode',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Risk Level',
      fieldName: 'riskLevel',
      dataformat: (cell, row) => {
        return GetBadge(row.riskLevel);
      },
    },
    {
      title: 'Deductibles %',
      fieldName: 'deductiblePercent',
      dataformat: (cell, row) => <CellValue cell={`${cell}%`} row={row} />,
    },
    {
      title: 'Status',
      fieldName: 'status',
      dataformat: (cell, row) =>
        GetBadge(row.status ? STATUS.ACTIVE.LABEL : STATUS.INACTIVE.LABEL),
    },
    {
      title: 'Actions',
      fieldName: 'action',
      dataformat: (cell, row) => (
        <div>
          <span className="action-icons-staff">
            <span
              className="edit-icon"
              onClick={() => {
                dispatch(setAreaModalOpen(true));
                dispatch(setEditDisasterArea(row));
              }}>
              <TbEdit />
            </span>

            <span className="delete-icon-staff">
              <ToggleButton
                status={!row?.status}
                onClick={() => {
                  dispatch(setIsUpdateStatusModal(true));
                  dispatch(setEditDisasterArea(row));
                }}
              />
            </span>
          </span>
        </div>
      ),
    },
  ];
}
