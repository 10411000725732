import React from 'react';
import {Tooltip} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import './style.scss';
import {ALL_TEXT} from '../../common';

export const CellValue = ({
  cell,
  row,
  tooltipData,
  disableTooltip,
  customClassName,
}) => {
  const shouldShowTooltip = !disableTooltip;

  return (
    <>
      <span
        className={customClassName}
        onClick={() => {
          navigator.clipboard.writeText(cell);
        }}
        data-tip={shouldShowTooltip ? row?.name || cell : null}
        data-tooltip-id={shouldShowTooltip ? cell : null}
        data-tooltip-content={shouldShowTooltip ? tooltipData || cell : null}
        style={{cursor: shouldShowTooltip ? 'pointer' : 'default'}}>
        {cell === 0 ? cell : cell || ALL_TEXT.NOT_AVAILABLE}
      </span>
      {shouldShowTooltip && <Tooltip id={cell} classNameArrow="arrow" />}
    </>
  );
};
