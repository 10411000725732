import { ALL_TEXT, ENUMS } from "../../../common";
import React from 'react';
import { Modal } from 'react-bootstrap';
import { colors } from '../../../common/theme';
import { GrClose } from 'react-icons/gr';
import { TextAreaFeild } from '../../../components';
import { GenericButton } from '../../../components/generic-button';
import { Grid } from '@mui/material';
import { usePostApiAdminRequestDocumentsMutation } from "../../../redux/slice/nodukAdmin.ts";
import {toast} from 'react-toastify';
import { useState } from "react";
import {useDispatch} from 'react-redux';
import { setIsRequestDocumentPopup } from "../../../redux/slice/administrator";
import { setModalDetails } from "../../../redux/slice/popup-modal";

export function RequestDocuments({ show, handleClose, data }) {
    const [isMessageValid, setIsMessageValid] = useState(false);
    const dispatch = useDispatch();  
    const [message, setMessage] = useState('');
    const handleChange = event => {
        const text = event.target.value;
        setMessage(text);
        setIsMessageValid(text.trim() !== '');
      };
    const [
        requestDocumentsAPI,
        requestdocumentsRespone,
    ] = usePostApiAdminRequestDocumentsMutation();
    const payload ={
        requestDocsDto:{
        employeeId:data?.employeeId,
        roleId:data?.roleId,
        name:data?.firstName+' '+data?.lastName,
        email:data?.email,
        message:message,
        }

    }
    const sendemail = async payload => {
        try {
          let result =   await requestDocumentsAPI(payload);
            {result?.data?.success &&
                toast.success(
                    result.data?.description ||
                    ALL_TEXT.EMAIL_SENT,
                  );
                }
                {result?.error?.data &&
                    toast.error(
                        result.error?.data?.title ||
                        ALL_TEXT.EMAIL_NOT_SENT,
                      );
                    }
        } catch (error) {
            dispatch(
                setModalDetails({
                    type: ENUMS.POPUP_ENUMS.ERROR,
                    details: error,
                }),
            );
        }
        dispatch(setIsRequestDocumentPopup(false));
    }
    return (
        <>
            <>
                <Modal
                    className="email-share-QR-wrapper"
                    centered
                    size="lg"
                    show={show}
                    onHide={handleClose}>
                    <Modal.Header>
                        <div className="top-add-payment-container">
                            <span>{ALL_TEXT.REQUEST_DOCUMENTS} </span>
                            <GrClose
                                className="add-payment-close-btn"
                                onClick={handleClose}
                                size={18}
                                color={colors.primary}
                            />
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="add-edit-deductibles-container">
                            <div>
                                <span>Please Specify which documents you would like to update.</span>
                            </div>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                                <div className="textArea-add-policy">
                                    <div className="noti-single-container">
                                        <label htmlFor="/">{ALL_TEXT.NOTI_MESSAGE}</label>
                                        <TextAreaFeild
                                            length={'400'}
                                            id={'message'}
                                            name={'message'}
                                            placeholder={'Enter Notification Message'}
                                            onChange={handleChange} value={message}

                                        />
                                    </div>
                                </div>
                            </Grid>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <GenericButton
                            loading={requestdocumentsRespone.isLoading}
                            buttonText={ALL_TEXT.SEND_EMAIL}
                            onPress={()=>sendemail(payload)
                        }
                        disable={!isMessageValid}
                        />
                    </Modal.Footer>
                </Modal>
            </>
        </>
    );

}
export default RequestDocuments;
