import React from 'react';
import './styles.scss';

export const ToggleButton = ({status, onToggleSwitch, onClick}) => {
  return (
    <>
      <div className="toggle-container toggle-component">
        <input
          type="checkbox"
          name="toggleButton"
          id="toggleButton"
          checked={status}
          onChange={onToggleSwitch}
          onClick={onClick}
        />
      </div>
    </>
  );
};
