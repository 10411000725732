import {createSlice} from '@reduxjs/toolkit';

export const disasterSlice = createSlice({
  name: 'disasterSlice',
  initialState: {
    isRiskStatusModal: '',
    isRiskLevelOpenMoadal: '',
    selectedRiskLevel: null,
  },
  reducers: {
    setIsRiskStatusOpenMoadal(state, {payload}) {
      state.isRiskStatusModal = payload;
    },
    setIsRiskLevelOpenMoadal(state, {payload}) {
      state.isRiskLevelOpenMoadal = payload;
    },
    setSelectedRiskLevel(state, {payload}) {
      state.selectedRiskLevel = payload;
    },
  },
});

export const {
  setIsRiskStatusOpenMoadal,
  setIsRiskLevelOpenMoadal,
  setSelectedRiskLevel,
} = disasterSlice.actions;
