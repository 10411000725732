import React from 'react';
import {ActionDropDownPopUp, CellValue} from '../../components';
import {GetBadge} from '../../utils/helpers/users';
import {ENUMS} from '../../common';
import {TbEdit} from 'react-icons/tb';
import './styles.scss';
import {planSelectorIcon} from '../../utils/helpers';
import {useDispatch} from 'react-redux';

import {ALL_TEXT} from './../../common/constants';
import {
  carrierOpenModal,
  deleteOpenModal,
  setEditCarrier,
  setRemoveCarrier,
  updateCarrier,
} from '../../redux/slice/carriers';
import {BsThreeDotsVertical} from 'react-icons/bs';

import {path} from '../../common/routesNames';

const {
  USERS: {STATUS},
} = ENUMS;

export function TableCarrier() {
  const dispatch = useDispatch();

  const getMenuItems = row => {
    return [
      {
        title: ALL_TEXT.EDIT,
        icon: (
          <span class="popup-gray-menu-icon gray-popup-icon">
            <TbEdit />
          </span>
        ),
        onItemPress: () => {
          dispatch(updateCarrier());
          dispatch(carrierOpenModal());
          dispatch(setEditCarrier(row));
        },
      },
      {
        title: row?.status
          ? ALL_TEXT.DEACTIVATE_CARRIER_
          : ALL_TEXT.ACTIVATE_CARRIER_,
        icon: row?.status ? (
          <span class="icon-error popup-menu-icon red-popup-icon"></span>
        ) : (
          <span class="icon-Vector-27-Stroke popup-menu-icon green-popup-icon"></span>
        ),
        onItemPress: () => {
          dispatch(setRemoveCarrier(row));
          dispatch(deleteOpenModal());
        },
      },
    ];
  };

  return [
    {
      title: 'Service Type',
      fieldName: 'serviceTypeId',
      dataformat: cell => (
        <div>
          <span>{planSelectorIcon(cell)}</span>
        </div>
      ),
    },
    {
      title: 'Carrier Name',
      fieldName: 'providerName',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Phone',
      fieldName: 'phone',
      dataformat: (cell, row) => (
        <CellValue
          cell={cell === '' ? ALL_TEXT.NOT_AVAILABLE : cell}
          row={row}
        />
      ),
    },
    {
      title: 'Website',
      fieldName: 'website',
      dataformat: (cell, row) => (
        <CellValue
          cell={cell === '' ? ALL_TEXT.NOT_AVAILABLE : cell}
          row={row}
        />
      ),
    },
    {
      title: 'Status',
      fieldName: 'status',
      dataformat: (cell, row) =>
        GetBadge(row.status ? STATUS.ACTIVE.LABEL : STATUS.INACTIVE.LABEL),
    },
    {
      title: 'Action',
      fieldName: '',
      dataformat: (cell, row) => (
        <ActionDropDownPopUp
          visibleIcon={<BsThreeDotsVertical />}
          items={getMenuItems(row)}
          userObj={row}
          screenCode={path.manageCarriers}
        />
      ),
    },
  ];
}
