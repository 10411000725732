import {createSlice} from '@reduxjs/toolkit';

export const logsSlice = createSlice({
  name: 'logsSlice',
  initialState: {
    isLogPopUp: '',
    isNotificationPopUp: '',
    isDeviceTokenPopUp: '',
    selectedDeviceTokenData: null,
    selectedEmailData: null,
    selectedNotificationData: null,
    selectedAuditData: null,
  },
  reducers: {
    setIsLogPopup(state, {payload}) {
      state.isLogPopUp = payload;
    },
    setSelectedEmailData(state, {payload}) {
      state.selectedEmailData = payload;
    },
    setNotificationPopUp(state, {payload}) {
      state.isNotificationPopUp = payload;
    },
    setSetlectedNotificationData(state, {payload}) {
      state.selectedNotificationData = payload;
    },
    setSelectedAuditData(state, {payload}) {
      state.selectedAuditData = payload;
    },
    setIsDeviceTokenPopUp(state, {payload}) {
      state.isDeviceTokenPopUp = payload;
    },
    setSelectedDeviceTokenData(state, {payload}) {
      state.selectedDeviceTokenData = payload;
    },
  },
});
export const {
  setIsLogPopup,
  setSelectedEmailData,
  setNotificationPopUp,
  setSetlectedNotificationData,
  setSelectedAuditData,
  setIsDeviceTokenPopUp,
  setSelectedDeviceTokenData,
} = logsSlice.actions;
