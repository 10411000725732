import {React} from 'react';
import {ActionDropDownPopUp, CellValue} from '../../../components';
// import {GetBadge} from '../../../utils/helpers/users';
import {BsThreeDotsVertical} from 'react-icons/bs';
import {ALL_TEXT, ENUMS} from '../../../common';
import {TbEdit} from 'react-icons/tb';
import {GetBadge} from '../../../utils/helpers/users';
import {
  setCouponEditModal,
  setDeactivateCoupon,
  setSelectedCouponData,
} from '../../../redux/slice/coupon-code';
import {useDispatch} from 'react-redux';

const {
  USERS: {STATUS},
} = ENUMS;
export function tableHeading() {
  const dispatch = useDispatch();

  const menuItems = row => {
    return [
      {
        title: ALL_TEXT.EDIT,
        icon: (
          <span class="popup-gray-menu-icon gray-popup-icon">
            <TbEdit />
          </span>
        ),
        onItemPress: () => {
          dispatch(setSelectedCouponData(row));
          dispatch(setCouponEditModal(true));
        },
      },
      {
        title: row?.isActive
          ? ALL_TEXT.DEACTIVATE_COUPON
          : ALL_TEXT.ACTIVE_COUPON,

        icon: row?.isActive ? (
          <span class="icon-error popup-menu-icon red-popup-icon"></span>
        ) : (
          <span class="icon-Vector-27-Stroke popup-menu-icon green-popup-icon"></span>
        ),
        onItemPress: () => {
          dispatch(setSelectedCouponData(row));
          dispatch(setDeactivateCoupon(true));
        },
      },
    ];
  };

  return [
    {
      title: 'Coupon Code',
      fieldName: 'name',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Description',
      fieldName: 'description',
      dataformat: (cell, row) => {
        if (!cell) {
          return 'N/A';
        }
        return <CellValue cell={cell} row={row} />;
      },
    },
    {
      title: 'Coupon Type',
      fieldName: 'duration',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Discount',
      fieldName: 'discount',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Start Date',
      fieldName: 'startDate',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Expiry Date',
      fieldName: 'endDate',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Redeem Count',
      fieldName: 'redeemCount',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Status',
      fieldName: 'isActive',
      dataformat: (cell, row) =>
        GetBadge(row.isActive ? STATUS.ACTIVE.LABEL : STATUS.INACTIVE.LABEL),
    },
    {
      title: 'Action',
      fieldName: 'isActive',
      dataformat: (cell, row) => {
        return (
          <ActionDropDownPopUp
            visibleIcon={<BsThreeDotsVertical />}
            items={menuItems(row)}
            userObj={row}
          />
        );
      },
    },
  ];
}
