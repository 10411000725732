import React, {useState} from 'react';
import {Formik} from 'formik';
import {resetSchema} from '../../common/schemas';
import {ALL_TEXT, ENUMS, images} from '../../common';
import {GenericButton, GenericModal, InputField} from '../../components';
import {useLocation, useNavigate} from 'react-router';
import {resetUserPassword} from '../../utils/rest-services';
import {path} from '../../common/routesNames';
import './styles.scss';
import {usePostApiAdminSetAdminPasswordMutation} from '../../redux/slice/nodukAdmin.ts';

const ResetPassword = () => {
  const initialValues = {
    password: '',
    confirmPassword: '',
  };
  const [btnLoading, setBnLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [apiResponse, setApiResponse] = useState({
    type: ENUMS.POPUP_ENUMS.SUCCESS,
    title: '',
    details: '',
  });

  const [setAdminPasswordAPI] = usePostApiAdminSetAdminPasswordMutation();
  let navigate = useNavigate();

  let location = useLocation();
  const getParamFromUrl = () => {
    return new URLSearchParams(location.search);
  };
  let query = getParamFromUrl();
  const newPassword = query.get('nu');
  const resetPasswordHandler = async (values, formik) => {
    setBnLoading(true);
    const token = query.get('tok');
    var response;
    const id = query.get('id');
    const employeeEncrypted = query.get('ec');
    if (newPassword == 1) {
      response = await setAdminPasswordAPI({
        resetPassDto: {
          employeeId: employeeEncrypted,
          password: values.password,
        },
      });
      response = response?.data;
    } else {
      let payloadObject = {
        signupId: '',
        employeeId: id,
        token: token,
        password: values.password,
      };
      response = await resetUserPassword(payloadObject);
    }

    if (response && response.success) {
      formik.resetForm();
      setApiResponse({
        type: ENUMS.POPUP_ENUMS.SUCCESS,
        title: ALL_TEXT.GENERIC_MODAL.SUCCESS,
        details: response.description,
      });
    } else {
      setApiResponse({
        type: ENUMS.POPUP_ENUMS.ERROR,
        title: ALL_TEXT.GENERIC_MODAL.ERROR,
        details: response.description,
      });
    }
    setShowModal(true);
    setBnLoading(false);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnMount={true}
        enableReinitialize
        validationSchema={resetSchema}
        onSubmit={(values, formikActions) => {
          resetPasswordHandler(values, formikActions);
        }}>
        {({handleChange, handleBlur, handleSubmit, touched, errors}) => (
          <div
            className="login-background"
            style={{
              backgroundImage: `url(${images.noDukAmdinBackground})`,
            }}>
            <div className="noduk-logo">
              <img
                src={images.newNodukLogo}
                alt={ALL_TEXT.ICON}
                style={{width: '85px'}}
              />
            </div>
            <div className="reset-password-box">
              <div className="login-logo">
                <img src={images.userLogin} alt={ALL_TEXT.ICON} />
                <p>
                  {newPassword == 1
                    ? ALL_TEXT.ADD_NEW_PASSWORD
                    : ALL_TEXT.RESET_PASSWORD}
                </p>
              </div>
              <div className="login-input-field">
                <InputField
                  icon={<span class="icon-password"></span>}
                  id={'password'}
                  name={'password'}
                  placeholder={ALL_TEXT.ENTER_PASSWORD}
                  error={touched.password && errors.password}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  isPassword
                />
                <InputField
                  icon={<span class="icon-password"></span>}
                  id={'confirmPassword'}
                  name={'confirmPassword'}
                  placeholder={ALL_TEXT.CONFIRM_NEW_PASSWORD_}
                  error={touched.confirmPassword && errors.confirmPassword}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  isPassword
                />
                <div className="reset-password-button">
                  <GenericButton
                    customStyle={'reset-password-button'}
                    buttonText={ALL_TEXT.SAVE}
                    onPress={handleSubmit}
                    loading={btnLoading}
                  />
                </div>
              </div>
            </div>
            <GenericModal
              show={showModal}
              title={ALL_TEXT.GENERIC_MODAL.SUCCESS}
              type={apiResponse.type}
              body={apiResponse.details}
              buttonText={ALL_TEXT.BUTTON_TEXT.OK}
              handleClose={() => {
                setShowModal(false);
                if (apiResponse.type === ENUMS.POPUP_ENUMS.SUCCESS) {
                  navigate({pathname: path.login});
                }
              }}
            />
          </div>
        )}
      </Formik>
    </>
  );
};

export default ResetPassword;
