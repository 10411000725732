import React from 'react';
import {BsInfoCircle} from 'react-icons/bs';
import {colors} from '../../../common';
import {Tooltip} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

export function UserCards({title, data, seeAll, tooltipMessage, cardTitle}) {
  return (
    <UserSection
      title={title}
      data={data}
      seeAll={seeAll}
      tooltipMessage={tooltipMessage}
      cardTitle={cardTitle}
    />
  );
}

const UserSection = ({title, data, seeAll, tooltipMessage}) => {
  return (
    <div className="user-section">
      <div className="user-card-title-container">
        <span>{title}</span>
        <span
          data-tooltip-id={'customer'}
          data-tooltip-content={tooltipMessage}
          className="url-link">
          <BsInfoCircle size={20} color={colors.black2} />
        </span>
        <Tooltip id={'customer'} />
      </div>
      {(data && data?.length === 0) || data == null ? (
        <div className="not-available">
          <span>{'No data available'}</span>
        </div>
      ) : (
        <>
          <div className="user-list-container-dash">
            {data?.map(i => (
              <UserItem
                key={i.fullName}
                name={i.fullName}
                email={i.email}
                day={i.day}
                date={i.date}
              />
            ))}
          </div>

          <div
            className={
              data && data?.length >= 5
                ? 'seemore-user-dashb'
                : 'no-seemore-user-dashb'
            }>
            <span onClick={seeAll}>
              {data && data?.length >= 5 ? 'See All' : ''}
            </span>
          </div>
        </>
      )}
    </div>
  );
};

const UserItem = ({name, email, day, date}) => {
  return (
    <div className="user-dashboard-list">
      <div>
        <div className="user-icon">
          <span class="icon-user"></span>
        </div>
        <div className="title-user-dashb">
          <span className="title">{name}</span>
          <span className="email"> {email}</span>
        </div>
      </div>
      <div className="date-user-dashb">
        <span className="date">{day}</span>
        <span className="time"> {date}</span>
      </div>
    </div>
  );
};
