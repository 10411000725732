import React from 'react';
import './style.scss';
import {ALL_TEXT} from '../../../common';
import {
  GenericButton,
  CriteriaDropdown,
  TextAreaFeild,
  InputField,
} from '../../../components';
import {Formik} from 'formik';
import {sendNotificationsSchema} from '../../../common/schemas';
import {usePostApiNotificationSendNotificationMutation} from '../../../redux/slice/nodukAdmin.ts';
import {toast} from 'react-toastify';

function Notifications() {
  const [sendNotification, sendNotificationResponse] =
    usePostApiNotificationSendNotificationMutation();

  const sendNotifications = async (values, formik) => {
    const notificationPayload = {
      notificationDto: {
        notifyGroup: values.group.value,
        title: values.title,
        body: values.message,
      },
    };
    let response = await sendNotification(notificationPayload);
    if (response && response.data.success) {
      toast.success(response.data.description);
      formik.resetForm();
    } else {
      toast.error(response.data.description);
    }
  };
  const notificationGroups = [
    {value: 1, label: 'Registrations'},
    {value: 2, label: 'Customers'},
    {value: 3, label: 'Cart Users'},
  ];
  return (
    <div className="claimlist-main-container">
      <div className="title-container-staff">
        <div>
          <span>{ALL_TEXT.ADMINISTRATION} / </span>
          <span>{ALL_TEXT.NOTIFICATIONS}</span>
        </div>
      </div>
      <div className="notifications-container">
        <Formik
          initialValues={{
            group: null,
            title: '',
            message: '',
          }}
          onSubmit={(values, formikActions) => {
            sendNotifications(values, formikActions);
          }}
          validationSchema={sendNotificationsSchema}
          enableReinitialize={true}>
          {({
            handleChange,
            handleSubmit,
            handleBlur,
            touched,
            errors,
            values,
            setFieldValue,
          }) => (
            <div className="fields-container">
              <div className="noti-single-container">
                <label htmlFor="/">{ALL_TEXT.NOTI_GROUP}</label>
                <CriteriaDropdown
                  options={notificationGroups}
                  name={'group'}
                  id={'group'}
                  selectedOption={values.group}
                  error={touched.group && errors.group}
                  dropdowntitle={[ALL_TEXT.SELECT + ' Notification Group']}
                  onChangeOption={e => {
                    setFieldValue('group', e);
                    handleChange('group');
                  }}
                />
              </div>
              <div className="noti-single-container">
                <label htmlFor="/">{ALL_TEXT.NOTI_TITLE}</label>
                <InputField
                  id={'title'}
                  name={'title'}
                  placeholder={'Enter Notification Title'}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  error={touched.title && errors.title}
                  value={values.title}
                />
              </div>
              <div className="textArea-add-policy">
                <div className="noti-single-container">
                  <label htmlFor="/">{ALL_TEXT.NOTI_MESSAGE}</label>
                  <TextAreaFeild
                    length={'400'}
                    id={'message'}
                    name={'message'}
                    placeholder={'Enter Notification Message'}
                    value={values.message}
                    setValue={handleChange}
                    handleBlur={handleBlur}
                  />
                  {touched.message && errors.message ? (
                    <p className="form-error">{errors.message}</p>
                  ) : null}
                </div>
              </div>
              <GenericButton
                loading={sendNotificationResponse?.isLoading}
                buttonText={ALL_TEXT.SEND_NOTI}
                onPress={handleSubmit}
              />
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default Notifications;
