import React from 'react';
import {CircularProgress} from '@material-ui/core';
import './styles.scss';

export function Loader({size, color, customClass, customContainerClass}) {
  return (
    <div className={`loaderContainer ${customContainerClass}`}>
      <CircularProgress
        size={size}
        color={color}
        style={{
          color: color,
        }}
        customClass={customClass}
      />
    </div>
  );
}
