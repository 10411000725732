import {createSlice} from '@reduxjs/toolkit';

export const AdminSlice = createSlice({
  name: 'administrator',
  initialState: {
    isDeleteModalOpens: false,
    isEditModal: false,
    isCommissionPopUp:false,
    selectedEmployeeData: {},
    isLinkGeneratorPopup: false,
    selectedMenuOptions: '',
    isAccountVerificationPopUp:false,
    isRequestDocumentsPopup:false,
    isDownloadFilesPopup:false,
    isDownloadSuccessPopup:false,
  },
  reducers: {
    setDeleteModal(state, {payload}) {
      state.isDeleteModalOpens = payload;
    },
    setEditModal(state, {payload}) {
      state.isEditModal = payload;
    },

    setSelectedEmployeeData(state, {payload}) {
      state.selectedEmployeeData = payload;
    },
    setIsLinkGeneratorPopup(state, {payload}) {
      state.isLinkGeneratorPopup = payload;
    },
    setSelectedMenuOptions(state, {payload}) {
      state.selectedMenuOptions = payload;
    },
    setIsCommissionPopUp(state, {payload}) {
      state.isCommissionPopUp = payload;
    },
    setIsAccountVerificationPopUp(state, {payload}) {
      state.isAccountVerificationPopUp = payload;
    },
    setIsRequestDocumentPopup(state,{payload}){
      state.isRequestDocumentsPopup = payload;
    },
    setIsDownloadFilesPopup(state, {payload}) {
      state.isDownloadFilesPopup = payload;
    },
    setIsDownloadSuccessPopup(state, {payload}) {
      state.isDownloadSuccessPopup = payload;
    },
  },
});

export const {
  setDeleteModal,
  setEditModal,
  setSelectedEmployeeData,
  setIsLinkGeneratorPopup,
  setSelectedMenuOptions,
  setIsCommissionPopUp,
  setIsAccountVerificationPopUp,
  setIsRequestDocumentPopup,
  setIsDownloadFilesPopup,
  setIsDownloadSuccessPopup,
} = AdminSlice.actions;
