import React, {useState, useEffect} from 'react';
import {ALL_TEXT, ENUMS} from '../../common';
import {GenericTable, CustomizedSearch} from '../../components';
import {tableHeading} from './table';
import Pagination from '@mui/material/Pagination';
import {getAllInvoices} from './../../utils/rest-services/index';
import './style.scss';
import {getValuesFromEnumsObj} from '../../utils/helpers';

function Invoices() {
  const [getInvoicesList, setGetInvoicesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedInvoiceStaus, setSelectedInvoiceStatus] = useState(0);
  const [loader, setLoader] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const [selectedSearchObj, setSelectedSearchObj] = useState({
    email: '',
    id: 0,
    isRefunded: 0,
  });

  useEffect(() => {
    const payLoadObj = {
      invoiceId: selectedSearchObj.id,
      email: selectedSearchObj.email,
      status: selectedInvoiceStaus,
      pageNumber: currentPage,
      pageSize: ENUMS.PAGE_SIZE,
      isRefunded: selectedSearchObj.isRefunded,
    };
    getInvoices(payLoadObj);
  }, [currentPage]);

  const getInvoices = async payLoadObj => {
    setLoader(true);
    let response = await getAllInvoices(payLoadObj);
    const {
      content: {list, totalPages},
    } = response;
    if (response && response.success) {
      setGetInvoicesList(list);
      setTotalPages(totalPages);
      setLoader(false);
    }
  };
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const invoiceSearchhandler = async (
    invoiceId,
    email,
    status,
    isRefunded,
    clear,
  ) => {
    var payload;
    if (clear) {
      payload = {
        invoiceId: 0,
        email: '',
        status: 0,
        pageNumber: 1,
        pageSize: ENUMS.PAGE_SIZE,
        isRefunded: 0,
      };
      setSelectedSearchObj({
        email: '',
        id: 0,
        isRefunded: 0,
      });
    } else {
      payload = {
        invoiceId: invoiceId ? invoiceId?.split('_')[1] || invoiceId : 0,
        email: email,
        status: getValuesFromEnumsObj(ENUMS.INVOICES, status),
        pageNumber: 1,
        pageSize: ENUMS.PAGE_SIZE,
        isRefunded: isRefunded !== undefined ? (isRefunded ? 1 : 0) : 0,
      };
      setSelectedSearchObj({
        email: email,
        id: invoiceId == '' ? 0 : invoiceId.split('_')[1],
        isRefunded: isRefunded !== undefined ? (isRefunded ? 1 : 0) : 0,
      });
    }
    setSelectedInvoiceStatus(getValuesFromEnumsObj(ENUMS.INVOICES, status));
    setCurrentPage(1);
    setBtnLoader(true);
    await getInvoices(payload);
    setBtnLoader(false);
  };
  return (
    <div className="invoices-main-container">
      <div className="title-container-invoices">
        <span>{ALL_TEXT.INVOICES}</span>
      </div>
      <div className="invoice-search-bar">
        <CustomizedSearch
          btnLoader={btnLoader}
          title={'Search Invoice By'}
          firstLabel={'Invoice Id'}
          firstPlaceholder={'Enter Id'}
          isFirstInputNumeric
          firstPrefixerValue={'inv_'}
          secondInput
          secondLabel={'Email'}
          secondPlaceholder={'Enter Email'}
          firstDropdown
          firstDropdownLabel={'Status'}
          checkbox
          checkboxLabel={'Refunded'}
          firstDropdownList={[
            ENUMS.INVOICES.PAID.LABEL,
            ENUMS.INVOICES.FAILED.LABEL,
            ENUMS.INVOICES.UPCOMING.LABEL,
            ENUMS.INVOICES.OPENED.LABEL,
            ENUMS.INVOICES.ALL.LABEL,
          ]}
          onClearFilterHandler={() => {
            invoiceSearchhandler('', '', '', '', true);
          }}
          onSearchBtnPress={(
            invoiceId,
            email,
            nothing,
            status,
            noVal,
            exmptVal,
            isRefunded,
          ) => {
            invoiceSearchhandler(invoiceId, email, status, isRefunded);
          }}
        />
      </div>
      <div className="table-invoices-container">
        <GenericTable
          loader={loader}
          headings={tableHeading}
          data={getInvoicesList}
        />
      </div>
      {totalPages > 1 && (
        <div className="pagination-container">
          <div className="inner-pagination">
            <Pagination
              page={currentPage}
              color={'secondary'}
              onChange={handlePageChange}
              count={totalPages}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Invoices;
