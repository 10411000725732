import React from 'react';
import './subscriptionStyles.scss';
import {ALL_TEXT} from '../../common';

export default function SubscriptionTabs({
  selectedTab,
  setSelectedTab,
  children,
}) {
  return (
    <div className="">
      <div className="sub-detail-tabs">
        <div
          onClick={() => {
            setSelectedTab(1);
          }}
          className={
            selectedTab === 1 ? 'selected-tab  items-tabs' : 'items-tabs'
          }>
          {ALL_TEXT.SUBSCRIPTION_DETAILS_.SUBSCRIPTION_ITEMS}
          <div
            className={
              selectedTab === 1 ? 'bottom-border' : '.bottom-border-none'
            }></div>
        </div>
        <div
          onClick={() => {
            setSelectedTab(2);
          }}
          className={
            selectedTab === 2 ? 'selected-tab  refunds-tabs' : 'refunds-tabs'
          }>
          {ALL_TEXT.SUBSCRIPTION_DETAILS_.REFUNDS}
          <div
            className={
              selectedTab === 2 ? 'bottom-border' : '.bottom-border-none'
            }></div>
        </div>
      </div>
      {children}
    </div>
  );
}
