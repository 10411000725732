import React, {useState} from 'react';
import {Formik} from 'formik';
import {Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import {Grid} from '@mui/material';
import './styles.scss';
import {GenericButton} from '../generic-button';
import {ALL_TEXT, ENUMS} from '../../common';
import {CriteriaDropdown} from '../criteria-dropdown';
import {InputField} from '../input-field';
// import {TextAreaFeild} from '../textarea';
import {GenericModal} from '../generic-modal';
import {addCarrierSchema} from '../../common/schemas';
import {addCarriers} from '../../utils/rest-services';
import {generateFormData} from '../../utils/helpers';
import {useSelector} from 'react-redux';

export default function CarrierModal({show, handleClose, addCarrier}) {
  const [showModal, setShowModal] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [popUpResponse, setPopUpResponse] = useState({
    type: ENUMS.POPUP_ENUMS.SUCCESS,
    title: '',
    description: '',
  });

  const paramData = useSelector(state => state.carrier.editCarrier);
  const getServiceTypeName = serviceTypeId => {
    switch (serviceTypeId) {
      case 1:
        return ENUMS.POLICY_TYPES.VEHICLE.LABEL;
      case 2:
        return ENUMS.POLICY_TYPES.HOME.LABEL;
      case 3:
        return ENUMS.POLICY_TYPES.DEVICE.LABEL;
      case 4:
        return ENUMS.POLICY_TYPES.COMMERCIAL.LABEL;
      default:
        return '';
    }
  };

  const carrierData = {
    serviceType: addCarrier ? '' : getServiceTypeName(paramData.serviceTypeId),
    name: addCarrier ? '' : paramData.providerName,
    phone: addCarrier ? '' : paramData.phone,
    website: addCarrier ? '' : paramData.website,
    description: '',
  };
  const options = [
    {
      label: ENUMS.CLAIMS.TYPE.VEHICLE.LABEL,
      value: ENUMS.CLAIMS.TYPE.VEHICLE.VALUE,
    },
    {
      label: ENUMS.CLAIMS.TYPE.HOME.LABEL,
      value: ENUMS.CLAIMS.TYPE.HOME.VALUE,
    },
    {
      label: ENUMS.CLAIMS.TYPE.DEVICE.LABEL,
      value: ENUMS.CLAIMS.TYPE.DEVICE.VALUE,
    },
    {
      label: ENUMS.CLAIMS.TYPE.COMMERCIAL.LABEL,
      value: ENUMS.CLAIMS.TYPE.COMMERCIAL.VALUE,
    },
  ];

  const handleChangePhoneNumber = (event, setFieldValue) => {
    const inputValue = event.target.value;
    const sanitizedValue = inputValue.replace(/[^0-9]/g, ''); // Remove non-numeric characters

    if (sanitizedValue.startsWith('1')) {
      const restrictedValue = sanitizedValue.substring(1, 12); // Remove leading "1" and restrict to 10 digits
      setFieldValue('phone', restrictedValue);
    } else {
      const restrictedValue = sanitizedValue.substring(0, 11); // Restrict to 10 digits if not starting with "1"
      setFieldValue('phone', restrictedValue);
    }
  };

  const addNewCarrier = async (values, formik) => {
    const carrierInfo = {
      serviceTypeId: values.serviceType.value,
      providerName: values.name,
      phone: values.phone,
      website: values.website,
      // status: true,
    };

    const carrierDetail = generateFormData(carrierInfo);
    setBtnLoader(true);
    let response = await addCarriers(carrierDetail);
    formik.setSubmitting(false);
    if (response && response?.success) {
      setPopUpResponse({
        type: ENUMS.POPUP_ENUMS.SUCCESS,
        title: '',
        details: response.description,
      });
      setBtnLoader(false);
      setShowModal(true);
    } else {
      setPopUpResponse({
        type: ENUMS.POPUP_ENUMS.ERROR,
        title: '',
        details: response.description,
      });
      setBtnLoader(false);
      setShowModal(true);
    }
  };

  const editCarrier = async (values, formik) => {
    const carrierInfo = {
      id: paramData.id,
      serviceTypeId: values.serviceType.value,
      providerName: values.name,
      phone: values.phone,
      website: values.website,
      // status: true,
    };
    setBtnLoader(true);
    let response = await addCarriers(carrierInfo);
    formik.setSubmitting(false);
    if (response && response?.success) {
      setPopUpResponse({
        type: ENUMS.POPUP_ENUMS.SUCCESS,
        title: '',
        details: response.description,
      });
      setBtnLoader(false);
      setShowModal(true);
    } else {
      setPopUpResponse({
        type: ENUMS.POPUP_ENUMS.ERROR,
        title: '',
        details: response.description,
      });
      setBtnLoader(false);
      setShowModal(true);
    }
  };
  return (
    <>
      <Formik
        initialValues={{
          serviceType: carrierData.serviceType
            ? options.find(option => option.label === carrierData.serviceType)
            : null,
          name: carrierData.name || '',
          phone: carrierData.phone || '',
          website: carrierData.website || '',
          description: carrierData.description || '',
        }}
        validateOnMount={true}
        validationSchema={addCarrierSchema}
        enableReinitialize
        onSubmit={(values, formik) => {
          if (addCarrier) {
            addNewCarrier(values, formik);
          } else {
            editCarrier(values, formik);
          }
        }}>
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          values,
          touched,
          errors,
        }) => (
          <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton={handleClose}>
              <h5>
                {addCarrier ? ALL_TEXT.ADD_NEW_CARRIER : ALL_TEXT.EDIT_CARRIER}
              </h5>
            </Modal.Header>
            <Modal.Body>
              <div className="carrier-manage">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <label htmlFor="/">{ALL_TEXT.SERVICE_TYPE_LABLE}</label>
                    <CriteriaDropdown
                      options={options}
                      name={'serviceType'}
                      id={'serviceType'}
                      selectedOption={values.serviceType} // Use the formik's field value directly
                      error={touched.serviceType && errors.serviceType}
                      dropdowntitle={ALL_TEXT.SELECT_SERVICE_TYPE}
                      onChangeOption={e => {
                        setFieldValue('serviceType', e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <label htmlFor="/">{`${ALL_TEXT.CARRIER_NAME}*`}</label>
                    <InputField
                      id={'name'}
                      name={'name'}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      error={touched.name && errors.name}
                      value={values.name}
                      placeholder={'Enter carrier name'}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <label htmlFor="/">{ALL_TEXT.USER_DETAIL.PHONE}</label>
                    <InputField
                      phone
                      id={'phone'}
                      name={'phone'}
                      value={
                        values.phone.startsWith('+1')
                          ? values.phone.slice(2)
                          : values.phone
                      }
                      handleBlur={handleBlur}
                      handleChange={e => {
                        handleChangePhoneNumber(e, setFieldValue);
                      }}
                      placeholder={'Enter phone number'}
                      error={touched.phone && errors.phone}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <label htmlFor="/">{ALL_TEXT.USER_DETAIL.WEBSITE}</label>
                    <InputField
                      id={'website'}
                      name={'website'}
                      value={values.website}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      placeholder={'Enter website'}
                      // error={touched.website && errors.website}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <label htmlFor="/">
                      {ALL_TEXT.USER_DETAIL.DESCRIPTION}
                    </label>
                    <div className="textArea-add-policy">
                      <TextAreaFeild
                        id={'description'}
                        name={'description'}
                        value={values.description}
                        setValue={handleChange}
                        handleBlur={handleBlur}
                      />
                    </div>
                  </Grid> */}
                </Grid>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <GenericButton
                buttonText={addCarrier ? ALL_TEXT.SAVE : ALL_TEXT.UPDATE}
                loading={btnLoader}
                onPress={handleSubmit}
              />
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
      <GenericModal
        show={showModal}
        type={popUpResponse.type}
        body={popUpResponse.details}
        buttonText={ALL_TEXT.BUTTON_TEXT.OK}
        handleClose={() => {
          if (popUpResponse.type === ENUMS.POPUP_ENUMS.SUCCESS) {
            setShowModal(false);
            handleClose(true);
          } else {
            setShowModal(false);
          }
        }}
      />
    </>
  );
}
