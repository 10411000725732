import * as Yup from 'yup';
import {ALL_TEXT} from './constants';
const {
  ERROR_PLACEHOLDERS: {
    SERVICE_TYPE,
    NAME,
    COMPANY_NAME,
    TAX_ID,
    FIRST_NAME,
    LAST_NAME,
    PHONE,
    EMAIL,
    ADDRESS,
    STATE,
    CITY,
    ZIP,
    PASSWORD,
    PASSWORD_REQUIRED,
    NEW_PASSWORD,
    CONFIRM_PASSWORD,
    PASSWORD_MATCH,
    PASSWORD_MUST_BE,
    PLEASE_SELECT,
    INVALID_EMAIL,
    MAKER,
    MODEL,
    YEAR,
    CARRIER,
    DEDUCTIBLES,
    OTHER_CARRIER,
    BRAND,
    PLEASE_ENTER,
    OTHER_DEDUCTIBLES,
    MINIMUM_DEDUCTIBLES,
    INVALID_DEDUCTIBLES,
    DATE,
    STATUS,
    COUPON_CODE,
    COUPON_TYPE,
    FIXED_AMOUNT,
    DISCOUNT_AMOUNT,
    REDEMPTIONS,
    COUPON_START_DATE,
    MINIMUM_DISCOUNT_AMOUNT,
    RISK_DEDUCTIBLE,
    RISK_LEVEL,
  },
  DATE_LOSS,
  DATE_FILED,
  ERROR_MESSAGES,
} = ALL_TEXT;

// need to verify String messages from Import

export const signinSchema = Yup.object({
  email: Yup.string().trim().email(INVALID_EMAIL).required(EMAIL),
  password: Yup.string().min(3, PASSWORD_MUST_BE).required(PASSWORD),
});

export const forgotSchema = Yup.object({
  email: Yup.string().trim().email(INVALID_EMAIL).required(EMAIL),
});
export const updateClaimScehma = Yup.object({
  date: Yup.string().trim().required(DATE),
  status: Yup.object().nullable().required(STATUS),
});

export const riskLevelSchema = Yup.object({
  riskValue: Yup.mixed().required(RISK_LEVEL),
  riskDeductibles: Yup.string().trim().required(RISK_DEDUCTIBLE),
});

export const editRiskLevelSchema = Yup.object({
  riskDeductibles: Yup.number('Deductibles should be a valid amount')
    .required(RISK_DEDUCTIBLE)
    .max(100, 'Deductibles % must not be greater than 100'),
});

export const disasterAreaSchema = Yup.object({
  // zipCode: Yup.string().trim().required(DISASTER_ZIP_CODE),
  areaRisk: Yup.object().nullable().required(RISK_LEVEL),
  deductibles: Yup.string().trim().required(RISK_DEDUCTIBLE),
  // riskValue: Yup.mixed().required(RISK_LEVEL),
});
export const resetSchema = Yup.object({
  password: Yup.string().trim().min(6, PASSWORD_MUST_BE).required(PASSWORD),
  confirmPassword: Yup.string()
    .trim()
    .required(CONFIRM_PASSWORD)
    .oneOf([Yup.ref('password'), null], PASSWORD_MATCH),
});

export const changePasswordSchema = Yup.object({
  currentPassword: Yup.string().required(PASSWORD_REQUIRED),
  newPassword: Yup.string().required(NEW_PASSWORD).min(6, PASSWORD_MUST_BE),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], PASSWORD_MATCH)
    .required(CONFIRM_PASSWORD),
});

export const userDetailSchema = Yup.object({
  firstName: Yup.string().trim().required(FIRST_NAME),
  lastName: Yup.string().trim().required(LAST_NAME),
  phone: Yup.string().trim().min(10).required(PHONE),
  email: Yup.string().trim().email(INVALID_EMAIL).required(EMAIL),
  address: Yup.string().trim().required(ADDRESS),
  state: Yup.string().trim().required(STATE),
  city: Yup.string().trim().required(CITY),
  zip: Yup.string().trim().required(ZIP),
  role: Yup.object().nullable().required(`${PLEASE_SELECT} role`),
});

export const companyDetailsSchema = Yup.object({
  firstName: Yup.string().trim().required(FIRST_NAME),
  lastName: Yup.string().trim().required(LAST_NAME),
  phone: Yup.string().trim().min(10).required(PHONE),
  email: Yup.string().trim().email(INVALID_EMAIL).required(EMAIL),
  address: Yup.string().trim().required(ADDRESS),
  state: Yup.string().trim().required(STATE),
  city: Yup.string().trim().required(CITY),
  zip: Yup.string().trim().required(ZIP),
  role: Yup.object().nullable().required(`${PLEASE_SELECT} role`),
  companyName: Yup.string().trim().required(COMPANY_NAME),
  taxId: Yup.string().trim().required(TAX_ID),
});

export const couponEditSchema = Yup.object({
  // description: Yup.string().trim().required(DESCRIPTION),
});
export const addCouponFixedAmountSchema = Yup.object({
  couponCode: Yup.string().trim().required(COUPON_CODE),
  couponType: Yup.object().nullable().required(COUPON_TYPE),
  redemptions: Yup.string().trim().required(REDEMPTIONS),
  couponStartDate: Yup.date('Start Date Required')
    .nullable()
    .required(COUPON_START_DATE),
  couponExpiryDate: Yup.date()
    .nullable()
    .required('Coupon expiry Date Required'),
  // couponExpiryDate: Yup.date()
  //   .required('Required')
  //   .when('couponStartDate', (startDate, schema) => {
  //     return schema.min(
  //       startDate,
  //       'Expiry date must be equal or greater than start date',
  //     );
  //   }),

  fixedAmount: Yup.number()
    .required(FIXED_AMOUNT)
    .typeError('Fixed Amount must be a valid number'),
  minimumDiscountAmount: Yup.number()
    .required(MINIMUM_DISCOUNT_AMOUNT)
    .test(
      'is-greater-than-fixed-amount',
      'Minimum discount amount must be greater than fixed amount',
      function (value) {
        const fixedAmount = this.resolve(Yup.ref('fixedAmount'));
        return value > fixedAmount;
      },
    ),
});
export const addCouponFirstMonthSchema = Yup.object({
  couponCode: Yup.string().trim().required(COUPON_CODE),
  couponType: Yup.object().nullable().required(COUPON_TYPE),
  redemptions: Yup.string().trim().required(REDEMPTIONS),
  couponStartDate: Yup.date('Start Date Required')
    .nullable()
    .required(COUPON_START_DATE),
  couponExpiryDate: Yup.date()
    .nullable()
    .required('Coupon expiry Date Required'),

  // couponExpiryDate: Yup.date()
  //   .required('Required')
  //   .when('couponStartDate', (startDate, schema) => {
  //     return schema.min(
  //       startDate,
  //       'Expiry date must be equal or greater than start date',
  //     );
  //   }),
});
export const addCouponDiscountAmountSchema = Yup.object({
  couponCode: Yup.string().trim().required(COUPON_CODE),
  couponType: Yup.object().nullable().required(COUPON_TYPE),
  redemptions: Yup.string().trim().required(REDEMPTIONS),
  couponStartDate: Yup.date('Start Date Required')
    .nullable()
    .required(COUPON_START_DATE),
  couponExpiryDate: Yup.date()
    .nullable()
    .required('Coupon expiry Date Required'),

  //   couponExpiryDate: Yup.date()
  //     .required('Required')
  //     .when('couponStartDate', (startDate, schema) => {
  //       return schema.min(
  //         startDate,
  //         'Expiry date must be equal or greater than start date',
  //       );
  //     }),
  discountAmount: Yup.number()
    .required(DISCOUNT_AMOUNT)
    .max(90, 'Discount amount must not be greater than 90'),
  minimumDiscountAmount: Yup.string().trim().required(MINIMUM_DISCOUNT_AMOUNT),
});
export const customerSchema = Yup.object({
  firstName: Yup.string().trim().required(FIRST_NAME),
  lastName: Yup.string().trim().required(LAST_NAME),
  phone: Yup.string().trim().min(10).required(PHONE),
  email: Yup.string().trim().email(INVALID_EMAIL).required(EMAIL),
  address: Yup.string().trim().required(ADDRESS),
  state: Yup.string().trim().required(STATE),
  city: Yup.string().trim().required(CITY),
  zip: Yup.string().trim().required(ZIP),
});

export const emailValidateSchema = Yup.object({
  email: Yup.string().trim().email(INVALID_EMAIL).required(EMAIL),
});

export const profileSchema = Yup.object({
  firstName: Yup.string().trim().required(FIRST_NAME),
  lastName: Yup.string().trim().required(LAST_NAME),
  phone: Yup.string().trim().min(10).required(PHONE),
  email: Yup.string().trim().email(INVALID_EMAIL).required(EMAIL),
  address: Yup.string().trim().required(ADDRESS),
  state: Yup.string().trim().required(STATE),
  city: Yup.string().trim().required(CITY),
  zip: Yup.string().trim().required(ZIP),

  // website: Yup.string()
  //   .trim()
  //   .matches(
  //     /^[a-zA-Z0-9-_.]+\.[a-zA-Z]{2,}$/,
  //     'Please enter a valid website format.',
  //   )
  //   .required(WEBSITE),
});
export const addCarrierSchema = Yup.object({
  serviceType: Yup.mixed().required(SERVICE_TYPE),
  name: Yup.string().trim().required(NAME),
  phone: Yup.string().trim().min(10).required(PHONE),
  website: Yup.string().trim(),
  // .matches(
  //   /^[a-zA-Z0-9-_.]+\.[a-zA-Z]{2,}$/,
  //   'Please enter a valid website format.',
  // )
  // .required(WEBSITE),
});
export const addVehicleSchema = (isOtherDeductibles, isOtherCarrier) => {
  const schemaObj = Yup.object({
    carrier: Yup.object().nullable().required(`${PLEASE_SELECT} ${CARRIER}`),
    maker: Yup.object().nullable().required(`${PLEASE_SELECT} ${MAKER}`),
    model: Yup.object().nullable().required(`${PLEASE_SELECT} ${MODEL}`),
    otherCarrier: Yup.string().trim().nullable(),
    otherDeductibles: Yup.string().trim().nullable(),
    // year: Yup.string().required(`${pleaseEnter} ${year}`),
    year: Yup.number()
      .typeError(YEAR.number)
      .integer(YEAR.integer)
      .min(1900, YEAR.min)
      .max(new Date().getFullYear(), YEAR.max)
      .required(YEAR.required),
    deductibles: Yup.object()
      .nullable()
      .required(`${PLEASE_SELECT} ${DEDUCTIBLES}`),
  });
  if (isOtherCarrier) {
    schemaObj.fields.otherCarrier = Yup.string()
      .trim()
      .required(`${PLEASE_ENTER} ${OTHER_CARRIER}`);
  }
  if (isOtherDeductibles) {
    schemaObj.fields.otherDeductibles = Yup.number()
      .min(100, MINIMUM_DEDUCTIBLES)
      .test(
        'type',
        'Other Deductibles must be a number',
        value => !isNaN(value),
      )
      .typeError('Other Deductibles must be a number')
      .required(`${PLEASE_ENTER} ${OTHER_DEDUCTIBLES}`)
      .test('onlydigits', INVALID_DEDUCTIBLES, value =>
        /^[1-9]\d*(\.\d+)?$/.test(value),
      )
      .test('twodecimals', 'Only 2 digits are required after decimal', value =>
        /^\d*(\.\d{0,2})?$/.test(value),
      );
  }
  return schemaObj;
};

export const addDeviceSchema = (isOtherDeductibles, isOtherCarrier) => {
  const schemaObj = Yup.object({
    carrier: Yup.object().nullable().required(`${PLEASE_SELECT} ${CARRIER}`),
    otherCarrier: Yup.string().trim().nullable(),
    deductibles: Yup.object()
      .nullable()
      .required(`${PLEASE_SELECT} ${DEDUCTIBLES}`),
    otherDeductibles: Yup.string().trim().nullable(),
    brand: Yup.string().trim().required(`${PLEASE_ENTER} ${BRAND}`),
    model: Yup.string().trim().required(`${PLEASE_ENTER} ${MODEL}`),
  });
  if (isOtherCarrier) {
    schemaObj.fields.otherCarrier = Yup.string()
      .trim()
      .required(`${PLEASE_ENTER} ${OTHER_CARRIER}`);
  }
  if (isOtherDeductibles) {
    schemaObj.fields.otherDeductibles = Yup.number()
      .min(100, MINIMUM_DEDUCTIBLES)
      .test(
        'type',
        'Other Deductibles must be a number',
        value => !isNaN(value),
      )
      .typeError('Other Deductibles must be a number')
      .required(`${PLEASE_ENTER} ${OTHER_DEDUCTIBLES}`)
      .test('onlydigits', INVALID_DEDUCTIBLES, value =>
        /^[1-9]\d*(\.\d+)?$/.test(value),
      )
      .test('twodecimals', 'Only 2 digits are required after decimal', value =>
        /^\d*(\.\d{0,2})?$/.test(value),
      );
  }
  return schemaObj;
};
export const addCommercialSchema = (isOtherDeductibles, isOtherCarrier) => {
  const schemaObj = Yup.object({
    carrier: Yup.object().nullable().required(`${PLEASE_SELECT} ${CARRIER}`),
    otherCarrier: Yup.string().trim().nullable(),
    deductibles: Yup.object()
      .nullable()
      .required(`${PLEASE_SELECT} ${DEDUCTIBLES}`),
    otherDeductibles: Yup.string().trim().nullable(),
    maker: Yup.string().trim().required(`${PLEASE_ENTER} ${MAKER}`),
    model: Yup.string().trim().required(`${PLEASE_ENTER} ${MODEL}`),
  });
  if (isOtherCarrier) {
    schemaObj.fields.otherCarrier = Yup.string()
      .trim()
      .required(`${PLEASE_ENTER} ${OTHER_CARRIER}`);
  }
  if (isOtherDeductibles) {
    schemaObj.fields.otherDeductibles = Yup.number()
      .min(100, MINIMUM_DEDUCTIBLES)
      .test(
        'type',
        'Other Deductibles must be a number',
        value => !isNaN(value),
      )
      .typeError('Other Deductibles must be a number')
      .required(`${PLEASE_ENTER} ${OTHER_DEDUCTIBLES}`)
      .test('onlydigits', INVALID_DEDUCTIBLES, value =>
        /^[1-9]\d*(\.\d+)?$/.test(value),
      )
      .test('twodecimals', 'Only 2 digits are required after decimal', value =>
        /^\d*(\.\d{0,2})?$/.test(value),
      );
  }
  return schemaObj;
};

export const sendNotificationsSchema = Yup.object({
  group: Yup.object().nullable().required(`${PLEASE_SELECT} Group`),
  title: Yup.string()
    .trim()
    .nullable()
    .required('Please enter Title of notification'),
  message: Yup.string().trim().nullable().required('Please enter message'),
});

export const addHomeSchemaWithDisaster = (
  isOtherDeductibles,
  isOtherCarrier,
) => {
  const schemaObj = Yup.object({
    carrier: Yup.object().nullable().required(`${PLEASE_SELECT} ${CARRIER}`),
    otherCarrier: Yup.string().trim().nullable(),
    deductibles: Yup.object()
      .nullable()
      .required(`${PLEASE_SELECT} ${DEDUCTIBLES}`),
    otherDeductibles: Yup.string().trim().nullable(),
    address: Yup.string().required(`${ADDRESS}`),
    state: Yup.string().trim().required(STATE),
    city: Yup.string().trim().required(CITY),
    zip: Yup.string().trim().required(ZIP),
    disasterDeductibles: Yup.object()
      .nullable()
      .required(`${PLEASE_SELECT} disaster ${DEDUCTIBLES}`),
  });
  if (isOtherCarrier) {
    schemaObj.fields.otherCarrier = Yup.string()
      .trim()
      .required(`${PLEASE_ENTER} ${OTHER_CARRIER}`);
  }
  if (isOtherDeductibles) {
    schemaObj.fields.otherDeductibles = Yup.number()
      .min(100, MINIMUM_DEDUCTIBLES)
      .test(
        'type',
        'Other Deductibles must be a number',
        value => !isNaN(value),
      )
      .typeError('Other Deductibles must be a number')
      .required(`${PLEASE_ENTER} ${OTHER_DEDUCTIBLES}`)
      .test('onlydigits', INVALID_DEDUCTIBLES, value =>
        /^[1-9]\d*(\.\d+)?$/.test(value),
      )
      .test('twodecimals', 'Only 2 digits are required after decimal', value =>
        /^\d*(\.\d{0,2})?$/.test(value),
      );
  }
  return schemaObj;
};

export const addHomeSchema = (isOtherDeductibles, isOtherCarrier) => {
  const schemaObj = Yup.object({
    carrier: Yup.object().nullable().required(`${PLEASE_SELECT} ${CARRIER}`),
    otherCarrier: Yup.string().trim().nullable(),
    deductibles: Yup.object()
      .nullable()
      .required(`${PLEASE_SELECT} ${DEDUCTIBLES}`),
    otherDeductibles: Yup.string().trim().nullable(),
    address: Yup.string().required(`${ADDRESS}`),
    state: Yup.string().trim().required(STATE),
    city: Yup.string().trim().required(CITY),
    zip: Yup.string().trim().required(ZIP),
  });
  if (isOtherCarrier) {
    schemaObj.fields.otherCarrier = Yup.string()
      .trim()
      .required(`${PLEASE_ENTER} ${OTHER_CARRIER}`);
  }
  if (isOtherDeductibles) {
    schemaObj.fields.otherDeductibles = Yup.number()
      .min(100, MINIMUM_DEDUCTIBLES)
      .test(
        'type',
        'Other Deductibles must be a number',
        value => !isNaN(value),
      )
      .typeError('Other Deductibles must be a number')
      .required(`${PLEASE_ENTER} ${OTHER_DEDUCTIBLES}`)
      .test('onlydigits', INVALID_DEDUCTIBLES, value =>
        /^[1-9]\d*(\.\d+)?$/.test(value),
      )
      .test('twodecimals', 'Only 2 digits are required after decimal', value =>
        /^\d*(\.\d{0,2})?$/.test(value),
      );
  }
  return schemaObj;
};
export const editDeductibleSchema = isOtherDeductibles => {
  const schemaObj = Yup.object({
    otherDeductibles: Yup.string().trim().nullable(),
    deductibles: Yup.object()
      .nullable()
      .required(`${PLEASE_SELECT} ${DEDUCTIBLES}`),
  });
  if (isOtherDeductibles) {
    schemaObj.fields.otherDeductibles = Yup.number()
      .min(100, MINIMUM_DEDUCTIBLES)
      .test(
        'type',
        'Other Deductibles must be a number',
        value => !isNaN(value),
      )
      .typeError('Other Deductibles must be a number')
      .required(`${PLEASE_ENTER} ${OTHER_DEDUCTIBLES}`)
      .test('onlydigits', INVALID_DEDUCTIBLES, value =>
        /^[1-9]\d*(\.\d+)?$/.test(value),
      )
      .test('twodecimals', 'Only 2 digits are required after decimal', value =>
        /^\d*(\.\d{0,2})?$/.test(value),
      );
  }
  return schemaObj;
};

export const claimTypeScehma = Yup.object({
  dateLoss: Yup.string().trim().required(`${PLEASE_ENTER} ${DATE_LOSS}`),
  dateField: Yup.string().trim().required(`${PLEASE_ENTER} ${DATE_FILED}`),
  // notes: Yup.string().trim().required(`${PLEASE_ENTER} ${notes}`),
  driverLicense: Yup.string().trim().required(ERROR_MESSAGES.DRIVER_LICENSSE),
  registrationDoc: Yup.string()
    .trim()
    .required(ERROR_MESSAGES.REGISTRATION_DOC),
  insuranceDeclaration: Yup.string()
    .trim()
    .required(ERROR_MESSAGES.INSURANCE_ERROR),
  claimInfo: Yup.string().trim().required(ERROR_MESSAGES.CLAIM_INFO_ERROR),
  incidentReport: Yup.string()
    .trim()
    .required(ERROR_MESSAGES.INCIDENT_REPORT_ERROR),
});
export const addClaimWithoutAutoScheme = Yup.object({
  dateLoss: Yup.string().trim().required(`${PLEASE_ENTER} ${DATE_LOSS}`),
  dateField: Yup.string().trim().required(`${PLEASE_ENTER} ${DATE_FILED}`),
  // notes: Yup.string().trim().required(`${pleaseEnter} ${notes}`),
  registrationDoc: Yup.string()
    .trim()
    .required(ERROR_MESSAGES.REGISTRATION_DOC),
  insuranceDeclaration: Yup.string()
    .trim()
    .required(ERROR_MESSAGES.INSURANCE_ERROR),
  claimInfo: Yup.string().trim().required(ERROR_MESSAGES.CLAIM_INFO_ERROR),
  incidentReport: Yup.string()
    .trim()
    .required(ERROR_MESSAGES.INCIDENT_REPORT_ERROR),
});
