import React, {useState, useEffect} from 'react';
import {IoIosLogOut} from 'react-icons/io';
import {AiOutlinePlus, AiOutlineMinus} from 'react-icons/ai';
import {images} from '../../common';
import './style.scss';
import {useNavigate} from 'react-router';
import {Grid} from '@mui/material';
import {PAGE_ENUMS_ID, path} from '../../common/routesNames';
import {ALL_TEXT, ENUMS} from '../../common/constants';
import {ActionDropDownPopUp, GenericModal} from '../../components';
import {useDispatch, useSelector} from 'react-redux';
import {closeModal} from '../../utils/store/slices/popup-modal';
import {getProfile, getToken} from '../../utils/localstorage';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import {setUserLoggedInId} from '../../redux/slice/user';
import {NotificationDropdown} from '../../components/notification-dropdown';
import {
  administratorTabAccess,
  staffMaintainanceTabAccess,
  sendNotificationTabAccess,
  couponCodeTabAccess,
  disasterManagmentTabAccess,
  logsTabAccess,
  auditLogsTabAccess,
} from './module-access';

const {
  STAFF: {
    ROLE: {
      ADMIN,
      AGENT,
      AGENCY,
      CUSTOMER_SUPPORT,
      SALES_REP,
      DISTRIBUTION_CHANNEL,
      BUSINESS_AMB,
      INDEPANDANT_AGENT,
    },
  },
} = ENUMS;

const SideMenuItem = ({
  text,
  onClick,
  isSelected,
  icon,
  expanded,
  expandIcon,
  notifyCount,
  isNotification,
}) => {
  return (
    <div
      onClick={onClick}
      className={
        isSelected
          ? `user-section-item selected-tab-sidebar `
          : `user-section-item`
      }>
      <div
        className={
          isNotification
            ? 'notify-count-div side-item-container justify-content-between '
            : 'side-item-container '
        }>
        <div className="side-item-container">
          {icon}
          <span>{text}</span>
        </div>
        {isNotification && notifyCount != '' && (
          <span class="notify-count">{notifyCount}</span>
        )}
      </div>
      {getExpandedIcon(expandIcon, expanded)}
    </div>
  );
};
const getExpandedIcon = (expandIcon, expanded) => {
  if (expandIcon) {
    if (!expanded) {
      return <AiOutlinePlus />;
    } else {
      return <AiOutlineMinus />;
    }
  }
};
const SubItem = ({text, isSelected, onClick}) => {
  return (
    <div className="sub-item-side-bar" onClick={onClick}>
      {isSelected && <img src={images.smallCircle} />}
      <span
        className={
          isSelected ? 'selected-color-side-bar' : 'padding-left-sub-menu'
        }>
        {text}
      </span>
    </div>
  );
};

function MainLayout({SubPage, ...rest}) {
  const [expandedPanel1, setExpandedPanel1] = useState(false);
  const [expandedPanel2, setExpandedPanel2] = useState(false);
  const [expandedPanel3, setExpandedPanel3] = useState(false);
  const [selectedTab, setSelectedTab] = useState(rest.selectedId);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = getToken() !== '';
  const [userRole, setUserRole] = useState(-1);

  const unreadNotificationCount = useSelector(
    state => state?.notificationSlice?.notificationCount,
  );

  const unreadNotificationList = useSelector(
    state => state?.notificationSlice?.unReadNotifications,
  );

  useEffect(() => {
    if (isLoggedIn) {
      let {roleId} = getProfile() || {};
      setUserRole(roleId);
      dispatch(setUserLoggedInId(roleId));
    }
  }, [isLoggedIn]);

  const modalPopup = useSelector(state => {
    return state.modal;
  });

  useEffect(() => {
    setSelectedTab(rest.selectedId);
  }, [rest.selectedId]);

  const handleChangePanel1 = (event, isExpanded) => {
    setExpandedPanel1(isExpanded ? 'panel1' : false);
    setExpandedPanel2(false);
    setExpandedPanel3(false);
    // if (isExpanded) {
    //   setSelectedTab(5); // Update the selectedTab to the desired value for Administration tab
    // }
  };

  const handleChangePanel2 = (event, isExpanded) => {
    setExpandedPanel1(false);
    setExpandedPanel2(isExpanded ? 'panel2' : false);
    setExpandedPanel3(false);

    // if (isExpanded) {
    //   setSelectedTab(7); // Update the selectedTab to the desired value for Account tab
    // }
  };
  const handleChangePanel3 = (event, isExpanded) => {
    setExpandedPanel1(false);
    setExpandedPanel2(false);
    setExpandedPanel3(isExpanded ? 'panel3' : false);

    // if (isExpanded) {
    //   setSelectedTab(7); // Update the selectedTab to the desired value for Account tab
    // }
  };

  const menuItems = [
    {
      title: ALL_TEXT.LOGOUT,
      icon: (
        <span>
          <IoIosLogOut />
        </span>
      ),
    },
  ];
  const navTabs = [
    {
      paths: path.dashboard,
      name: ALL_TEXT.DASHBOARD,
      icon: <span class="icon-dashboard"></span>,
      roles: [
        ADMIN.VALUE,
        DISTRIBUTION_CHANNEL.VALUE,
        AGENT.VALUE,
        INDEPANDANT_AGENT.VALUE,
        AGENCY.VALUE,
        SALES_REP.VALUE,
      ],
      id: PAGE_ENUMS_ID.DASHBOARD,
    },
    {
      paths: path.users,
      name: ALL_TEXT.USERS,
      icon: <span class="icon-users"></span>,
      roles: [
        ADMIN.VALUE,
        DISTRIBUTION_CHANNEL.VALUE,
        AGENT.VALUE,
        INDEPANDANT_AGENT.VALUE,

        AGENCY.VALUE,
        SALES_REP.VALUE,
        CUSTOMER_SUPPORT.VALUE,
      ],
      id: PAGE_ENUMS_ID.USER,
    },
    {
      paths: path.invoices,
      name: ALL_TEXT.INVOICES,
      icon: <span class="icon-invoices"></span>,
      roles: [
        ADMIN.VALUE,
        DISTRIBUTION_CHANNEL.VALUE,
        AGENCY.VALUE,
        SALES_REP.VALUE,
        CUSTOMER_SUPPORT.VALUE,
      ],
      id: PAGE_ENUMS_ID.INVOICES,
    },
    {
      paths: path.susbcriptions,
      name: ALL_TEXT.SUBSCRIPTIONS,
      icon: <span class="icon-subscriptionIcon"></span>,
      roles: [
        ADMIN.VALUE,
        DISTRIBUTION_CHANNEL.VALUE,
        AGENT.VALUE,
        INDEPANDANT_AGENT.VALUE,

        AGENCY.VALUE,
        CUSTOMER_SUPPORT.VALUE,
      ],
      id: PAGE_ENUMS_ID.SUBSCRIPTION,
    },
    {
      paths: path.claims,
      name: ALL_TEXT.CLAIMS,
      icon: <span class="icon-claims"></span>,
      roles: [
        ADMIN.VALUE,
        DISTRIBUTION_CHANNEL.VALUE,
        AGENCY.VALUE,
        SALES_REP.VALUE,
        CUSTOMER_SUPPORT.VALUE,
      ],
      id: PAGE_ENUMS_ID.CLAIM,
    },
    {
      paths: path.manageCarriers,
      name: ALL_TEXT.MANAGE_CARRIERS,
      icon: <span class="icon-managecarriers"></span>,
      roles: [
        ADMIN.VALUE,
        DISTRIBUTION_CHANNEL.VALUE,
        AGENT.VALUE,
        INDEPANDANT_AGENT.VALUE,
        AGENCY.VALUE,
        CUSTOMER_SUPPORT.VALUE,
      ],
      id: PAGE_ENUMS_ID.MANAGE_CARRIER,
    },
    {
      paths: path.generateQr,
      name: ALL_TEXT.PROMOTIONS,
      icon: <span class="icon-QRCode"></span>,
      roles: [
        ADMIN.VALUE,
        DISTRIBUTION_CHANNEL.VALUE,
        AGENT.VALUE,
        INDEPANDANT_AGENT.VALUE,

        AGENCY.VALUE,
        SALES_REP.VALUE,
        BUSINESS_AMB.VALUE,
      ],
      id: PAGE_ENUMS_ID.QR_CODE,
    },
    {
      paths: path.adminNotifications,
      name: ALL_TEXT.NOTIFICATIONS_SIDE.NOTIFICATION,
      icon: <span class="icon-notification"></span>,
      roles: [
        ADMIN.VALUE,
        DISTRIBUTION_CHANNEL.VALUE,
        AGENCY.VALUE,
        BUSINESS_AMB.VALUE,
        SALES_REP.VALUE,
        CUSTOMER_SUPPORT.VALUE,
      ],
      id: PAGE_ENUMS_ID.ADMINNOTIFICATION,
    },
    {
      paths: path.tutorialGuide,
      name: ALL_TEXT.TUTORIAL_GUIDE.TUTORIAL_AND_GUIDE,
      icon: <span class="icon-tutorialGuide"></span>,
      roles: [
        ADMIN.VALUE,
        DISTRIBUTION_CHANNEL.VALUE,
        AGENT.VALUE,
        INDEPANDANT_AGENT.VALUE,

        AGENCY.VALUE,
        BUSINESS_AMB.VALUE,
        SALES_REP.VALUE,
        CUSTOMER_SUPPORT.VALUE,
      ],
      id: PAGE_ENUMS_ID.TUTORIAL_GUIDE,
    },
    {
      paths: path.commissionCalculator,
      name: ALL_TEXT.COMMISSION_CALCULATOR,
      icon: <span class="icon-commissionCalculator"></span>,
      roles: [
        ADMIN.VALUE,
        DISTRIBUTION_CHANNEL.VALUE,
        AGENT.VALUE,
        INDEPANDANT_AGENT.VALUE,
        AGENCY.VALUE,
        BUSINESS_AMB.VALUE,
        SALES_REP.VALUE,
      ],
      id: PAGE_ENUMS_ID.COMMISSION_CALCULATOR,
    },
    // {
    //   paths: path.testPage,
    //   name: ALL_TEXT.TP.TEST_PAGE,
    //   icon: <span class="icon-tutorialGuide"></span>,
    //   roles: [ADMIN.VALUE, AGENCY.VALUE, AGENT.VALUE],
    //   id: PAGE_ENUMS_ID.TEST,
    // },
  ];
  const imageURL = getProfile()?.imageURL;

  const isImageURL = url => {
    const img = new Image();
    img.src = url;
    return img.complete && img.naturalWidth !== 0;
  };

  const displayImage = isImageURL(imageURL) ? imageURL : images.dummyUser;
  const notificationCount_ =
    unreadNotificationCount == '' || unreadNotificationCount == 0
      ? ''
      : `${
          unreadNotificationCount >= 100 ? '99+' : `${unreadNotificationCount}`
        }`;
  return (
    <div className="background-dashboard">
      <div className="header-container">
        <div className="header-icon-container">
          <img className="noduk-icon-header" src={images.newNodukLogo} />
        </div>
        {notificationCount_.length > 0 && (
          <div className="notify-section-container">
            {selectedTab !== PAGE_ENUMS_ID.ADMINNOTIFICATION && (
              <NotificationDropdown
                icon={
                  <div>
                    <span
                      className="icon-notification"
                      id="main-notify-icon"></span>
                    {unreadNotificationCount && unreadNotificationCount > 0 && (
                      <span className="notify-count">{notificationCount_}</span>
                    )}
                  </div>
                }
                items={unreadNotificationList}
              />
            )}
          </div>
        )}
        <div className="header-secondary-container">
          <div className="log-out-popUp">
            <ActionDropDownPopUp
              userInfo
              userImage={
                <img
                  className="header-dp-icon"
                  src={displayImage}
                  alt="Profile Image"
                />
              }
              userEmail={getProfile()?.email || 'email@gmail.com'}
              userName={`${getProfile()?.firstName} ${getProfile()?.lastName}`}
              items={menuItems}
              visibleIcon={
                <img
                  className="header-dp-icon"
                  src={displayImage}
                  alt="Profile Image"
                />
              }
            />
          </div>
        </div>
      </div>
      <div className="main-layout-handler">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2.2} md={2.2} lg={2.2} xl={2.2}>
            <div className="side-bar-container">
              {navTabs?.map((i, index) => {
                const {name, paths, icon, roles} = i;
                const isAuthorized = roles.includes(userRole);

                if (isAuthorized === true) {
                  return (
                    <SideMenuItem
                      key={index}
                      icon={icon}
                      isSelected={i.id == selectedTab}
                      onClick={() => {
                        setSelectedTab(i.id);
                        setExpandedPanel1(false);
                        setExpandedPanel2(false);
                        setExpandedPanel3(false);
                        // if (i.id !== selectedTab) {
                        navigate({pathname: paths});
                        // }
                      }}
                      text={name}
                      notifyCount={notificationCount_}
                      isNotification={i.id == PAGE_ENUMS_ID.ADMINNOTIFICATION}
                    />
                  );
                }
              })}
              {/* {userRole !== AGENCY.VALUE && userRole !== AGENT.VALUE && ( */}
              {administratorTabAccess.includes(userRole) && (
                <Accordion
                  expanded={expandedPanel1 === 'panel1'}
                  onChange={handleChangePanel1}>
                  <AccordionSummary
                    // expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header">
                    <SideMenuItem
                      icon={<span class="icon-administration"></span>}
                      isSelected={
                        selectedTab === PAGE_ENUMS_ID.STAFF_MAINTAINANCE ||
                        selectedTab === PAGE_ENUMS_ID.NOTIFICATION ||
                        selectedTab === PAGE_ENUMS_ID.COUPON_CODE ||
                        selectedTab === PAGE_ENUMS_ID.DISASTER_MANAGEMENT
                      }
                      expandIcon
                      expanded={expandedPanel1}
                      onClick={() => {
                        if (selectedTab === PAGE_ENUMS_ID.STAFF_MAINTAINANCE) {
                          setSelectedTab(PAGE_ENUMS_ID.STAFF_MAINTAINANCE);
                          navigate({pathname: path.staffMaintainance});
                        } else if (selectedTab === PAGE_ENUMS_ID.NOTIFICATION) {
                          setSelectedTab(PAGE_ENUMS_ID.NOTIFICATION);
                          navigate({pathname: path.notifications});
                        } else if (selectedTab === PAGE_ENUMS_ID.COUPON_CODE) {
                          setSelectedTab(PAGE_ENUMS_ID.COUPON_CODE);
                          navigate({pathname: path.couponCode});
                        } else if (
                          selectedTab === PAGE_ENUMS_ID.DISASTER_MANAGEMENT
                        ) {
                          setSelectedTab(PAGE_ENUMS_ID.DISASTER_MANAGEMENT);
                          navigate({pathname: path.disasterManagement});
                        } else {
                          if (
                            userRole === AGENT.VALUE ||
                            userRole === INDEPANDANT_AGENT.VALUE
                          ) {
                            setSelectedTab(PAGE_ENUMS_ID.NOTIFICATION);
                            navigate({pathname: path.notifications});
                          } else if (userRole === CUSTOMER_SUPPORT.VALUE) {
                            setSelectedTab(PAGE_ENUMS_ID.NOTIFICATION);
                            navigate({pathname: path.notifications});
                          } else {
                            setSelectedTab(PAGE_ENUMS_ID.STAFF_MAINTAINANCE);
                            navigate({pathname: path.staffMaintainance});
                          }
                        }
                      }}
                      text={ALL_TEXT.ADMINISTRATION}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    {staffMaintainanceTabAccess.includes(userRole) && (
                      <SubItem
                        text={'Staff Maintainance'}
                        isSelected={
                          selectedTab === PAGE_ENUMS_ID.STAFF_MAINTAINANCE
                        }
                        onClick={() => {
                          setSelectedTab(PAGE_ENUMS_ID.STAFF_MAINTAINANCE);
                          navigate({pathname: path.staffMaintainance});
                        }}
                      />
                    )}
                    {sendNotificationTabAccess.includes(userRole) && (
                      <SubItem
                        text={'Send Notifications'}
                        isSelected={selectedTab === PAGE_ENUMS_ID.NOTIFICATION}
                        onClick={() => {
                          setSelectedTab(PAGE_ENUMS_ID.NOTIFICATION);
                          navigate({pathname: path.notifications});
                        }}
                      />
                    )}
                    {couponCodeTabAccess.includes(userRole) && (
                      <SubItem
                        text={'Coupon Code'}
                        isSelected={selectedTab === PAGE_ENUMS_ID.COUPON_CODE}
                        onClick={() => {
                          setSelectedTab(PAGE_ENUMS_ID.COUPON_CODE);
                          navigate({pathname: path.couponCode});
                        }}
                      />
                    )}
                    {disasterManagmentTabAccess.includes(userRole) && (
                      <SubItem
                        text={'Disaster Management'}
                        isSelected={
                          selectedTab === PAGE_ENUMS_ID.DISASTER_MANAGEMENT
                        }
                        onClick={() => {
                          setSelectedTab(PAGE_ENUMS_ID.DISASTER_MANAGEMENT);
                          navigate({pathname: path.disasterManagement});
                        }}
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
              )}
              <Accordion
                expanded={expandedPanel2 === 'panel2'}
                onChange={handleChangePanel2}>
                <AccordionSummary
                  // expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header">
                  <SideMenuItem
                    icon={<span class="icon-user"></span>}
                    isSelected={
                      selectedTab === PAGE_ENUMS_ID.MY_PROFILE ||
                      selectedTab === PAGE_ENUMS_ID.CHANGE_PASSWORD
                    }
                    expandIcon
                    expanded={expandedPanel2}
                    onClick={() => {
                      if (selectedTab === PAGE_ENUMS_ID.MY_PROFILE) {
                        setSelectedTab(PAGE_ENUMS_ID.MY_PROFILE);
                        navigate({pathname: path.myProfile});
                      } else if (
                        selectedTab === PAGE_ENUMS_ID.CHANGE_PASSWORD
                      ) {
                        setSelectedTab(PAGE_ENUMS_ID.CHANGE_PASSWORD);
                        navigate({pathname: path.changePassword});
                      } else {
                        setSelectedTab(PAGE_ENUMS_ID.ADMINISTRATION);
                        navigate({pathname: path.myProfile});
                      }
                    }}
                    text={ALL_TEXT.ACCOUNT}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <SubItem
                    text={'My Profile'}
                    isSelected={selectedTab === PAGE_ENUMS_ID.MY_PROFILE}
                    onClick={() => {
                      setSelectedTab(PAGE_ENUMS_ID.MY_PROFILE);
                      navigate({pathname: path.myProfile});
                    }}
                  />
                  <SubItem
                    text={'Change Password'}
                    isSelected={selectedTab === PAGE_ENUMS_ID.CHANGE_PASSWORD}
                    onClick={() => {
                      setSelectedTab(
                        selectedTab === PAGE_ENUMS_ID.CHANGE_PASSWORD,
                      );
                      navigate({pathname: path.changePassword});
                    }}
                  />
                </AccordionDetails>
              </Accordion>
              {logsTabAccess.includes(userRole) && (
                <Accordion
                  expanded={expandedPanel3 === 'panel3'}
                  onChange={handleChangePanel3}>
                  <AccordionSummary
                    // expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel3bh-header">
                    <SideMenuItem
                      icon={<span class="icon-logsIcon"></span>}
                      isSelected={
                        selectedTab === PAGE_ENUMS_ID.EMAIL_LOGS ||
                        selectedTab === PAGE_ENUMS_ID.NOTIFICATIN_LOGS ||
                        selectedTab === PAGE_ENUMS_ID.AUDIT_LOGS
                      }
                      expandIcon
                      expanded={expandedPanel3}
                      onClick={() => {
                        if (selectedTab === PAGE_ENUMS_ID.EMAIL_LOGS) {
                          setSelectedTab(PAGE_ENUMS_ID.EMAIL_LOGS);
                          navigate({pathname: path.emailLogs});
                        } else if (
                          selectedTab === PAGE_ENUMS_ID.NOTIFICATIN_LOGS
                        ) {
                          setSelectedTab(PAGE_ENUMS_ID.NOTIFICATIN_LOGS);
                          navigate({pathname: path.notificationLogs});
                        } else {
                          setSelectedTab(PAGE_ENUMS_ID.LOGS);
                          navigate({pathname: path.emailLogs});
                        }
                      }}
                      text={ALL_TEXT.EMAIL_LOG.LOGS}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <SubItem
                      text={'Email Logs'}
                      isSelected={selectedTab === PAGE_ENUMS_ID.EMAIL_LOGS}
                      onClick={() => {
                        setSelectedTab(PAGE_ENUMS_ID.EMAIL_LOGS);
                        navigate({pathname: path.emailLogs});
                      }}
                    />
                    <SubItem
                      text={'Notification Logs'}
                      isSelected={
                        selectedTab === PAGE_ENUMS_ID.NOTIFICATIN_LOGS
                      }
                      onClick={() => {
                        setSelectedTab(
                          selectedTab === PAGE_ENUMS_ID.NOTIFICATIN_LOGS,
                        );
                        navigate({pathname: path.notificationLogs});
                      }}
                    />
                    {auditLogsTabAccess.includes(userRole) && (
                      <SubItem
                        text={'Audit Logs'}
                        isSelected={
                          selectedTab === PAGE_ENUMS_ID.AUDIT_LOGS ||
                          selectedTab === PAGE_ENUMS_ID.AUDIT_LOGS_DETAILS
                        }
                        onClick={() => {
                          setSelectedTab(
                            selectedTab === PAGE_ENUMS_ID.AUDIT_LOGS,
                          );
                          navigate({pathname: path.auditLogs});
                        }}
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={9.8} md={9.8} lg={9.8} xl={9.8}>
            <div className="sub-page-container">
              <SubPage />
            </div>
          </Grid>
        </Grid>
      </div>
      <GenericModal
        show={modalPopup.openModal}
        type={modalPopup.type}
        title={modalPopup.title}
        body={modalPopup.details}
        buttonText={modalPopup.primaryBtnText}
        handleClose={() => dispatch(closeModal())}
      />
    </div>
  );
}

export default MainLayout;
