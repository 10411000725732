import {configureStore} from '@reduxjs/toolkit';
// import { api } from './slice/api'
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import {emptySplitApi} from './slice/emptySplitApi';
import {modalSlice} from './slice/popup-modal';
import {checkoutSlice} from './slice/checkout';
import {carrierSlice} from './slice/carriers';
import {AdminSlice} from './slice/administrator';
import {userSlice} from './slice/user';
import {ClaimsSlice} from './slice/claims';
import {couponSlice} from './slice/coupon-code';
import {disasterSlice} from './slice/disaster-management';
import {disasterAreas} from './slice/disaster-areas';
import {notificationSlice} from './slice/notifications';
import {logsSlice} from './slice/logs';
import {CommissionSlice} from './slice/commission-calculator';

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [emptySplitApi.reducerPath]: emptySplitApi.reducer,
    modal: modalSlice.reducer,
    checkout: checkoutSlice.reducer,
    carrier: carrierSlice.reducer,
    staffManagment: AdminSlice.reducer,
    User: userSlice.reducer,
    claims: ClaimsSlice.reducer,
    coupon: couponSlice.reducer,
    disaster: disasterSlice.reducer,
    disasterAreas: disasterAreas.reducer,
    notificationSlice: notificationSlice.reducer,
    logsSlice: logsSlice.reducer,
    commission: CommissionSlice.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(emptySplitApi.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
export const persistor = persistStore(store);
