import React from 'react';
import {ENUMS} from '../../../../common';
import {CellValue, ToggleButton} from '../../../../components';
import {GetBadge} from '../../../../utils/helpers/users';
import {TbEdit} from 'react-icons/tb';
import {riskLevelIcon} from '../../../../utils/helpers';
import {
  setIsRiskLevelOpenMoadal,
  setIsRiskStatusOpenMoadal,
  setSelectedRiskLevel,
} from '../../../../redux/slice/disaster-management';
import {useDispatch} from 'react-redux';

const {
  USERS: {STATUS},
} = ENUMS;
export function DisasterTable() {
  const dispatch = useDispatch();
  return [
    {
      title: 'Risk Level',
      fieldName: 'riskLevel',
      dataformat: cell => (
        <div className="risk-level-text">
          <span style={{textTransform: 'capitalize'}}>{cell}</span>
          <span>{riskLevelIcon(cell)}</span>
        </div>
      ),
    },
    {
      title: 'Deductibles %',
      fieldName: 'deductiblePercent',
      dataformat: (cell, row) => (
        <CellValue cell={`${cell} ${'%'}`} row={row} />
      ),
    },
    {
      title: 'Status',
      fieldName: 'status',
      dataformat: (cell, row) =>
        GetBadge(row.status ? STATUS.ACTIVE.LABEL : STATUS.INACTIVE.LABEL),
    },
    {
      title: 'Actions',
      fieldName: 'action',
      dataformat: (cell, row) => (
        <div>
          <span className="action-icons-staff">
            <span
              className="edit-icon"
              onClick={() => {
                dispatch(setSelectedRiskLevel(row));
                dispatch(setIsRiskLevelOpenMoadal(true));
              }}>
              <TbEdit />
            </span>

            <span className="delete-icon-staff">
              <ToggleButton
                status={!row?.status}
                onClick={() => {
                  dispatch(setSelectedRiskLevel(row));
                  dispatch(setIsRiskStatusOpenMoadal(true));
                }}
              />
            </span>
          </span>
        </div>
      ),
    },
  ];
}
