import React from 'react';
import './style.scss';
import { ENUMS, images } from '../../common';

export function SaleDistributionAdmin({
  commissionDistributionItem,
  title,
  isFixedRate,
}) {
  const getIcons = id => {
    switch (id) {
      case ENUMS.COVERAGE_TYPES.HOME:
        return images.roundHomeIcon;
      case ENUMS.COVERAGE_TYPES.DEVICE:
        return images.roundDeviceIcon;
      case ENUMS.COVERAGE_TYPES.COMMERCIALS:
        return images.roundCommercialIcon;
      case ENUMS.COVERAGE_TYPES.VEHICLE:
        return images.roundAutoIcon;
      case ENUMS.COVERAGE_TYPES.DISASTER:
        return images.roundDisasterIcon;
      default:
        return images.roundAutoIcon;
    }
  };

  return (
    <div className="commission-distribution-container">
      {title ? (
        <div className="title-black"> {title} </div>
      ) : (
        <div className="title">Sales Commission Distribution</div>
      )}
      <div className="items-heading">
        <div className="blank"></div>
        <div className="heading">
          <span>Total sales</span>
          <span>Commission Rate</span>
          <span>Commission</span>
        </div>
      </div>
      {commissionDistributionItem?.length === 0
        ? null
        : commissionDistributionItem?.map((i, id) => (
          <div className="commission-type-item" key={id}>
            <div className="title-item">
              <span>
                <img src={getIcons(i?.serviceTypeId)} />
              </span>
              <span className="title-txt">{i?.planType} </span>
            </div>
            <div className="item-details">
              <span>{i.totalSales}</span>
              <span>{`${isFixedRate?'$':''}${i.rate}${!isFixedRate?'%':''}`}</span>
              <span>${i.commission}</span>
            </div>
          </div>
        ))}
    </div>
  );
}
