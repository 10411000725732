import {createSlice} from '@reduxjs/toolkit';

export const CommissionSlice = createSlice({
  name: 'commission',
  initialState: {
    viewDetailsPopup: false,
    addEditCommissionPopup: false,
    commissionDetails: null,
  },
  reducers: {
    setViewDetails(state, {payload}) {
      state.viewDetailsPopup = payload;
    },
    setAddEditCommissionPopup(state, {payload}) {
      state.addEditCommissionPopup = payload;
    },
    setCommissionDetails(state, {payload}) {
      state.commissionDetails = payload;
    },
  },
});

export const {setViewDetails, setAddEditCommissionPopup, setCommissionDetails} =
  CommissionSlice.actions;
