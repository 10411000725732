import React from 'react';
import {ALL_TEXT, images} from '../../../common';
import {Grid} from '@mui/material';
import {BarChart} from '../../../components';
import {SalesRefundSection} from '../sales-refund';
import {Tooltip} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

export function RevenueSection({data, daysDifference}) {
  const revenueStats = data?.revenueStats;
  const revenueStatcount = data?.revenueStatcount;

  const totalDays = daysDifference;

  const revenueCardData = [
    {
      image: images.roundAutoIcon,
      serviceType: revenueStats != undefined ? revenueStats[0]?.typeName : '',
      deductibles: revenueStats != undefined ? revenueStats[0]?.totalPlans : '',
      amount: `$${
        revenueStats != undefined
          ? parseFloat(revenueStats[0]?.totalAmount).toFixed(2)
          : ''
      }`,
      arrow: revenueStats != undefined ? revenueStats[0]?.scaleRate : '',
      percentage:
        revenueStats != undefined ? revenueStats[0]?.totalPercentage : '',
      totalDays: totalDays,
    },
    {
      image: images.roundDeviceIcon,
      serviceType: revenueStats != undefined ? revenueStats[1]?.typeName : '',
      deductibles: revenueStats != undefined ? revenueStats[1]?.totalPlans : '',
      amount: `$${
        revenueStats != undefined
          ? parseFloat(revenueStats[1]?.totalAmount).toFixed(2)
          : ''
      }`,
      arrow: revenueStats != undefined ? revenueStats[1]?.scaleRate : '',
      percentage:
        revenueStats != undefined ? revenueStats[1]?.totalPercentage : '',
      totalDays: totalDays,
    },
    {
      image: images.roundHomeIcon,
      serviceType: revenueStats != undefined ? revenueStats[2]?.typeName : '',
      deductibles: revenueStats != undefined ? revenueStats[2]?.totalPlans : '',
      amount: `$${
        revenueStats != undefined
          ? parseFloat(revenueStats[2]?.totalAmount).toFixed(2)
          : ''
      }`,
      arrow: revenueStats != undefined ? revenueStats[2]?.scaleRate : '',
      percentage:
        revenueStats != undefined ? revenueStats[2]?.totalPercentage : '',
      totalDays: totalDays,
    },
    {
      image: images.roundCommercialIcon,
      serviceType: revenueStats != undefined ? revenueStats[3]?.typeName : '',
      deductibles: revenueStats != undefined ? revenueStats[3]?.totalPlans : '',
      amount: `$${
        revenueStats != undefined
          ? parseFloat(revenueStats[3]?.totalAmount).toFixed(2)
          : ''
      }`,
      arrow: revenueStats != undefined ? revenueStats[3]?.scaleRate : '',
      percentage:
        revenueStats != undefined ? revenueStats[3]?.totalPercentage : '',
      totalDays: totalDays,
    },
  ];

  const RevenueCard = ({
    typeIcon,
    serviceType,
    deductibles,
    amount,
    arrow,
    percentage,
    totalDays,
  }) => {
    return (
      <div className="revenue-card">
        <div className="revenue-card-upper">
          <div className="reveune-card-icon">
            <img src={typeIcon} alt="Icon" />
          </div>
          <div className="reveune-card-info">
            <h6>{serviceType}</h6>
            <span>
              <span>{`${ALL_TEXT.TOTAL_DEDUCTIBLES}${deductibles}`}</span>
            </span>
          </div>
        </div>
        <div className="revenue-card-lower">
          <div className="revenue-amount">
            <span>{amount}</span>
          </div>
          <div className="revenue-percent">
            <span>
              <img src={arrow} alt="icon" />
              <span
                className={percentage <= 40 ? 'downArrow' : 'upArrow'}
                data-tooltip-id={'percentage'}
                data-tooltip-content={`Compared to last ${totalDays} days.`}>
                {`${percentage}%`}
              </span>
              <Tooltip id={'percentage'} />
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="dashboard-revenue-container">
      <h5>{ALL_TEXT.REVENUE}</h5>
      <Grid container spacing={2}>
        {revenueCardData?.map((item, index) => {
          return (
            <Grid item xs={12} sm={3} md={3} lg={3} xl={3} key={index}>
              <RevenueCard
                typeIcon={item.image}
                serviceType={item.serviceType}
                deductibles={item.deductibles}
                amount={item.amount}
                arrow={
                  item.arrow === 'DN' && item.percentage != 0
                    ? images.downWordsIcon
                    : item.arrow === 'UP' && item.percentage != 0
                    ? images.upWordsIcon
                    : item.percentage === 0
                    ? images.zeroPercentIcon
                    : null
                }
                percentage={item.percentage}
                totalDays={parseFloat(item.totalDays).toFixed(0)}
              />
            </Grid>
          );
        })}
      </Grid>
      <div className="chart-container">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <BarChart
              title={'Revenue'}
              data={revenueStatcount?.map(item => ({
                date: item.date,
                Cost: item.count,
              }))}
              key1={'date'}
              key2={'Cost'}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <div className="sales-refund">
              <SalesRefundSection revenuesalesRefunds={data} />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
