export const ALL_TEXT = {
  ADDRESSKEY: process.env.REACT_APP_ADDRESSKEY,
  AGENTS: 'Agents',
  AGENT: 'Agent',
  UPDATE: 'Update',
  TOTAL: 'Total',
  TOTAL_SALES: 'Total Sales',
  TOTAL_REFUNDS: 'Total Refunds',
  CANCELLED: 'Cancelled',
  UPDATE_PAYMENT: 'Update Payment',
  TOTAL_DEDUCTIBLES: 'Total Deducibles Plans: ',
  SEND_EMAIL: 'Send email',
  SAME_BILING_ADDRESS: 'Same As Billing Address',

  CUSTOMER_TOOLTIP: 'Customers are those who have purchased deductible plans.',
  REGISTER_TOOLTIP:
    'Registered users are those who have sign up but not purchased any plan.',
  SUCCESS_PAYMENT_DETAILS:
    'We are excited to provide you with coverage for your protection. If you need anything at all, please contact us at ',
  OK: 'OK',
  EDIT: 'Edit',
  CHANGE_DEDUCTIBLES: 'Change Deductible Amount',
  SOMETHING_ERROR: `Something Went Wrong!`,
  CHECKOUT_ERROR: 'Error While Checkout!',
  ARE_YOU_SURE: 'Are you Sure?',
  CLAIMS_DOCS_SEND: 'Are you sure to send Claim documents to your email?',
  DASHBOARD: 'Dashboard',
  REVENUE: 'Revenue',
  COMMISSION_CALCULATOR: 'Commission Calculator',
  selectDisasterDeductible: 'Select Disaster Deductibles',
  homeTermsAndConditions1: 'I have read and agree on',
  homeTermsAndConditions2: 'about disaster situation.',
  iAcceptTerms: 'I accept the terms and conditions',
  errorPageText: 'The page you are looking for is temporarily unavailable.',
  TAndC: 'Terms and Conditions',
  ROLE: 'Role',
  FAILED_REASON: 'Failed Reason',
  MOB: 'Mobile',
  WEB: 'Web',
  ADMINISTRATION: 'Administration',
  CURRENT_PASSWORD: 'Current Password*',
  NEW_PASSWORD: 'New Password*',
  CONFIRM_NEW_PASSWORD: 'Confirm New Password*',
  CONFIRM_NEW_PASSWORD_: 'Confirm New Password',
  ACCOUNT: 'Account',
  ENTER_CURRENT_PASSWORD: 'Enter current password',
  ENTER_NEW_PASSWORD: 'Enter new password',
  ACTIVATE_CARRIER_: 'Activate Carrier',
  DEACTIVATE_CARRIER_: 'Deactivate Carrier',
  MY_PROFILE: 'My Profile',
  SEARCH_USER_BY: 'Search Customer By',
  SEARCH_CLAIM_BY: 'Search Claim By',
  SEARCH_MANAGE_CARRIERS_BY: 'Search Carriers By',
  SEARCH_STAFF_BY: 'Search Staff By',
  ADD_STAFF: 'Add Staff',
  QR_CODE: 'Generate QR Code',
  PROMOTIONS: 'Promotions',
  ENTER_NAME: 'Enter Name',
  ENTER_NUMBER: 'Enter Number',
  INVOICES: 'Invoices',
  SUBSCRIPTIONS: 'Subscriptions',
  SUBSCRIPTIONS_DETAILS: 'Subscriptions Details',
  SUBSCRIPTION_ID: 'Subscription Id',
  SUBTOTAL: 'Subtotal',
  TOTAL_AMOUNT: 'Total Amount',
  TAXES_AND_OTHER_FEE: 'Taxes and other fee',
  SUBSCRIPTION_ITEM: 'Subscription Items',
  INVOICE_ID: 'Invoice Id',
  AMOUNT: 'Amount',
  INVOICE_DETAILS: 'Invoice Details',
  SUBSCRIPTION_DETAILS: 'Subscription Details',
  VIEW_SUBSCRIPTION: 'View Subscription',
  USERS: 'Customers',
  CLAIMS: 'Claims',
  COUPON_CODE: 'Coupon Code',
  COUPON_CODE_: 'Coupon Code*',
  SEARCH_COUPON_CODE: 'Search Coupon Code By',
  DEACTIVATE_COUPON: 'Deactivate Coupon',
  ACTIVE_COUPON: 'Activate Coupon',
  DEACTIVATE_COUPON_MESSAGE: 'Are you sure you want to deactivate this coupon?',
  ACTIVATE_COUPON_MESSAGE: 'Are you sure you want to activate this coupon?',
  EDIT_COUPON_DETAIL: 'Edit Coupon Details',
  ADD_COUPON_CODE: 'Add Coupon Code',
  COUPON_NOTE:
    'Forever coupons will be applied to all future invoices. Once coupon will be applied to only one invoice per subscription. ',
  START_DATE: 'Start Date',
  EXPIRY_DATE: 'Expiry Date',
  COUPON_TYPE: 'Coupon Type',
  COUPON_TYPE_: 'Coupon Type*',
  COUPON_DISCOUNT: 'Coupon Discount*',
  LAST_FOUR: 'Card Last 4',
  BILLING_CARD: 'Billing Card',
  PAID_DATE: 'Paid Date',
  SUBTOTAL_AFTER_DISCOUNT: 'Subtotal After Discount',
  SUBTOTAL: 'Subtotal',
  TAXES_AND_OTHER_FEE: 'Sales Tax',
  VIEW_DETAILS: 'View Details',
  ADD_EDIT_COMMISSION: 'Add / Edit Commission Rate',
  CLAIMS_DETAILS: 'Claims Details',
  CLAIM_NUMBER: 'Claim Id ',
  CLAIM_NUMBER_: 'Claim Number :  ',
  CLAIM_ID: 'Claim Id  ',
  CLAIM_POLICY_ID: 'Claim Plan Id',
  ENTER_CLAIM_NUMBER: 'Enter Claim Number',
  ENTER_CLAIM_ID: 'Enter Claim Id',
  CLAIM_TYPE: 'Claim Type',
  CLAIM_AMOUNT: 'Claim Amount',
  CURRENT_BALANCE: 'Current Balance',
  UPDATE_CLAIM: 'Update Claim',
  DRIVER_LICENSE: 'Driver License',
  REGISTRATION_DOC: 'Registration Doc',
  INSURANCE_DECLARATION: 'Insurance Declaration Pages',
  CLAIM_INFO: 'Claim Info',
  INCIDENT_REPORT: 'Incident Report',
  OTHER_MISC: 'Other / Misc',
  PROVIDER_NUMBER: 'Carrier Claim Number',
  PROVIDER_NAME: 'Carrier Name',
  NOT_AVAILABLE: 'N/A',
  ATTACHMENTS_NOT_AVAILABLE: 'Attachments are not available',
  COUPON_CODE_VALUE:
    ' Note: While creating Coupon make sure that payable amount should not be less then $1.',

  SERVICE_TYPE_LABLE: 'Service Type*',
  CARRIER_NAME: 'Carrier Name',
  CARRIER_TYPE: 'Carrier Type',

  DATE_SETTLED: 'Date Settled',
  DUE_DATE: 'Due Date',
  CREATED_DATE: 'Created Date',
  DATE_UPDATED: 'Date Updated*',

  DATE: 'Date',
  STATUS: 'Status',
  STATUS_: 'Status *',
  SELECT: 'Select',
  SELECT_SERVICE_TYPE: 'Select service type',
  CLAIM_STATUS: 'Status',
  DATE_LOSS: 'Date Loss',
  DATE_FILED: 'Date Filed',
  CANCELLED_DATE: 'Cancelled Date',

  ADMIN_AGENT: 'ADMIN/AGENT updated Claim Info',
  NOTES: 'Notes',
  USER_NOTES: 'Customer Notes',
  MANAGE_CARRIERS: 'Manage Carriers',
  GENERATE_QR_CODE: 'Generate QR code',
  YOUR_SALE: 'Your Sales',
  AGENT_SALE: 'Agent Sales',
  UPDATE_ACCOUNT_VERIFICATION: 'Update Account Verification Status',
  REQUEST_DOCUMENTS: 'Request For Documents',
  DOWNLOAD_DOCUMENTS: 'Download Documents',
  EMAIL_SENT: 'Email sent sucessfully',
  EMAIL_NOT_SENT: 'Due to one or more error email not sent',
  ACCOUNT_VERIFICATION_STATUS_UPDATED:
    'Account verifcation status has been update successfully',
  ACCOUNT_VERIFICATION_STATUS_FAILED:
    'Due to one or more error account verification status not updated',
  DOWNLOAD_FORM: 'Download Form',
  DOWNLOAD: 'Download',
  UPLOAD: 'Upload',
  W9_Form: 'W-9 Form',
  EFT_Banking: 'EFT For Banking',
  DRIVER_LICENSE: 'Driving License',
  OTHER: 'Other/Misc',
  NO_DOCUMENTS: 'There are no documents available',
  ENTER_NOTES: 'Enter notes',
  SELECT_OPTION: 'Select OPtions',
  DOCUMENTS_UPLOADED: 'Your Documents Upload  Successfully',
  INAVLID_LINK: 'This link is invalid please try again with valid link.',
  DOWNLOAD_ERROR: 'Some thing while downloading',
  DOWNLOAD_SUCCESSFULLY: 'Documents are download successfully',
  QR_CODE_HEADING:
    'Download our brochure attachments for a visual journey like never before.',
  AUTO_BROCHURE: 'Auto Brochure',
  DEVICE_BROCHURE: 'Device Brochure',
  HOME_BROCHURE: 'Home Brochure',
  COMMERCIAL_BROCHURE: 'Commercial Brochure',
  SCAN_QR:
    ' Scan the QR code for an exclusive journey into our marketing world.',
  Amount: 'Ammount',
  CURRENT_INVOICE: 'Current Invoice',
  TAXES_AND_OTHER_FEES: 'Taxes & Other Fee',
  TOTAL_INVOICE: 'Total Invoice',
  FUTURE_INVOICE: 'Future Invoice',
  Notification_Not_Available: 'Notification logs not avaible.',
  No_BROCHURE: 'No brochure available',

  DASHBOARD_DATA: {
    TOTAL_DEDUCTIBLES: 'Total Deductibles Plans : ',
  },
  EMAIL_LOG: {
    LOGS: 'Logs',
    EMAIL_LOGS_TITLE: 'Email Logs',
    SEARCH_EMAIL_LOGS_BY: 'Search Email Logs By',
    MODULE_ACTION: 'Module Action',
    EMAIL_CONTENT: 'Email Content',
    EMAIL_FAILED: 'Email Failed',
    REQUEST: 'Request',
    RESPONSE: 'Response',
  },

  NOTIFICATION_LOGS: {
    NOTIFICATIONS: 'Notifications',
    NOTIFICATION_LOGS_TITLE: 'Notification Logs',
    SEARCH_NOTIFICATION_LOGS_BY: 'Search Notification Logs By',
    NOTIFICATION_FAILED: 'Notification Failed',
    NOTIFICATION_CONTENT: 'Notification Content',
    DEVICE_TOKEN: 'Device Token',
    CLAIM_FILED: 'Claim Filed',
    WEB_DEVICE_TOKEN: 'Web Device Token:',
    IOS_DEVICE_TOKEN: 'IOS Device Token:',
    ANDROID_DEVICE_TOKEN: 'Android Device Token:',
    NO_TOKEN_AVAILABLE: 'No Token Available',
  },
  AUDIT_LOGS: {
    LOGS: 'Logs /',
    SEARCH_AUDIT_LOGS_BY: 'Search Audit Logs By',
    AUDIT_LOGS_TITLE: 'Audit Logs',
    EMAIL: 'Email',
    ENTER_EMAIL: 'Enter Email',
    MODULE_ACTION: 'Module Action',
    REQUEST_STATUS: 'Request Status',
    ROLE: 'Role',
    SEARCH_USER: 'Search to see user logs',
    ADMINISTRATOR: 'Administrator',
    AGENT: 'Agent',
    CUSTOMER_SUPPORT: 'Customer Support',
    HTTP_REQUEST: 'Http Request',
    PLATFORM: 'Platform',
    REFERRER: 'Referrer',
    REQUEST_DATE_TIME: 'Request Date Time',
    SUCCESS: 'Success',
    IP_ADDRESS: 'IP Address',
    LOG_DATE_TIME: 'Log Date Time',
    RESPONSE_DATE_TIME: 'Response Date Time',
    DESCRIPTION: 'Description',
    USER: 'User',
    EMAIL: 'Email',
    SENT_TO_WEB: 'Sent To Web',
    SENT_TO_MOBILE: 'Sent To Mobile',
    TRUE: 'true',
  },
  SUBSCRIPTION_DETAILS_: {
    COUPON_DISCOUNT_: 'Coupon Discount',
    PLAN_DISCOUNT: 'Discount Percent',
    DISCOUNT: 'Discount',
    SUBTOTAL: 'Subtotal',
    SUBTOTAL_AFTER_DISCOUNT: 'Subtotal After Discount',
    ENDED_DATE: 'Ended Date',
    BILLING_CARD: 'Billing Card',
    SUBSCRIPTION_ITEMS: 'Subscription Items',
    REFUNDS: 'Refunds',
    REFUND_DETAILS: 'Refund Details',
    REFUND_ID: 'Refunds Id',
    REFUND_DATE: 'Refunds Date',
    REFUND_TYPE: 'Refunds Type',
    REFUND_CART_ITEMS: 'Refunded Cart Items',
    NO_REFUND_ITEMS: 'No Refunds Available',

    AMOUNT: 'Amount',
  },
  DISASTER: {
    DISASTER_MANAGEMENT: 'Disaster Management',
    DISASTER_RISK_LEVELS: 'Disaster Risk Levels',
    DISASTER_AREAS: 'Disaster Areas',
    ADD_DISASTER_AREAS: 'Add Disaster Areas',
    EDIT_DISASTER_AREAS: 'Edit Disaster Areas',
    ADD_RISK_LEVEL: '+ Add Risk Level',
    ADD_NEW_AREA: '+ Add New Area',
    ADD_RISK_LEVEL_: 'Add Risk Level',
    EDIT_RISK_LEVEL_: 'Edit Risk Level',
    RISK_LEVEL: 'Risk Level*',
    DEDUCTIBLES: 'Deductibles %*',
    DEDUCTIBLE: 'Deductibles %',
    ACTIVATE: 'Activate',
    DEACTIVATE: 'Deactivate',
    High_risk: 'Cannot insert duplicate records of high risk',

    DOWNLOAD_TEMPLATE: 'Download Template',
    IMPORT: 'Import',
    SEARCH_DISASTER_AREA: 'Search Disaster Area By',
    AREA_ZIP_CODE_: 'Area Zip Code*',
    AREA_RISK: 'Area Risk*',
    SEARCH_ZIP_CODE: 'Search By Zip Code',
    ENTER_ZIP_CODE: 'Enter Zip Code',
    SEARCH_AREA_RISK: 'Search By Area Risk',
    SELECT_AREA: 'Select Area',
    STATUS: 'Status',
    SELECT_STATUS: 'Select Status',
    IMPORT_DISASTER_AREAS: 'Import Disaster Areas',
    SELECT_FILE: 'Select File',
    FETCHING: 'Please wait while we are fetching data',
    UPDATE: 'Update',
  },

  SEARCH: 'Search',
  CUSTOMERS: 'Customers',
  ATTACHMENTS: 'Attachments:',
  ATTACHMENTS_PDF: 'Attachments PDF:',
  ATTACHMENTS_IMAGES: 'Attachments Images:',
  UPLOAD_ATTACHMENTS: 'Upload Attachments',
  REGISTRATION: 'Registrations',
  ADD_NEW_CARRIER: 'Add New Carrier',
  EDIT_CARRIER: 'Edit Carrier',
  REGISTER_USER: 'Register Customer',
  PREVIOUSE_COVERAGE: 'Previous Coverage',
  PREVIOUSE_BALANCE: 'Previous Balance',
  INVOICE_ITEMS: 'Invoice Items',
  ACTIVE: 'Active',
  ADD: 'Add',
  CANCEL: 'Cancel',
  COST: 'Cost',
  WEBSITE: 'Website',
  COUPON_REMOVED:
    'Coupon code has been removed, Apply Coupon code again for further proceeding.',
  COUPON_NOT_VALID:
    'Unfortunately, this coupon is not applicable to the current amount being processed.',
  COUPON_GREATER_THAN:
    'Unfortunately,this coupon is not applicable. Minimum checkout amount should be greater than 1$.',
  COUPON_DISCOUNT_MONTHLY:
    'Unfortunately,this coupon is only applicable on Monthly Plan.',
  STAFF_MAINTAINANCE: 'Staff Maintenance',
  SEND_NOTI: 'Send Notification',
  NOTI_GROUP: 'Notification Group*',
  NOTI_TITLE: 'Notification Title*',
  NOTI_MESSAGE: 'Notification Message*',
  NOTIFICATIONS: 'Send Notifications',
  VIEW_PROFILE: 'View Profile',
  CUSTOMER_PROFILE: 'Customer Profile',
  CLAIM_DETAILS: 'Claim Details',
  DOWNLOAD_CLAIMS_DOCS: 'Get Claim Docs',
  DEACTIVATE: 'Deactivate Account',
  DEACTIVATE_STAFF: 'Deactivate Staff Account',
  ADD_POLICY: 'Add Plan',
  ADD_NEW_POLICY: 'Add New Plan',
  ADD_CLAIM: 'Add claim',
  CHANGE_PASSWORD: 'Change Password',
  CHANGE_PASSWORD_LINK: 'Create Forget Password Link',
  GENERATE_FORGOT_PASSWORD_LINK: 'Forget Password Link.',
  ALL: 'All',
  CLOSED_CLAIMS: 'Cancelled Claims',
  CLOSED_CLAIMS__: 'Closed Claims',
  OPENED_CLAIMS: 'Open Claims',
  SETTLED_CLAIMS: 'Closed Claims',
  ERROR_PAGE_DESCRIPTION:
    'The page you are looking for is temporarily unavailable.',
  LOGIN: 'Login',
  SEND: 'Send',
  SAVE: 'Save',
  ICON: 'Icon',
  PROFILE_IMAGE: 'Image',
  NAME: 'John Doe',
  NAME_LABEL: 'Name *',
  ADD_DISASTER_COVERAGE: 'Add Disaster Coverage',
  LOGOUT: 'logout',
  EMPTY_CART: 'Empty Cart',
  FORGOT_PASSWORD: 'Forgot Password?',
  FORGOT_PASSWORD1: 'Forgot Password?',
  RESET_PASSWORD: 'Reset Password?',
  ADD_NEW_PASSWORD: 'Add New Password?',
  EMAIL: 'Email',
  ENTER_EMAIL: 'Enter Email',
  EMAIL_SENT: 'Email Sent',
  ENTER_PASSWORD: 'Enter Password',
  POLICY_ID: 'Plan Id',
  PROVIDER: 'Carrier Name',

  WIZARD_STEP_1: 'Purchase Plan',
  WIZARD_STEP_2: 'Select Payment Method',
  WIZARD_STEP_3: 'Review & Pay ',
  SELECT_CARRIER: 'Select Carrier',
  SELECT_MAKER: 'Select Maker',
  SELECT_MODEL: 'Select Model',
  SELECT_YEAR: 'Enter Year',
  ENTER_BRAND: 'Enter Brand',
  ENTER_MODEL: 'Enter Model',
  ENTER_Maker: 'Enter Maker',
  MONTHLY_COST: 'Monthly Cost:',
  PLAN_COST: 'Plan Cost',
  CANCEL_POLICY: 'Cancel Plan',
  CANCEL_SUBSCRIPTION: 'Cancel Subscription',
  CREATE_ACTIVATION_LINK: 'Create Account Setup link',
  CREATE_ACTIVATION_LINK_STAFF: 'Create Account Setup Link',
  CANT_CANCEL_POLICY: `Can't Cancel Plan`,
  CANT_ADD_CLAIM: `Can't add Claim`,
  CANT_CANCEL_SUBS: `Can't Cancel Subscription`,
  CANT_CANCEL_SUBS_DETAILS:
    "This subscription can't be cancelled. You can only cancel a subscription after 60 days of its creation.",
  CANT_CANCEL_POLICY_DETAILS: `You can’t cancel your plan before 60 days. Once your time is complete you can cancel anytime.`,
  ENTER_ADDRESS: 'Enter Address',
  ENTER_CITY: 'Enter City',
  ENTER_STATE: 'Enter State',
  ENTER_ZIPCODE: 'Enter Zip Code',
  EDIT_CUSTOMER: 'Edit Customer',
  EMPTY_CART_DETAILS: 'Are you sure to Empty Cart?',
  YES: 'YES',
  NO: 'NO',
  TYPE_REASOM: 'Type Reason',
  CHANGE_PASSWORD_LINK_MODAL: 'Change password link',
  FORGOT_PASSWORD_LINK: 'Forgot password link',
  ACTIVATION_LINK: 'Activation link',
  ACCOUNT_SETUP_LINK: 'Account Setup link',
  CREATE_ACCOUNT_SETUP_LINK: 'Create account setup link',
  OTHER_CARRIER: 'Other Carrier',
  NO_POLICY: 'No Plans Available!',
  NO_INVOICES: 'No Invoices Available!',
  DEACTIVATE_CARRIER: 'Are you sure you want to deactivate this carrier?',
  ACTIVATE_CARRIER: 'Are you sure you want to activate this carrier?',
  ACTIVATE_RISK: 'Are you sure you want to activate this risk level?',
  ACTIVATE_DISASTER_AREA:
    'Are you sure you want to activate this disaster area?',
  DEACTIVATE_RISK: 'Are you sure you want to deactivate this risk level?',
  DEACTIVATE_DISASTER_AREA:
    'Are you sure you want to deactivate this disaster area?',
  DEACTIVATE_ACCOUNT: 'Are you sure you want to deactivate this account?',
  ACTIVATE_ACCOUNT: 'Are you sure you want to activate this account?',
  ACTIVE_ACCONT: 'Activate Account',
  ACTIVE_STAFF_ACCONT: 'Activate Staff Account',
  NO_CLAIMS: 'No Claims Available!',
  SUBSCRIPTION_AMOUNT: 'Subscription Amount',
  AMOUNT: 'Amount',
  SUBSCRIPTION_TYPE: 'Subscription Type',
  NO_SUBSCRIPTION: 'No Subscription Available!',
  POLICY_REASON_CANCEL: 'Please Enter your Cancel Reason',
  SELECT_DEDUCTIBLES: 'Select Deductibles',
  OTHER_DEDUCTIBLES: 'Other Deductibles',
  NOT_AVAILABLE_ID: -1,
  PLAN_CATEGORY: {
    month: 'month',
    monthly: 'monthly',
    biannual: 'biannual',
    biannually: 'biannually',
    annual: 'annual',
    annually: 'annually',
    rejected: 'Rejected',
    cancelled: 'Cancelled',
    pending: 'Pending',
    settled: 'Settled',
  },
  RISK_CATEGORY: {
    HIGH: 'high',
    LOW: 'low',
    MEDIUM: 'medium',
  },

  DUMMY_TEXT:
    'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',

  SELECT_PAYMENT: {
    SELECT_PAYMENT_METHOD: 'Select Payment Card',
    CARD_SAVE_AS_DEFAULT: 'Card saved as default',
    ADD_CARD: '+ Add New Card',
    BILLING_CARD_LIMIT: 'You can not add more than 5 payment cards',
    ADD_NEW_CARD: 'New Credit/Debit Card.',
    NAME: 'Card Holder Name*',
    EXPIRY: 'Expiration*',
    CVV: 'CVC*',
    SAVE_CARD: 'Save Card',
    CARD_NUMBER: 'Card Number*',
    ADD_CARD_MESSAGE:
      'You haven’t added any new card. Please add a new card to proceed checkout!',
  },
  SUBSCRIPTION: {
    SUBSCRIPTION_SUMMARY: 'Subscription Summary',
    TOTAL_DEDUCTIBLES: 'Total Deductibles',
    DISCOUNT: 'Discount',
    TOTAL_RECURRING: 'Total Recurring',
    NOTE: 'Note: ',
    LEARN_MORE: 'Learn more',
    NODUK_INFO:
      'No Duk is not providing insurance services it’s a deductibles app. To know more about No Duk',
  },
  TP: {
    TEST_PAGE: ' Test Page',
  },
  QR: {
    GENERATE_QR: ' Generate QR Code',
  },
  NOTIFICATIONS_SIDE: {
    NOTIFICATION: 'Notifications ',
    NO_NOTIFICATION: 'No Notifications Available!',
  },
  TUTORIAL_GUIDE: {
    TUTORIAL_AND_GUIDE: 'Tutorials and Guides ',
    TUTORIALS: 'Tutorials',
    GUIDE: 'Guides',
    NO_TUTORIAL: 'No Tutorails Availabale',
  },
  ADD_COUPON: {
    DESCRIPTION: 'Description',
    DESCPTION_PLACE_HOLDER: 'Add Description',
    COUPON_START_DATE: 'Coupon Start Date*',
    ENTER_COUPON_CODE: 'Enter Coupon Code',
    COUPON_CODE: ' Coupon Code',
    FIXED_AMOUNT: 'Fixed Amount',
    DISCOUNT_AMOUNT: 'Discount Percent',
    MAXIMUM_REDEMPTIONS: 'Maximum Redemptions*',
    MINIMUM_DISCOUNT_AMOUNT: 'Minimum Payable Amount*',
    COUPON_EXPIRY_DATE: 'Coupon Expiry Date*',
    ENTER_AMOUNT: 'Enter Amount',
    ENTER_PERCENTAGE: 'Enter Percentage',
    ENTER_COUNT: 'Enter Count',
    ENTER_AMOUNT: 'Enter Amount',
    EXPIRY_DATE: 'Expiry Date',
    START_DATE: 'Start Date',
  },
  BUTTON_TEXT: {
    ADD: 'Add',
    OK: 'OK',
    NEXT: 'Next',
    BACK: 'Back',
    CHANGE: 'Change',
    CHECKOUT: 'Checkout',
    SAVE_AND_CONTINUE: 'Save and Continue',
    UPDATE_CARD: 'Update Card',
    CONTINUE: 'Continue',
    UPDATE: 'Update',
    YES: 'YES',
    NO: 'NO',
  },
  PLACE_HOLDERS: {
    ENTER_HOLDER_NAME: 'Enter Card Holder Name',
    SERVICE_TYPE: 'Service Type',
  },

  ERROR_PLACEHOLDERS: {
    EMAIL: 'Email is required',
    PASSWORD_REQUIRED: 'Password is required',
    PASSWORD: 'Password is required',
    CONFIRM_PASSWORD: 'Confirm password is required',
    PASSWORD_MUST_BE: 'Password must be at least 6 characters ',
    NEW_PASSWORD: 'New password is required',
    NEW_PASSWORD_MATCH_ERROR: `New and confirm passwords must match`,
    INVALID_EMAIL: 'Email address is invalid',
    INVALID_WEBSITE: 'Website is invalid',
    PASSWORD_MATCH: 'Password must be matched',
    NAME: 'Name is required',
    WEBSITE: 'Website is required',
    COMPANY_NAME: 'Company name is required',
    TAX_ID: 'Tax id is required',
    DESCRIPTION: 'Description is required',
    COUPON_CODE: 'Coupon code is required',
    COUPON_TYPE: 'Coupon type is required',
    FIXED_AMOUNT: 'Amount is required',
    MINIMUM_DISCOUNT_AMOUNT: 'Minimum discount amount is required',
    DISCOUNT_AMOUNT: 'Discount amount is required',
    REDEMPTIONS: 'Redemption count is required',
    COUPON_START_DATE: 'Coupon start date is required',
    COUPON_EXPIRY_DATE: 'Coupon expiry date is required',
    SERVICE_TYPE: 'Service type is required',
    FIRST_NAME: 'First name is required',
    LAST_NAME: 'Last name is required',
    DOB: 'Date of birth is required',
    PHONE: 'Phone number is required',
    ADDRESS: 'Address is required',
    CITY: 'City is required',
    STATE: 'State is required',
    ZIP: 'Zip code is required',
    DATE: 'Date is required',
    STATUS: 'Status is required',
    PLEASE_SELECT: 'Please select',
    PLEASE_ENTER: 'Please enter',
    CARRIER: 'carrier',
    MAKER: 'maker',
    OTHER_DEDUCTIBLES: 'Other Deductibles',
    OTHER_CARRIER: 'Other Carrier',
    BRAND: 'brand',
    MODEL: 'model',
    MINIMUM_DEDUCTIBLES: 'Minimun deductible should be $100',
    INVALID_DEDUCTIBLES: 'Other deductibles must contain valid amount',
    RISK_DEDUCTIBLE: 'Deductibles % is required',
    RISK_LEVEL: 'Risk level is required',
    AREA_RISK: 'Area risk is required',
    DISASTER_ZIP_CODE: ' Zip code is required',
    YEAR: {
      number: 'Year must be a number',
      integer: 'Year must be an integer',
      max: `Year must be less than or equal to ${new Date().getFullYear()}`,
      min: 'Year must be greater than or equal to 1900',
      required: 'Year is required',
    },
    DEDUCTIBLES: 'deductibles',
  },
  SERVICE_TYPE: {
    VEHICLE: 'Auto',
    HOME: 'Home',
    DEVICE: 'Device',
    COMMERCIAL: 'Commercial',
    DISASTER: 'Home Disaster',
    DISASTER_: 'Disaster',
    ALL: 'All',
  },
  ADD_PLAN_LABELS: {
    CARRIER: 'Carrier*',
    MAKER: 'Maker*',
    MODEL: 'Model*',
    YEAR: 'Year*',
    BRAND: 'Brand*',
    DEDUCTIBLES: 'Deductibles*',
    OTHER_CARRIER: 'Other Carrier*',
    OTHER_DEDUCTIBLES: 'Other Deductibles*',
    ADDRESS: 'Address*',
  },

  ERROR_MESSAGES: {
    CARD_NAME: 'Please enter card holder name',
    CARD_NUMBER: 'Please enter card number',
    CARD_EXPIRY: 'Please enter expiry date',
    CARD_CVV: 'Please enter CVC',
    INVALID_EMAIL: 'Invalid email address',
    DRIVER_LICENSSE: `Driver license  is required`,
    REGISTRATION_DOC: `Registration doc is required`,
    INSURANCE_ERROR: `Insurance declaration is required`,
    CLAIM_INFO_ERROR: `Claim info is required`,
    INCIDENT_REPORT_ERROR: `Incident report is required`,
    MINIMUM_DEDUCTIBLES: 'Minimun deductible should be $100',
  },
  GENERIC_MODAL: {
    SUCCESS: 'Success',
    ERROR: 'Error',
    ALERT: 'Alert',
    CARRIER_DELETE: 'Delete Carrier ',
  },
  USER_DETAIL: {
    ADD_NEW_USER_TITLE: 'Add New Customer',
    ADD_NEW_STAFF_TITLE: 'Add New Staff',
    COMPANY_NAME: 'Company Name*',
    TAX_ID: 'Tax Id*',
    ADD_COUPON_CODE: ' Add Coupon Code',
    FIRST_NAME: 'First Name*',
    LAST_NAME: 'Last Name*',
    PHONE: 'Phone Number*',
    EMAIL: 'Email*',
    ADDRESS: 'Address*',
    STATE: 'State*',
    CITY: 'City*',
    ZIP: 'Zip Code*',
    WEBSITE: 'Website',
    PASSWORD: 'Password*',
    CONFIRM_PASSWORD: 'Confirm Password',
    DESCRIPTION: 'Description',
    DESCRIPTION_: 'Description',
  },
  MY_PROFILE_: {
    FIRST_NAME: 'First Name*',
    LAST_NAME: 'Last Name*',
    PHONE: 'Phone*',
    CONTACT: 'Contact*',
    EMAIL: 'Email',
    ADDRESS: 'Address*',
    STATE: 'State*',
    CITY: 'City*',
    ZIP: 'Zip Code*',
    WEBSITE: 'Website',
  },

  FIELD_TYPE_ENUMS: {
    STREET_NUMBER: `street_number`,
    ROUTE: `route`,
    NAIBHOUR: `neighborhood`,
    LOCALITY: `locality`,
    ADMIN: `administrative_area_level_1`,
    CODE: `postal_code`,
  },
  FIELD_VALUE: {
    ADDRESS: 'address',
    CITY: 'city',
    STATE: 'state',
    ZIP: 'zip',
  },
  PlANS_TYPE: {
    MONTHLY: 'Monthly Plan',
    BIANNUAL: 'Biannual Plan',
    ANNUAL: 'Annual Plan',
  },
  DISCOUNTS_PLANS: {
    MONTHLY: 'No Discount',
    ANNUALLY: '10% ',
    BIANNUAL: '5% ',
  },
  PlANS: {
    MONTHLY: 'monthly',
    BIANNUAL: 'biannual',
    BIANNUALLY: 'biannually ',
    ANNUAL: 'annual',
  },
  RENEWS: {
    MONTHLY: 'Renews Every Month',
    BIANNUAL: 'Renews Automatically Biannually',
    YEARLY: 'Renews Automatically Every Year',
  },
  SUBSCRIPTION_PLANS_HEADING: {
    ITEM: 'Items',
    DEDUCTIBLES: 'Deductibles',
    RECURRING: 'Recurring',
  },
  URLS: {
    HOME: 'https://noduk.com/',
    INFO: 'info@noduk.com',
  },
  SUCCESSFULLY_PAYMENT: {
    TITLE: 'Thank you for your payment!',
    PAYMENT_RECEIVED: ' Your payment has been received successfully!',
    DESCRIPTION:
      'We are excited to provide you with coverage for your protection. If you need anything at all, please contact us at ',
  },
};

export const ENUMS = {
  UPDATE_ACCOUNT_VERIFICATION: [
    {
      label: 'Verified',
      value: 2,
    },
    {
      label: 'Rejected',
      value: 3,
    },
  ],
  UPDATE_ACCOUNT_VERIFICATION_STATUS: {
    Pending: {
      LABEL: 'Pending',
      VALUE: 1,
    },
    Verified: {
      LABEL: 'Verified',
      VALUE: 2,
    },
    Rejected: {
      LABEL: 'Rejected',
      VALUE: 3,
    },
  },
  UPDATE_ACCOUNT_STATUS: [
    {
      LABEL: 'Pending',
      VALUE: 1,
    },
    {
      LABEL: 'Verified',
      VALUE: 2,
    },
    {
      LABEL: 'Rejected',
      VALUE: 3,
    },
  ],
  SUBSCRIPTION: {
    ACTIVE: {
      VALUE: 1,
      LABEL: 'Active',
    },
    CANCELLED: {
      VALUE: 2,
      LABEL: 'Cancelled',
    },
    ALL: {
      VALUE: 0,
      LABEL: 'All',
    },
  },
  SUBSCRIPTION_TYPES: {
    ALL: {
      VALUE: 0,
      LABEL: 'All',
    },
    MONTHLY: {
      VALUE: 1,
      LABEL: 'Monthly',
    },
    MONTH: {
      VALUE: 1,
      LABEL: 'Month',
    },
    BIANNUAL: {
      VALUE: 2,
      LABEL: 'Biannual',
    },
    ANNUAL: {
      VALUE: 3,
      LABEL: 'Annual',
    },
  },
  POLICY_TYPES: {
    ALL: {
      VALUE: 0,
      LABEL: 'All',
    },
    VEHICLE: {
      VALUE: 1,
      LABEL: 'Auto',
    },
    DEVICE: {
      VALUE: 3,
      LABEL: 'Device',
    },
    HOME: {
      VALUE: 2,
      LABEL: 'Home',
    },
    COMMERCIAL: {
      VALUE: 4,
      LABEL: 'Commercial',
    },
  },
  POPUP_ENUMS: {
    SUCCESS: 1,
    ERROR: 2,
    ALERT: 3,
    DELETE: 4,
  },
  COUPON_CODE: {
    TYPE: {
      ONCE: {
        VALUE: 1,
        LABEL: 'Once',
      },
      FOREVER: {
        VALUE: 2,
        LABEL: 'Forever',
      },
    },
  },
  COVERAGE_TYPES: {
    VEHICLE: 1,
    HOME: 2,
    DEVICE: 3,
    COMMERCIALS: 4,
    DISASTER: 5,
  },
  COUPON_DISCOUNT: {
    FIXED_AMOUNT: 1,
    DISCOUNT_AMOUNT: 2,
  },
  SERVICE_TYPE: {
    VEHICLE: 1,
    HOME: 2,
    DEVICE: 3,
    COMMERCIAL: 4,
    DISASTER: 5,
    ALL: 0,
  },
  INVOICES: {
    ALL: {LABEL: 'All', VALUE: 0},
    PAID: {LABEL: 'Paid', VALUE: 1},
    UPCOMING: {LABEL: 'Upcoming', VALUE: 2},
    FAILED: {LABEL: 'Failed', VALUE: 3},
    OPENED: {LABEL: 'Open', VALUE: 4},
  },
  STAFF: {
    ROLE: {
      ALL: {LABEL: 'All', VALUE: 0},
      ADMIN: {LABEL: 'Admin', VALUE: 1},
      DISTRIBUTION_CHANNEL: {LABEL: 'Distribution Channel', VALUE: 2},
      AGENT: {LABEL: 'Agent', VALUE: 3},
      SALES_REP: {LABEL: 'Sales Ambassador', VALUE: 4},
      AGENCY: {LABEL: 'Agency', VALUE: 5},
      CUSTOMER_SUPPORT: {LABEL: 'Customer Support', VALUE: 6},
      BUSINESS_AMB: {LABEL: 'Business Ambassador', VALUE: 7},
      INDEPANDANT_AGENT: {LABEL: 'Agent', VALUE: 8},
      CUSTOMER: {LABEL: 'Customer'},
    },
    STATUS: {
      ACTIVE: {
        VALUE: 1,
        LABEL: 'Active',
      },
      INACTIVE: {
        VALUE: 2,
        LABEL: 'Inactive',
      },
      ALL: {
        VALUE: 0,
        LABEL: 'All',
      },
    },
  },
  MODULE: {
    ACTION: {
      ALL: {LABEL: 'All', VALUE: 0},
      INVOICE: {LABEL: 'Invoice', VALUE: 1},
      CLAIM: {LABEL: 'Claim', VALUE: 2},
      SUBSCRIPTION: {LABEL: 'Subscription', VALUE: 3},
      CUSTOMER: {LABEL: 'Customer', VALUE: 4},
      DISASTER: {LABEL: 'Disaster', VALUE: 5},
      CARRIER: {LABEL: 'Carrier', VALUE: 6},
    },
    EMAIL_STATUS: {
      ACTIVE: {
        VALUE: true,
        LABEL: 'Sent',
      },
      INACTIVE: {
        VALUE: false,
        LABEL: 'Failed',
      },
      ALL: {
        VALUE: '',
        LABEL: 'All',
      },
    },
    STATUS: {
      ACTIVE: {
        VALUE: true,
        LABEL: 'Active',
      },
      INACTIVE: {
        VALUE: false,
        LABEL: 'Inactive',
      },
      ALL: {
        VALUE: '',
        LABEL: 'All',
      },
    },
  },
  USERS: {
    ROLE: {
      REGISTERED: {
        VALUE: 2,
        LABEL: 'Registered',
      },
      CUSTOMER: {
        VALUE: 1,
        LABEL: 'Customer',
      },
      ALL: {
        VALUE: 0,
        LABEL: 'All',
      },
    },
    STATUS: {
      ACTIVE: {
        VALUE: 1,
        LABEL: 'Active',
      },
      INACTIVE: {
        VALUE: 2,
        LABEL: 'Inactive',
      },
      ALL: {
        VALUE: 0,
        LABEL: 'All',
      },
    },
    REQUEST_STATUS: {
      ALL: {
        VALUE: 0,
        LABEL: '200',
      },
      SEND: {
        VALUE: 1,
        LABEL: '400',
      },
      FAILED: {
        VALUE: 2,
        LABEL: '404',
      },
    },
  },
  CLAIMS: {
    TYPE: {
      ALL: {
        VALUE: 0,
        LABEL: 'All',
      },
      VEHICLE: {
        VALUE: 1,
        LABEL: 'Auto',
      },
      HOME: {
        VALUE: 2,
        LABEL: 'Home',
      },
      DEVICE: {
        VALUE: 3,
        LABEL: 'Device',
      },
      COMMERCIAL: {
        VALUE: 4,
        LABEL: 'Commercial',
      },
    },
    STAND: {
      ALL: {
        VALUE: 0,
        LABEL: 'All',
      },
      PENDING: {
        VALUE: 1,
        LABEL: 'Pending',
      },
      SETTLED: {
        VALUE: 2,
        LABEL: 'Settled',
      },
      CANCELLED: {
        VALUE: 3,
        LABEL: 'Cancelled',
      },
      REJECTED: {
        VALUE: 4,
        LABEL: 'Rejected',
      },
    },
  },
  RISK_LEVEL: {
    ALL: {
      VALUE: 0,
      LABEL: 'All',
    },
    HIGH: {
      VALUE: 1,
      LABEL: 'High',
    },
    LOW: {
      VALUE: 2,
      LABEL: 'Low',
    },
    MEDIUM: {
      VALUE: 3,
      LABEL: 'Medium',
    },
  },
  RISKS: {
    ALL: {
      VALUE: 0,
      LABEL: 'All',
    },
    HIGH: {
      VALUE: 1,
      LABEL: 'high',
    },
    MEDIUM: {
      VALUE: 2,
      LABEL: 'medium',
    },
    LOW: {
      VALUE: 3,
      LABEL: 'low',
    },
  },
  MODAL_TYPES: {
    SUCCESS: 1,
    ERROR: 2,
    ALERT: 3,
    DELETE: 4,
  },
  PLANS_ID: {
    MONTHLY: 1,
    BIANNUAL: 2,
    ANNUAL: 3,
  },
  CUSTOMER_TAB_INDEX: {
    PROFILE: 1,
    POLICY: 2,
    CLAIMS: 3,
    SUBSCRIPTION: 4,
    INVOICES: 5,
    CARDS: 6,
  },
  PAGE_SIZE: 10,
  VIEW_CARD_TYPE: {
    INVOICE: 1,
    SUBSCRIPTION: 2,
    POLICY: 3,
    CLAIMS: 4,
    DETAILS_BTN: 1,
    REFUND: 4,
  },
};
export const QUERYPARAM = {
  SIGNUPID: 'signupId',
  LOGID: 'logId',
  SERVICE_PROVIDER: 'serviceprovider',
  SERVICE_TYPE: 'serviceType',
  AMOUNT: 'amount',
  SERVICE_PROVIDER_ID: 'serviceProviderId',
  CART_ITEM_ID: 'cartItemId',
  CLAIM_ID: '?claimId',
  SERVICE_PROVIDER_TYPE: 'serviceProviderType',
  ENC_ID: 'ec',
};
