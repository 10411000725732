import {createSlice} from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'userSlice',
  initialState: {
    isActiveUser: false,
    selectredUser: {},
    isChangePasswordModalOpens: false,
    isActivationLinkPopUp: false,
    userLoggedInId: 1,
  },
  reducers: {
    setActiveUser(state, {payload}) {
      state.isActiveUser = payload;
    },
    setSelectedUserData(state, {payload}) {
      state.selectredUser = payload;
    },
    setChangePasswordModal(state, {payload}) {
      state.isChangePasswordModalOpens = payload;
    },
    setActivationLinkPopup(state, {payload}) {
      state.isActivationLinkPopUp = payload;
    },
    setUserLoggedInId(state, {payload}) {
      state.userLoggedInId = payload;
    },
  },
});

export const {
  setActiveUser,
  setSelectedUserData,
  setChangePasswordModal,
  setActivationLinkPopup,
  setUserLoggedInId,
} = userSlice.actions;
