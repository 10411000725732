import React from 'react';
import {Calendar} from '../calendar';

import './style.scss';

export function FilterCommissionWithDate({
  onDateChange,
  dateRange,
  setDateRange,
}) {
  return (
    <div className="calculate-commission-with-date-container">
      <div className="title-search">Calculate Commission For</div>
      <div className="calender-container">
        <Calendar
          filterdate
          initialValue={dateRange}
          onChangeDate={d => {
            setDateRange(d);

            const [startDate, endDate] = d;
            const formattedStartDate = startDate.toISOString();
            const formattedEndDate = endDate.toISOString();
            onDateChange(formattedStartDate, formattedEndDate);
          }}
        />
      </div>
    </div>
  );
}
