import React, {useState, useEffect} from 'react';
import './App.css';
import ContextProvider from './utils/context-api/contextProvider';
import ErrorBoundary from './ErrorBoundary';
import BaseRoutes from './routes';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {ToastContainer, toast} from 'react-toastify';
import {Provider, useDispatch, useSelector} from 'react-redux';
import {store} from '../src/redux/store';
import {onMessageListener} from './firebase';
import {NodukAdmnApi} from './redux/slice/nodukAdmin.ts';
import {
  setNotificationCount,
  setUnReadNotifications,
} from './redux/slice/notifications';
import {getToken} from './utils/localstorage';

function App() {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIP_KEY);

  // for Testing
  // const stripePromise = loadStripe(
  //   'pk_test_51KC643FKQ9IO2pYuc7cYnRXj2XkwmyNxcBKD5x1dRZRw0LQFZ9d5HbAl5tvVQ2reuR0MUk4Nh1QrFedtUzf5ShWA00MwoxCxjW',
  // );
  // for Production
  // const stripePromise = loadStripe(
  //   'pk_live_51KC643FKQ9IO2pYuWOnO2bpRE1TC41B1eRBvrgqfXV5A9T7ev8jDd9gDpbmyFwg34CMcosn9T08g93w9S9kWlVnO00JixNf4Pq',
  // );
  return (
    <Elements stripe={stripePromise}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Provider store={store}>
        <HelperApp />
      </Provider>
    </Elements>
  );
}

const HelperApp = () => {
  const isLoggedIn = getToken() !== '';
  const {useLazyGetApiNotificationGetEmployeeNotificationCountQuery} =
    NodukAdmnApi;
  const [adminNotificationsAPI] =
    useLazyGetApiNotificationGetEmployeeNotificationCountQuery();
  const dispatch = useDispatch();
  const newLoginUser = useSelector(state => state.modal.isUserLoggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      fetchEmpNotifications();
    }
  }, [newLoginUser]);

  const fetchEmpNotifications = async () => {
    let result = await adminNotificationsAPI();
    if (result && result.data?.success) {
      let notificationsList = result.data?.content;
      let notificationCount =
        notificationsList?.length > 0 ? notificationsList[0].count : 0;
      dispatch(setNotificationCount(notificationCount));
      dispatch(setUnReadNotifications(notificationsList));
    }
  };

  const [notification, setNotification] = useState({title: '', body: ''});
  const notify = () => toast(<ToastDisplay />);
  function ToastDisplay() {
    return (
      <div>
        <p>
          <b>{notification?.title}</b>
        </p>
        <p>{notification?.body}</p>
      </div>
    );
  }

  useEffect(() => {
    if (notification?.title) {
      notify();
      fetchEmpNotifications();
    }
  }, [notification]);

  onMessageListener().then(payload => {
    setNotification({
      title: payload?.notification?.title,
      body: payload?.notification?.body,
    });
  });

  return (
    <ContextProvider>
      <ErrorBoundary>
        <BaseRoutes />
      </ErrorBoundary>
    </ContextProvider>
  );
};

export default App;
