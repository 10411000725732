import React from 'react';
import { CellValue } from '../../../components';
import { getEmailLogStatusIcon } from '../../../utils/helpers';
import { setIsLogPopup, setSelectedEmailData } from '../../../redux/slice/logs';
import { useDispatch } from 'react-redux';
import { QUERYPARAM, images } from '../../../common';
import { useNavigate } from 'react-router';
import { path } from '../../../common/routesNames';

export function TableHeading() {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleRedirect = row => {
    const isTargetPage = window.location.pathname === path.emailLogs;
    if (row.roleId !== 1) {
      if (isTargetPage) {
        if (row.signupId === 0 && row.employeeId !== 0) {
          navigate({
            pathname: path.staffMaintainance,
            search: `?${QUERYPARAM.SIGNUPID}=${row.userEmail}`,
          });
        }
        else if (row.signupId !== 0 && row.employeeId === 0) {
          navigate({
            pathname: path.customerProfile,
            search: `?${QUERYPARAM.SIGNUPID}=${row.userEmail}`,
          });
        }
        else if (row.signupId === 0 && row.employeeId === 0) {
          navigate({
          });
        }
      }
    }
  };

  return [
    {
      title: 'User',
      fieldName: 'userEmail',
      dataformat: (cell, row) => {
        return (
          <div className="email-logs-email">
            <p onClick={() => handleRedirect(row)}>{row.userName}</p>
            <CellValue cell={cell} row={row?.sendTo} />
          </div>
        );
      },
    },
    {
      title: 'Module',
      fieldName: 'moduleAction',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Send by',
      fieldName: 'sentBy',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Sent',
      fieldName: 'sent',
      dataformat: cell => {
        const status = cell;
        const icon = getEmailLogStatusIcon(status);
        return <div>{icon}</div>;
      },
    },
    {
      title: 'Send Date',
      fieldName: 'sentDate',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Send Time',
      fieldName: 'sentTime',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Last Login',
      fieldName: 'lastLogin',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Actions',
      fieldName: '',
      dataformat: (cell, row) => (
        <div>
          <span className="action-icons-staff">
            <span
              className="search-icon-"
              onClick={() => {
                dispatch(setIsLogPopup(true));
                dispatch(setSelectedEmailData(row));
              }}>
              <img src={images.searchIcon} />
            </span>
          </span>
        </div>
      ),
    },
  ];
}
