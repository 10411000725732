import React from 'react';
import {BsCloudUpload} from 'react-icons/bs';
import './styles.scss';
import {ALL_TEXT} from './../../common/constants';
import {Grid} from '@mui/material';
import {HiCheckCircle} from 'react-icons/hi';
import {RiCloseFill} from 'react-icons/ri';

export const ChooseFile = ({
  uploadTitle,
  note,
  value,
  handleBlur,
  error,
  onRemoveFileHandler,
  addNewClaimScreen,  
  onChangeHandler,
  files,  
  customTitle,
  ...props
}) => {
  const uploadHandler = ({target: {files}}) => {
    onChangeHandler(files[0]);
  };

  const removeFile = file => {
    onRemoveFileHandler(file);
  };
  return (
    <>
      <div className="file-card">
        <div className="file-input">
          <input
            value={value}
            onBlur={handleBlur}
            type="file"
            multiple
            onChange={uploadHandler}
            onClick={e => (e.target.value = null)}
            {...props}
          />
          <button>
            <div className="cloud-icon">
              <BsCloudUpload className="uploaderButton" />
              {addNewClaimScreen ? null : customTitle ? (
                <span>{ALL_TEXT.DISASTER.SELECT_FILE}</span>
              ) : (
                <h6>{ALL_TEXT.UPLOAD_ATTACHMENTS}</h6>
              )}
            </div>
            <div className="file-info">
              <span>{uploadTitle}</span>
              <p>{note}</p>
            </div>
          </button>
        </div>
      </div>
      {addNewClaimScreen ? (
        <ul className="fileList">
          {files &&
            files.map((item, index) => {
              return (
                <li key={index}>
                  <div className="list-item">
                    <div className="left-list-elements">
                      <HiCheckCircle />
                      <span>{item.name}</span>
                    </div>
                    <div
                      className="right-list-elements"
                      onClick={() => {
                        removeFile(item);
                      }}>
                      <RiCloseFill />
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
      ) : (
        <ul className="file-list">
          <Grid container spacing={2}>
            {files &&
              files.map((file, index) => (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={index}>
                  <li className="file-item">
                    <div className="left-list-elements">
                      {/* <span class="icon-pdf popup-menu-icon red-popup-icon"></span> */}
                      <span> {file.name}</span>
                    </div>
                    <div
                      className="right-list-elements"
                      onClick={() => {
                        removeFile(file);
                      }}>
                      <RiCloseFill />
                    </div>
                  </li>
                </Grid>
              ))}
          </Grid>
        </ul>
      )}

      {error && (
        <div className="error-input-container">
          {error ? <p className="form-error">{error}</p> : null}
        </div>
      )}
    </>
  );
};
