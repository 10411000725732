import {createSlice} from '@reduxjs/toolkit';

export const disasterAreas = createSlice({
  name: 'disasterAreas',
  initialState: {
    isAreaModalOpen: false,
    selectedDisasterArea: null,
    isUpdateStatusModal: false,
    risklevels: [],
  },
  reducers: {
    setEditDisasterArea(state, {payload}) {
      state.selectedDisasterArea = payload;
    },
    setAreaModalOpen(state, {payload}) {
      state.isAreaModalOpen = payload;
    },
    setIsUpdateStatusModal(state, {payload}) {
      state.isUpdateStatusModal = payload;
    },
    setRiskLevels(state, {payload}) {
      state.risklevels = payload;
    },
  },
});
export const {
  setEditDisasterArea,
  setAreaModalOpen,
  setIsUpdateStatusModal,
  setRiskLevels,
} = disasterAreas.actions;
