import React, {useEffect} from 'react';
import {GenericModal, GenericTable} from '../../../../components';
import {DisasterTable} from './table';
import {useDispatch, useSelector} from 'react-redux';
import {AddRiskModal} from '../../../../components/add-risk-level-modal';
import {
  setIsRiskLevelOpenMoadal,
  setIsRiskStatusOpenMoadal,
} from '../../../../redux/slice/disaster-management';
import {ALL_TEXT, ENUMS} from '../../../../common';
import {NodukAdmnApi} from '../../../../redux/slice/nodukAdmin.ts';
import {toast} from 'react-toastify';
import {setRiskLevels} from '../../../../redux/slice/disaster-areas';

export function RiskLevel() {
  const {
    useLazyGetApiDisasterGetRiskLevelsListQuery,
    useLazyGetApiDisasterActiveInactiveDisasterRiskQuery,
  } = NodukAdmnApi;
  const dispatch = useDispatch();

  const [riskLevelListAPI, riskLevelListResponse] =
    useLazyGetApiDisasterGetRiskLevelsListQuery();

  const [deactivateRiskLevelAPI, deactivateRiskLevelResponse] =
    useLazyGetApiDisasterActiveInactiveDisasterRiskQuery();

  useEffect(() => {
    fetchRiskLevels();
  }, []);

  const fetchRiskLevels = async () => {
    try {
      let response = await riskLevelListAPI();
      if (response && response.data.success) {
        dispatch(setRiskLevels(response.data.content));
      }
    } catch (error) {
      throw Error(error);
    }
  };

  const activeOrDeactiveRiskLevel = async () => {
    let result = await deactivateRiskLevelAPI({
      riskId: selectedRiskLevel.id,
      isActive: !selectedRiskLevel.status,
    });

    if (result && result.data?.success) {
      dispatch(setIsRiskStatusOpenMoadal(false));
      toast.success(result.data?.description);
      fetchRiskLevels();
    } else {
      toast.error(result.data?.description);
    }
  };
  const riskLevelData = riskLevelListResponse?.data?.content || [];

  const riskStatusModal = useSelector(
    state => state.disaster.isRiskStatusModal,
  );

  const selectedRiskLevel = useSelector(
    state => state.disaster.selectedRiskLevel,
  );

  const selectedRisksLevel = useSelector(
    state => state.disaster.isRiskLevelOpenMoadal,
  );

  return (
    <>
      <GenericTable
        headings={DisasterTable()}
        data={riskLevelData}
        loader={riskLevelListResponse.isFetching}
      />

      {selectedRisksLevel && (
        <AddRiskModal
          show={selectedRisksLevel}
          handleClose={() => {
            dispatch(setIsRiskLevelOpenMoadal(false));
          }}
          updatelist={() => {
            fetchRiskLevels();
          }}
        />
      )}
      {riskStatusModal && (
        <GenericModal
          show={riskStatusModal}
          type={ENUMS.POPUP_ENUMS.ALERT}
          btnLoader={deactivateRiskLevelResponse?.isFetching}
          title={
            !selectedRiskLevel.status
              ? ALL_TEXT.DISASTER.ACTIVATE
              : ALL_TEXT.DISASTER.DEACTIVATE
          }
          button2
          grayButton2
          body={
            !selectedRiskLevel.status
              ? ALL_TEXT.ACTIVATE_RISK
              : ALL_TEXT.DEACTIVATE_RISK
          }
          buttonText={ALL_TEXT.BUTTON_TEXT.YES}
          buttonText2={ALL_TEXT.BUTTON_TEXT.NO}
          primaryBtnPress={() => {
            activeOrDeactiveRiskLevel();
          }}
          secondaryBtnPress={() => {
            dispatch(setIsRiskStatusOpenMoadal(false));
          }}
        />
      )}
    </>
  );
}
