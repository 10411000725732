import React, { useState, useEffect } from 'react';
import './styles.scss';
import ReactJson from 'react-json-view';
import { ALL_TEXT, ENUMS, QUERYPARAM, images } from '../../../common';
import { GenericBadge } from './../../../components/generic-badge/index';
import { AiFillCheckCircle } from 'react-icons/ai';
import { IoMdCloseCircle } from 'react-icons/io';
import { LogTabs } from './log-tabs';
import { NodukAdmnApi } from '../../../redux/slice/nodukAdmin.ts';
import { useLocation } from 'react-router';
import { Loader } from '../../../components';
import createDOMPurify from 'dompurify';
import { getStringFromEnumsObj } from '../../../utils/helpers';

export const RequestAuditLogs = ({ request }) => {
  if (request === undefined || request === null) {
    return <div className="request-container">Request data is empty.</div>;
  }
  try {
    const requestObject = JSON.parse(request);
    return (
      <div className="request-container">
        <ReactJson src={requestObject} />
      </div>
    );
  } catch (error) {
    return (
      <div className="request-container">
        Error parsing JSON: {error.message}
      </div>
    );
  }
};

export const ResponseAuditLogs = ({ response }) => {
  const responseObject = JSON.parse(response);
  return (
    <div className="response-container">
      <ReactJson src={responseObject} />
    </div>
  );
};
export const EmailAuditLogs = ({ emailLogsData }) => {
  const infoData = [
    {
      key: 'Sent By',
      value: emailLogsData?.sentBy,
    },
    {
      key: 'Sent To',
      value: emailLogsData?.sendTo,
    },
    {
      key: 'Send Date Time',
      value: emailLogsData?.sentDateTime,
    },
    {
      key: 'Subject',
      value: emailLogsData?.subject,
    },
  ];
  const DOMPurify = createDOMPurify(window);

  return (
    <div className="email-log-container">
      {emailLogsData?.length === 0 || !emailLogsData ? (
        <div className="not-availabale">Email logs not available!</div>
      ) : (
        <>
          <div className=" email-info">
            {infoData.map((item, index) => {
              const { key, value } = item;
              return (
                <div className="info-data" key={index}>
                  <span>{key}</span>
                  <span>{value}</span>
                </div>
              );
            })}
          </div>
          <div className="email-detail">
            <div className="detail-content">
              {
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(emailLogsData?.mailBody),
                  }}
                />
              }
            </div>
            {/*<div className="list-content">
          <ul>
            <li>Simple Tools to manage your account on the go.</li>
            <li>No any hidden charges.</li>
            <li>Multiple coverages to secure your items.</li>
            <li>Claim your coverages.</li>
          </ul>
        </div> */}
          </div>
        </>
      )}
    </div>
  );
};

export const NotificationAuditLogs = ({ adminLogsData, customerLogsData }) => {
  if (!adminLogsData?.length && !customerLogsData?.length)  {
    return <div className="not-availabale">{ALL_TEXT.Notification_Not_Available}</div>;
  }
  const GetAuditRole = id => {
    let result = {};

    if (id === ENUMS.STAFF.ROLE.ADMIN.VALUE) {
      result.badge = 'red-badge';
      result.role = ENUMS.STAFF.ROLE.ADMIN.LABEL;
    } else if (id === ENUMS.STAFF.ROLE.AGENCY.VALUE) {
      result.badge = 'green-badge';
      result.role = ENUMS.STAFF.ROLE.AGENCY.LABEL;
    } else if (id === ENUMS.STAFF.ROLE.AGENT.VALUE) {
      result.badge = 'gray-badge';
      result.role = ENUMS.STAFF.ROLE.AGENT.LABEL;
    } else if (id === ENUMS.STAFF.ROLE.SALES_REP.VALUE) {
      result.badge = 'yellow-badge';
      result.role = ENUMS.STAFF.ROLE.SALES_REP.LABEL;
    } else if (id === ENUMS.STAFF.ROLE.CUSTOMER_SUPPORT.VALUE) {
      result.badge = 'blue-badge';
      result.role = ENUMS.STAFF.ROLE.CUSTOMER_SUPPORT.LABEL;
    } else{
      result.badge = 'green-badge';
      result.role = '';
    }

    return result;
  };

  const getEmailLogStatusIcon = status => {
    if (status === true) {
      return <AiFillCheckCircle size={20} color="#1FB141" />;
    } else {
      return <IoMdCloseCircle size={20} color="#DD3333" />;
    }
  };
  return (
    <div className="notification-log-main-layout">
      {adminLogsData?.length !== 0 ||
        !adminLogsData ||
        customerLogsData?.length !== 0 ? (
        <>
          {customerLogsData && (
            <div className="audit-notification-logs-container">
              <h6>Customer Notification</h6>
              <div className="audit-notification-logs">
                <div className="audit-notification-logs-header">
                  <div className="audit-notification-claim-info">
                    <div className="claim-img">
                      <img src={images.claimIcon} alt="Icon" />
                    </div>
                    <div className="claim-failed">
                      <h6>{customerLogsData?.clickScreen}</h6>

                      <span>{customerLogsData?.body}</span>
                    </div>
                  </div>
                  <div className="audit-notification-time-info">
                    <span>{customerLogsData?.sentDateTime.split('T')}</span>
                  </div>
                </div>
                <div className="audit-notification-logs-table">
                  <div className="table-heading">
                    <span>{ALL_TEXT.AUDIT_LOGS.USER}</span>
                    <span>{ALL_TEXT.AUDIT_LOGS.EMAIL}</span>
                    <span>{ALL_TEXT.AUDIT_LOGS.ROLE}</span>
                    <span>{ALL_TEXT.AUDIT_LOGS.SENT_TO_WEB}</span>
                    <span>{ALL_TEXT.AUDIT_LOGS.SENT_TO_MOBILE}</span>
                  </div>
                  <div className="table-detail">
                    <span>{customerLogsData?.sendTo[0]?.customerName}</span>
                    <span>{customerLogsData?.sendTo[0]?.customerEmail}</span>
                    <span>
                      {
                        <GenericBadge
                          value={
                            GetAuditRole(customerLogsData?.sendTo[0]?.signupId)
                              .role
                          }
                          colors={
                            GetAuditRole(customerLogsData?.sendTo[0]?.signupId)
                              .badge
                          }
                        />
                      }
                    </span>
                    <span>{getEmailLogStatusIcon(true)}</span>
                    <span>{getEmailLogStatusIcon(false)}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {adminLogsData && (
            <div className="audit-notification-logs-container">
              <h6>Admin Notification</h6>
              <div className="audit-notification-logs">
                <div className="audit-notification-logs-header">
                  <div className="audit-notification-claim-info">
                    <div className="claim-img">
                      <img src={images.claimIcon} alt="Icon" />
                    </div>
                    <div className="claim-failed">
                      <h6>{adminLogsData?.clickScreen}</h6>
                      <span>{`${adminLogsData?.sendTo[0]?.customerName}${adminLogsData?.body}`}</span>
                    </div>
                  </div>
                  <div className="audit-notification-time-info">
                    <span>{adminLogsData?.sentDateTime}</span>
                  </div>
                </div>
                <div className="audit-notification-logs-table">
                  <div className="table-heading">
                    <span>{ALL_TEXT.AUDIT_LOGS.USER}</span>
                    <span>{ALL_TEXT.AUDIT_LOGS.EMAIL}</span>
                    <span>{ALL_TEXT.AUDIT_LOGS.ROLE}</span>
                    <span>{ALL_TEXT.AUDIT_LOGS.SENT_TO_WEB}</span>
                    <span>{ALL_TEXT.AUDIT_LOGS.SENT_TO_MOBILE}</span>
                  </div>
                  <div className="table-detail">
                    <span>{adminLogsData?.sendTo[0]?.customerName}</span>
                    <span>{adminLogsData?.sendTo[0]?.customerEmail}</span>
                    <span>
                      {
                        <GenericBadge
                          value={GetAuditRole(adminLogsData?.roleId).role}
                          colors={GetAuditRole(adminLogsData?.roleId).badge}
                        />
                      }
                    </span>
                    <span>
                      {getEmailLogStatusIcon(
                        adminLogsData?.sendTo[0]?.sendToWeb,
                      )}
                    </span>
                    <span>
                      {getEmailLogStatusIcon(
                        adminLogsData?.sendTo[0]?.sendToMobile,
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="not-availabale">
          Notification Log Data not available!
        </div>
      )}
    </div>
  );
};

function AuditLogsDetail() {
  const [selectedTab, setselectedTab] = useState(1);
  const [loader, setLoader] = useState(false);

  let location = useLocation();

  const getParamsFromUrl = () => {
    return new URLSearchParams(location.search);
  };
  let query = getParamsFromUrl();
  const logIdd = query.get(QUERYPARAM.LOGID);

  const { useLazyGetApiAuditLogsGetAuditLogDetailsQuery } = NodukAdmnApi;

  const [allAuditDetailsAPI, allAuditDetailsResponse] =
    useLazyGetApiAuditLogsGetAuditLogDetailsQuery();

  const auditDetailData = allAuditDetailsResponse?.data?.content;
  const requestData = JSON.stringify(
    allAuditDetailsResponse?.data?.content?.log?.requestObject,
  );
  const responseData =
    allAuditDetailsResponse?.data?.content?.log?.responseObject;
  const emailLogsData = allAuditDetailsResponse?.data?.content?.emailLog;
  const adminLogsData =
    allAuditDetailsResponse?.data?.content?.adminNotificationLog;

  const customerLogsData =
    allAuditDetailsResponse?.data?.content?.custNotificationLog;

  useEffect(() => {
    fetchAuditLogsDetails({ logId: logIdd });
  }, []);

  const fetchAuditLogsDetails = async logId => {
    setLoader(true);
    let response = await allAuditDetailsAPI(logId);
    if (response && response?.data?.success) {
      setLoader(false);
    }
  };

  const LOG_TAB_ID = {
    REQUEST: 1,
    RESPONSE: 2,
    EMAIL_LOGS: 3,
    NOTIFICATION_LOGS: 4,
  };

  const AuditDetail = ({ title, value, successBadge, customBadge, status }) => {
    return (
      <div className="auditLogs-detail-content-detail">
        <span className="auditLogs-title">{title}</span>
        {successBadge ? (
          <GenericBadge
            value={value}
            colors={
              value === ALL_TEXT.AUDIT_LOGS.TRUE ? 'green-badge' : 'red-badge'
            }
          />
        ) : customBadge ? (
          <span>
            <span className="custom-badge">{status}</span>
            {value}
          </span>
        ) : (
          <span>{value}</span>
        )}
      </div>
    );
  };

  const GetTabDetailHandler = ({ selectedId }) => {
    switch (selectedId) {
      case LOG_TAB_ID.REQUEST:
        return <RequestAuditLogs request={requestData} />;
      case LOG_TAB_ID.RESPONSE:
        return <ResponseAuditLogs response={responseData} />;
      case LOG_TAB_ID.EMAIL_LOGS:
        return <EmailAuditLogs emailLogsData={emailLogsData} />;
      case LOG_TAB_ID.NOTIFICATION_LOGS:
        return (
          <NotificationAuditLogs
            adminLogsData={adminLogsData}
            customerLogsData={customerLogsData}
          />
        );
    }
  };

  // const GetAgentRole = id => {
  //   if (id === ENUMS.STAFF.ROLE.ADMIN.VALUE) {
  //     return ENUMS.STAFF.ROLE.ADMIN.LABEL;
  //   } else if (id === ENUMS.STAFF.ROLE.AGENCY.VALUE) {
  //     return ENUMS.STAFF.ROLE.AGENCY.LABEL;
  //   } else if (id === ENUMS.STAFF.ROLE.SALES_REP.VALUE) {
  //     return ENUMS.STAFF.ROLE.SALES_REP.LABEL;
  //   } else if (id === ENUMS.STAFF.ROLE.CUSTOMER_SUPPORT.VALUE) {
  //     return ENUMS.STAFF.ROLE.CUSTOMER_SUPPORT.LABEL;
  //   } else {
  //     return ENUMS.STAFF.ROLE.CUSTOMER.LABEL;
  //   }
  // };
  function formatDateToYYYYMMDD(dateString) {
    const parsedDate = new Date(dateString);

    const year = parsedDate.getFullYear();
    const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(parsedDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }
  return (
    <div className="auditLogs-detail-main-container">
      <div className="title-container-auditLogs-detail">
        <div>
          <span>{ALL_TEXT.AUDIT_LOGS.LOGS} </span>
          <span>{ALL_TEXT.AUDIT_LOGS.AUDIT_LOGS_TITLE} </span>
        </div>
      </div>
      <div className="auditLogs-detail-content-outer-layer">
        {loader ? (
          <div className="edit-popup-loader">
            <Loader />
          </div>
        ) : (
          <div className="auditLogs-detail-content-inner-layer">
            <div className="auditLogs-detail-content-header">
              <div className="audit-header-left">
                <div className="user-image">
                  <span class="icon-user"></span>
                </div>
                <div className="user-name">
                  <p>{auditDetailData?.name}</p>
                  <p>{auditDetailData?.email}</p>
                </div>
                <div className="user-role">
                  {/* <span>{GetAgentRole(auditDetailData?.agentId)}</span> */}
                  {auditDetailData?.roleId > 0 ?
                    <span>{`${getStringFromEnumsObj(
                      ENUMS.STAFF.ROLE,
                      auditDetailData?.roleId,
                    )}`}</span>
                    : <span>{'Customer'}</span>
                  }                 
                </div>
              </div>
              <div className="audit-header-right">
                <span>{`Last login: ${auditDetailData?.lastLogin}`}</span>
              </div>
            </div>
            <div className="auditLogs-detail-content-box">
              <AuditDetail
                title={ALL_TEXT.AUDIT_LOGS.HTTP_REQUEST}
                customBadge
                status={'Post'}
                value={auditDetailData?.url}
              />
              <AuditDetail
                title={ALL_TEXT.AUDIT_LOGS.MODULE_ACTION}
                value={auditDetailData?.moduleAction}
              />
            </div>
            <div className="auditLogs-detail-content-box">
              <AuditDetail
                title={ALL_TEXT.AUDIT_LOGS.PLATFORM}
                value={auditDetailData?.platform.split('/')[0]}
              />
              <AuditDetail
                title={ALL_TEXT.AUDIT_LOGS.IP_ADDRESS}
                value={auditDetailData?.ipAddress}
              />
            </div>
            <div className="auditLogs-detail-content-box">
              <AuditDetail
                title={ALL_TEXT.AUDIT_LOGS.REFERRER}
                value={auditDetailData?.referer}
              />
              <AuditDetail
                title={ALL_TEXT.AUDIT_LOGS.LOG_DATE_TIME}
                value={formatDateToYYYYMMDD(auditDetailData?.logDateTime)}
              />
            </div>
            <div className="auditLogs-detail-content-box">
              <AuditDetail
                title={ALL_TEXT.AUDIT_LOGS.REQUEST_DATE_TIME}
                value={formatDateToYYYYMMDD(auditDetailData?.requestDateTime)}
              />
              <AuditDetail
                title={ALL_TEXT.AUDIT_LOGS.RESPONSE_DATE_TIME}
                value={formatDateToYYYYMMDD(auditDetailData?.responseDateTime)}
              />
            </div>
            <div className="auditLogs-detail-content-box">
              <AuditDetail
                title={ALL_TEXT.AUDIT_LOGS.SUCCESS}
                successBadge
                value={auditDetailData?.success ? 'true' : 'false'}
              />
              <AuditDetail
                title={ALL_TEXT.AUDIT_LOGS.DESCRIPTION}
                value={auditDetailData?.description}
              />
            </div>
            <LogTabs
              selectedTab={selectedTab}
              setselectedTab={i => {
                setselectedTab(i);
              }}>
              <GetTabDetailHandler selectedId={selectedTab} />
            </LogTabs>
          </div>
        )}
      </div>
    </div>
  );
}

export default AuditLogsDetail;
