import React from 'react';
import {GenericButton} from '../../../components';
import {ALL_TEXT} from '../../../common';
import './styles.scss';

export function LogTabs({setselectedTab, selectedTab, children}) {
  return (
    <div className="">
      <div className="audit-logs-tabs">
        <div className="audit-logs-tabs-wrapper">
          <GenericButton
            onPress={() => {
              setselectedTab(1);
            }}
            customStyle={
              selectedTab === 1
                ? 'generic-btn-container'
                : 'generic-btn-container unSelectedTab '
            }
            buttonText={ALL_TEXT.EMAIL_LOG.REQUEST}
          />

          <GenericButton
            onPress={() => {
              setselectedTab(2);
            }}
            customStyle={
              selectedTab === 2
                ? 'generic-btn-container'
                : 'generic-btn-container unSelectedTab '
            }
            buttonText={ALL_TEXT.EMAIL_LOG.RESPONSE}
          />
          <GenericButton
            onPress={() => {
              setselectedTab(3);
            }}
            customStyle={
              selectedTab === 3
                ? 'generic-btn-container'
                : 'generic-btn-container unSelectedTab '
            }
            buttonText={ALL_TEXT.EMAIL_LOG.EMAIL_LOGS_TITLE}
          />
          <GenericButton
            onPress={() => {
              setselectedTab(4);
            }}
            customStyle={
              selectedTab === 4
                ? 'generic-btn-container'
                : 'generic-btn-container unSelectedTab '
            }
            buttonText={ALL_TEXT.NOTIFICATION_LOGS.NOTIFICATION_LOGS_TITLE}
          />
        </div>
      </div>
      {children}
    </div>
  );
}
