import React from 'react';
import './style.scss';

export function TotalCommission({totalSale}) {
  return (
    <div className="total-commission">
      <span>Total Sales Commission </span>
      <span>{totalSale}</span>
    </div>
  );
}
export function TotalSalesRecord({
  totalSale,
  totalMonthlySale,
  commissionRate,
}) {
  return (
    <div className="total-sale-rate">
      <div>
        <span>Total Monthly Sales </span>
        <span>{totalMonthlySale}</span>
      </div>
      <div>
        <span>Commission Rate </span>
        <span>{commissionRate}</span>
      </div>
      <div>
        <span>Total Sales Commission </span>
        <span>{totalSale}</span>
      </div>
    </div>
  );
}
export function ViewDetailsItems({items}) {
  return (
    <div className="total-sale-rate">
      {items?.map((i, id) => (
        <div key={id}>
          <span>{i.key} </span>
          <span>{i.value}</span>
        </div>
      ))}
    </div>
  );
}
