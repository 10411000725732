import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
// import {BASE_URL} from './url';
import {getToken} from '../../utils/localstorage';

// Define a service using a base URL and expected endpoints
export const emptySplitApi = createApi({
  reducerPath: 'emptySplitApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL,
    // custom redux toolkit query header
    prepareHeaders: async headers => {
      try {
        const token = getToken();
        // headers.set('Accept', 'application/json');
        // headers.set('Content-Type', 'application/json');
        // headers.set('Access-Control-Allow-Origin', '*');
        if (token) {
          headers.set('Authorization', `${token}`);
        } else {
          headers.set('Authorization', '');
        }
      } catch (err) {
        headers.set('Authorization', '');
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
  // tag use for invalidate api
  tagTypes: [],
});
