import {React} from 'react';
import {CellValue} from '../../../../components';

export function tableHeading() {
  return [
    {
      title: 'Date',
      fieldName: 'date',
      dataformat: (cell, row) => {
        if (!cell) {
          return 'N/A';
        }
        return (
          <CellValue disableTooltip isToolTip={false} cell={cell} row={row} />
        );
      },
    },
    {
      title: 'Subscriptions',
      fieldName: 'subscriptions',
      dataformat: (cell, row) => (
        <CellValue
          disableTooltip
          customClassName={'commission-value'}
          cell={cell}
          row={row}
        />
      ),
    },
    {
      title: '1st Month Sale',
      fieldName: 'monthlySale',
      dataformat: (cell, row) => (
        <CellValue
          disableTooltip
          customClassName={'commission-value'}
          cell={`$${cell}`}
          row={row}
        />
      ),
    },
  ];
}
