import React from 'react';
import {ALL_TEXT} from '../../../common';

export function SalesRefundSection({revenuesalesRefunds}) {
  const dataSalesRefund = [
    {
      amount: revenuesalesRefunds?.totalSales,
      icon: (
        <span class="icon-salesIcon">
          <span class="path1"></span>
          <span class="path2"></span>
          <span class="path3"></span>
          <span class="path4"></span>
        </span>
      ),
      cardTitle: ALL_TEXT.TOTAL_SALES,
      totalCounts: '',
    },
    {
      amount: revenuesalesRefunds?.totalRefundAmount,
      icon: (
        <span class="icon-refundIcon">
          <span class="path1"></span>
          <span class="path2"></span>
          <span class="path3"></span>
        </span>
      ),
      cardTitle: ALL_TEXT.TOTAL_REFUNDS,
      totalCounts: revenuesalesRefunds?.totalRefundsCount,
    },
  ];

  return (
    <>
      {dataSalesRefund.map((item, index) => {
        return (
          <SalesRefundCard
            key={index}
            icon={item.icon}
            cardTitle={item.cardTitle}
            totalCounts={item.totalCounts}
            amount={item.amount}
          />
        );
      })}
    </>
  );
}

const SalesRefundCard = ({icon, cardTitle, totalCounts, amount}) => {
  return (
    <div className="sales-container">
      <div className="sales-box">
        {icon}
        <p>{cardTitle}</p>

        {totalCounts === '' ? (
          <div className="dummy-box">
            <span></span>
          </div>
        ) : (
          <div className="total-count-box">
            <span>{totalCounts}</span>
          </div>
        )}
      </div>
      <div className="value-box">
        <span>{`$${parseFloat(amount).toFixed(2)}`}</span>
      </div>
    </div>
  );
};
