import React, {useState, useEffect} from 'react';
import './style.scss';
import {ALL_TEXT, ENUMS} from '../../common';
import {CustomizedSearch, GenericModal} from '../../components';
import {GenericTable} from './../../components/generic-table/index';
import {tableHeading} from './table';
import Pagination from '@mui/material/Pagination';
import {getValuesFromEnumsObj} from '../../utils/helpers';
import {getAllClaims} from '../../utils/rest-services';
import {useSelector, useDispatch} from 'react-redux';
import {setDownloadClaimsPopup} from '../../redux/slice/claims';
import {usePostApiAdminClaimDocZipMutation} from '../../redux/slice/nodukAdmin.ts';
import {toast} from 'react-toastify';

function Claims() {
  const [btnLoader, setBtnLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [claimList, setClaimList] = useState([]);
  const [selectedType, setSelectedType] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState(0);
  const isClaimDocsPopUpOpens = useSelector(
    state => state.claims.isDownloadClaimsPopup,
  );
  const selectedClaim = useSelector(state => state.claims.selectedClaim);
  const dispatch = useDispatch();

  // RTK Query

  const [sendClaimDocAPI, sendClaimDocResponse] =
    usePostApiAdminClaimDocZipMutation();

  useEffect(() => {
    const payload = {
      pageNo: currentPage,
      pageSize: ENUMS.PAGE_SIZE,
      claimNo: 0,
      claimType: selectedType,
      status: selectedStatus,
      email: '',
    };
    getAllClaimList(payload);
  }, [currentPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const getAllClaimList = async payload => {
    setLoader(true);
    let response = await getAllClaims(payload);
    // debugger;
    if (response && response.success) {
      const {
        content: {list, totalPages},
      } = response;
      setLoader(false);
      setTotalPage(totalPages);
      setClaimList(list);
    } else {
      setBtnLoader(false);
      setClaimList([]);
      setTotalPage(1);
    }
  };

  const userSearchhandler = async (
    number,
    email,
    typeDropDown,
    statusDropDown,
    clear,
  ) => {
    let payload;
    const claimType = getValuesFromEnumsObj(ENUMS.POLICY_TYPES, typeDropDown);
    const status = getValuesFromEnumsObj(ENUMS.CLAIMS.STAND, statusDropDown);
    if (clear) {
      payload = {
        claimNo: 0,
        email: '',
        claimType: 0,
        status: 0,
        pageNo: 1,
        pageSize: ENUMS.PAGE_SIZE,
      };
      setSelectedType(0);
      setSelectedStatus(0);
    } else {
      setSelectedType(claimType);
      setSelectedStatus(status);
      payload = {
        claimNo: number || 0,
        email: email,
        claimType: claimType,
        status: status,
        pageNo: 1,
        pageSize: ENUMS.PAGE_SIZE,
      };
    }
    setCurrentPage(1);
    setBtnLoader(true);
    await getAllClaimList(payload);
    setBtnLoader(false);
  };

  const sendClaimDocuments = async () => {
    let result = await sendClaimDocAPI({
      signupId: selectedClaim.signupId,
      claimId: selectedClaim.claimId,
    });
    if (result && result?.data?.success) {
      toast.success(result.data.description, {autoClose: true});
      dispatch(setDownloadClaimsPopup(false));
    } else {
      toast.error(result.data.description, {autoClose: true});
    }
  };

  return (
    <div className="claimlist-main-container">
      <div className="title-container-claimlist">
        <span>{ALL_TEXT.CLAIMS}</span>
      </div>
      <div className="claim-search-bar">
        <CustomizedSearch
          title={ALL_TEXT.SEARCH_CLAIM_BY}
          firstLabel={ALL_TEXT.CLAIM_NUMBER}
          isFirstInputNumeric
          firstPlaceholder={'Enter Claim Id'}
          firstPrefixerValue={'clm_'}
          secondInput
          secondLabel={ALL_TEXT.EMAIL}
          secondPlaceholder={ALL_TEXT.ENTER_EMAIL}
          firstDropdown
          firstDropdownLabel={ALL_TEXT.CLAIM_TYPE}
          firstDropdownList={[
            ENUMS.POLICY_TYPES.ALL.LABEL,
            ENUMS.POLICY_TYPES.VEHICLE.LABEL,
            ENUMS.POLICY_TYPES.DEVICE.LABEL,
            ENUMS.POLICY_TYPES.HOME.LABEL,
            ENUMS.POLICY_TYPES.COMMERCIAL.LABEL,
          ]}
          secondDropdown
          secondDropdownLabel={ALL_TEXT.STATUS}
          seconddropdownlist={[
            ENUMS.CLAIMS.STAND.ALL.LABEL,
            ENUMS.CLAIMS.STAND.REJECTED.LABEL,
            ENUMS.CLAIMS.STAND.PENDING.LABEL,
            ENUMS.CLAIMS.STAND.SETTLED.LABEL,
            ENUMS.CLAIMS.STAND.CANCELLED.LABEL,
          ]}
          onClearFilterHandler={() => {
            userSearchhandler('', '', '', '', true);
          }}
          btnLoader={btnLoader}
          onSearchBtnPress={(
            number,
            email,
            _,
            typeDropDown,
            statusDropDown,
          ) => {
            userSearchhandler(number, email, typeDropDown, statusDropDown);
          }}
        />
      </div>
      <div className="table-claimlist-container">
        <GenericTable
          loader={loader}
          headings={tableHeading()}
          data={claimList}
        />
      </div>
      {totalPage > 1 && (
        <div className="pagination-container">
          <div className="inner-pagination">
            <Pagination
              page={currentPage}
              color={'secondary'}
              onChange={handlePageChange}
              count={totalPage}
            />
          </div>
        </div>
      )}
      {isClaimDocsPopUpOpens && (
        <GenericModal
          show={isClaimDocsPopUpOpens}
          btnLoader={sendClaimDocResponse.isLoading}
          type={ENUMS.POPUP_ENUMS.ALERT}
          title={ALL_TEXT.ARE_YOU_SURE}
          buttonText={ALL_TEXT.BUTTON_TEXT.YES}
          buttonText2={ALL_TEXT.BUTTON_TEXT.NO}
          body={ALL_TEXT.CLAIMS_DOCS_SEND}
          button2={'No'}
          grayButton2
          primaryBtnPress={() => {
            sendClaimDocuments();
          }}
          secondaryBtnPress={() => {
            dispatch(setDownloadClaimsPopup(false));
          }}
        />
      )}
    </div>
  );
}

export default Claims;
