import React, {useEffect} from 'react';
import {
  CommissionCards,
  CommissionRateTable,
  FilterCommissionWithDate,
  GenericTable,
  Loader,
  TotalCommission,
} from '../../../../components';
import {Grid} from '@mui/material';
import {ENUMS, images} from '../../../../common';
import {TotalSalesRecord} from '../../../../components/total-commission';
import {tableHeading as SalesAmbassadorTable} from '../admin(sales-ambassador)/table';
import {tableHeading as BussinessSalesAmbassadorTable} from '../admin(business-ambassador)/table';
import {Tooltip} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

export function AmbassadorCalculator({
  userId,
  data,
  onDateChangeFilter,
  dateRange,
  setDateRange,
  loader,
}) {
  const {
    deductibles,
    subscriptions,
    customers,
    registrations,
    salesDistribution,
    commissionTable,
    totalCommission,
    totalMonthlySale,
    totalCommissionRate,
  } = data || {};

  useEffect(() => {
    const [startDate, endDate] = dateRange;
    onDateChangeFilter(startDate, endDate);
  }, []);

  const topCardsList = [
    {
      icon: images.deductiblesIcon,
      tile: 'Total Sales',
      count: (
        <>
          <span
            style={{cursor: 'pointer'}}
            data-tooltip-id={'sales'}
            data-tooltip-content={'Sales based on 1st month'}>
            {`$${deductibles}` || 'N/A'}
          </span>
          <Tooltip id={'sales'} classNameArrow="arrow" />
        </>
      ),
    },
    {
      icon: images.subscriptionsIcon,
      tile: 'Total Subscriptions',
      count: `${subscriptions}` || 'N/A',
    },
    {
      icon: images.customersIcon,
      tile: 'Customers',
      count: `${customers}` || 'N/A',
    },
    {
      icon: images.registerationIcon,
      tile: 'Registrations',
      count: `${registrations}` || 'N/A',
    },
  ];

  const GetSalesTable = () => {
    if (userId === ENUMS.STAFF.ROLE.SALES_REP.VALUE) {
      return {
        table: SalesAmbassadorTable(),
        saleRecord: <TotalCommission totalSale={`$${totalCommission}`} />,
      };
    } else {
      return {
        table: BussinessSalesAmbassadorTable(),
        saleRecord: (
          <TotalSalesRecord
            totalMonthlySale={`$${totalMonthlySale}`}
            commissionRate={`${totalCommissionRate}%`}
            totalSale={`$${totalCommission}`}
          />
        ),
      };
    }
  };

  return (
    <div>
      <div className="filter-container">
        <FilterCommissionWithDate
          dateRange={dateRange}
          setDateRange={setDateRange}
          onDateChange={(start, end) => {
            onDateChangeFilter(start, end);
          }}
        />
      </div>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="cards-container-calculator">
            <CommissionCards list={topCardsList} />
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
              <div className="commission-distribution-container">
                <div className="title">Sales Commission Distribution</div>
                <GenericTable
                  headings={GetSalesTable().table}
                  data={salesDistribution}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <CommissionRateTable dataList={commissionTable} />
              {GetSalesTable().saleRecord}
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}
