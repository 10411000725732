import React, {useState, useEffect} from 'react';
import './styles.scss';
import {ALL_TEXT, ENUMS} from '../../../common';
import {CustomizedSearch, GenericTable} from '../../../components';
import {TableHeading} from './table.js';
import Pagination from '@mui/material/Pagination';
// import {getValuesFromEnumsObj} from '../../../utils/helpers';
import LogsPopup from '../../../components/logs-popup';
import {useDispatch, useSelector} from 'react-redux';
import {setIsLogPopup} from './../../../redux/slice/logs';
import {
  usePostApiAuditLogsGetAllEmailLogsMutation,
  NodukAdmnApi,
} from '../../../redux/slice/nodukAdmin.ts';
import createDOMPurify from 'dompurify';
import {getValuesFromEnumsObj} from '../../../utils/helpers';

function EmailLogs() {
  // const [Loader, setLoader] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedEmail, setSelectedEmail] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedModule, setSelectedModule] = useState('');
  const {useLazyGetApiAuditLogsGetEmailLogByIdQuery} = NodukAdmnApi;

  const dispatch = useDispatch();
  const isLogPopUp = useSelector(state => state.logsSlice.isLogPopUp);
  const emailData = useSelector(state => state.logsSlice.selectedEmailData);

  const [getAllEmailsAPI, getAllEmailsResponse] =
    usePostApiAuditLogsGetAllEmailLogsMutation();

  const [logDetailsAPI, logDetailsResponse] =
    useLazyGetApiAuditLogsGetEmailLogByIdQuery();

  useEffect(() => {
    if (isLogPopUp) {
      fetchLogDetails({logId: emailData?.logId});
    }
  }, [isLogPopUp]);

  const fetchLogDetails = async logId => {
    await logDetailsAPI(logId);
  };

  const getEmailList = getAllEmailsResponse?.data?.content?.list;
  const emailBody = logDetailsResponse?.data?.content?.mailBody;
  const emailLogDetails = logDetailsResponse?.data?.content;

  useEffect(() => {
    let payload = {
      logPageDto: {
        pageNo: currentPage,
        pageSize: ENUMS.PAGE_SIZE,
        email: selectedEmail,
        moduleAction: selectedModule,
        status: selectedStatus,
      },
    };
    getEmailLogsLit(payload);
  }, [currentPage]);

  const getEmailLogsLit = async payload => {
    let response = await getAllEmailsAPI(payload);
    if (response && response?.data?.success) {
      setTotalPages(response?.data?.content?.totalPages);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const emailSearchHandler = async (
    email,
    moduleDropDown,
    statusDropDown,
    clear,
  ) => {
    let payload;
    if (clear) {
      payload = {
        logPageDto: {
          email: '',
          pageNo: 1,
          pageSize: ENUMS.PAGE_SIZE,
          moduleAction: '',
          status: '',
        },
      };
      setSelectedEmail('');
      setSelectedModule('');
      setSelectedStatus('');
    } else {
      // const moduleAction = getValuesFromEnumsObj(
      //   ENUMS.MODULE.ACTION,
      //   moduleDropDown,
      // );
      const status = getValuesFromEnumsObj(ENUMS.MODULE.STATUS, statusDropDown);
      payload = {
        logPageDto: {
          email: email,
          moduleAction: moduleDropDown === 'All' ? '' : moduleDropDown,
          status: status,
          pageNo: currentPage,
          pageSize: ENUMS.PAGE_SIZE,
        },
      };
      setSelectedEmail(email);
      setSelectedModule(moduleDropDown === 'All' ? '' : moduleDropDown);
      setSelectedStatus(status);
    }
    setCurrentPage(1);
    setBtnLoader(true);
    await getEmailLogsLit(payload);
    setBtnLoader(false);
  };
  const {
    MODULE: {
      ACTION: {ALL, INVOICE, CLAIM, SUBSCRIPTION, CUSTOMER, DISASTER, CARRIER},
    },
  } = ENUMS;

  const emailContent = () => {
    const DOMPurify = createDOMPurify(window);
    const {sentBy, sendTo, sentDateTime, subject, moduleAction} =
      emailLogDetails || {};
    return (
      <div>
        <div className="email-log-data">
          <div className="email-log-item">
            <span>Send By</span>
            <span>{sentBy ?? 'N/A'}</span>
          </div>
          <div className="email-log-item">
            <span>Send To</span>
            <span>{sendTo ?? 'N/A'}</span>
          </div>
          <div className="email-log-item">
            <span>Send Date Time</span>
            <span>{sentDateTime ?? 'N/A'}</span>
          </div>
          <div className="email-log-item">
            <span>Subject</span>
            <span>{subject ?? 'N/A'}</span>
          </div>
          <div className="email-log-item">
            <span>Module Action</span>
            <span>{moduleAction || 'N/A'}</span>
          </div>
        </div>
        <div className="email-content">
          {
            <div
              dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(emailBody)}}
            />
          }
        </div>
      </div>
    );
  };
  const failedContent = () => {
    const {failedReason} = emailLogDetails || {};
    return (
      <div className="failed-content">
        <p>{failedReason ?? 'Something went wrong while sending Email!'}</p>
      </div>
    );
  };

  return (
    <div className="email-logs-main-container">
      <div className="title-container-email-logs">
        <div>
          <span>{ALL_TEXT.EMAIL_LOG.EMAIL_LOGS_TITLE} </span>
        </div>
      </div>
      <div className="email-logs-search-bar">
        <CustomizedSearch
          title={ALL_TEXT.EMAIL_LOG.SEARCH_EMAIL_LOGS_BY}
          firstLabel={ALL_TEXT.EMAIL}
          firstPlaceholder={ALL_TEXT.ENTER_EMAIL}
          firstDropdown
          firstDropdownLabel={ALL_TEXT.EMAIL_LOG.MODULE_ACTION}
          firstDropdownList={[
            ALL.LABEL,
            INVOICE.LABEL,
            CLAIM.LABEL,
            SUBSCRIPTION.LABEL,
            CUSTOMER.LABEL,
            DISASTER.LABEL,
            CARRIER.LABEL,
          ]}
          secondDropdown
          secondDropdownLabel={ALL_TEXT.STATUS}
          seconddropdownlist={[
            ENUMS.MODULE.EMAIL_STATUS.ACTIVE.LABEL,
            ENUMS.MODULE.EMAIL_STATUS.INACTIVE.LABEL,
            ENUMS.MODULE.EMAIL_STATUS.ALL.LABEL,
          ]}
          onClearFilterHandler={() => {
            emailSearchHandler('', '', '', true);
          }}
          btnLoader={btnLoader}
          onSearchBtnPress={(email, _, __, moduleDropDown, statusDropDown) => {
            emailSearchHandler(email, moduleDropDown, statusDropDown, false);
          }}
        />
      </div>
      <div className="table-emailLog-List-container">
        <GenericTable
          headings={TableHeading()}
          data={getEmailList}
          loader={getAllEmailsResponse.isLoading}
        />
      </div>
      {totalPages > 1 && (
        <div className="pagination-container">
          <div className="inner-pagination">
            <Pagination
              page={currentPage}
              color={'secondary'}
              onChange={handlePageChange}
              count={totalPages}
            />
          </div>
        </div>
      )}
      {isLogPopUp && (
        <LogsPopup
          show={isLogPopUp}
          loading={logDetailsResponse?.isFetching}
          title={
            emailData.sent === true
              ? ALL_TEXT.EMAIL_LOG.EMAIL_CONTENT
              : ALL_TEXT.EMAIL_LOG.EMAIL_FAILED
          }
          body={emailData.sent === true ? emailContent() : failedContent()}
          handleClose={() => dispatch(setIsLogPopup(false))}
        />
      )}
    </div>
  );
}

export default EmailLogs;
