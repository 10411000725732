import React from 'react';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import { ALL_TEXT, ENUMS } from '../../../common/constants';
import { colors } from '../../../common/theme';
import { GrClose } from 'react-icons/gr';
import Dropdown from 'react-dropdown';
import { TextAreaFeild } from '../../../components';
import { GenericButton } from '../../../components/generic-button';
import { BsChevronDown } from 'react-icons/bs'
import { BsChevronUp } from 'react-icons/bs'
import { Grid } from '@mui/material';
import { images } from '../../../common';
import { useDispatch } from 'react-redux';
import { usePostApiAdminUpdateAgentStatusMutation } from '../../../redux/slice/nodukAdmin.ts';
import { setIsAccountVerificationPopUp } from '../../../redux/slice/administrator';
import { setModalDetails } from '../../../redux/slice/popup-modal';
import { toast } from 'react-toastify';

export function AccountVerificationstatus({ show, handleClose, data, getEmployees }) {

    const verificationStatus = ENUMS.UPDATE_ACCOUNT_VERIFICATION?.find(i => i.value == data.verificationStatus)
    const [dropdownValue, setDropdownValue] = useState(verificationStatus);
    const [isTextAreaValid, setIsTextAreaValid] = useState(false);
    const [message, setMessage] = useState('');
    const dispatch = useDispatch();
    const handleChange = event => {
        const inputValue = event.target.value;
        setMessage(event.target.value);
        const isValid = inputValue.trim().length > 0;
        setIsTextAreaValid(isValid);
    };
    const [
        updateAgentStatusAPI,
        { isLoading },
    ] = usePostApiAdminUpdateAgentStatusMutation();
    const verfication = async () => {
        const payload = {
            agentStatusDto: {
                employeeId: data.employeeId,
                status: dropdownValue.value,
                email: data.email,
                name: data.firstName + '' + data.lastName,
                rejectionReason: message,
            }
        }

        try {
            let result = await updateAgentStatusAPI(payload);
            {
                result?.data?.success &&
                    toast.success(
                        result.data?.description ||
                        ALL_TEXT.ACCOUNT_VERIFICATION_STATUS_UPDATED
                    );
            }
            {
                result?.error?.data &&
                    toast.error(
                        result.error?.data?.title ||
                        ALL_TEXT.ACCOUNT_VERIFICATION_STATUS_FAILED
                    );
            }
        } catch (error) {
            dispatch(
                setModalDetails({
                    type: ENUMS.POPUP_ENUMS.ERROR,
                    details: error,
                }),
            );
        }
        dispatch(setIsAccountVerificationPopUp(false));
        getEmployees();
    }

    return (
        <>
            <Modal
                className="accountverifiactionpopup"
                centered
                size="md"
                show={show}
                onHide={handleClose}>
                <Modal.Header>
                    <div className="top-add-payment-container " >
                        <span>{ALL_TEXT.UPDATE_ACCOUNT_VERIFICATION} </span>
                        <GrClose
                            className="add-payment-close-btn"
                            onClick={handleClose}
                            size={18}
                            color={colors.primary}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="add-edit-deductibles-container">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className='user-info-container'>
                                    <img height={'55px'} src={images.activeAccountLink} className='logo' />
                                    <div className='name-email-container'>
                                        <span className='namespan' >{data.firstName + " " + data.lastName}</span>
                                        <span>{data.email}</span>
                                    </div>
                                </div>

                                <div className='status-drop-down-popup'  >
                                    <label className='label'>{ALL_TEXT.STATUS}</label>
                                    <Dropdown
                                        options={ENUMS.UPDATE_ACCOUNT_VERIFICATION}
                                        onChange={i => {
                                            setDropdownValue(i);
                                        }}
                                        value={dropdownValue}
                                        menuClassName="dropdown-menu-alluser"
                                        className="dropdown-style-by-status"
                                        controlClassName="status-control-dropdown"
                                        placeholder={ALL_TEXT.SELECT_OPTION}
                                        arrowClosed={<BsChevronDown />}
                                        arrowOpen={<BsChevronUp />}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                                <div className="textArea-add-policy">
                                    <div className="noti-single-container">
                                        <label htmlFor="/">{ALL_TEXT.NOTES}</label>
                                        {<TextAreaFeild
                                            length={'400'}
                                            id={'message'}
                                            name={'message'}
                                            placeholder={ALL_TEXT.ENTER_NOTES}
                                            value={message}
                                            onChange={handleChange}
                                        />
                                        }
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <GenericButton
                        buttonText={ALL_TEXT.UPDATE}
                        loading={isLoading}
                        onPress={() => verfication()}
                        disable={!isTextAreaValid}
                    />
                </Modal.Footer>
            </Modal>

        </>
    );

}

export default AccountVerificationstatus;