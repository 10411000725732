import React, {useState, useEffect, useRef} from 'react';
import {Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import {GenericButton} from '../generic-button';
import {ALL_TEXT, ENUMS} from '../../common/constants';
import {GrClose} from 'react-icons/gr';
import './style.scss';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import {colors} from './../../common/theme';
import {useDispatch, useSelector} from 'react-redux';
import {setSelectedCard} from '../../redux/slice/checkout';
import {openModal, setModalDetails} from '../../redux/slice/popup-modal';

export function AddPaymentMethod({
  show,
  handleClose,
  existingCards,
  onSubscriptionScreen,
}) {
  const [isCardSave, setIsCardSave] = useState(false);
  const [addNewSelected, setAddNewSelected] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [cardName, setCardName] = useState('');
  const [slectedPaymentCardId, setSlectedPaymentCardId] = useState(-1);
  // const [btnLoading, setBtnLoading] = useState(false);
  const [currentCard, setCurrentCard] = useState({});
  const [stripeError, setStripeError] = useState({
    cardName: '',
    cardNumber: '',
    cardExpiry: '',
    cardCvc: '',
  });
  const dispatch = useDispatch();
  const selectedCard = useSelector(state => {
    return state.checkout.selectedCard;
  });

  const handleCardFieldChange = (event, name) => {
    if (event.error) {
      setStripeError(other => ({other, [name]: event.error.message}));
    } else {
      setStripeError(other => ({other, [name]: null}));
    }
  };
  // const {setSelectedCard} = useContext(ApplicationContext);
  const stripe = useStripe();
  const elements = useElements();
  const myRef = useRef(null);

  const scrollToRef = () => {
    myRef?.current?.scrollIntoView({behavior: 'smooth'});
  };
  const openErrorModal = details => {
    dispatch(
      setModalDetails({
        title: ALL_TEXT.GENERIC_MODAL.ERROR,
        type: ENUMS.MODAL_TYPES.ERROR,
        details: details || ALL_TEXT.CHECKOUT_ERROR,
      }),
    );
    dispatch(openModal());
  };
  useEffect(() => {
    if (show) {
      if (existingCards?.length > 0 && selectedCard) {
        // let selectedCard = existingCards.find(
        //   e => e.stripePaymentMethodId == stripePaymentMethodId,
        // );
        setSlectedPaymentCardId(parseInt(selectedCard?.paymentInfoId));
        // const newCardObj = {
        //   paymentMethodId: selectedCard?.stripePaymentMethodId,
        //   cardType: selectedCard?.cardType,
        //   last4: selectedCard?.cardNumber,
        //   isCardSave: false,
        //   cardName: '',
        //   newCard: false,
        //   temporaryCard: false,
        // };
        setCurrentCard(selectedCard);
        setTimeout(() => {
          scrollToRef();
        }, 1000);
        return;
      }
      setBtnLoader(false);
      setStripeError(null);
      setCardName('');
      // selectedCard?.temporaryCard && setSelectedCard(null);   // for the time being not discard new card untill user select new card
      if (existingCards?.length > 0) {
        // scrollToRef();
        setAddNewSelected(false);
      }
      if (existingCards?.length > 0 && !selectedCard) {
        // if there are list of cards available and it will auto select card 1 and no user card is selected previously
        setSlectedPaymentCardId(parseInt(existingCards[0]?.paymentInfoId));
        const item = existingCards[0];
        const newCardObj = {
          paymentMethodId: item.stripePaymentMethodId,
          cardType: item.cardType,
          last4: item.cardNumber,
          isCardSave: false,
          cardName: '',
          newCard: false,
          temporaryCard: false,
        };
        setCurrentCard(newCardObj);
      } else {
        setAddNewSelected(false);
      }
    }
  }, [show]);

  const CreateCardStripe = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const cardNumber = elements.getElement(CardNumberElement);
    const cardExpiry = elements.getElement(CardExpiryElement);
    const cardCvc = elements.getElement(CardCvcElement);
    const isValidCard = cardNumber._empty || cardNumber._invalid;
    const isValidExpiry = cardExpiry._empty || cardExpiry._invalid;
    const isValidCvc = cardCvc._empty || cardCvc._invalid;

    if (isValidCard || isValidExpiry || isValidCvc || cardName == '') {
      setStripeError(other => ({
        other,
        cardName: cardName == '' ? ALL_TEXT.ERROR_MESSAGES.CARD_NAME : null,
        cardNumber: isValidCard && ALL_TEXT.ERROR_MESSAGES.CARD_NUMBER,
        cardExpiry: isValidExpiry && ALL_TEXT.ERROR_MESSAGES.CARD_EXPIRY,
        cardCvc: isValidCvc && ALL_TEXT.ERROR_MESSAGES.CARD_CVV,
      }));
      return;
    }
    setBtnLoader(true);
    const {error, token} = await stripe.createToken(
      elements.getElement(CardNumberElement),
    );
    if (error) {
      openErrorModal(error.message);
      setBtnLoader(false);
    } else {
      const {paymentMethod, error} = await stripe.createPaymentMethod({
        type: 'card',
        card: {
          token: token.id,
        },
      });
      const {
        id,
        card: {last4, brand},
      } = paymentMethod;
      if (paymentMethod) {
        setBtnLoader(false);
        const newCardObj = {
          paymentMethodId: id,
          cardType: brand,
          last4,
          isCardSave,
          cardName,
          newCard: true,
          temporaryCard: true,
        };
        dispatch(setSelectedCard(newCardObj));
        handleClose();
      } else if (error) {
        openErrorModal(error.message);
      }
    }
  };

  const onCardSelectHandler = item => {
    setSlectedPaymentCardId(parseInt(item.paymentInfoId));
    setAddNewSelected(false);
    const newCardObj = {
      paymentMethodId: item.stripePaymentMethodId,
      cardType: item.cardType,
      last4: item.cardNumber,
      isCardSave: false,
      cardName: '',
      newCard: false,
      temporaryCard: false,
      paymentInfoId: item?.paymentInfoId,
    };
    setCurrentCard(newCardObj);
  };
  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Body>
        <div className="add-payment-popup-wrapper">
          <div className="top-add-payment-container">
            <span>{ALL_TEXT.SELECT_PAYMENT.SELECT_PAYMENT_METHOD} </span>
            <GrClose
              className="add-payment-close-btn"
              onClick={handleClose}
              size={18}
              color={colors.primary}
            />
          </div>
          <div className={`add-payment-main-content`}>
            <GenericButton
              id="add-payment"
              onPress={() => {
                setAddNewSelected(!addNewSelected);
                setSlectedPaymentCardId(-1);
              }}
              buttonText={ALL_TEXT.SELECT_PAYMENT.ADD_CARD}
              disable={existingCards?.length >= 5} // for the Time Being its commented
            />

            {existingCards?.length >= 5 && (
              <div className="card-limit-exceed">
                <span>{ALL_TEXT.SUBSCRIPTION.NOTE}</span>
                <span> {ALL_TEXT.SELECT_PAYMENT.BILLING_CARD_LIMIT} </span>
              </div>
            )}
            {/* we have to replace no image card here // MZ */}
            {(existingCards?.length === 0 || !existingCards) &&
              !addNewSelected && (
                <div className="no-card-available">
                  <div className="card-image">
                    <span class="icon-creditcard"></span>
                  </div>
                  <p>{ALL_TEXT.SELECT_PAYMENT.ADD_CARD_MESSAGE}</p>
                </div>
              )}
            {addNewSelected && (
              <div className="add-payment-card-option">
                <div className="payment-title">
                  {ALL_TEXT.SELECT_PAYMENT.ADD_NEW_CARD}
                </div>
                <>
                  <div className="holder-name-container">
                    <label>{ALL_TEXT.SELECT_PAYMENT.NAME} </label>
                    <input
                      placeholder={ALL_TEXT.PLACE_HOLDERS.ENTER_HOLDER_NAME}
                      type={'text'}
                      className="card-holder-name"
                      value={cardName}
                      onChange={e => {
                        const {value} = e.target;
                        const re = /^[A-Za-z ]+$/;
                        if (value === '' || re.test(value)) {
                          setCardName(value);
                          setStripeError(other => ({
                            other,
                            cardName: null,
                          }));
                        }
                      }}
                    />
                    {stripeError?.cardName != '' && (
                      <span className="error-field">
                        {stripeError?.cardName}
                      </span>
                    )}
                  </div>
                  <label>{ALL_TEXT.SELECT_PAYMENT.CARD_NUMBER} </label>
                  <div className="card-number">
                    <CardNumberElement
                      onChange={i => handleCardFieldChange(i, 'cardNumber')}
                      required
                      options={{
                        showIcon: true,
                      }}
                    />
                  </div>
                  {stripeError?.cardNumber != '' && (
                    <span className="error-field">
                      {stripeError?.cardNumber}
                    </span>
                  )}
                </>
                <div className="expiry-cvc-container">
                  <div className="expiry-container">
                    <label>{ALL_TEXT.SELECT_PAYMENT.EXPIRY} </label>
                    <div className="card-expiry">
                      <CardExpiryElement
                        onChange={i => handleCardFieldChange(i, 'cardExpiry')}
                        required
                      />
                    </div>
                    {stripeError?.cardExpiry != '' && (
                      <span className="error-field">
                        {stripeError?.cardExpiry}
                      </span>
                    )}
                  </div>
                  <div className="cvv-container">
                    <label>{ALL_TEXT.SELECT_PAYMENT.CVV} </label>
                    <div className="card-cvc">
                      <CardCvcElement
                        onChange={i => handleCardFieldChange(i, 'cardCvc')}
                        required
                      />
                    </div>
                    {stripeError?.cardCvc != '' && (
                      <span className="error-field">
                        {stripeError?.cardCvc}
                      </span>
                    )}
                  </div>
                </div>
                {onSubscriptionScreen && <div style={{height: 10}} />}
                {!onSubscriptionScreen && (
                  <div class="form-check save-card ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                      checked={isCardSave}
                      onChange={d => {
                        setIsCardSave(d.target.checked);
                      }}
                    />
                    <label class="form-check-label" for="flexCheckChecked">
                      {ALL_TEXT.SELECT_PAYMENT.SAVE_CARD}
                    </label>
                  </div>
                )}
                <div className="save-btn-container">
                  <GenericButton
                    id="save-button"
                    buttonText={ALL_TEXT.BUTTON_TEXT.SAVE_AND_CONTINUE}
                    onPress={CreateCardStripe}
                    loading={btnLoader}
                  />
                </div>
              </div>
            )}
          </div>
          {existingCards?.length > 0 && (
            <div className="mt-3">
              <span className="cards-count">{`Available Cards (${existingCards?.length})`}</span>
              <div className="existing-card-container">
                {existingCards?.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      onCardSelectHandler(item);
                    }}
                    className="existing-card-info">
                    <input
                      ref={
                        slectedPaymentCardId == parseInt(item.paymentInfoId)
                          ? myRef
                          : null
                      }
                      class="form-check-input"
                      type="radio"
                      name="radio-group-1"
                      value={`name-${index}`}
                      id="flexRadioDefault2"
                      key={index}
                      onClick={() => {
                        onCardSelectHandler(item);
                      }}
                      checked={
                        slectedPaymentCardId == parseInt(item.paymentInfoId)
                      }
                      onChange={() => {
                        onCardSelectHandler(item);
                      }}
                    />
                    <i
                      class={`fa-brands fa-cc-${item.cardType}`}
                      style={{color: colors.primary}}></i>
                    <span>{`**** **** **** ${item?.cardNumber}`}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <GenericButton
          id="add-payment-modal"
          disable={slectedPaymentCardId == -1}
          // loading={btnLoading}
          onPress={() => {
            handleClose();
            dispatch(setSelectedCard(currentCard));
          }}
          buttonText={
            onSubscriptionScreen
              ? ALL_TEXT.BUTTON_TEXT.UPDATE_CARD
              : ALL_TEXT.BUTTON_TEXT.CONTINUE
          }
        />
      </Modal.Footer>
    </Modal>
  );
}
