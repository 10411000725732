import {createSlice} from '@reduxjs/toolkit';

export const ClaimsSlice = createSlice({
  name: 'claims',
  initialState: {
    isDownloadClaimsPopup: false,
    selectedClaim:{},
  },
  reducers: {
    setDownloadClaimsPopup(state, {payload}) {
      state.isDownloadClaimsPopup = payload;
    },
    setSelectedClaim(state, {payload}) {
      state.selectedClaim = payload;
    },
  },
});

export const {setDownloadClaimsPopup,setSelectedClaim} = ClaimsSlice.actions;
