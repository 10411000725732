import React from 'react';
import '../../common/themeColor.scss';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import './styles.scss';
import {colors} from '../../common';

const formatXAxisDate = date => {
  const options = {month: 'short', day: 'numeric'};
  const formattedDate = new Date(date).toLocaleDateString('en-US', options);
  return formattedDate;
};

export const BarChart = ({data, title, key1, key2}) => {
  const showDollarSign = title === 'Revenue';
  return (
    <>
      <div className="chart-graph-revenue">
        <div className="chart-title">
          <h5>{title}</h5>
        </div>
        {data && data?.length < 0 ? (
          <div className="not-available-user-chart">
            <span>No data available.</span>
          </div>
        ) : (
          <ResponsiveContainer aspect={3}>
            <LineChart
              data={data}
              margin={{top: 0, right: 50, bottom: 5, left: 0}}>
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey={key1}
                interval="preserveStartEnd"
                tickFormatter={formatXAxisDate}
                tick={{fontWeight: 'bold', fill: 'black'}}
              />
              <YAxis
                dataKey={key2}
                tick={{fontWeight: 'bold', fill: 'black'}}
              />
              <Tooltip
                formatter={showDollarSign ? value => `$${value}` : undefined}
              />

              <Line
                type="monotone"
                dataKey={key2}
                displayName={key2}
                stroke={colors.primary}
                fill={colors.black2}
                activeDot={{r: 8}}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    </>
  );
};
