import React from 'react';
import './style.scss';

export function CommissionRateTable({dataList}) {
  return (
    dataList?.length > 0 && (
      <div className="table-container-commission">
        <div className="table-header-commission">
          <span>Commisssion Rate Table </span>
        </div>
        <div className="table-body-commission">
          {dataList?.map((i, id) => (
            <div key={id} className="table-i">
              <div className="table-item">
                <div className="item-text">{i?.description} </div>
              </div>
              <div className="rate-container">
                <div className="item-rate">{`${i.isFixedRate ? '$' : ''}${
                  i?.rate
                }${!i.isFixedRate ? '%' : ''}`}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  );
}
