import React from 'react';
import {DateRangePicker} from 'rsuite';
import subDays from 'date-fns/subDays';
import './styles.scss';

const predefinedBottomRanges = [
  {
    label: 'Last 24 Hours',
    value: [new Date(), new Date()],
  },
  {
    label: 'Last 7 days',
    value: [subDays(new Date(), 6), new Date()],
  },
  {
    label: 'Last 30 days',
    value: [subDays(new Date(), 29), new Date()],
  },
];

const styles = {display: 'block', marginBottom: 10};
const isDateDisabled = date => {
  // Get the current date
  const currentDate = new Date();
  // Compare the selected date to the current date
  return date > currentDate;
};

export const Calendar = ({
  initialValue,
  onChangeDate,
  filterdate,
  customClassName,
  testClick,
}) => {
  return (
    <>
      <div className="dates">
        <DateRangePicker
          placeholder={filterdate ? 'Filter by date' : 'Select Date '}
          size="lg"
          style={styles}
          character={' / '}
          ranges={predefinedBottomRanges}
          format={'yyyy-MM-dd'}
          value={initialValue}
          onChange={onChangeDate}
          cleanable={false}
          editable={false}
          className={customClassName}
          test={testClick}
          disabledDate={isDateDisabled} // Disable future dates
        />
      </div>
    </>
  );
};
