import React from 'react';
import {ALL_TEXT, ENUMS} from './../../common/constants';
import {images} from '../../common';
import {BsFillRecordFill} from 'react-icons/bs';
import {AiFillCheckCircle} from 'react-icons/ai';
import {IoMdCloseCircle} from 'react-icons/io';

const fileArrayAppendHandler = (key, file, formData) => {
  if (file.length === 0 || !file) {
    formData.append(key, null);
  } else {
    file.map(item => {
      formData.append(key, item);
    });
  }
  return formData;
};

export const generateFormData = (values, files) => {
  let formData = new FormData();
  for (const [key, value] of Object.entries(values)) {
    formData.append(key, value);
  }
  if (files) {
    for (const [key, value] of Object.entries(files)) {
      fileArrayAppendHandler(key, value, formData);
    }
  }
  return formData;
};

export const getValuesFromEnumsObj = (EnumsObj, value) => {
  let status;
  const matching = Object.values(EnumsObj).find(
    item => item.LABEL.toLowerCase() === value.toLowerCase(),
  );
  if (matching) {
    status = matching.ID || matching.VALUE;
  }
  return status;
};

export const getStringFromEnumsObj = (EnumsObj, value) => {
  let status;
  const matching = Object.values(EnumsObj).find(item => item.VALUE == value);
  if (matching) {
    status = matching.LABEL || matching.ID;
  }
  return status;
};

export const NumberToText = number => {
  let text;
  switch (number) {
    case ENUMS.SERVICE_TYPE.VEHICLE:
      text = ALL_TEXT.SERVICE_TYPE.VEHICLE;
      break;
    case ENUMS.SERVICE_TYPE.HOME:
      text = ALL_TEXT.SERVICE_TYPE.HOME;
      break;
    case ENUMS.SERVICE_TYPE.DEVICE:
      text = ALL_TEXT.SERVICE_TYPE.DEVICE;
      break;
    case ENUMS.SERVICE_TYPE.COMMERCIAL:
      text = ALL_TEXT.SERVICE_TYPE.COMMERCIAL;
      break;
    case ENUMS.SERVICE_TYPE.DISASTER:
      text = ALL_TEXT.SERVICE_TYPE.DISASTER;
      break;
    default:
      text = ALL_TEXT.SERVICE_TYPE.ALL;
  }
  return text;
};

export const planSelectorIcon = id => {
  switch (id) {
    case ENUMS.SERVICE_TYPE.VEHICLE:
      return (
        <span class="icon-vehuicle popup-menu-icon-secondry red-popup-icon"></span>
      );
    case ENUMS.SERVICE_TYPE.HOME:
      return (
        <span class="icon-home popup-menu-icon-secondry red-popup-icon"></span>
      );
    case ENUMS.SERVICE_TYPE.COMMERCIAL:
      return (
        <span class="icon-commercial popup-menu-icon-secondry red-popup-icon"></span>
      );
    case ENUMS.SERVICE_TYPE.DEVICE:
      return (
        <span class="icon-devices popup-menu-icon-secondry red-popup-icon"></span>
      );
    case ENUMS.COVERAGE_TYPES.DISASTER:
      return (
        <span class="icon-DisasterIcon popup-menu-icon-secondry red-popup-icon"></span>
      );
    default:
      return (
        <span class="icon-vehuicle popup-menu-icon-secondry red-popup-icon"></span>
      );
  }
};

export const riskLevelIcon = text => {
  if (text.toLowerCase() === ALL_TEXT.RISK_CATEGORY.HIGH.toLowerCase()) {
    return (
      <span className="high-risk">
        <BsFillRecordFill size={20} />
      </span>
    );
  } else if (text.toLowerCase() === ALL_TEXT.RISK_CATEGORY.LOW.toLowerCase()) {
    return (
      <span className="low-risk">
        <BsFillRecordFill size={20} />
      </span>
    );
  } else if (
    text.toLowerCase() === ALL_TEXT.RISK_CATEGORY.MEDIUM.toLowerCase()
  ) {
    return (
      <span className="medium-risk">
        <BsFillRecordFill size={20} />
      </span>
    );
  } else {
    return (
      <span className="high-risk">
        <BsFillRecordFill size={20} />
      </span>
    );
  }
};

export const serviceTypeIcon = id => {
  switch (id) {
    case ENUMS.COVERAGE_TYPES.VEHICLE:
      return <img src={images.vehicleV} alt={ALL_TEXT.ICON} />;
    case ENUMS.COVERAGE_TYPES.HOME:
      return <img src={images.homeV} alt={ALL_TEXT.ICON} />;
    case ENUMS.COVERAGE_TYPES.COMMERCIALS:
      return <img src={images.commercialV} alt={ALL_TEXT.ICON} />;
    case ENUMS.COVERAGE_TYPES.DEVICE:
      return <img src={images.devicesV} alt={ALL_TEXT.ICON} />;
    case ENUMS.COVERAGE_TYPES.DISASTER:
      return <img src={images.disasterV} alt={ALL_TEXT.ICON} />;
  }
};

export const planCategory = id => {
  if (
    id.toLowerCase() === ALL_TEXT.PLAN_CATEGORY.month.toLowerCase() ||
    id.toLowerCase() === ALL_TEXT.PLAN_CATEGORY.monthly.toLowerCase()
  ) {
    return 'rounded-pill monthly-background';
  } else if (
    id.toLowerCase() === ALL_TEXT.PLAN_CATEGORY.biannual.toLowerCase() ||
    id.toLowerCase() === ALL_TEXT.PLAN_CATEGORY.biannually.toLowerCase()
  ) {
    return 'rounded-pill biAnnual-background';
  } else if (
    id.toLowerCase() === ALL_TEXT.PLAN_CATEGORY.annual.toLowerCase() ||
    id.toLowerCase() === ALL_TEXT.PLAN_CATEGORY.annually.toLowerCase()
  ) {
    return 'rounded-pill annual-background';
  } else if (
    id.toLowerCase() === ALL_TEXT.PLAN_CATEGORY.rejected.toLowerCase()
  ) {
    return 'rounded-pill rejected-background';
  } else if (
    id.toLowerCase() == ALL_TEXT.PLAN_CATEGORY.cancelled.toLowerCase()
  ) {
    return 'rounded-pill cancelled-background';
  } else if (
    id.toLowerCase() === ALL_TEXT.PLAN_CATEGORY.pending.toLowerCase()
  ) {
    return 'rounded-pill pending-background';
  } else {
    return 'rounded-pill settled-background';
  }
};
export const getInvoiceStatus = status => {
  if (status === 'False') {
    return (
      <span class="badge rounded-pill bg-warning text-light">
        {ENUMS.INVOICES.UPCOMING.LABEL}
      </span>
    );
  } else {
    return (
      <span class="badge rounded-pill bg-success">
        {ENUMS.INVOICES.PAID.LABEL}
      </span>
    );
  }
};

export const getInvoicesStatus = text => {
  if (text === ENUMS.INVOICES.UPCOMING.LABEL) {
    return 'badge rounded-pill bg-warning text-light';
  } else if (text === ENUMS.INVOICES.PAID.LABEL) {
    return 'badge rounded-pill bg-success text-light';
  } else if (text === ENUMS.INVOICES.OPENED.LABEL) {
    return 'badge rounded-pill bg-secondary text-light';
  } else if (text === ENUMS.INVOICES.FAILED.LABEL) {
    return 'badge rounded-pill bg-danger text-light';
  }
};
export const getClaimStatus = text => {
  if (text === ENUMS.CLAIMS.STAND.PENDING.LABEL) {
    return 'badge rounded-pill bg-warning text-light';
  } else if (text === ENUMS.CLAIMS.STAND.SETTLED.LABEL) {
    return 'badge rounded-pill bg-success text-light';
  } else if (text === ENUMS.CLAIMS.STAND.CANCELLED.LABEL) {
    return 'badge rounded-pill bg-secondary text-light';
  } else if (text === ENUMS.CLAIMS.STAND.REJECTED.LABEL) {
    return 'badge rounded-pill bg-danger text-light';
  }
};

export const getEmailLogStatusIcon = status => {
  if (status) {
    return <AiFillCheckCircle size={20} color="#1FB141" />;
  } else {
    return <IoMdCloseCircle size={20} color="#DD3333" />;
  }
};
