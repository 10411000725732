export const images = {
  noDukLogo: 'images/noduk-logo.svg',
  errorIcon: 'images/error-icon.png',
  userIcon: 'images/user-dp.jpeg',
  noDukAmdinBackground: 'images/nodukAdminBG.svg',
  userLogin: 'images/userLogin.svg',
  forgotPassword: 'images/forgotPassword.svg',
  resetPassword: 'images/resetPassword.svg',
  eyeOpen: 'images/eye-open.svg',
  eyeClose: 'images/eye-close.svg',
  success: 'images/successModal.svg',
  error: 'images/errorModal.svg',
  alert: 'images/alertModal.svg',
  delete: 'images/deleteSymbol.svg',
  vehicleV: 'images/vehicleIconV.svg',
  homeV: 'images/homeVIcon.svg',
  devicesV: 'images/devicesVIcon.svg',
  commercialV: 'images/commercialVIcon.svg',
  disasterV: 'images/disasterIcon.svg',
  cartSmall: 'images/cartIcon.svg',
  noImage: 'images/no-image.jpeg',
  attachment: 'images/attachments.svg',
  smallCircle: 'images/circle.svg',
  profile: 'images/frame.svg',
  uploadIcon: 'images/uploadIcon.svg',
  dummyUser: 'images/dummyuser.svg',
  camera: 'images/camera.svg',
  searchIcon: 'images/SearchIcon.svg',
  mobileToken: 'images/mobileToken.svg',
  calimNotification: 'images/calimNotification.svg',
  accountSetupLink: 'images/accountSetupLink.svg',
  activeAccountLink: 'images/active-account-link.svg',
  claimIcon: 'images/claimIcon.svg',
  roundAutoIcon: 'images/roundAuto.svg',
  roundCommercialIcon: 'images/roundCommercial.svg',
  roundDeviceIcon: 'images/roundDevice.svg',
  roundHomeIcon: 'images/roundHome.svg',
  roundDisasterIcon: 'images/roundDisasterIcon.svg',
  upWordsIcon: 'images/upWordsIcon.svg',
  downWordsIcon: 'images/downWordsIcon.svg',
  openClaims: 'images/openClaims.svg',
  rejectedClaims: 'images/rejectedClaims.svg',
  settledClaims: 'images/settledClaims.svg',
  roundSubscriptionIcon: 'images/roundSubscriptionIcon.svg',
  deductiblesIcon: 'images/deductibles.svg',
  customersIcon: 'images/customers.svg',
  subscriptionsIcon: 'images/subscriptions.svg',
  registerationIcon: 'images/registeration.svg',
  zeroPercentIcon: 'images/zeroPercent.svg',
  uploadDocumentsIcon: 'images/uploadDocuments.svg',
  homeBrochure: 'images/HomeBrochure.svg',
  autoBrochure: 'images/AutoBrochure.svg',
  deviceBrochure: 'images/DevicesBrochure.svg',
  commercailBrochure: 'images/CommericalBrochure.svg',
  F9_FORM: 'images/BLANK W9.pdf',
  newNodukLogo: 'images/newNodukLogo.png',
};
