import React from 'react'
import './styles.scss';
import { ALL_TEXT, images } from '../../common';
import { AiFillCheckCircle } from 'react-icons/ai';
import '../../common/themeColor.scss';
import { MdCancel} from 'react-icons/md';
import '../../common/themeColor.scss'
import Button from './button';
import { TbArrowNarrowDown } from 'react-icons/tb';

function UploadAndDownload({ name, icon, text, uploaditem, props, className,onChangeImage,removeFile ,filesData,W9Form}) {
   
    return (
        <>
            <div className='bar-container'>
                <div className='bar-item'>
                    <div className='textdocuments'>
                        <div className='documenticon'>
                            <img src={images.uploadDocumentsIcon} alt='icon' />
                        </div>
                        <div className='bartext'>
                            <h6>{name}</h6>
                        </div>
                    </div>
                </div>
                <div className='bar-item right-content'>
                    <div className='file-input' style={{ position: 'relative' }}>
                        {W9Form === 3 ?
                            <Button
                                style={{ position: 'absolute' }}
                                buttontext={text}
                                icon={icon}
                                className={className}
                                onClick={onChangeImage}
                            /> : W9Form ===1 ?
                            <button className='downloadcustombtn'>
                                <a href={images.F9_FORM}
                                    download="F9 Form"
                                    target="_blank"
                                    rel="noreferrer"
                                    className='atag'
                                     >{ALL_TEXT.DOWNLOAD}</a>
                                     <TbArrowNarrowDown style={{marginLeft:'8px'}}/>
                            </button> :W9Form ===2?
                            <button className='downloadcustombtn'>
                            <a href={images. deviceBrochure}
                                download="EFT FORM"
                                target="_blank"
                                rel="noreferrer"
                                className='atag'
                                 >{ALL_TEXT.DOWNLOAD}</a>
                                 <TbArrowNarrowDown style={{marginLeft:'8px'}}/>
                        </button>:{}
                        }
                        {uploaditem === true && (
                            <>
                                <input
                                    type="file"
                                    multiple
                                    onChange={onChangeImage}
                                    onClick={(e) => (e.target.value = null)}
                                    {...props}
                                    className='inputclass'
                                />
                            </>
                        )}
                    </div>
                </div>

                {uploaditem === true &&
                    filesData.map((file, index) => (
                        <div key={index} className='statusbar'>
                            <div className='statusbarconatiner1'>
                                <AiFillCheckCircle style={{ color: '#22b141', height: '20px', width: '20px' }} />
                                <span className='text' key={index}>{file.name}</span>
                            </div>
                            <div className='statusbarconatiner2'>
                                {/* <button className='btnclass'><MdOutlineManageSearch style={{ backgroundColor: 'black', width: '40px', height: '22px', borderRadius: '30px', color: 'white' }} /></button> */}
                                <button className='btnclass' onClick={() => removeFile(file.name)}><MdCancel style={{ color: '#dd3333', height: '15px', width: '15px' }} /></button>
                            </div>
                        </div>
                    ))
                }
            </div>
        </>
    )
}
export default UploadAndDownload;
