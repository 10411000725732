import React, {useState} from 'react';
import {ALL_TEXT, ENUMS, images} from '../../common';
import {GenericButton, GenericModal, InputField} from '../../components';
import {Formik} from 'formik';
import {forgotSchema} from '../../common/schemas';
import {requestForgotPassword} from '../../utils/rest-services';
// import {path} from '../../common/routesNames';
// import {useNavigate} from 'react-router-dom';
import './styles.scss';

const ForgotPassword = () => {
  // const navigate = useNavigate();
  const [btnLoading, setBnLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [apiResponse, setApiResponse] = useState({
    type: ENUMS.POPUP_ENUMS.SUCCESS,
    title: '',
    details: '',
  });

  const initialValues = {
    email: '',
  };

  const forgotPasswordHandler = async values => {
    setBnLoading(true);
    let response = await requestForgotPassword(values.email);
    setBnLoading(false);
    if (response && response.success) {
      setApiResponse({
        type: ENUMS.POPUP_ENUMS.SUCCESS,
        title: ALL_TEXT.GENERIC_MODAL.SUCCESS,
        details: response.description,
      });
      setShowModal(true);
    } else {
      setApiResponse({
        type: ENUMS.POPUP_ENUMS.ERROR,
        title: ALL_TEXT.GENERIC_MODAL.ERROR,
        details: response.description,
      });
      setShowModal(true);
    }
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnMount={true}
        onSubmit={(values, formikActions) => {
          forgotPasswordHandler(values, formikActions);
        }}
        validationSchema={forgotSchema}
        enableReinitialize>
        {({handleChange, handleBlur, handleSubmit, touched, errors}) => (
          <div
            className="forgot-password login-background "
            style={{
              backgroundImage: `url(${images.noDukAmdinBackground})`,
            }}>
            <div className="noduk-logo">
              <img
                src={images.newNodukLogo}
                alt={ALL_TEXT.ICON}
                style={{width: '85px'}}
              />
            </div>
            <div className="forgot-password-box">
              <div className="login-logo">
                <img src={images.forgotPassword} alt={ALL_TEXT.ICON} />
                <p>{ALL_TEXT.FORGOT_PASSWORD}</p>
              </div>
              <div className="login-input-field">
                <InputField
                  icon={<span class="icon-email"></span>}
                  name={'email'}
                  id={'email'}
                  placeholder={ALL_TEXT.ENTER_EMAIL}
                  error={touched.email && errors.email}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <div className="login-button">
                  <GenericButton
                    loading={btnLoading}
                    buttonText={ALL_TEXT.SEND}
                    onPress={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
      <GenericModal
        show={showModal}
        buttonText={ALL_TEXT.BUTTON_TEXT.OK}
        type={apiResponse.type}
        body={apiResponse.details}
        handleClose={() => {
          setShowModal(false);
          // navigate({pathname: path.login});
        }}
      />
    </>
  );
};

export default ForgotPassword;
