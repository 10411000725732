import React, {useEffect} from 'react';
import './style.scss';
import {NotificationItem} from '../../components/notification-item';
import {Loader} from '../../components/loader';
import {ALL_TEXT} from '../../common/constants';
import {NodukAdmnApi} from '../../redux/slice/nodukAdmin.ts';
//import ApplicationContext from "../../utils/context-api/context";

export default function AdminNotification() {
  const {useLazyGetApiNotificationGetEmployeeNotificationsQuery} = NodukAdmnApi;
  const [adminNotificationsAPI, adminNotificationsResponse] =
    useLazyGetApiNotificationGetEmployeeNotificationsQuery();
  //const { setNotificationCount } = useContext(ApplicationContext);

  useEffect(() => {
    fetchEmpNotifications();
  }, []);

  const fetchEmpNotifications = async () => {
    await adminNotificationsAPI();
  };
  const notificationList = adminNotificationsResponse?.data?.content || [];

  return (
    <div className="main-notification-container">
      <div className="container-title">
        <span>{ALL_TEXT.NOTIFICATIONS_SIDE.NOTIFICATION}</span>
      </div>
      <div className="container-box">
        {adminNotificationsResponse.isLoading ? (
          <div className="no-subscription-container">
            <Loader size={40} />
          </div>
        ) : notificationList?.length > 0 ? (
          notificationList?.map((i, index) => (
            <NotificationItem key={index} notify={i} />
          ))
        ) : (
          !adminNotificationsResponse.isLoading && (
            <div className="no-notification-container">
              <span className="no-notification-container">
                {ALL_TEXT.NOTIFICATIONS_SIDE.NO_NOTIFICATION}
              </span>
            </div>
          )
        )}
      </div>
    </div>
  );
}
