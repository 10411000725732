import React, {useEffect} from 'react';
import './styles.scss';
import {Modal} from 'react-bootstrap';
import {GrClose} from 'react-icons/gr';
import {ALL_TEXT, ENUMS, colors, images} from '../../common';
import {NodukAdmnApi} from '../../redux/slice/nodukAdmin.ts';
import {Loader} from '../loader';

export const SusbcriptionsItem = ({
  name,
  deductible,
  cost,
  serviceTypeId,
  make,
  model,
  address,
}) => {
  const planSelectorIcon = id => {
    switch (id) {
      case ENUMS.SERVICE_TYPE.VEHICLE:
        return images.vehicleV;
      case ENUMS.SERVICE_TYPE.HOME:
        return images.homeV;
      case ENUMS.SERVICE_TYPE.DEVICE:
        return images.devicesV;
      case ENUMS.SERVICE_TYPE.COMMERCIAL:
        return images.commercialV;
      case ENUMS.COVERAGE_TYPES.DISASTER:
        return images.disasterV;
    }
  };
  return (
    <div className="invoice-item-view">
      <div className="plan-icon">
        <img src={planSelectorIcon(serviceTypeId)} alt="" />
      </div>
      <div className="invoice-detail-card">
        <div className="primary-card-container-invoice ">
          <span>{name}</span>
          {make && <span>{`Make: ${make}`} </span>}
          {model && <span>{`Model: ${model}`} </span>}
          {address && <span>{`Address: ${address}`} </span>}
        </div>
        <div className="secondary-card-container-invoice">
          <span>{`${
            ALL_TEXT.ERROR_PLACEHOLDERS.DEDUCTIBLES.charAt(0).toUpperCase() +
            ALL_TEXT.ERROR_PLACEHOLDERS.DEDUCTIBLES.slice(1)
          }: ${deductible}`}</span>
          <div>
            <span> {`${ALL_TEXT.COST}: ${cost}`} </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export function SubscriptionDetailPopup({
  show,
  handelClose,
  refundId,
  signupId,
}) {
  const {useLazyGetApiAdminGetRefundDetailQuery} = NodukAdmnApi;

  const [getRefundDetailsAPI, getRefundDetailsResponse] =
    useLazyGetApiAdminGetRefundDetailQuery();

  const fetchSubscriptionDetails = async (refundId, signupId) => {
    await getRefundDetailsAPI({
      refundId: parseInt(refundId),
      signupId: signupId,
    });
  };

  let content = getRefundDetailsResponse?.data?.content?.refund || {};
  let refundItems = getRefundDetailsResponse?.data?.content?.items || {};
  useEffect(() => {
    fetchSubscriptionDetails(refundId, signupId);
  }, []);

  return (
    <Modal centered show={show} onHide={handelClose} size="md">
      <Modal.Body className="modal-body-subscription-detail">
        {getRefundDetailsResponse.isFetching ? (
          <Loader />
        ) : (
          <>
            <div className="claim-detail-container">
              <span>{ALL_TEXT.SUBSCRIPTION_DETAILS_.REFUND_DETAILS}</span>
              <GrClose
                className="add-payment-close-btn"
                onClick={handelClose}
                size={15}
                color={colors.primary}
              />
            </div>
            <div className="refund-details-container">
              <div className="refund-details-content">
                <span>{ALL_TEXT.SUBSCRIPTION_DETAILS_.REFUND_ID}</span>
                <span>{content?.refundId}</span>
              </div>
              <div className="refund-details-content">
                <span>{ALL_TEXT.SUBSCRIPTION_DETAILS_.REFUND_DATE}</span>
                <span>{content?.refundDate}</span>
              </div>
              <div className="refund-details-content">
                <span>{ALL_TEXT.SUBSCRIPTION_DETAILS_.REFUND_TYPE}</span>
                <span>{content?.refundType}</span>
              </div>
              <div className="refund-details-content">
                <span>{ALL_TEXT.SUBSCRIPTION_DETAILS_.AMOUNT}</span>
                <span className="amount-style">{content?.refundAmount}</span>
              </div>
            </div>
            <div className="refunded-cart-item-container">
              <span className="refunded-cart-title">
                {ALL_TEXT.SUBSCRIPTION_DETAILS_.REFUND_CART_ITEMS}
              </span>
              <div className="sub-detail-item">
                {refundItems &&
                  refundItems.length > 0 &&
                  refundItems?.map((item, index) => {
                    const {
                      providerName,
                      make,
                      model,
                      serviceTypeId,
                      address,
                      cost,
                      deductibleAmount,
                      brand,
                    } = item || {};
                    return (
                      <SusbcriptionsItem
                        key={index}
                        name={providerName}
                        make={make}
                        model={model}
                        brand={brand}
                        serviceTypeId={serviceTypeId}
                        address={address}
                        cost={`$${parseFloat(cost).toFixed(2)}`}
                        deductible={`$${parseFloat(deductibleAmount).toFixed(
                          2,
                        )}`}
                      />
                    );
                  })}
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
