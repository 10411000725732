import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import './styles.scss';
import {images} from '../../common/images';
import {ALL_TEXT} from '../../common/constants';
import {GenericButton} from '../generic-button';

export const ConfirmCancellationPopup = ({
  show,
  handleClose,
  handelSubmit,
  reason,
  setReason,
  loader,
  cancelMessage,
  noteMessage,
  subscription,
}) => {
  const [isPolicyError, setIsPolicyError] = useState(false);
  const handleChange = event => {
    setReason(event.target.value);
    setIsPolicyError(false);
  };

  return (
    <>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="modal-body-generic">
            <img src={images.error} alt="Icon" />
            <span className="add-claim-cancel-policy">
              {subscription
                ? ALL_TEXT.CANCEL_SUBSCRIPTION
                : ALL_TEXT.CANCEL_POLICY}
            </span>
            <span className="add-claim-cancel-message">
              {cancelMessage}
              <p>{noteMessage}</p>
            </span>
            <div className="texarea-box">
              <textarea
                value={reason}
                onChange={handleChange}
                placeholder={ALL_TEXT.TYPE_REASOM}
              />
              {isPolicyError && (
                <div className="policy-error">
                  <span>{ALL_TEXT.POLICY_REASON_CANCEL}</span>
                </div>
              )}
            </div>
            <div className="add-claim-modal-footerContainer">
              <button
                className="cancel-button"
                onClick={() => {
                  setReason('');
                  handleClose();
                }}>
                {ALL_TEXT.CANCEL}
              </button>
              <GenericButton
                id="add-claim-modal-reason"
                loading={loader}
                buttonText={ALL_TEXT.OK}
                onPress={() => {
                  if (reason.trim() != '') {
                    handelSubmit(reason);
                  } else if (reason.trim() == '') {
                    setIsPolicyError(true);
                  }
                }}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
