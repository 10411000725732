import {initializeApp} from 'firebase/app';
import {getMessaging, onMessage} from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyCbFLKp6jsSCNWh6E1hjJmBzvSxZn9_yZY',
  authDomain: 'noduk-dd915.firebaseapp.com',
  projectId: 'noduk-dd915',
  storageBucket: 'noduk-dd915.appspot.com',
  messagingSenderId: '667821378942',
  appId: '1:667821378942:web:93d44b7c6e1ca19022772f',
  measurementId: 'G-PL6DH15GTV',
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const onMessageListener = () =>
  new Promise(resolve => {
    onMessage(messaging, payload => {
      resolve(payload);
    });
  });
