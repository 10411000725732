import React, {useState, useEffect} from 'react';
import {ALL_TEXT, ENUMS} from '../../common';
import {BarChartDashboard, Calendar, Loader} from '../../components';
import './style.scss';
import {
  usePostApiAdminGetDashboardOverviewMutation,
  usePostApiAdminGetDashboardRevenuestatsMutation,
  usePostApiAdminGetSubsClaimsStatsMutation,
} from '../../redux/slice/nodukAdmin.ts';
import {useSelector} from 'react-redux';
import subDays from 'date-fns/subDays';
import {DashboardCards} from './dashboard-cards';
import {UserCards} from './user-cards';
import {RevenueSection} from './revenue-card';
import {DashboardSubscription} from './dashboard-subscription';
import {DashboardClaim} from './dashboard-claim';
import {useNavigate} from 'react-router';
import {path} from '../../common/routesNames';
import {subMonths} from 'date-fns';

function Dashboard() {
  const currentDate = new Date();
  const endDate = currentDate.toISOString();
  const startDate = subMonths(currentDate, 1).toISOString(); // Subtract 1 month to set it one month back

  const [dateOfdashboard, setDateOfdashboard] = useState({
    startDate,
    endDate,
  });

  // Day Calculator
  const startDateCalculted = new Date(dateOfdashboard.startDate);
  const endDateCalculted = new Date(dateOfdashboard.endDate);
  const timeDifference = endDateCalculted - startDateCalculted;
  const daysDifference = timeDifference / (1000 * 3600 * 24) + 1;

  // 1.API
  const [dashBoardOverviewAPI, dashBoardOverviewResponse] =
    usePostApiAdminGetDashboardOverviewMutation();
  const dashBoardOverview = dashBoardOverviewResponse?.data?.content;
  //  2.API
  const [RevenuestatsAPI, RevenuestatsResponse] =
    usePostApiAdminGetDashboardRevenuestatsMutation();
  const dashBoardRevenuestats = RevenuestatsResponse?.data?.content;
  // 3.API
  const [SubscriptionsClaimAPI, SubscriptionsClaimResponse] =
    usePostApiAdminGetSubsClaimsStatsMutation();
  const dataSubscriptionsClaim = SubscriptionsClaimResponse?.data?.content;

  useEffect(() => {
    getDashBoardOverview(dateOfdashboard.startDate, dateOfdashboard.endDate);
    getRevenueStats(dateOfdashboard.startDate, dateOfdashboard.endDate);
    getSubscriptionClaim(dateOfdashboard.startDate, dateOfdashboard.endDate);
  }, [dateOfdashboard]);

  const getDashBoardOverview = (startDate, endDate) => {
    const payLoad = {
      adminDashboard: {
        empId: 0,
        roleId: 0,
        startDate: startDate,
        endDate: endDate,
      },
    };
    dashBoardOverviewAPI(payLoad);
  };

  const getRevenueStats = (startDate, endDate) => {
    const payLoad = {
      adminDashboard: {
        empId: 0,
        roleId: 0,
        startDate: startDate,
        endDate: endDate,
      },
    };
    RevenuestatsAPI(payLoad);
  };
  const getSubscriptionClaim = (startDate, endDate) => {
    const payLoad = {
      adminDashboard: {
        empId: 0,
        roleId: 0,
        startDate: startDate,
        endDate: endDate,
      },
    };
    SubscriptionsClaimAPI(payLoad);
  };

  const [dateRange, setDateRange] = useState([
    subDays(new Date(), 30),
    new Date(),
  ]);
  const logginInUserId = useSelector(state => state?.User?.userLoggedInId);
  const navigate = useNavigate();

  return (
    <div className="dashboard-main-container">
      <div className="title-container-dashboard">
        <span>{ALL_TEXT.DASHBOARD}</span>
        <div className="dashbord-date-picker">
          <div>
            <Calendar
              filterdate={'select Date'}
              initialValue={dateRange}
              onChangeDate={d => {
                setDateRange(d);
                const [startDate, endDate] = d;
                const formattedStartDate = startDate.toISOString();
                const formattedEndDate = endDate.toISOString();
                setDateOfdashboard({
                  startDate: formattedStartDate,
                  endDate: formattedEndDate,
                });
              }}
            />
          </div>
        </div>
      </div>
      {dashBoardOverviewResponse?.isLoading ? (
        <div className="loader-container-dasboard">
          <Loader />
        </div>
      ) : (
        <div>
          <DashboardCards
            logginInUserId={logginInUserId}
            data={dashBoardOverview}
          />
          <div className="users-section-container">
            <UserCards
              title={'Customers'}
              data={dashBoardOverview?.customersData}
              tooltipMessage={ALL_TEXT.CUSTOMER_TOOLTIP}
              seeAll={() => {
                navigate({
                  pathname: path.users,
                  search: `?role=${ENUMS.USERS.ROLE.CUSTOMER.VALUE}`,
                });
              }}
              cardTitle={ALL_TEXT.USERS}
            />
            <UserCards
              title={'Registrations'}
              data={dashBoardOverview?.users}
              tooltipMessage={ALL_TEXT.REGISTER_TOOLTIP}
              seeAll={() => {
                navigate({
                  pathname: path.users,
                  search: `?role=${ENUMS.USERS.ROLE.REGISTERED.VALUE}`,
                });
              }}
              cardTitle={ALL_TEXT.REGISTRATION}
            />
            <div className="bar-chart-container">
              <BarChartDashboard
                graphData={dashBoardOverview?.registeredGraph}
                title={'Registered Count'}
              />
            </div>
          </div>
          <RevenueSection
            data={dashBoardRevenuestats}
            salesData={dataSubscriptionsClaim}
            daysDifference={daysDifference}
          />
          {logginInUserId !== ENUMS.STAFF.ROLE.SALES_REP.VALUE ? (
            <DashboardSubscription data={dataSubscriptionsClaim} />
          ) : (
            ''
          )}
          {logginInUserId !== ENUMS.STAFF.ROLE.AGENT.VALUE &&
          logginInUserId !== ENUMS.STAFF.ROLE.INDEPANDANT_AGENT.VALUE ? (
            <DashboardClaim data={dataSubscriptionsClaim} />
          ) : (
            ''
          )}
        </div>
      )}
    </div>
  );
}
export default Dashboard;
