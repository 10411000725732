import Notifications from '../pages/administration/notifications';
import {
  Login,
  ForgotPassword,
  ResetPassword,
  ErrorPage,
  Dashboard,
  MainLayout,
  Users,
  Invoices,
  Susbcriptions,
  SubscriptionDetail,
  Claims,
  ClaimDetails,
  ManageCarriers,
  AddPolicy,
  ReviewPay,
  SelectPaymentMethod,
  CustomerProfile,
  CustomerPolicy,
  AddNewClaimScreen,
  CustomerInvoices,
  CustomerClaims,
  CustomerSubscription,
  StaffMaintainance,
  MyProfile,
  ChangePassword,
  CouponCode,
  AddCouponCode,
  QRCode,
  TutorialGuide,
  AdminNotification,
  DisasterManagement,
  EmailLogs,
  NofificationLogs,
  AuditLogs,
  AuditLogsDetail,
  CommissionCalculator,
  UploadDocuments,
  // TestPage,
} from './../pages';
import {ENUMS} from './constants';

const {
  STAFF: {
    ROLE: {
      ADMIN,
      AGENCY,
      AGENT,
      SALES_REP,
      CUSTOMER_SUPPORT,
      DISTRIBUTION_CHANNEL,
      BUSINESS_AMB,
      INDEPANDANT_AGENT,
    },
  },
} = ENUMS;

export const path = {
  login: '/login',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  dashboard: '/dasboard',
  errorPage: '/error',
  users: '/users',
  invoices: '/invoices',
  susbcriptions: `/susbcriptions`,
  subscriptionsDetails: `/susbcriptions-detail`,
  claims: '/claims',
  claimDetails: '/claim-details',
  manageCarriers: '/manage-carriers',
  addPolicy: '/add-policy',
  selectPayment: '/select-payment',
  reviewAndPay: '/review-and-pay',
  generateQr: '/generate-qr',
  customerProfile: '/customer-profile',
  customerPolicies: '/customer-policies',
  addNewClaim: '/add-new-claims',
  customerInvoices: '/customer-invoices',
  customerClaims: '/customer-claims',
  customerSubscription: '/customer-subscription',
  staffMaintainance: '/staff-maintainance',
  notifications: '/notifications',
  myProfile: '/my-Profile',
  changePassword: '/change-password',
  couponCode: '/coupon-code',
  addCouponCode: `/add-coupon-code`,
  tutorialGuide: `/tutorial-guide`,
  adminNotifications: `/admin-notifications`,
  disasterManagement: `/disaster-management`,
  emailLogs: `/email-logs`,
  notificationLogs: `/notification-logs`,
  auditLogs: `/auditLogs`,
  auditLogsDetail: `/auditLogsDetail`,
  testPage: `/testPage`,
  commissionCalculator: `/commission-calculator`,
  uploadDocuments: `/upload-documents`,
};
export const routes = {
  login: 'login',
  forgotPassword: 'forgotPassword',
  resetPassword: 'ResetPassword',
  dashboard: 'dasboard',
  errorPage: 'error',
  users: 'users',
  invoices: 'invoices',
  susbcriptions: 'susbcriptions',
  subscriptionsDetails: 'susbcriptionsDetails',
  claims: 'claims',
  claimDetails: '/claimDetails',
  manageCarriers: 'manageCarriers',
  addPolicy: 'addPolicy',
  selectPayment: 'selectPayment',
  reviewAndPay: 'reviewAndPay',
  customerProfile: 'customerProfile',
  customerPolicies: 'customerPolicies',
  addNewClaim: 'AddNewClaims',
  customerInvoices: 'customerInvoices',
  customerClaims: 'customerclaims',
  customerSubscription: 'customerSubscription',
  staffMaintainance: 'staffMaintainance',
  notifications: 'notifications',
  myProfile: 'myProfile',
  changePassword: 'changePassword',
  couponCode: 'couponCode',
  addCouponCode: 'addCouponCode',
  generateQr: 'generateQr',
  tutorialGuide: 'tutorialGuide',
  adminNotifications: 'adminNotifications',
  disasterManagement: 'disasterManagement',
  emailLogs: 'emailLogs',
  notificationLogs: 'notificationLogs',
  auditLogs: 'auditLogs',
  auditLogsDetail: 'auditLogsDetail',
  testPage: 'testPage',
  commissionCalculator: `commissionCalculator`,
  uploadDocuments: 'upload-documents,',
};

export const PAGE_ENUMS_ID = {
  TEST: 'test_99',
  DASHBOARD: 'dash_0',
  USER: 'user_1',
  INVOICES: 'inv_2',
  CLAIM: 'clm_3',
  MANAGE_CARRIER: 'mc_4',
  STAFF_MAINTAINANCE: 'sm_5',
  NOTIFICATION: 'noti_6',
  COUPON_CODE: 'coupon_7',
  MY_PROFILE: 'profile_8',
  CHANGE_PASSWORD: 'changePass_9',
  QR_CODE: 'qc_10',
  ADMINISTRATION: 'adm_11',
  ACCOUNT: 'acc_12',
  ADMINNOTIFICATION: 'ad_noti_13',
  TUTORIAL_GUIDE: 'tg_14',
  DISASTER_MANAGEMENT: 'dm_15',
  LOGS: 'LG_16',
  EMAIL_LOGS: 'EL_17',
  NOTIFICATIN_LOGS: 'NL_18',
  AUDIT_LOGS: 'AL_19',
  AUDIT_LOGS_DETAILS: 'ALD_20',
  SUBSCRIPTION: 'SUB_21',
  SUBSCRIPTION_DETAIL: 'SUBD_22',
  COMMISSION_CALCULATOR: 'COMIS_23',
};

export const privatePageRoutes = {
  [routes.dashboard]: {
    name: routes.dashboard,
    path: path.dashboard,
    component: MainLayout,
    subPage: Dashboard,
    selectedId: PAGE_ENUMS_ID.DASHBOARD,
    roles: [
      ADMIN.VALUE,
      AGENCY.VALUE,
      AGENT.VALUE,
      INDEPANDANT_AGENT.VALUE,
      SALES_REP.VALUE,
      DISTRIBUTION_CHANNEL.VALUE,
      CUSTOMER_SUPPORT.VALUE,
    ],
  },
  [routes.users]: {
    name: routes.users,
    path: path.users,
    component: MainLayout,
    subPage: Users,
    selectedId: PAGE_ENUMS_ID.USER,
    roles: [
      ADMIN.VALUE,
      DISTRIBUTION_CHANNEL.VALUE,
      AGENT.VALUE,
      INDEPANDANT_AGENT.VALUE,
      AGENCY.VALUE,
      SALES_REP.VALUE,
      CUSTOMER_SUPPORT.VALUE,
    ],
  },
  [routes.invoices]: {
    name: routes.invoices,
    path: path.invoices,
    component: MainLayout,
    subPage: Invoices,
    selectedId: PAGE_ENUMS_ID.INVOICES,
    roles: [
      ADMIN.VALUE,
      DISTRIBUTION_CHANNEL.VALUE,
      AGENCY.VALUE,
      SALES_REP.VALUE,
      CUSTOMER_SUPPORT.VALUE,
    ],
  },
  [routes.claims]: {
    name: routes.claims,
    path: path.claims,
    component: MainLayout,
    subPage: Claims,
    selectedId: PAGE_ENUMS_ID.CLAIM,
    roles: [
      ADMIN.VALUE,
      DISTRIBUTION_CHANNEL.VALUE,
      AGENCY.VALUE,
      SALES_REP.VALUE,
      CUSTOMER_SUPPORT.VALUE,
    ],
  },
  [routes.claimDetails]: {
    name: routes.claimDetails,
    path: path.claimDetails,
    component: MainLayout,
    subPage: ClaimDetails,
    selectedId: PAGE_ENUMS_ID.CLAIM,
    roles: [
      ADMIN.VALUE,
      DISTRIBUTION_CHANNEL.VALUE,
      AGENCY.VALUE,
      SALES_REP.VALUE,
      CUSTOMER_SUPPORT.VALUE,
    ],
  },
  [routes.susbcriptions]: {
    name: routes.susbcriptions,
    path: path.susbcriptions,
    component: MainLayout,
    subPage: Susbcriptions,
    selectedId: PAGE_ENUMS_ID.SUBSCRIPTION,
    roles: [
      ADMIN.VALUE,
      DISTRIBUTION_CHANNEL.VALUE,
      AGENT.VALUE,
      INDEPANDANT_AGENT.VALUE,
      AGENCY.VALUE,
      CUSTOMER_SUPPORT.VALUE,
    ],
  },
  [routes.subscriptionsDetails]: {
    name: routes.subscriptionsDetails,
    path: path.subscriptionsDetails,
    component: MainLayout,
    subPage: SubscriptionDetail,
    selectedId: PAGE_ENUMS_ID.SUBSCRIPTION,
    roles: [
      ADMIN.VALUE,
      DISTRIBUTION_CHANNEL.VALUE,
      AGENT.VALUE,
      INDEPANDANT_AGENT.VALUE,
      AGENCY.VALUE,
      CUSTOMER_SUPPORT.VALUE,
    ],
  },
  [routes.manageCarriers]: {
    name: routes.manageCarriers,
    path: path.manageCarriers,
    component: MainLayout,
    subPage: ManageCarriers,
    selectedId: PAGE_ENUMS_ID.MANAGE_CARRIER,
    roles: [
      ADMIN.VALUE,
      DISTRIBUTION_CHANNEL.VALUE,
      AGENT.VALUE,
      INDEPANDANT_AGENT.VALUE,
      AGENCY.VALUE,
      CUSTOMER_SUPPORT.VALUE,
    ],
  },
  [routes.addPolicy]: {
    name: routes.addPolicy,
    path: path.addPolicy,
    component: MainLayout,
    subPage: AddPolicy,
    selectedId: PAGE_ENUMS_ID.USER,
    roles: [
      ADMIN.VALUE,
      DISTRIBUTION_CHANNEL.VALUE,
      AGENT.VALUE,
      INDEPANDANT_AGENT.VALUE,
      AGENCY.VALUE,
      SALES_REP.VALUE,
      CUSTOMER_SUPPORT.VALUE,
    ],
  },
  [routes.selectPayment]: {
    name: routes.selectPayment,
    path: path.selectPayment,
    component: MainLayout,
    subPage: SelectPaymentMethod,
    selectedId: PAGE_ENUMS_ID.USER,
    roles: [
      ADMIN.VALUE,
      DISTRIBUTION_CHANNEL.VALUE,
      AGENT.VALUE,
      INDEPANDANT_AGENT.VALUE,
      AGENCY.VALUE,
      SALES_REP.VALUE,
      CUSTOMER_SUPPORT.VALUE,
    ],
  },
  [routes.reviewAndPay]: {
    name: routes.reviewAndPay,
    path: path.reviewAndPay,
    component: MainLayout,
    subPage: ReviewPay,
    selectedId: PAGE_ENUMS_ID.USER,
    roles: [
      ADMIN.VALUE,
      DISTRIBUTION_CHANNEL.VALUE,
      AGENT.VALUE,
      INDEPANDANT_AGENT.VALUE,
      AGENCY.VALUE,
      SALES_REP.VALUE,
      CUSTOMER_SUPPORT.VALUE,
    ],
  },
  [routes.customerProfile]: {
    name: routes.customerProfile,
    path: path.customerProfile,
    component: MainLayout,
    subPage: CustomerProfile,
    selectedId: PAGE_ENUMS_ID.USER,
    roles: [
      ADMIN.VALUE,
      DISTRIBUTION_CHANNEL.VALUE,
      AGENT.VALUE,
      INDEPANDANT_AGENT.VALUE,
      AGENCY.VALUE,
      SALES_REP.VALUE,
      CUSTOMER_SUPPORT.VALUE,
    ],
  },
  [routes.customerPolicies]: {
    name: routes.customerPolicies,
    path: path.customerPolicies,
    component: MainLayout,
    subPage: CustomerPolicy,
    selectedId: PAGE_ENUMS_ID.USER,
    roles: [
      ADMIN.VALUE,
      DISTRIBUTION_CHANNEL.VALUE,
      AGENT.VALUE,
      INDEPANDANT_AGENT.VALUE,
      AGENCY.VALUE,
      SALES_REP.VALUE,
      CUSTOMER_SUPPORT.VALUE,
    ],
  },
  [routes.customerInvoices]: {
    name: routes.customerInvoices,
    path: path.customerInvoices,
    component: MainLayout,
    subPage: CustomerInvoices,
    selectedId: PAGE_ENUMS_ID.USER,
    roles: [
      ADMIN.VALUE,
      DISTRIBUTION_CHANNEL.VALUE,
      AGENT.VALUE,
      INDEPANDANT_AGENT.VALUE,
      AGENCY.VALUE,
      SALES_REP.VALUE,
      CUSTOMER_SUPPORT.VALUE,
    ],
  },
  [routes.customerSubscription]: {
    name: routes.customerSubscription,
    path: path.customerSubscription,
    component: MainLayout,
    subPage: CustomerSubscription,
    selectedId: PAGE_ENUMS_ID.USER,
    roles: [
      ADMIN.VALUE,
      DISTRIBUTION_CHANNEL.VALUE,
      AGENT.VALUE,
      INDEPANDANT_AGENT.VALUE,
      AGENCY.VALUE,
      SALES_REP.VALUE,
      CUSTOMER_SUPPORT.VALUE,
    ],
  },
  [routes.customerClaims]: {
    name: routes.customerClaims,
    path: path.customerClaims,
    component: MainLayout,
    subPage: CustomerClaims,
    selectedId: PAGE_ENUMS_ID.USER,
    roles: [
      ADMIN.VALUE,
      DISTRIBUTION_CHANNEL.VALUE,
      AGENT.VALUE,
      INDEPANDANT_AGENT.VALUE,
      AGENCY.VALUE,
      SALES_REP.VALUE,
      CUSTOMER_SUPPORT.VALUE,
    ],
  },
  [routes.addNewClaim]: {
    name: routes.addNewClaim,
    path: path.addNewClaim,
    component: MainLayout,
    subPage: AddNewClaimScreen,
    roles: [
      ADMIN.VALUE,
      DISTRIBUTION_CHANNEL.VALUE,
      AGENCY.VALUE,
      SALES_REP.VALUE,
      CUSTOMER_SUPPORT.VALUE,
    ],
  },
  [routes.staffMaintainance]: {
    name: routes.staffMaintainance,
    path: path.staffMaintainance,
    component: MainLayout,
    subPage: StaffMaintainance,
    selectedId: PAGE_ENUMS_ID.STAFF_MAINTAINANCE,
    roles: [
      ADMIN.VALUE,
      DISTRIBUTION_CHANNEL.VALUE,
      AGENCY.VALUE,
      SALES_REP.VALUE,
    ],
  },
  [routes.notifications]: {
    // Send Notifications
    name: routes.notifications,
    path: path.notifications,
    component: MainLayout,
    subPage: Notifications,
    selectedId: PAGE_ENUMS_ID.NOTIFICATION,
    roles: [
      ADMIN.VALUE,
      DISTRIBUTION_CHANNEL.VALUE,
      AGENT.VALUE,
      INDEPANDANT_AGENT.VALUE,
      AGENCY.VALUE,
      SALES_REP.VALUE,
      CUSTOMER_SUPPORT.VALUE,
    ],
  },
  [routes.couponCode]: {
    name: routes.couponCode,
    path: path.couponCode,
    component: MainLayout,
    subPage: CouponCode,
    selectedId: PAGE_ENUMS_ID.COUPON_CODE,
    roles: [
      ADMIN.VALUE,
      AGENCY.VALUE,
      AGENT.VALUE,
      INDEPANDANT_AGENT.VALUE,
      SALES_REP.VALUE,
      DISTRIBUTION_CHANNEL.VALUE,
    ],
  },
  [routes.addCouponCode]: {
    name: routes.addCouponCode,
    path: path.addCouponCode,
    component: MainLayout,
    subPage: AddCouponCode,
    selectedId: PAGE_ENUMS_ID.COUPON_CODE,
    roles: [ADMIN.VALUE, DISTRIBUTION_CHANNEL.VALUE, AGENCY.VALUE],
  },
  [routes.myProfile]: {
    name: routes.myProfile,
    path: path.myProfile,
    component: MainLayout,
    subPage: MyProfile,
    selectedId: PAGE_ENUMS_ID.MY_PROFILE,
    roles: [
      ADMIN.VALUE,
      DISTRIBUTION_CHANNEL.VALUE,
      AGENT.VALUE,
      INDEPANDANT_AGENT.VALUE,
      AGENCY.VALUE,
      BUSINESS_AMB.VALUE,
      SALES_REP.VALUE,
      CUSTOMER_SUPPORT.VALUE,
    ],
  },
  [routes.changePassword]: {
    name: routes.changePassword,
    path: path.changePassword,
    component: MainLayout,
    subPage: ChangePassword,
    selectedId: PAGE_ENUMS_ID.CHANGE_PASSWORD,
    roles: [
      ADMIN.VALUE,
      DISTRIBUTION_CHANNEL.VALUE,
      AGENT.VALUE,
      INDEPANDANT_AGENT.VALUE,
      AGENCY.VALUE,
      BUSINESS_AMB.VALUE,
      SALES_REP.VALUE,
      CUSTOMER_SUPPORT.VALUE,
    ],
  },

  [routes.generateQr]: {
    name: routes.generateQr,
    path: path.generateQr,
    component: MainLayout,
    subPage: QRCode,
    selectedId: PAGE_ENUMS_ID.QR_CODE,
    roles: [
      ADMIN.VALUE,
      DISTRIBUTION_CHANNEL.VALUE,
      AGENT.VALUE,
      INDEPANDANT_AGENT.VALUE,
      AGENCY.VALUE,
      SALES_REP.VALUE,
      BUSINESS_AMB.VALUE,
    ],
  },
  [routes.tutorialGuide]: {
    name: routes.tutorialGuide,
    path: path.tutorialGuide,
    component: MainLayout,
    subPage: TutorialGuide,
    selectedId: PAGE_ENUMS_ID.TUTORIAL_GUIDE,
    roles: [
      ADMIN.VALUE,
      DISTRIBUTION_CHANNEL.VALUE,
      AGENT.VALUE,
      INDEPANDANT_AGENT.VALUE,
      AGENCY.VALUE,
      BUSINESS_AMB.VALUE,
      SALES_REP.VALUE,
      CUSTOMER_SUPPORT.VALUE,
    ],
  },
  [routes.disasterManagement]: {
    name: routes.disasterManagement,
    path: path.disasterManagement,
    component: MainLayout,
    subPage: DisasterManagement,
    selectedId: PAGE_ENUMS_ID.DISASTER_MANAGEMENT,
    roles: [
      ADMIN.VALUE,
      DISTRIBUTION_CHANNEL.VALUE,
      AGENT.VALUE,
      INDEPANDANT_AGENT.VALUE,
      AGENCY.VALUE,
      CUSTOMER_SUPPORT.VALUE,
    ],
  },
  [routes.adminNotifications]: {
    //notification listing
    name: routes.adminNotifications,
    path: path.adminNotifications,
    component: MainLayout,
    subPage: AdminNotification,
    selectedId: PAGE_ENUMS_ID.ADMINNOTIFICATION,
    roles: [
      ADMIN.VALUE,
      DISTRIBUTION_CHANNEL.VALUE,
      AGENCY.VALUE,
      BUSINESS_AMB.VALUE,
      SALES_REP.VALUE,
      CUSTOMER_SUPPORT.VALUE,
    ],
  },

  [routes.emailLogs]: {
    name: routes.emailLogs,
    path: path.emailLogs,
    component: MainLayout,
    subPage: EmailLogs,
    selectedId: PAGE_ENUMS_ID.EMAIL_LOGS,
    roles: [
      ADMIN.VALUE,
      DISTRIBUTION_CHANNEL.VALUE,
      AGENT.VALUE,
      INDEPANDANT_AGENT.VALUE,
      AGENCY.VALUE,
      SALES_REP.VALUE,
      CUSTOMER_SUPPORT.VALUE,
    ],
  },
  [routes.notificationLogs]: {
    name: routes.notificationLogs,
    path: path.notificationLogs,
    component: MainLayout,
    subPage: NofificationLogs,
    selectedId: PAGE_ENUMS_ID.NOTIFICATIN_LOGS,
    roles: [
      ADMIN.VALUE,
      DISTRIBUTION_CHANNEL.VALUE,
      AGENT.VALUE,
      INDEPANDANT_AGENT.VALUE,
      AGENCY.VALUE,
      SALES_REP.VALUE,
      CUSTOMER_SUPPORT.VALUE,
    ],
  },
  [routes.auditLogs]: {
    name: routes.auditLogs,
    path: path.auditLogs,
    component: MainLayout,
    subPage: AuditLogs,
    selectedId: PAGE_ENUMS_ID.AUDIT_LOGS,
    roles: [
      ADMIN.VALUE,
      DISTRIBUTION_CHANNEL.VALUE,
      AGENT.VALUE,
      INDEPANDANT_AGENT.VALUE,
      AGENCY.VALUE,
      SALES_REP.VALUE,
    ],
  },
  [routes.auditLogsDetail]: {
    name: routes.auditLogsDetail,
    path: path.auditLogsDetail,
    component: MainLayout,
    subPage: AuditLogsDetail,
    selectedId: PAGE_ENUMS_ID.AUDIT_LOGS_DETAILS,
    roles: [
      ADMIN.VALUE,
      DISTRIBUTION_CHANNEL.VALUE,
      AGENT.VALUE,
      INDEPANDANT_AGENT.VALUE,
      AGENCY.VALUE,
      SALES_REP.VALUE,
    ],
  },
  [routes.commissionCalculator]: {
    name: routes.commissionCalculator,
    path: path.commissionCalculator,
    component: MainLayout,
    subPage: CommissionCalculator,
    selectedId: PAGE_ENUMS_ID.COMMISSION_CALCULATOR,
    roles: [
      ADMIN.VALUE,
      DISTRIBUTION_CHANNEL.VALUE,
      AGENT.VALUE,
      INDEPANDANT_AGENT.VALUE,
      AGENCY.VALUE,
      SALES_REP.VALUE,
      BUSINESS_AMB.VALUE,
    ],
  },
  // [routes.testPage]: {
  //   name: routes.testPage,
  //   path: path.testPage,
  //   component: MainLayout,
  //   subPage: TestPage,
  //   selectedId: PAGE_ENUMS_ID.TEST,
  //   roles: [ADMIN.VALUE, AGENCY.VALUE, AGENT.VALUE, SALES_REP.VALUE],
  // },
};
export const publicPageRoutes = {
  [routes.login]: {
    name: routes.login,
    path: path.login,
    component: Login,
  },
  [routes.forgotPassword]: {
    name: routes.forgotPassword,
    path: path.forgotPassword,
    component: ForgotPassword,
  },
  [routes.resetPassword]: {
    name: routes.resetPassword,
    path: path.resetPassword,
    component: ResetPassword,
  },
  [routes.uploadDocuments]: {
    name: routes.uploadDocuments,
    path: path.uploadDocuments,
    component: UploadDocuments,
  },
};
export const errorPageRoute = {
  [routes.errorPage]: {
    name: routes.errorPage,
    path: path.errorPage,
    component: ErrorPage,
  },
};

export const privatePaths = [
  //All Private Routes Should be place here
  path.dashboard,
];

export const publicPaths = [path.login];
