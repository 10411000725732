import React from 'react';
import {
  CommissionCards,
  CommissionRateTable,
  FilterCommissionWithDate,
  Loader,
  SaleDistributionAdmin,
  TotalCommission,
} from '../../../../components';
import {Grid} from '@mui/material';
import {images} from '../../../../common';
import {Tooltip} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

export function AgencySalesCalculator({
  data,
  dateRange,
  setDateRange,
  loader,
  onDateChangeFilter,
}) {
  const {
    deductibles,
    subscriptions,
    customers,
    registrations,
    salesDistribution,
    commissionTable,
    totalCommission,
  } = data || {};

  const topCardsList = [
    {
      icon: images.deductiblesIcon,
      tile: 'Total Sales',
      count: (
        <>
          <span
            style={{cursor: 'pointer'}}
            data-tooltip-id={'sales'}
            data-tooltip-content={'Sales based on 1st month'}>
            {`$${deductibles}` || 'N/A'}
          </span>
          <Tooltip id={'sales'} classNameArrow="arrow" />
        </>
      ),
    },
    {
      icon: images.subscriptionsIcon,
      tile: 'Total Subscriptions',
      count: `${subscriptions}` || 'N/A',
    },
    {
      icon: images.customersIcon,
      tile: 'Customers',
      count: `${customers}` || 'N/A',
    },
    {
      icon: images.registerationIcon,
      tile: 'Registrations',
      count: `${registrations}` || 'N/A',
    },
  ];

  return (
    <div>
      <div className="filter-container">
        <FilterCommissionWithDate
          dateRange={dateRange}
          setDateRange={setDateRange}
          onDateChange={(start, end) => {
            onDateChangeFilter(start, end);
          }}
        />
      </div>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="cards-container-calculator">
            <CommissionCards list={topCardsList} />
          </div>
          <Grid container spacing={1.5}>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
              <SaleDistributionAdmin
                commissionDistributionItem={salesDistribution}
                isFixedRate={
                  commissionTable?.length > 0 && commissionTable[0]?.isFixedRate
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <CommissionRateTable dataList={commissionTable} />
              <TotalCommission totalSale={`$${totalCommission}`} />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}
