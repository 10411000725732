
import { ActionDropDownPopUp, CellValue } from '../../../components';
import { GetBadge } from '../../../utils/helpers/users';
import { ALL_TEXT, ENUMS, images } from '../../../common';
import { TbEdit, TbFileDownload } from 'react-icons/tb';
import { TiDocumentText } from 'react-icons/ti';
import { AiFillCheckCircle } from 'react-icons/ai';
import { AiFillCloseCircle } from 'react-icons/ai';
import './styles.scss';
import React from 'react'
import { useDispatch } from 'react-redux';
import {
  setDeleteModal,
  setEditModal,
  setIsAccountVerificationPopUp,
  setIsCommissionPopUp,
  setIsLinkGeneratorPopup,
  setIsRequestDocumentPopup,
  setSelectedEmployeeData,
  setSelectedMenuOptions,
  setIsDownloadFilesPopup
} from '../../../redux/slice/administrator';
import { getStringFromEnumsObj } from '../../../utils/helpers';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { colors } from '../../../common/theme'
import { getProfile } from '../../../utils/localstorage';
const {
  STAFF: { ROLE, STATUS },
} = ENUMS;

export const staffLinkpopupTypes = {
  [ALL_TEXT.GENERATE_FORGOT_PASSWORD_LINK]: 1,
  [ALL_TEXT.CREATE_ACTIVATION_LINK_STAFF]: 2,
};

export function TableHeading() {
  const dispatch = useDispatch();
  const profile = getProfile();
  const getMenuItems = row => {
    const menuItems = [
      {
        title: 'Edit Staff',
        icon: (
          <span className="edit-icon-staff">
            <TbEdit />
          </span>
        ),
        onItemPress: () => {
          dispatch(setEditModal(true));
          dispatch(setSelectedEmployeeData(row));
        },
      },
      {
        title: !row?.isActive
          ? `${ALL_TEXT.ACTIVE_STAFF_ACCONT}`
          : `${ALL_TEXT.DEACTIVATE_STAFF}`,
        icon: !row?.isActive ? (
          <span class="icon-Vector-27-Stroke popup-menu-icon green-popup-icon"></span>
        ) : (
          <span class="icon-close popup-menu-icon red-popup-icon"></span>
        ),
        onItemPress: () => {
          dispatch(setDeleteModal(true));
          dispatch(setSelectedEmployeeData(row));
        },
      },
      {
        title: ALL_TEXT.ADD_EDIT_COMMISSION,
        icon: (
          <span class="edit-icon-staff">
            <TbEdit />
          </span>
        ),
        onItemPress: () => {
          dispatch(
            dispatch(setSelectedEmployeeData(row)),
            dispatch(setIsCommissionPopUp(true)),
          );
        },
      },
      {
        title: ALL_TEXT.UPDATE_ACCOUNT_VERIFICATION,
        icon: (
          <span class="edit-icon-staff">
            <TbEdit />
          </span>
        ),
        onItemPress: () => {
          dispatch(
            dispatch(setSelectedEmployeeData(row)),
            dispatch(setIsAccountVerificationPopUp(true)),
          );
        },
      },

      {
        title: ALL_TEXT.REQUEST_DOCUMENTS,
        icon: (
          <span class="edit-icon-staff">
            <TiDocumentText />
          </span>
        ),
        onItemPress: () => {
          dispatch(
            dispatch(setSelectedEmployeeData(row)),

            dispatch(setIsRequestDocumentPopup(true)),
          );
        },
      },

      {
        title: row?.isEmailVerified === true
          ? ALL_TEXT.CHANGE_PASSWORD_LINK
          : `${ALL_TEXT.CREATE_ACCOUNT_SETUP_LINK}`,
        icon: row?.isEmailVerified === true ?
          <span class="icon-resetpassword popup-menu-icon red-popup-icon"></span> :
          <img height={'25px'} src={images.activeAccountLink} />,
        onItemPress: () => {
          row?.isEmailVerified === true ?
            dispatch(
              setSelectedMenuOptions(
                staffLinkpopupTypes[ALL_TEXT.GENERATE_FORGOT_PASSWORD_LINK],
              ),
              dispatch(setSelectedEmployeeData(row)),
              dispatch(setIsLinkGeneratorPopup(true)),
            ) :
            dispatch(
              setSelectedMenuOptions(
                staffLinkpopupTypes[ALL_TEXT.CREATE_ACTIVATION_LINK_STAFF],
              ),
              dispatch(setSelectedEmployeeData(row)),
              dispatch(setIsLinkGeneratorPopup(true)),
            )
        },
      },
      row.verificationStatus !== 1 &&
      {
        title: ALL_TEXT.DOWNLOAD_DOCUMENTS,
        icon: (
          <span class="edit-icon-staff">
            <TbFileDownload />
          </span>
        ),

        onItemPress: () => {
          dispatch(setSelectedEmployeeData(row)),

            dispatch(setIsDownloadFilesPopup(true))
        },
      },
    ];
    const filteredMenuItems = menuItems.filter(item => {
      return (
        row?.isActive || item.title !== ALL_TEXT.GENERATE_FORGOT_PASSWORD_LINK
      );
    });
    const agencyFilterMenu = menuItems.filter(item => {
      return (
        item.title !== ALL_TEXT.ADD_EDIT_COMMISSION
      );
    });

    if (profile.roleId !== 5) {
      return agencyFilterMenu;
    } else {
      return filteredMenuItems;
    }
  };

  return [
    {
      title: 'First Name',
      fieldName: 'firstName',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Last Name',
      fieldName: 'lastName',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Email',
      fieldName: 'email',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Email Verified',
      fieldName: 'isEmailVerified',
      dataformat: (cell) =>
        cell === true ? <AiFillCheckCircle style={{ color: colors.green, fontSize: '25px' }} /> : <AiFillCloseCircle style={{ color: colors.primary, fontSize: '25px' }} />
    },
    {
      title: 'Account Verification Status',
      fieldName: 'verificationStatus',
      dataformat: (cell) =>
        GetBadge(
          cell === 1 ? ENUMS.UPDATE_ACCOUNT_VERIFICATION_STATUS.Pending.LABEL : cell === 2 ? ENUMS.UPDATE_ACCOUNT_VERIFICATION_STATUS.Verified.LABEL : cell === 3 ? ENUMS.UPDATE_ACCOUNT_VERIFICATION_STATUS.Rejected.LABEL : ""
        ),
    },
    {
      title: 'Company Name',
      fieldName: 'companyName',
      dataformat: (cell, row) => (
        <CellValue
          cell={cell !== '' ? cell : ALL_TEXT.NOT_AVAILABLE}
          row={row}
          disableTooltip={row?.taxId == ''}
          tooltipData={`Tax Id:${row.taxId}`}
        />
      ),
    },
    {
      title: 'Role',
      fieldName: 'role',
      dataformat: (cell, row) =>
        <div className="role-cell">
          {row.userRoleId <= 8 && row.userRoleId != '' && row.userRoleId > 0
            ? getStringFromEnumsObj(ROLE, row.userRoleId)
            : ALL_TEXT.NOT_AVAILABLE
          }
        </div>
    },
    // {
    //   title: 'Assigned Sales Rep',
    //   fieldName: 'saleRepId',
    //   dataformat: (cell, row) => (
    //     <CellValue
    //       cell={cell !== '' ? cell : ALL_TEXT.NOT_AVAILABLE}
    //       row={row}
    //     />
    //   ),
    // },
    {
      title: 'Status',
      fieldName: 'status',
      dataformat: (cell, row) =>
        GetBadge(
          row?.isActive === true ? STATUS.ACTIVE.LABEL : STATUS.INACTIVE.LABEL,
        ),
    },
    {
      title: 'Actions',
      fieldName: '',
      dataformat: (_, row) => (
        <ActionDropDownPopUp
          visibleIcon={<BsThreeDotsVertical />}
          items={getMenuItems(row)}
          userObj={row}
        />
      ),
    },
  ];
}
