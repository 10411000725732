import React from 'react';
import './style.scss';

export function CommissionCards({list}) {
  return list?.map(i => (
    <div key={i} className="info-commission-card">
      <img src={i.icon} />
      <span className="title">{i.tile}</span>
      <span className="item-detail">{i.count}</span>
    </div>
  ));
}
