import React from 'react';
import {CellValue} from '../../../components';
import {ENUMS, QUERYPARAM, images} from '../../../common';
import {useDispatch} from 'react-redux';
import {GenericBadge} from '../../../components';
import {useNavigate} from 'react-router';
import {path} from '../../../common/routesNames';
import {setSelectedAuditData} from '../../../redux/slice/logs';
import {getStringFromEnumsObj} from '../../../utils/helpers';

export function TableHeading() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const GetStatusBadge = number => {
    if (number == 200) {
      return <GenericBadge value={number} colors="green-badge" />;
    } else {
      return <GenericBadge value={number} colors="red-badge" />;
    }
  };

  const handleRedirect = row => {
    const isTargetPage = window.location.pathname === path.auditLogs;

    if (row.roleId !== 1) {
      if (isTargetPage) {
        if (row.signupId === 0 && row.employeeId !== 0) {
          navigate({
            pathname: path.staffMaintainance,
            search: `?${QUERYPARAM.SIGNUPID}=${row.userEmail}`,
          });
        }
        else if (row.signupId !== 0 && row.employeeId === 0) {
          navigate({
            pathname: path.customerProfile,
            search: `?${QUERYPARAM.SIGNUPID}=${row.userEmail}`,
          });
        }
        else if (row.signupId === 0 && row.employeeId === 0) {
          navigate({
          });
        }
      }
    }
  };
  return [
    {
      title: 'User',
      fieldName: 'email',
      dataformat: (cell, row) => {
        return (
          <div className="email-logs-email">
            {/* <p onClick={() => {console.log('Test Click')}>{row.name}</p> */}
            <p onClick={() => handleRedirect(row)}>{row.name}</p>
            <CellValue cell={cell} row={row.email} />
          </div>
        );
      },
    },
    {
      title: 'Role',
      fieldName: 'roleId',
      dataformat: (cell, row) => {
        return (
          <div className="audit-log-role">
            <CellValue
              cell={getStringFromEnumsObj(ENUMS.STAFF.ROLE,cell)}
              row={row}
              disableTooltip={true}
            />
          </div>
        );
      },
    },
    {
      title: 'Http Request',
      fieldName: 'httpRequest',
      dataformat: (cell, row) => {
        return (
          <div className="httpStatus-badge">
            {row.httpRequestType === null ? (
              ''
            ) : (
              <span className="httpStatus">{row.httpRequestType}</span>
            )}
            <CellValue cell={cell} row={row.httpRequest} />,
          </div>
        );
      },
    },

    {
      title: 'Module Actions',
      fieldName: 'moduleAction',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },

    {
      title: 'Status Code',
      fieldName: 'statusCode',
      dataformat: (cell, row) => (
        <CellValue
          cell={GetStatusBadge(cell != '' ? cell : 'N/A ')}
          row={row}
          disableTooltip={true}
        />
      ),
    },
    {
      title: 'Date Time',
      fieldName: 'dateTime',
      dataformat: (cell, row) => {
        return <CellValue cell={row.dateTime} row={row} />;
      },
    },

    {
      title: 'Action',
      fieldName: 'action',
      dataformat: (cell, row) => (
        <span className="action-icons-staff">
          <span
            className="search-icon-"
            onClick={() => {
              navigate({
                pathname: path.auditLogsDetail,
                search: `?${QUERYPARAM.LOGID}=${row.logId}`,
              });
              // dispatch(setNotificationPopUp(true));
              dispatch(setSelectedAuditData(row));
            }}>
            <img src={images.searchIcon} />
          </span>
        </span>
      ),
    },
  ];
}
