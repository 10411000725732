import React, {useState, useEffect} from 'react';
import './styles.scss';
import CustomerProfileLayout from '../main-layout';
import {ENUMS} from '../../../common';
import {CustomizedSearch, Loader, PolicyCard} from '../../../components';
import {ALL_TEXT, QUERYPARAM} from './../../../common/constants';
import {
  getClaimStatus,
  getValuesFromEnumsObj,
  planSelectorIcon,
} from '../../../utils/helpers';
import {useLocation} from 'react-router';
import {getCustomerClaims} from '../../../utils/rest-services';
import {Pagination} from '@mui/material';
import {ClaimDetails} from '../../../components/claim-detail-popup';
import {useSelector} from 'react-redux';

function CustomerClaims() {
  const [btnLoader, setBtnLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [claimsStatus, setClaimsStatus] = useState(0);
  const [claimsList, setClaimsList] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [detailsModal, setDetailsModal] = useState(false);
  const [selectedClaimId, setSelectedClaimId] = useState(0);
  const CLAIM_STATUS = {
    Pending: 1,
    Settled: 2,
    Cancelled: 3,
    Rejected: 4,
  };

  const location = useLocation();

  const getParamFromUrl = () => {
    return new URLSearchParams(location.search);
  };
  let query = getParamFromUrl();
  const signupId = query.get(QUERYPARAM.SIGNUPID);
  const isSelectedUser = useSelector(
    state => state.checkout.isUserChange ?? null,
  );

  useEffect(() => {
    const payloadObj = {
      signupId: signupId,
      pageNo: currentPage,
      rowsPerPage: ENUMS.PAGE_SIZE,
      status: claimsStatus,
      id: 0,
      policyId: 0,
    };
    getCustomerClaimHandler(payloadObj);
  }, [currentPage, isSelectedUser]);

  const getCustomerClaimHandler = async payloadObj => {
    setLoader(true);
    let response = await getCustomerClaims(payloadObj);
    const {
      content: {list, totalPage},
    } = response;
    if (response && response.success) {
      setClaimsList(list);
      setTotalPage(totalPage);
    }
    setLoader(false);
  };

  const claimSearchHandler = async (policyId, ClaimNumber, status, clear) => {
    let payload;
    if (clear) {
      payload = {
        signupId: signupId,
        pageNo: 1,
        rowsPerPage: ENUMS.PAGE_SIZE,
        status: 0,
        id: 0,
        policyId: 0,
      };
    } else {
      const claimsStatus = getValuesFromEnumsObj(ENUMS.CLAIMS.STAND, status);
      setClaimsStatus(claimsStatus);
      payload = {
        signupId: signupId,
        pageNo: 1,
        rowsPerPage: ENUMS.PAGE_SIZE,
        status: claimsStatus,
        policyId: policyId ? policyId?.split('_')[1] || policyId : 0,
        id: ClaimNumber || 0,
      };
    }

    setBtnLoader(true);
    await getCustomerClaimHandler(payload);
    setBtnLoader(false);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  return (
    <CustomerProfileLayout selectedId={ENUMS.CUSTOMER_TAB_INDEX.CLAIMS}>
      <div className="claims-main-container ">
        <CustomizedSearch
          title={'Search Claim By'}
          firstLabel={'Claim Plan Id'}
          isFirstInputNumeric
          firstPrefixerValue={'pln_'}
          firstPlaceholder={'Enter Plan Id'}
          secondInput
          isSecondInputNumeric
          secondLabel={ALL_TEXT.CLAIM_NUMBER}
          secondPlaceholder={ALL_TEXT.ENTER_CLAIM_ID}
          secondPrefixerValue={'clm_'}
          firstDropdown
          firstDropdownLabel={'Status'}
          firstDropdownList={[
            ENUMS.CLAIMS.STAND.SETTLED.LABEL,
            ENUMS.CLAIMS.STAND.PENDING.LABEL,
            ENUMS.CLAIMS.STAND.CANCELLED.LABEL,
            ENUMS.CLAIMS.STAND.REJECTED.LABEL,
          ]}
          onClearFilterHandler={() => {
            claimSearchHandler('', '', '', true);
          }}
          onSearchBtnPress={(policyId, ClaimNumber, _, status) => {
            claimSearchHandler(policyId, ClaimNumber, status);
          }}
          btnLoader={btnLoader}
        />
        {!loader && claimsList.length === 0 && (
          <div className="loader-container-dasboard flex-center">
            <span> {ALL_TEXT.NO_CLAIMS}</span>
          </div>
        )}
        {loader ? (
          <div className="loader-container-dasboard">
            <Loader />
          </div>
        ) : (
          claimsList.length > 0 &&
          claimsList.map((item, index) => {
            const {
              providerName,
              claimAmount,
              claimStatus,
              dateFiled,
              dateLoss,
              dateSettled,
              serviceType,
              serviceTypeId,
              cartItemId,
              viewClaimId,
              claimStatusId,
            } = item || {};
            return (
              <PolicyCard
                key={index}
                cardType={ENUMS.VIEW_CARD_TYPE.CLAIMS}
                onDetailBtnPress={() => {
                  setSelectedClaimId(viewClaimId.split('_')[1]);
                  setDetailsModal(true);
                }}
                planIconTitle={
                  <div className="plan-name">
                    {planSelectorIcon(serviceTypeId)}
                    <span className="description-title">{serviceType}</span>
                  </div>
                }
                badge={
                  <span class={getClaimStatus(claimStatus)}>{claimStatus}</span>
                }
                cost={
                  <span className="bold-payment">
                    {`Amount: $${parseFloat(claimAmount).toFixed(2)}`}
                  </span>
                }
                LeftTopDetails={
                  <div className="details-item">
                    <span>{ALL_TEXT.PROVIDER}</span>
                    <span>{providerName}</span>
                  </div>
                }
                LeftBottomDetails={
                  <div className="details-item">
                    <span>{ALL_TEXT.CLAIM_POLICY_ID}</span>
                    <span>{cartItemId}</span>
                  </div>
                }
                rightTopDetails={
                  <div className="details-item">
                    <span>{ALL_TEXT.DATE_LOSS}</span>
                    <span>{dateLoss}</span>
                  </div>
                }
                rigthBottomDetails={
                  <div className="details-item">
                    <span>{ALL_TEXT.DATE_FILED}</span>
                    <span>{dateFiled}</span>
                  </div>
                }
                lastLeftBottomDetails={
                  claimStatusId != CLAIM_STATUS.Pending && (
                    <div className="details-item">
                      <span>{`Date ${claimStatus}`}</span>
                      <span>
                        {dateSettled == ''
                          ? ALL_TEXT.NOT_AVAILABLE
                          : dateSettled}
                      </span>
                    </div>
                  )
                }
                lastBottomDetails={
                  <div className="details-item claim-extra-box"></div>
                }
              />
            );
          })
        )}
      </div>
      {totalPage > 1 && (
        <div className="pagination-container">
          <div className="inner-pagination">
            <Pagination
              page={currentPage}
              color={'secondary'}
              onChange={handlePageChange}
              cont={totalPage}
            />
          </div>
        </div>
      )}

      {detailsModal && (
        <ClaimDetails
          claimId={selectedClaimId}
          signUpId={signupId}
          show={detailsModal}
          handelClose={() => {
            setDetailsModal(false);
          }}
        />
      )}
    </CustomerProfileLayout>
  );
}

export default CustomerClaims;
