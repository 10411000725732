import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {GrClose} from 'react-icons/gr';
import {colors} from '../../common';
import {ALL_TEXT, ENUMS} from '../../common/constants';
import {getCustomerClaimsDetail} from '../../utils/rest-services';
import './styles.scss';
import {Loader} from '..';
import {Tooltip} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import {
  NumberToText,
  getClaimStatus,
  planSelectorIcon,
} from '../../utils/helpers';
import _ from 'lodash';

export function ClaimDetails({show, handelClose, claimId, signUpId}) {
  const [loader, setLoader] = useState(true);
  const [claimsData, setClaimsData] = useState(null);
  const [claimDocList, setclaimDocList] = useState({
    images: [],
    docs: [],
  });
  useEffect(() => {
    fetchClaimDetails();
  }, []);

  const fetchClaimDetails = async () => {
    setLoader(true);
    let response = await getCustomerClaimsDetail(claimId, signUpId);
    if (response && response.success) {
      const {
        content: {claim, claimDocs},
      } = response;

      const images = _.filter(
        claimDocs,
        item =>
          _.endsWith(item.documentPath, '.jpeg') ||
          _.endsWith(item.documentPath, '.jpg') ||
          _.endsWith(item.documentPath, '.png'),
      );
      const pdfDocuments = _.filter(
        claimDocs,
        item =>
          _.endsWith(item.documentPath, '.pdf') ||
          _.endsWith(item.documentPath, '.doc') ||
          _.endsWith(item.documentPath, '.docx') ||
          _.endsWith(item.documentPath, '.txt'),
      );
      setClaimsData(claim);
      setclaimDocList({
        images: images,
        docs: pdfDocuments,
      });
    }
    setLoader(false);
  };

  return (
    <Modal centered show={show} onHide={handelClose} size="lg">
      <Modal.Body className="modal-body-invoice">
        {loader ? (
          <div className="loader-container-invoice">
            <Loader />
          </div>
        ) : (
          <>
            <div className="claim-detail-container">
              <span>{ALL_TEXT.CLAIMS_DETAILS}</span>
              <GrClose
                className="add-payment-close-btn"
                onClick={handelClose}
                size={15}
                color={colors.primary}
              />
            </div>
            <div className="claim-details-info mt-3">
              <div className="claim-title">
                <div className="claim-icon">
                  {planSelectorIcon(claimsData?.serviceTypeId)}
                  <span className="description-title">
                    {NumberToText(claimsData?.serviceTypeId)}
                  </span>
                </div>
                <span
                  class={`${getClaimStatus(
                    claimsData.claimStatus,
                  )} badge-style`}>
                  {claimsData.claimStatus}
                </span>
              </div>
              <div className="claim-content-info">
                <div className="claim-content">
                  <span>{ALL_TEXT.CLAIM_POLICY_ID}</span>
                  <span
                    data-tooltip-id={`claim-id-tooltip`}
                    data-tooltip-content={`Copy to Clipboard`}
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      navigator.clipboard.writeText(claimsData.cartItemId);
                    }}>
                    {claimsData.cartItemId.split('_')[1]}
                  </span>
                  <Tooltip id={`claim-id-tooltip`} />
                </div>
                <div className="claim-content">
                  <span>{ALL_TEXT.CLAIM_ID}</span>
                  <span
                    data-tooltip-id={`claim-id-tooltip`}
                    data-tooltip-content={`Copy to Clipboard`}
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      navigator.clipboard.writeText(claimsData.viewClaimId);
                    }}>
                    {claimsData.viewClaimId == ''
                      ? ALL_TEXT.NOT_AVAILABLE
                      : claimsData.viewClaimId}
                  </span>
                </div>
                <div className="claim-content">
                  <span>{ALL_TEXT.PROVIDER}</span>
                  <span>{claimsData.providerName}</span>
                </div>
                <div className="claim-content">
                  <span>{ALL_TEXT.DATE_LOSS}</span>
                  <span>{claimsData.dateLoss}</span>
                </div>
                <div className="claim-content">
                  <span>{ALL_TEXT.DATE_FILED}</span>
                  <span>{claimsData.dateFiled}</span>
                </div>
                {claimsData.claimStatus.toLowerCase() !==
                  ENUMS.CLAIMS.STAND.PENDING.LABEL.toLowerCase() && (
                  <div className="claim-content">
                    <span>{`Date ${claimsData.claimStatus}`}</span>
                    <span>
                      {claimsData.dateSettled == ''
                        ? ALL_TEXT.NOT_AVAILABLE
                        : claimsData.dateSettled}
                    </span>
                  </div>
                )}
                <div className="claim-content">
                  <span class="fw-bold ">{ALL_TEXT.AMOUNT}</span>
                  <span class="fw-bold">{`$${parseFloat(
                    claimsData.claimAmount,
                  ).toFixed(2)}`}</span>
                </div>
              </div>
            </div>

            {claimDocList?.images && claimDocList?.images?.length > 0 && (
              <div className="attachment-claim-details mt-3">
                <span>{ALL_TEXT.ATTACHMENTS_IMAGES}</span>
                <>
                  <div className="attachment-images-container">
                    {claimDocList?.images?.map((item, index) => {
                      return (
                        <div
                          className="attachment-images"
                          key={index}
                          style={{
                            backgroundImage: `url('${item.documentPath}')`,
                          }}>
                          <img src={item.documentPath} alt={ALL_TEXT.ICON} />
                        </div>
                      );
                    })}
                  </div>
                </>
                {claimDocList?.docs && claimDocList?.docs?.length > 0 && (
                  <div className="attachment-pdf-detail mt-3">
                    <span>{ALL_TEXT.ATTACHMENTS_PDF}</span>
                    <div className="attachment-pdf-container">
                      {claimDocList?.docs?.map((item, index) => {
                        return (
                          <a
                            href={item.documentPath}
                            target="_blank"
                            rel="noreferrer"
                            className="attachment-pdf-info"
                            key={index}>
                            <span class="icon-pdf popup-menu-icon red-popup-icon"></span>
                            <span>{item.documentPath.split('\\')[2]}</span>
                          </a>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
