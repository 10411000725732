import React, {useState} from 'react';
import './style.scss';
import {ALL_TEXT, ENUMS} from '../../../common';
import {GenericModal, InputField} from '../../../components';
import {Grid} from '@mui/material';
import {Formik} from 'formik';
import {GenericButton} from './../../../components/generic-button/index';
import {changePasswordSchema} from '../../../common/schemas';
import {usePostApiAdminChangePasswordMutation} from '../../../redux/slice/nodukAdmin.ts';
import {useDispatch} from 'react-redux';
import {openModal, setModalDetails} from '../../../redux/slice/popup-modal';
import {clearStorage} from '../../../utils/localstorage';
import {path} from '../../../common/routesNames';
import {useNavigate} from 'react-router';

function ChangePassword() {
  const [showModal, setShowModal] = useState(false);
  const [apiResponse, setApiResponse] = useState({});
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [changePasswordAPI, responseChangePassword] =
    usePostApiAdminChangePasswordMutation();

  const passwordValue = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };
  const performLogout = () => {
    clearStorage();
    navigate(path.login, {replace: true});
  };
  const changePasswordHandle = async values => {
    const changePasswordPayload = {
      password: {
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      },
    };
    let response = await changePasswordAPI(changePasswordPayload);
    if (response && response?.data?.success) {
      setApiResponse({
        type: ENUMS.POPUP_ENUMS.SUCCESS,
        body: response?.data?.description,
      });
      setShowModal(true);
    } else {
      dispatch(
        setModalDetails({
          type: ENUMS.POPUP_ENUMS.ERROR,
          details: response?.data?.description,
        }),
      );
      dispatch(openModal());
    }
  };
  return (
    <Formik
      initialValues={{
        currentPassword: passwordValue.currentPassword || '',
        newPassword: passwordValue.newPassword || '',
        confirmPassword: passwordValue.confirmNewPassword || '',
      }}
      validateOnMount={true}
      validationSchema={changePasswordSchema}
      enableReinitialize
      onSubmit={(values, formik) => {
        changePasswordHandle(values, formik);
      }}>
      {({handleBlur, handleChange, handleSubmit, errors, touched, values}) => (
        <div className="claimlist-main-container">
          <div className="title-container-change-password">
            <div>
              <span>{ALL_TEXT.ACCOUNT} / </span>
              <span>{ALL_TEXT.CHANGE_PASSWORD}</span>
            </div>
          </div>
          <div className="change-password-container">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={10} md={12} lg={12} xl={12}>
                <label>{ALL_TEXT.CURRENT_PASSWORD}</label>
                <InputField
                  id={'currentPassword'}
                  name={'currentPassword'}
                  value={values.currentPassword}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={touched.currentPassword && errors.currentPassword}
                  isPassword
                  placeholder={ALL_TEXT.ENTER_CURRENT_PASSWORD}
                />
              </Grid>
              <Grid item xs={12} sm={10} md={12} lg={12} xl={12}>
                <label>{ALL_TEXT.NEW_PASSWORD}</label>
                <InputField
                  id={'newPassword'}
                  name={'newPassword'}
                  value={values.newPassword}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={touched.newPassword && errors.newPassword}
                  isPassword
                  placeholder={ALL_TEXT.ENTER_NEW_PASSWORD}
                />
              </Grid>
              <Grid item xs={12} sm={10} md={12} lg={12} xl={12}>
                <label>{ALL_TEXT.CONFIRM_NEW_PASSWORD}</label>
                <InputField
                  id={'confirmPassword'}
                  name={'confirmPassword'}
                  value={values.confirmPassword}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={touched.confirmPassword && errors.confirmPassword}
                  isPassword
                  placeholder={ALL_TEXT.CONFIRM_NEW_PASSWORD_}
                />
              </Grid>
              <Grid item xs={12} sm={10} md={12} lg={12} xl={12}>
                <GenericButton
                  buttonText={ALL_TEXT.SAVE}
                  onPress={handleSubmit}
                  loading={responseChangePassword.isLoading}
                />
              </Grid>
            </Grid>
          </div>
          {showModal && (
            <GenericModal
              show={showModal}
              type={apiResponse.type}
              body={apiResponse.body}
              handleClose={() => {
                setShowModal(false);
                performLogout();
              }}
              buttonText={ALL_TEXT.OK}
            />
          )}
        </div>
      )}
    </Formik>
  );
}

export default ChangePassword;
