import React from 'react';
import {ALL_TEXT, ENUMS} from '../../common';
import {CellValue} from '../../components';
import {getStringFromEnumsObj} from '../../utils/helpers';
import {GetBadge} from '../../utils/helpers/users';

export const tableHeading = [
  {
    title: 'Id',
    fieldName: 'viewInvoiceId',
    dataformat: (cell, row) => (
      <CellValue cell={cell} row={row} />
      // <CellValue cell={cell.split('_')[1]} row={row} />
    ),
  },
  {
    title: 'Name',
    fieldName: 'firstName',
    dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
  },
  {
    title: 'Email',
    fieldName: 'email',
    dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
  },
  {
    title: 'Amount',
    fieldName: 'cost',
    dataformat: (cell, row) => (
      <CellValue cell={`$${parseFloat(cell).toFixed(2)}`} row={row} />
    ),
  },
  {
    title: 'Status',
    fieldName: 'status',
    dataformat: (cell, row) =>
      row.status <= 4 && row.status != ''
        ? GetBadge(getStringFromEnumsObj(ENUMS.INVOICES, row.status))
        : ALL_TEXT.NOT_AVAILABLE,
  },
  {
    title: 'Paid Date',
    fieldName: 'datePaid',
    dataformat: (cell, row) => (
      <CellValue
        cell={cell !== '' ? cell.split(' ')[0] : ALL_TEXT.NOT_AVAILABLE}
        row={row}
      />
    ),
  },
  {
    title: 'Due On',
    fieldName: 'dueOn',
    dataformat: (cell, row) => (
      <CellValue cell={cell.split(' ')[0]} row={row} />
    ),
  },
  {
    title: 'Refunded',
    fieldName: 'isRefunded',
    dataformat: (cell, row) => <GetRefundIcon row={row} />,
 
  },
  
];

const GetRefundIcon = ({ row }) => {
  if (row && row.isRefunded) {
    return <span className="icon-checkmark green-refund-icon"></span>;
  } else {
    return null;
  }

};

