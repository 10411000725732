import React, {useState} from 'react';
import './style.scss';
import {ALL_TEXT} from '../../../common';
import DisasterTemplate from '../../../common/DisasterImport.xlsx';
import {RiskLevel} from './disaster-risk-level';
import {DisasterAreas} from './disaster-areas';
import {GenericButton} from './../../../components/generic-button/index';
import {HiDownload} from 'react-icons/hi';
import {BsBoxArrowInRight} from 'react-icons/bs';
import {ImportDisasterAreaModal} from '../../../components';
import {generateFormData} from '../../../utils/helpers';
import {useDispatch} from 'react-redux';
import {
  setAreaModalOpen,
  setEditDisasterArea,
} from '../../../redux/slice/disaster-areas';
import {usePostApiDisasterImportDisasterAreaMutation} from '../../../redux/slice/nodukAdmin.ts';
import {toast} from 'react-toastify';

function DisasterManagement() {
  const [uploadAPI, uploadAPIResponse] =
    usePostApiDisasterImportDisasterAreaMutation();
  const [isImportModal, setIsmportModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);
  const [areaFile, setAreaFile] = useState([]);
  const [isDisasterAPI, setIsDisasterAPI] = useState(false);

  const dispatch = useDispatch();

  const uploadFileHandler = async () => {
    let formData = generateFormData({file: areaFile[0]});
    let response = await uploadAPI({body: formData});
    if (response && response.data.success) {
      toast(response.data.description);
      setIsmportModal(false);
      setAreaFile([]);
      setIsDisasterAPI(true);
    } else {
      toast.error(response.data.description);
    }
  };

  return (
    <>
      <div className="disaster-management-main-container">
        <div className="title-container-disaster-management">
          <div className="title-container-disaster-title">
            <span>{ALL_TEXT.ADMINISTRATION}</span>
            <span> / {ALL_TEXT.DISASTER.DISASTER_MANAGEMENT}</span>
          </div>
          {selectedTab == 2 && (
            <div className="disaster-areas-action">
              <GenericButton
                customStyle={'add-area-button'}
                buttonText={ALL_TEXT.DISASTER.ADD_NEW_AREA}
                onPress={() => {
                  dispatch(setEditDisasterArea(null));
                  dispatch(setAreaModalOpen(true));
                }}
              />
              <a
                className="text-decor"
                href={DisasterTemplate}
                download="Zip-Code-File-Template"
                target="_blank"
                rel="noreferrer">
                <GenericButton
                  customStyle={'download-template-button'}
                  icon={<HiDownload color="black" />}
                  buttonText={ALL_TEXT.DISASTER.DOWNLOAD_TEMPLATE}
                />
              </a>
              <GenericButton
                customStyle={'import-button'}
                icon={<BsBoxArrowInRight color="#4285F4" />}
                buttonText={ALL_TEXT.DISASTER.IMPORT}
                onPress={() => {
                  setIsmportModal(true);
                  setIsDisasterAPI(false);
                }}
              />
            </div>
          )}
        </div>
        <div className="disaster-tab">
          <div
            className={
              selectedTab == 1 ? 'selected-tab disaster-risk' : 'disaster-risk'
            }
            onClick={() => setSelectedTab(1)}>
            {ALL_TEXT.DISASTER.DISASTER_RISK_LEVELS}
          </div>
          <div
            className={
              selectedTab == 2 ? 'selected-tab disaster-risk' : 'disaster-risk'
            }
            onClick={() => setSelectedTab(2)}>
            {ALL_TEXT.DISASTER.DISASTER_AREAS}
          </div>
        </div>

        <div className="table-disaster-list-container">
          {selectedTab == 1 && <RiskLevel />}
          {selectedTab == 2 && <DisasterAreas isDisasterAPI={isDisasterAPI} />}
        </div>
      </div>
      {isImportModal && (
        <ImportDisasterAreaModal
          show={isImportModal}
          file={areaFile}
          onChangeHandler={setAreaFile}
          handleClose={() => {
            setIsmportModal(false);
            setAreaFile([]);
          }}
          onUploadButtonPress={uploadFileHandler}
          btnLoading={uploadAPIResponse.isLoading}
          disbaleButton={areaFile.length == 0}
        />
      )}
    </>
  );
}

export default DisasterManagement;
