import React, {useState} from 'react';
import './styles.scss';
import Stepper from 'react-stepper-horizontal';
import {ALL_TEXT, colors} from '../../common';
import {UserInfo} from '../../components';
import {useNavigate} from 'react-router';
import {path} from '../../common/routesNames';

const AddPolicyMainLayout = ({children, selectedId}) => {
  const [activeStep, setActiveStep] = useState(selectedId);

  let navigate = useNavigate();
  return (
    <div className="invoices-main-container px-2">
      <UserInfo />
      <div className="secondary-policy-container">
        <div className="wizard-container">
          <Stepper
            activeColor={colors.primary}
            activeTitleColor={colors.primary}
            completeColor={colors.primary}
            steps={[
              {
                title: ALL_TEXT.WIZARD_STEP_1,
                onClick: () => {
                  setActiveStep(0);
                  navigate(path.addPolicy, {replace: true});
                },
              },
              {
                title: ALL_TEXT.WIZARD_STEP_2,
                onClick: () => {
                  setActiveStep(1);
                  navigate(path.selectPayment, {replace: true});
                },
              },
              {
                title: ALL_TEXT.WIZARD_STEP_3,
                onClick: () => {
                  setActiveStep(2);
                  navigate(path.reviewAndPay, {replace: true});
                },
              },
            ]}
            activeStep={activeStep}
          />
        </div>
        {children}
      </div>
    </div>
  );
};
export default AddPolicyMainLayout;
