import {React} from 'react';
import {ALL_TEXT, ENUMS, QUERYPARAM} from '../../common';
import {ActionDropDownPopUp, CellValue} from '../../components';
import {GetBadge} from '../../utils/helpers/users';
import {BsThreeDotsVertical} from 'react-icons/bs';
import {NumberToText, getStringFromEnumsObj} from '../../utils/helpers';
import {setSelectedCard, setSelectedUser} from '../../redux/slice/checkout';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import {path} from '../../common/routesNames';
import {
  setDownloadClaimsPopup,
  setSelectedClaim,
} from '../../redux/slice/claims';

export function tableHeading() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const setUserObject = userObj => {
    const selectedUserObj = {
      signupId: userObj.signupId,
      email: userObj.email,
      phone: userObj.phone,
      name: `${userObj.firstName} ${userObj.lastName}`,
      value: `${userObj.firstName} ${userObj.lastName}`,
      label: `${userObj.firstName} ${userObj.lastName}`,
      id: userObj.signupId,
      isActive: userObj.isActive,
      status: userObj.status,
    };
    dispatch(setSelectedUser(selectedUserObj));
    dispatch(setSelectedCard(null));
  };
  const getMenuItems = row => {
    return [
      {
        title: ALL_TEXT.CUSTOMER_PROFILE,
        icon: (
          <span class="icon-customers popup-menu-icon red-popup-icon"></span>
        ),
        onItemPress: () => {
          setUserObject(row);
          navigate({
            pathname: path.customerProfile,
            search: `?${QUERYPARAM.SIGNUPID}=${row.signupId}&isActive=${row.isActive}`,
          });
        },
      },
      {
        title: ALL_TEXT.CLAIM_DETAILS,
        icon: (
          <span class="icon-claimdetail popup-menu-icon red-popup-icon"></span>
        ),
        onItemPress: () => {
          navigate({
            pathname: path.claimDetails,
            search: `?claimId=${row.claimId}`,
          });
        },
      },
      {
        title: ALL_TEXT.DOWNLOAD_CLAIMS_DOCS,
        icon: (
          <span class="icon-download-pdf popup-menu-icon red-popup-icon"></span>
        ),
        onItemPress: () => {
          dispatch(setDownloadClaimsPopup(true));
          dispatch(setSelectedClaim(row));
        },
      },
    ];
  };

  return [
    {
      title: 'Claim',
      fieldName: 'claimId',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Amount',
      fieldName: 'amount',
      dataformat: (cell, row) => (
        <CellValue cell={`$${parseFloat(cell).toFixed(2)}`} row={row} />
      ),
    },
    {
      title: 'Status',
      fieldName: 'status',
      dataformat: (cell, row) =>
        GetBadge(getStringFromEnumsObj(ENUMS.CLAIMS.STAND, row.status)),
    },
    {
      title: 'Claim Type',
      fieldName: 'serviceTypeId',
      dataformat: (cell, row) => (
        <CellValue cell={NumberToText(cell)} row={row} />
      ),
    },
    {
      title: 'First Name',
      fieldName: 'firstName',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Last Name',
      fieldName: 'lastName',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Email',
      fieldName: 'email',
      dataformat: (cell, row) => <CellValue cell={cell} row={row} />,
    },
    {
      title: 'Date Filed',
      fieldName: 'dateFiled',
      dataformat: (cell, row) => (
        <CellValue cell={cell.split(' ')[0]} row={row} />
      ),
    },
    {
      title: 'Actions',
      fieldName: 'action',
      dataformat: (cell, row) => {
        return (
          <ActionDropDownPopUp
            visibleIcon={<BsThreeDotsVertical />}
            items={getMenuItems(row)}
            userObj={row}
          />
        );
      },
    },
  ];
}
