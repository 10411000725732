import React, {useState, useRef, useEffect} from 'react';
import {Formik} from 'formik';
import {Grid} from '@mui/material';
import './style.scss';
import Autocomplete from 'react-google-autocomplete';
import {ALL_TEXT, ENUMS} from './../../../common/constants';
import {GenericButton, InputField, Loader} from '../../../components';
import {profileSchema} from '../../../common/schemas';
import {images} from '../../../common';
import {
  NodukAdmnApi,
  usePostApiAdminUpdateProfileMutation,
} from '../../../redux/slice/nodukAdmin.ts';
import {generateFormData, getStringFromEnumsObj} from '../../../utils/helpers';
import {useDispatch} from 'react-redux';
import {openModal, setModalDetails} from '../../../redux/slice/popup-modal';
import {getProfile, setProfile} from '../../../utils/localstorage';

function MyProfile() {
  const {useLazyGetApiAdminGetProfileQuery} = NodukAdmnApi;
  const fileInputRef = useRef(null);
  // const [btnLoader, setBtnLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [userProfileData, userProfileResponse] =
    useLazyGetApiAdminGetProfileQuery();
  const [updateProfileAPI, updateProfileResponse] =
    usePostApiAdminUpdateProfileMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    userProfileData();
  }, []);

  const userInfo = userProfileResponse?.data?.content ?? null;
  const profileDetail = {
    firstName: userInfo?.firstName || '',
    lastName: userInfo?.lastName || '',
    email: userInfo?.email || '',
    phone: userInfo?.phone || '',
    address: userInfo?.address || '',
    city: userInfo?.city || '',
    state: userInfo?.state || '',
    zip: userInfo?.zip || '',
    website: userInfo?.website || '',
  };

  const saveUserData = (employee, email, fName, LName, newUrl) => {
    employee.email = email;
    employee.firstName = fName;
    employee.lastName = LName;
    employee.imageURL = newUrl;

    setProfile(employee);
  };

  const profileHandler = async values => {
    const payloadFormData = {
      FirstName: values.firstName.trim(),
      LastName: values.lastName.trim(),
      Email: values.email.trim(),
      Phone: values.phone.trim(),
      Address: values.address.trim(),
      City: values.city.trim(),
      State: values.state.trim(),
      Zip: values.zip.trim(),
      Website: values.website.trim(),
      EmployeeId: userInfo?.employeeId,
      ContactId: userInfo?.contactId,
      RoleId: userInfo?.roleId,
      IsActive: userInfo?.isActive,
      ProfilePic: selectedImage,
    };
    const payload = generateFormData(payloadFormData);
    let result = await updateProfileAPI({body: payload});
    if (result && result?.data?.success) {
      userProfileData();
      const newImageURL = result?.data?.content?.imageURL;

      let userObj = getProfile();
      saveUserData(
        userObj,
        values.email,
        values.firstName,
        values.lastName,
        newImageURL,
      );
      dispatch(
        setModalDetails({
          type: ENUMS.POPUP_ENUMS.SUCCESS,
          details: result?.data?.description,
        }),
      );
    } else {
      dispatch(
        setModalDetails({
          type: ENUMS.POPUP_ENUMS.ERROR,
          details: result?.data?.description,
        }),
      );
    }
    dispatch(openModal());
  };

  const handleChangePhoneNumber = (event, setFieldValue) => {
    const inputValue = event.target.value;
    const sanitizedValue = inputValue.replace(/[^0-9]/g, '');

    if (sanitizedValue.startsWith('1')) {
      const restrictedValue = sanitizedValue.substring(1, 12);
      setFieldValue('phone', restrictedValue);
    } else {
      const restrictedValue = sanitizedValue.substring(0, 11);
      setFieldValue('phone', restrictedValue);
    }
  };
  const convertArrayToString = data => {
    const joined = data.join();
    let newAddress = joined.replace(/,/g, ' ');
    return newAddress;
  };
  const AddressSeprateHandler = (data, setFieldValue, values) => {
    let address = [];
    let city = [];
    let state = [];
    let postal = [];
    data.map(dataItem => {
      dataItem.types.map(type => {
        if (type === ALL_TEXT.FIELD_TYPE_ENUMS.STREET_NUMBER) {
          address.push(dataItem.long_name);
        } else if (type === ALL_TEXT.FIELD_TYPE_ENUMS.ROUTE) {
          address.push(dataItem.long_name);
        } else if (type === ALL_TEXT.FIELD_TYPE_ENUMS.NAIBHOUR) {
          address.push(dataItem.long_name);
        } else if (type === ALL_TEXT.FIELD_TYPE_ENUMS.LOCALITY) {
          city.push(dataItem.long_name);
        } else if (type === ALL_TEXT.FIELD_TYPE_ENUMS.ADMIN) {
          state.push(dataItem.long_name);
        } else if (type === ALL_TEXT.FIELD_TYPE_ENUMS.CODE) {
          postal.push(dataItem.long_name);
        }
      });
    });
    // === convert Address array to string === //
    setFieldValue(ALL_TEXT.FIELD_VALUE.ADDRESS, values);
    setFieldValue('city', convertArrayToString(city));
    setFieldValue('zip', convertArrayToString(postal));
    setFieldValue('state', convertArrayToString(state));
  };

  const handleFileChange = event => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const getImageFromFile = file => {
    if (file) {
      return URL.createObjectURL(file);
    } else {
      return null;
    }
  };

  const handleChangeZip = (event, setFieldValue) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9+]/g, ''); // remove non-numeric characters
    const restrictedValue = numericValue.substring(0, 9); // restrict to 10 digits
    setFieldValue('zip', restrictedValue);
  };

  return (
    <Formik
      initialValues={{
        firstName: profileDetail.firstName || '',
        lastName: profileDetail.lastName || '',
        email: profileDetail.email || '',
        phone: profileDetail.phone || '',
        address: profileDetail.address || '',
        city: profileDetail.city || '',
        state: profileDetail.state || '',
        zip: profileDetail.zip || '',
        website: profileDetail.website || '',
      }}
      enableReinitialize
      validateOnMount={true}
      validationSchema={profileSchema}
      onSubmit={(values, formik) => {
        profileHandler(values, formik);
      }}>
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        values,
        errors,
        touched,
      }) => (
        <div className="claimlist-main-container">
          <div className="title-container-myProfile">
            <div>
              <span>{ALL_TEXT.ACCOUNT} / </span>
              <span>{ALL_TEXT.MY_PROFILE}</span>
            </div>
          </div>
          {userProfileResponse?.isFetching ? (
            <div className="loader-container-dasboard">
              <Loader />
            </div>
          ) : (
            <div className="my-profile-container">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="profile_header">
                    <div className="profile-image">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        style={{display: 'none'}}
                        ref={fileInputRef}
                      />
                      {userInfo?.imageURL || selectedImage ? (
                        <img
                          src={
                            getImageFromFile(selectedImage) ||
                            userInfo?.imageURL
                          }
                          alt={ALL_TEXT.ICON}
                          onClick={() => fileInputRef.current.click()}
                          style={{cursor: 'pointer'}}
                        />
                      ) : (
                        <img
                          src={images.profile}
                          alt={ALL_TEXT.ICON}
                          onClick={() => fileInputRef.current.click()}
                          style={{cursor: 'pointer'}}
                        />
                      )}
                      <div className="camera-image">
                        <img src={images.camera} />
                      </div>
                    </div>
                    <div className="name-box">
                      <span>{`${userInfo?.firstName} ${userInfo?.lastName}`}</span>
                      <span>
                        {getStringFromEnumsObj(
                          ENUMS.STAFF.ROLE,
                          userInfo?.roleId,
                        )}
                      </span>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <label>{ALL_TEXT.MY_PROFILE_.FIRST_NAME}</label>
                  <InputField
                    id={'firstName'}
                    name={'firstName'}
                    value={values.firstName}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    error={touched.firstName && errors.firstName}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <label>{ALL_TEXT.MY_PROFILE_.LAST_NAME}</label>
                  <InputField
                    id={'lastName'}
                    name={'lastName'}
                    value={values.lastName}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    error={touched.lastName && errors.lastName}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <label>{ALL_TEXT.MY_PROFILE_.CONTACT}</label>
                  <InputField
                    phone
                    id={'phone'}
                    name={'phone'}
                    value={values.phone}
                    handleChange={e =>
                      handleChangePhoneNumber(e, setFieldValue)
                    }
                    handleBlur={handleBlur}
                    error={touched.phone && errors.phone}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <label>{ALL_TEXT.MY_PROFILE_.EMAIL}</label>
                  <div className="profile-email">
                    <InputField
                      id={'email'}
                      name={'email'}
                      value={values.email}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      error={touched.email && errors.email}
                      disabled={true}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label>{ALL_TEXT.MY_PROFILE_.ADDRESS}</label>
                  <Autocomplete
                    className="input-field-container p-2 drop-down-height"
                    apiKey={ALL_TEXT.ADDRESSKEY}
                    defaultValue={values.address}
                    placeholder=""
                    onChange={handleChange('address')}
                    onBlur={handleBlur('address')}
                    onPlaceSelected={data => {
                      AddressSeprateHandler(
                        data.address_components,
                        setFieldValue,
                        data.formatted_address,
                      );
                    }}
                    options={{
                      componentRestrictions: {country: 'us'},
                    }}
                  />
                  <div className="error-input-container">
                    {errors.address && touched.address ? (
                      <p className="form-error">{errors.address}</p>
                    ) : null}
                  </div>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <label>{ALL_TEXT.MY_PROFILE_.CITY}</label>
                  <InputField
                    id={'city'}
                    name={'city'}
                    value={values.city}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    error={touched.city && errors.city}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <label>{ALL_TEXT.MY_PROFILE_.STATE}</label>
                  <InputField
                    id={'state'}
                    name={'state'}
                    value={values.state}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    error={touched.state && errors.state}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <label>{ALL_TEXT.MY_PROFILE_.ZIP}</label>
                  <InputField
                    id={'zip'}
                    name={'zip'}
                    value={values.zip}
                    handleChange={e => handleChangeZip(e, setFieldValue)}
                    handleBlur={handleBlur}
                    error={touched.zip && errors.zip}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <label>{ALL_TEXT.MY_PROFILE_.WEBSITE}</label>
                  <InputField
                    id={'website'}
                    name={'website'}
                    value={values.website}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    // error={touched.website && errors.website}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <GenericButton
                    buttonText={ALL_TEXT.SAVE}
                    loading={updateProfileResponse?.isLoading}
                    onPress={handleSubmit}
                  />
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      )}
    </Formik>
  );
}

export default MyProfile;
