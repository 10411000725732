import {createSlice} from '@reduxjs/toolkit';
import {ALL_TEXT, ENUMS} from '../../common';

export const carrierSlice = createSlice({
  name: 'carrierSlice',
  initialState: {
    isCarrierOpenModal: false,
    isAddCarrier: false,
    isDeleteOpenModal: false,
    title: ALL_TEXT.GENERIC_MODAL.CARRIER_DELETE,
    detail: '',
    type: ENUMS.MODAL_TYPES.DELETE,
    removecarrier: '',
    editCarrier: '',
    selectedCarrierData: {},
  },
  reducers: {
    carrierOpenModal(state) {
      state.isCarrierOpenModal = true;
    },
    carrierCloseModal(state) {
      state.isCarrierOpenModal = false;
    },
    addCarrier(state) {
      state.isAddCarrier = true;
    },
    updateCarrier(state) {
      state.isAddCarrier = false;
    },

    deleteOpenModal(state) {
      state.isDeleteOpenModal = true;
    },
    deleteCloseModal(state) {
      state.isDeleteOpenModal = false;
    },
    setDeleteModalDetail(state, {payload}) {
      state.title = payload.title;
      state.detail = payload.detail;
      state.type = payload.type;
    },
    setRemoveCarrier(state, {payload}) {
      state.removecarrier = payload;
    },
    setEditCarrier(state, {payload}) {
      state.editCarrier = payload;
    },
    selectedCarrierData(state, {payload}) {
      state.selectedCarrierData = payload;
    },
  },
});

export const {
  carrierOpenModal,
  carrierCloseModal,
  addCarrier,
  updateCarrier,
  deleteOpenModal,
  deleteCloseModal,
  setDeleteModalDetail,
  setRemoveCarrier,
  setEditCarrier,
  selectedCarrierData,
} = carrierSlice.actions;
