import React from 'react';
import './styles.scss';
import {Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import {CircularProgress} from '@mui/material';

export default function LogsPopup({
  show,
  title,
  body,
  handleClose,
  loading,
  size,
}) {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size={size || 'lg'}
        // isVisible={isModalVisible}
        backdropOpacity={0.3}
        className="logs-modal">
        <Modal.Header closeButton={handleClose}>
          <h5 class="fw-bold">{title}</h5>
        </Modal.Header>

        <Modal.Body>
          {
            <div className="modal-body-generic">
              {loading ? (
                <CircularProgress />
              ) : (
                <span className="modal-description">{body}</span>
              )}
            </div>
          }
        </Modal.Body>
      </Modal>
    </>
  );
}
