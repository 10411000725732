import React from 'react';
import './styles.scss';

export const TextAreaFeild = ({
  key,
  value,
  setValue,
  handleBlur,
  error,
  placeholder,
  reff,
  length,
  disabled,
  ...props
}) => {
  return (
    <>
      <textarea
        disabled={disabled}
        rows="2"
        cols="50"
        maxlength={length || '200'}
        id={key}
        key={`random-${key}`}
        value={value}
        onChange={setValue}
        onBlur={handleBlur}
        placeholder={placeholder}
        className={disabled ? 'isDisabled' : 'isEnable'}
        ref={reff}
        {...props}
      />
      {error && (
        <div className="error-input-container">
          {error ? <p className="form-error">{error}</p> : null}
        </div>
      )}
    </>
  );
};
