import React, {useEffect} from 'react';
import {Modal} from 'react-bootstrap';
import {ALL_TEXT, ENUMS, colors} from '../../../../common';
import {GrClose} from 'react-icons/gr';
import {
  CommissionRateTable,
  Loader,
  SaleDistributionAdmin,
} from '../../../../components';
import {
  TotalCommission,
  ViewDetailsItems,
} from '../../../../components/total-commission';
import {usePostApiAdminCalculateCommissionMutation} from '../../../../redux/slice/nodukAdmin.ts';

export function ViewDetails({show, handleClose, data, startDate, endDate}) {
  const {employeeId, fullName} = data || {};
  const [getCommissionDataAPI, getCommissionDataResult] =
    usePostApiAdminCalculateCommissionMutation();
  const commissionData = getCommissionDataResult?.data?.content;
  const getFormattedDate = originalDateString => {
    const originalDate = new Date(originalDateString);
    // Extract year, month, and day components
    const year = originalDate.getFullYear().toString().slice(-2); // Extract the last 2 digits of the year
    const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = originalDate.getDate().toString().padStart(2, '0');
    // Format the new date string as "DD-MM-YY"
    return `${day}-${month}-${year}`;
  };
  const {
    deductibles,
    subscriptions,
    customers,
    registrations,
    salesDistribution,
    commissionTable,
    totalCommission,
  } = commissionData || {};
  const isDataNull =
    salesDistribution &&
    salesDistribution.length === 0 &&
    commissionTable &&
    commissionTable.length === 0;

  useEffect(() => {
    getCommissionDataAPI({
      adminDashboard: {
        empId: employeeId,
        roleId: ENUMS.STAFF.ROLE.AGENT.VALUE,
        startDate: startDate,
        endDate: endDate,
      },
    });
  }, []);

  return (
    <>
      <>
        <Modal
          className="email-share-QR-wrapper"
          centered
          size="lg"
          show={show}
          onHide={handleClose}>
          <Modal.Header>
            <div className="top-add-payment-container">
              <span>{ALL_TEXT.VIEW_DETAILS} </span>
              <GrClose
                className="add-payment-close-btn"
                onClick={handleClose}
                size={18}
                color={colors.primary}
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            {getCommissionDataResult.isLoading ? (
              <Loader />
            ) : (
              <>
                {isDataNull ? (
                  <div className="no-deductible-error">
                    No deductible commission rate added by the agency
                  </div>
                ) : (
                  <div className="commission-details-wrapper">
                    <div className="title-search-bar shaddow ">
                      <span>{fullName}</span>
                      <span>
                        {`(${getFormattedDate(startDate)} -  ${getFormattedDate(
                          endDate,
                        )})`}
                      </span>
                    </div>
                    <div className="view-details-commission">
                      <ViewDetailsItems
                        items={[
                          {key: 'Total Sales', value: `$${deductibles}`},
                          {
                            key: 'Total Subscriptions',
                            value: `${subscriptions}`,
                          },
                          {key: 'Customers', value: `${customers}`},
                          {key: 'Registrations', value: `${registrations}`},
                        ]}
                      />
                      <CommissionRateTable dataList={commissionTable} />
                    </div>
                    <div className="view-details-popup">
                      <SaleDistributionAdmin
                        title={'Agent Sales'}
                        isFixedRate={
                          commissionTable?.length > 0 &&
                          commissionTable[0]?.isFixedRate
                        }
                        commissionDistributionItem={salesDistribution}
                      />
                    </div>
                    <TotalCommission totalSale={`$${totalCommission}`} />
                  </div>
                )}
              </>
            )}
          </Modal.Body>
        </Modal>
      </>
    </>
  );
}
