import {createSlice} from '@reduxjs/toolkit';

export const notificationSlice = createSlice({
  name: 'notificationSlice',
  initialState: {
    notificationCount: '',
    unReadNotifications: [],
    allNotificationList: [],
  },
  reducers: {
    setNotificationCount(state, {payload}) {
      state.notificationCount = payload;
    },
    setUnReadNotifications(state, {payload}) {
      state.unReadNotifications = payload;
    },
    setAllNotificationList(state, {payload}) {
      state.allNotificationList = payload;
    },
  },
});

export const {
  setUnReadNotifications,
  setAllNotificationList,
  setNotificationCount,
} = notificationSlice.actions;
