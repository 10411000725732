import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import {BsInfoCircleFill} from 'react-icons/bs';
import {ALL_TEXT, colors} from '../../common';
import {Tooltip} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import './styles.scss';
import {GenericButton} from '../generic-button';

export function HomeTermsAndConditions({show, handleClose, onSelectTerms}) {
  const [acceptTerms, setAcceptTerms] = useState(false);
  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Header>
        <div className="terms-modal-header">
          <span>Terms & Conditions </span>
          <Tooltip id={`id-tooltip-terms-home`} />
          <BsInfoCircleFill
            data-tooltip-id={`id-tooltip-terms-home`}
            data-tooltip-content={`Scroll down to accept terms and conditions`}
            color={colors.primary}
            size={20}
            style={{cursor: 'pointer'}}
          />
        </div>
      </Modal.Header>
      <div className="edit-modal-body">
        <Modal.Body>
          <div className="body-container">
            <span>
              <b>
                <center>Disaster Protection Terms of Service Agreement</center>
              </b>
              Effective Date: {'Date'} <br />
              This Disaster Protection Terms of Service Agreement governs the
              use of the disaster protection features, services, and information
              provided by <b>No Duk LLC</b> through its website and mobile
              application App. By accessing or using our website and {"App's "}
              disaster protection features, you agree to comply with the terms
              and conditions outlined in this Agreement. If you disagree with
              this Agreement, please do not use our disaster protection
              features. <br />
              <br />
              <b>1. Description of Services</b>
              <br /> Our website and App provide disaster protection
              information, resources, alerts, and tools to assist individuals
              and organizations in preparing for and responding to various
              disaster scenarios. These services aim to enhance disaster
              awareness and facilitate effective response strategies.
              <br />
              <br />
              <b>2. User Responsibilities</b>
              <br /> You are responsible for the accuracy and completeness of
              the information you provide when using our disaster protection
              features. You agree to use our services responsibly and refrain
              from engaging in any unauthorized, unlawful, or harmful
              activities.
              <br />
              <br />
              <b>3. Information Accuracy</b>
              <br /> While we strive to provide accurate and up-to-date disaster
              protection information, we do not guarantee the accuracy,
              completeness, or reliability of the information presented. Users
              should independently verify the information and consider
              consulting relevant authorities or professionals in disaster
              preparedness.
              <br />
              <br />
              <b>4. Limitation of Liability</b>
              <br /> We shall not be held liable for any direct, indirect,
              incidental, consequential, or punitive damages arising from your
              use of our disaster protection features, including any reliance on
              the information provided. Our services are provided on an{' '}
              {'"as-is" and "as available" basis'}.
              <br />
              <br />
              <b>5. Data Security and Privacy</b>
              <br /> We are committed to safeguarding the privacy and security
              of your data. By using our disaster protection features, you
              acknowledge and agree to our Privacy Policy, which outlines how we
              collect, use, and protect your personal and non-personal
              information.
              <br />
              <br />
              <b>6. Third-Party Content</b>
              <br />
              Our website and App may include links to third-party websites,
              content, or resources. We do not endorse, control, or guarantee
              the accuracy or availability of such third-party content. You
              acknowledge and agree that we are not responsible for any content
              or practices of these third-party websites.
              <br />
              <br />
              <b>7. Changes to Agreement</b>
              <br /> We reserve the right to modify or update this Agreement at
              any time. Any changes will be effective upon posting on our
              website or App. Your continued use of our disaster protection
              features after changes to this Agreement signifies your acceptance
              of the modified terms.
              <br />
              <br />
              <b>8. Termination of Access</b>
              <br /> We reserve the right to suspend or terminate your access to
              our disaster protection features, in whole or in part, if we
              believe you have violated this Agreement or engaged in
              unauthorized or harmful activities.
              <br />
              <br />
              <b>Deductible Service Terms of Service - Disaster Event</b>
              <br /> Effective Date: [Date]
              <br /> This Deductible Service Terms of Service outlines the terms
              and conditions related to insurance deductibles in the event of a
              disaster covered by <b>No Duk LLC</b>. By purchasing and using our
              insurance products and services, you agree to comply with these
              Terms. If you do not agree with these Terms, please do not
              purchase our insurance products.
              <br />
              <br />
              <b>1. Definition of Deductible</b>
              <br />
              An insurance deductible is the specified amount that the
              Policyholder agrees to pay out of pocket before the insurance
              coverage comes into effect for a covered disaster event.
              <br />
              <br />
              <b>2. Disclosure of Deductible</b>
              <br /> The deductible amount is clearly outlined in your insurance
              policy documents. The deductible may vary based on the type of
              coverage and the terms of your policy.
              <br />
              <br />
              <b>3. Payment of Deductible</b>
              <br /> In the event of a covered disaster, the Policyholder is
              responsible for paying the deductible amount as indicated in the
              insurance policy. The deductible is payable to the No Duk before
              the insurance coverage is applied to the claim.
              <br />
              <br />
              <b>4. Deductible Application</b>
              <br /> The deductible applies to each covered claim or occurrence
              of a disaster event as specified in your policy. The deductible is
              subtracted from the total amount of the covered claim before the
              insurance coverage is calculated.
              <br />
              <br />
              <b>5. Policyholder Responsibility</b>
              <br /> It is the {"Policyholder's"} responsibility to understand
              the terms of the deductible and to ensure the timely payment of
              the deductible amount. Failure to pay the deductible may result in
              a delay or denial of the claim.
              <br />
              <br />
              <b>6. Non-Covered Events</b>
              <br /> The deductible only applies to covered disaster events as
              defined in your insurance policy. It does not apply to events that
              are specifically excluded or events that fall outside the scope of
              your coverage.
              <br />
              <br />
              <b>7. Changes to Deductible</b>
              <br />
              We reserve the right to adjust deductible amounts based on changes
              in your insurance policy or regulatory requirements. Notice of any
              changes will be provided to you in accordance with applicable
              laws.
              <br />
              <br />
              <b>8. Governing Law</b>
              <br /> These Terms are governed by and construed in accordance
              with the laws of the jurisdiction where the company is registered.
              <br />
              <br />
              <b>9. Deductible Service Plan</b>
              <br /> In the event of a triggering event that would result in a
              client submitting a claim to their insurance company. Upon the
              process, the insurance No Duk must approve the claim in its
              entirety in the event of any and all-natural disasters. No Duk
              will issue payouts as claims and verification amongst all parties
              are completed of all third-party verification.
              <br />
              <br />
              If you have any questions or concerns about the deductible service
              terms or your deductible plan, please contact us at{' '}
              <a href="mailto:someone@example.com">info@noduk.com</a>.
              <br />
              <br />
              By purchasing our deductible services plan you acknowledge that
              you have read, understood, and agreed to these Deductible Service
              Terms of Service. <br />
              <br />
              Last updated: 08/14/2023 <br />
              <br />
              No Duk LLC <br />8 The Green <br />
              Dover, DE 19901
              <br />
              <a href="www.noduk.com">www.noduk.com</a>
            </span>
            <div class="i-accept-check-box home-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckChecked"
                checked={acceptTerms}
                onChange={d => {
                  setAcceptTerms(d.target.checked);
                }}
              />
              <span
                style={{cursor: 'pointer'}}
                onClick={() => {
                  setAcceptTerms(!acceptTerms);
                }}
                class="add-coverage-box-label"
                for="flexCheckChecked">
                {ALL_TEXT.iAcceptTerms}
              </span>
            </div>
          </div>
        </Modal.Body>
      </div>
      <Modal.Footer className="modal-footer-terms">
        <div className="terms-and-condition-footer">
          <GenericButton
            buttonText={'Cancel'}
            grayButton
            onPress={() => {
              handleClose();
            }}
          />
          <GenericButton
            buttonText={'Submit'}
            disable={!acceptTerms}
            onPress={() => {
              onSelectTerms();
            }}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
}
