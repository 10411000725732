import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router';
import {ENUMS} from '../../common';
import {path} from '../../common/routesNames';
import {UserInfo} from '../../components';
import './style.scss';

const CustomerProfileLayout = ({children, selectedId}) => {
  const [selectedTab, setSelectedTab] = useState(selectedId);
  const navigate = useNavigate();

  const TopTab = ({text, className, onClick}) => {
    return (
      <>
        <div onClick={onClick} className={`customer-tab ${className} `}>
          <span>{text}</span>
          <p></p>
        </div>
      </>
    );
  };
  const location = useLocation();
  return (
    <div>
      <UserInfo />
      <div className="main-user-tabs-content">
        <div className="top-customer-tabs">
          <TopTab
            onClick={() => {
              setSelectedTab(ENUMS.CUSTOMER_TAB_INDEX.PROFILE);
              navigate({
                pathname: path.customerProfile,
                search: location.search,
              });
            }}
            className={
              selectedTab === ENUMS.CUSTOMER_TAB_INDEX.PROFILE && 'selectedTab'
            }
            text={'Customer Profile'}
          />
          <TopTab
            onClick={() => {
              setSelectedTab(ENUMS.CUSTOMER_TAB_INDEX.POLICY);
              navigate({
                pathname: path.customerPolicies,
                search: location.search,
              });
            }}
            className={
              selectedTab === ENUMS.CUSTOMER_TAB_INDEX.POLICY && 'selectedTab'
            }
            text={'Plans'}
          />
          <TopTab
            onClick={() => {
              setSelectedTab(ENUMS.CUSTOMER_TAB_INDEX.CLAIMS);
              navigate({
                pathname: path.customerClaims,
                search: location.search,
              });
            }}
            className={
              selectedTab === ENUMS.CUSTOMER_TAB_INDEX.CLAIMS && 'selectedTab'
            }
            text={'Claims'}
          />
          <TopTab
            onClick={() => {
              setSelectedTab(ENUMS.CUSTOMER_TAB_INDEX.SUBSCRIPTION);
              navigate({
                pathname: path.customerSubscription,
                search: location.search,
              });
            }}
            className={
              selectedTab === ENUMS.CUSTOMER_TAB_INDEX.SUBSCRIPTION &&
              'selectedTab'
            }
            text={'Subscriptions'}
          />
          <TopTab
            onClick={() => {
              setSelectedTab(ENUMS.CUSTOMER_TAB_INDEX.INVOICES);
              navigate({
                pathname: path.customerInvoices,
                search: location.search,
              });
            }}
            className={
              selectedTab === ENUMS.CUSTOMER_TAB_INDEX.INVOICES && 'selectedTab'
            }
            text={'Invoices'}
          />
        </div>
        {children}
      </div>
    </div>
  );
};

export default CustomerProfileLayout;
