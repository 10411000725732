import React, { useState } from 'react';
import './style.scss';
import { NodukAdmnApi } from '../../redux/slice/nodukAdmin.ts';
import {
  setNotificationCount,
  setUnReadNotifications,
} from '../../redux/slice/notifications';
import { useDispatch, useSelector } from 'react-redux';

export function NotificationItem({ notify }) {
  const {
    useLazyGetApiNotificationUpdateAdminNotificationStatusQuery,
    useLazyGetApiNotificationGetEmployeeNotificationCountQuery,
  } = NodukAdmnApi;
  const dispatch = useDispatch();
  const [readStatusAPI] =
    useLazyGetApiNotificationUpdateAdminNotificationStatusQuery();
  const unreadNotificationCount = useSelector(
    state => state?.notificationSlice?.notificationCount,
  );
  const [adminNotificationsCountAPI] =
    useLazyGetApiNotificationGetEmployeeNotificationCountQuery();

  const fetchEmpNotificationsCount = async () => {
    let result = await adminNotificationsCountAPI();
    if (result && result.data?.content) {
      let notificationsList = result.data?.content;
      dispatch(setUnReadNotifications(notificationsList));
    }
  };

  const [isRead, setisRead] = useState(notify.isRead);

  const setNotificationRead = async id => {
    if (!isRead) {
      let response = await readStatusAPI({
        notifyId: id,
      });
      if (response && response?.data?.success) {
        setisRead(true);
        if (unreadNotificationCount > 0) {
          dispatch(setNotificationCount(unreadNotificationCount - 1));
          fetchEmpNotificationsCount();
        }
      }
    }
  };

  return (
    <div className="notification-div">
      <li
        className={isRead ? 'notify-item' : 'notify-item readNotification'}
        onClick={() => setNotificationRead(notify.notificationId)}>
        <div className="notify-image">
          <span
            className={
              notify?.notifyType == 'refund'
                ? 'icon-refund iconFont'
                : notify.notifyType == 'invoice'
                  ? 'icon-invoices iconFont'
                  : notify.notifyType == 'subscription'
                    ? 'icon-claims iconFont'
                    : notify.notifyType == 'claim'
                      ? 'icon-claims iconFont'
                      : 'icon-notification iconFont'
            }></span>
        </div>
        <div className="notify-detail">
          <p className="notify-head">{notify.title}</p>
          <p className="notify-text">{notify.description}</p>
        </div>
        <div className="notify-date-time">
          <p className="notify-date">{notify.sendDate}</p>
          <p className="notify-date">{notify.sendTime}</p>
        </div>
      </li>
    </div>
  );
}
