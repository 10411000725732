import React from 'react';
import { Modal } from 'react-bootstrap';
import {
    setIsDownloadFilesPopup,setIsDownloadSuccessPopup
} from '../../../redux/slice/administrator';
import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { downloadStaffDocuments } from '../../../utils/rest-services/index.js';

function DownloadFilesPopup({ show, data }) {
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch();
    useEffect(() => {
        downloadFile()
    }, []);

    const downloadFile = async () => {
        const result = await downloadStaffDocuments(data?.employeeId);
        setLoading(false)
        if (result?.status === 200 && result?.data) {
            dispatch(setIsDownloadFilesPopup(false));
            dispatch(setIsDownloadSuccessPopup(true));
            const url = window.URL.createObjectURL(new Blob([result.data]));
            const a = document.createElement('a');
            a.href = url;
            a.download ='staff-docx.zip';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);

        } else {
            dispatch(setIsDownloadFilesPopup(false));
            toast.error(result.data.description);
        }

    }

    return (
        <>

            <Modal
            className='modalclass'
                show={show}>
                <Modal.Header>
                <span className='DownloadHeading'>Download Documents</span>
                </Modal.Header>
                <Modal.Body>
                    <span className='downloadingspan'>Please wait file is downloading...</span>
                    {loading === true &&
                        <CircularProgress size={50} color="inherit" />
                    }
                </Modal.Body>
            </Modal>
        </>

    );

}

export default DownloadFilesPopup;