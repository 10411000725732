import React, {useState} from 'react';
import Carousel from 'react-spring-3d-carousel';
import {v4 as uuidv4} from 'uuid';
import {MdOutlineArrowBackIosNew} from 'react-icons/md';
import {MdOutlineArrowForwardIos} from 'react-icons/md';
import {useRef} from 'react';

const YoutubeCarousel3D = ({videos, onVideoChange}) => {
  const goToPreviousSlide = () => {
    const newSlide = (currentSlide - 1 + videos.length) % videos.length;
    setCurrentSlide(newSlide);
    if (carouselRef.current) {
      carouselRef.current.slideTo(newSlide);
    }
    onVideoChange(newSlide);
  };

  const goToNextSlide = () => {
    const newSlide = (currentSlide + 1) % videos.length;
    setCurrentSlide(newSlide);
    if (carouselRef.current) {
      carouselRef.current.slideTo(newSlide);
    }
    onVideoChange(newSlide);
  };
  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselRef = useRef(null);
  const slides = videos.map(video => ({
    key: uuidv4(),
    content: (
      <iframe
        width="600"
        height="355"
        src={video.id}
        title={`YouTube Video`}
        frameBorder="0"
        allowFullScreen></iframe>
    ),
  }));

  return (
    <div className="carousel-overlay">
      <Carousel slides={slides} goToSlide={currentSlide} offsetRadius={1} />
      {videos.length > 1 && (
        <div className="button-container">
          <button className="navigation-button" onClick={goToPreviousSlide}>
            <MdOutlineArrowBackIosNew />
          </button>
          <button className="navigation-button" onClick={goToNextSlide}>
            <MdOutlineArrowForwardIos />
          </button>
        </div>
      )}
    </div>
  );
};

export default YoutubeCarousel3D;
