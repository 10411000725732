import axios from 'axios';
import {axiosInstance, axiosMultiPartInstance} from './config';
const controllerName = {
  customer: `/api/Customer`,
  admin: `/api/Admin`,
  payment: `/api/Payment`,
  adminCustomer: `/api/AdminCustomer`,
  disaster: `/api/Disaster`,
};
const API_QR_KEY = 'wyruuhxszqansnisphbgjeijhtiucgtpchjpflee';
// const API_QR_KEY = 'xpymoncgecbnckzhsrtydyexwegpcqnewgrqhczh';
const LOGO_URL =
  'https%3A%2F%2Fnoduk.com%2Fwp-content%2Fuploads%2F2022%2F05%2Fplaystore.png';

const endpoints = {
  login: `${controllerName.admin}/login`,
  requestForgotPassword: `${controllerName.admin}/forget-password`,
  resetPassword: `${controllerName.admin}/reset-password`,
  addNewPassword: `${controllerName.admin}/set-admin-password`,
  getDashboard: `${controllerName.admin}/get-dashboard-stats`,
  addUser: `${controllerName.admin}/add-user`,
  getAllUsers: `${controllerName.admin}/get-all-users`,
  getAllInvoices: `${controllerName.admin}/get-all-invoices`,
  getAllAgents: `${controllerName.admin}/get-all-agents`,
  updateSubscription: `${controllerName.payment}/UpdateSubscriptionCard`,
  stripeSubId: `${controllerName.payment}/SaveSubscriptionDetails?SubId=`,
  createCustomerSubscription: `${controllerName.admin}/CreateUserSubscription`,
  getCustomerCards: `${controllerName.customer}/GetCardPaymentInfo`,
  carrierMakerByType: `${controllerName.customer}/GetCarriersMakersByType?serviceTypeId=`,
  deductibleList: `${controllerName.customer}/GetDeductibleList`,
  vehicleModelListById: `${controllerName.customer}/GetVehicleModelListById?serviceTypeId=`,
  getCartItem: `${controllerName.adminCustomer}/get-cartitem?cartItemId=`,
  getCustomerInvoices: `${controllerName.adminCustomer}/get-customer-invoices`,
  getCartItemList: `${controllerName.adminCustomer}/get-cartitem-list?signupId=`,
  removeCartItem: `${controllerName.adminCustomer}/remove-cartitem?cartItemId=`,
  paymentPlan: `${controllerName.adminCustomer}/get-payment-plans?signupId=`,
  checkoutCompleteObject: `${controllerName.adminCustomer}/get-complete-checkout?signupId=`,
  getBillingCards: `${controllerName.adminCustomer}/get-user-billingcards?signupId=`,
  getCheckoutDetails: `${controllerName.adminCustomer}/get-checkout-details?signupId=`,
  createOrder: `${controllerName.adminCustomer}/create-subscription-v3`,
  addStripSubsId: `${controllerName.adminCustomer}/save-subscription-detail`,
  emptyCart: `${controllerName.adminCustomer}/remove-all-cartitems?signupId=`,
  addCartItem: `${controllerName.adminCustomer}/add-cartitem`,
  getCustomerProfile: `${controllerName.adminCustomer}/get-customer-profile?signUpId=`,
  getAllClaims: `${controllerName.admin}/get-all-claims`,
  getClaimDetail: `${controllerName.admin}/get-claim-detail`,
  updateClaimInfo: `${controllerName.admin}/update-claim-info`,
  getUserDropdown: `${controllerName.admin}/get-users-dropdown-list`,
  getManageCarrier: `${controllerName.admin}/get-all-carriers`,
  getCustomerPolicies: `${controllerName.adminCustomer}/get-customer-policies`,
  getCustomerClaims: `${controllerName.adminCustomer}/get-customer-claims`,
  getCustomerClaimsDetail: `${controllerName.adminCustomer}/get-claim-detail`,
  getInvoiceDetails: `${controllerName.adminCustomer}/get-invoice-detail`,
  addNewClaim: `${controllerName.adminCustomer}/add-claim`,
  cancelCustomerPolicy: `${controllerName.adminCustomer}/cancel-customer-policy`,
  cancelCustomerSubscription: `${controllerName.adminCustomer}/cancel-subscription`,
  getCustomerSubscription: `${controllerName.adminCustomer}/get-customer-subscriptions`,
  getSubscriptionDetails: `${controllerName.adminCustomer}/get-subsc-detail`,
  updateCustomerInfo: `${controllerName.adminCustomer}/update-customer-info`,
  removeCarrier: `${controllerName.admin}/delete-carrier?id=`,
  addCarriers: `${controllerName.admin}/add-update-carrier`,
  getRiskLevels: `${controllerName.disaster}/get-risk-levels-list`,
  removeDeviceToken: `${controllerName.admin}/remove-emp-token`,
  downloadStaffDocument: `${controllerName.admin}/download-staff-files?empId=`,
  getTutorialLinks: `${controllerName.admin}/get-tutorial-links`,
};

export const performLogin = async payloadObject => {
  try {
    return (await axiosInstance.post(`${endpoints.login}`, payloadObject)).data;
  } catch (error) {
    return error;
  }
};

export const removeDeviceTokenAPI = async token => {
  try {
    let result = await axiosInstance.post(
      `${endpoints.removeDeviceToken}`,
      token,
    );
    return result;
  } catch (error) {
    return error;
  }
};
export const requestForgotPassword = async email => {
  try {
    return await (
      await axiosInstance.get(
        `${endpoints.requestForgotPassword}?email=${email}`,
      )
    ).data;
  } catch (error) {
    return error;
  }
};
export const getRiskLevel = async () => {
  try {
    return await (
      await axiosInstance.get(`${endpoints.getRiskLevels}`)
    ).data;
  } catch (error) {
    return error;
  }
};

export const resetUserPassword = async payloadObject => {
  try {
    return await (
      await axiosInstance.post(`${endpoints.resetPassword}`, payloadObject)
    ).data;
  } catch (error) {
    return error;
  }
};
export const addNewUserPassword = async payloadObject => {
  try {
    return await (
      await axiosInstance.post(`${endpoints.addNewPassword}`, payloadObject)
    ).data;
  } catch (error) {
    return error;
  }
};

export const getCustomerProfile = async id => {
  try {
    return (await axiosInstance.get(`${endpoints.getCustomerProfile}${id}`))
      .data;
  } catch (error) {
    return error;
  }
};
export const getCustomerPolicies = async payload => {
  try {
    return (
      await axiosInstance.post(`${endpoints.getCustomerPolicies}`, payload)
    ).data;
  } catch (error) {
    return error;
  }
};
export const getCustomerClaims = async payload => {
  try {
    return (await axiosInstance.post(`${endpoints.getCustomerClaims}`, payload))
      .data;
  } catch (error) {
    return error;
  }
};
export const getDashboard = async () => {
  try {
    return (await axiosInstance.get(`${endpoints.getDashboard}`)).data;
  } catch (error) {
    return error;
  }
};

export const addUserDetail = async payloadObject => {
  try {
    return await axiosInstance.post(`${endpoints.addUser}`, payloadObject);
  } catch (error) {
    return error;
  }
};
export const updateCustomerInfo = async payloadObject => {
  try {
    return await axiosInstance.post(
      `${endpoints.updateCustomerInfo}`,
      payloadObject,
    );
  } catch (error) {
    return error;
  }
};

export const gettAllUsers = async payloadObject => {
  try {
    return await (
      await axiosInstance.post(`${endpoints.getAllUsers}`, payloadObject)
    ).data;
  } catch (error) {
    return error;
  }
};

export const getAllAgents = async () => {
  try {
    return await (
      await axiosInstance.get(`${endpoints.getAllAgents}`)
    ).data;
  } catch (error) {
    return error;
  }
};

export const getAllInvoices = async payloadObject => {
  try {
    return await (
      await axiosInstance.post(`${endpoints.getAllInvoices}`, payloadObject)
    ).data;
  } catch (error) {
    return error;
  }
};

export const getAllClaims = async payLoadObj => {
  try {
    return await (
      await axiosInstance.post(`${endpoints.getAllClaims}`, payLoadObj)
    ).data;
  } catch (error) {
    return error;
  }
};

export const getClaimDetails = async id => {
  try {
    return await (
      await axiosInstance.get(`${endpoints.getClaimDetail}?claimId=${id}`)
    ).data;
  } catch (error) {
    return error;
  }
};

export const getTutorialLinks = async () => {
  try {
    return await (
      await axiosInstance.get(`${endpoints.getTutorialLinks}`)
    ).data;
  } catch (error) {
    return error;
  }
};

export const updateClaimInfo = async payloadObject => {
  try {
    return await axiosMultiPartInstance.post(
      `${endpoints.updateClaimInfo}`,
      payloadObject,
    );
  } catch (error) {
    return error;
  }
};

export const getManageCarriers = async payLoadObj => {
  try {
    return await (
      await axiosInstance.post(`${endpoints.getManageCarrier}`, payLoadObj)
    ).data;
  } catch (error) {
    return error;
  }
};
export const addCarriers = async payLoadObj => {
  try {
    return await (
      await axiosInstance.post(`${endpoints.addCarriers}`, payLoadObj)
    ).data;
  } catch (error) {
    return error;
  }
};

// export const addCarriers = async payLoadObj => {
//   try {
//     let result = await axiosInstance.post(
//       `${endpoints.addCarriers}`,
//       payLoadObj,
//     );
//     return result;
//   } catch (error) {
//     return error;
//   }
// };

//
export const updateSubscription = async payLoadObj => {
  try {
    let result = await axiosInstance.post(
      `${endpoints.updateSubscription}`,
      payLoadObj,
    );
    return result;
  } catch (error) {
    return error;
  }
};
export const AddStripeSubId = async subId => {
  try {
    let response = await axiosInstance.post(`${endpoints.stripeSubId}${subId}`);
    return response;
  } catch (error) {
    return error;
  }
};
export const CreateSubscription = async payloadObject => {
  try {
    let response = await axiosInstance.post(
      `${endpoints.createCustomerSubscription}`,
      payloadObject,
    );
    return response;
  } catch (error) {
    return error;
  }
};
export const cancelCustomerPolicy = async payloadObject => {
  try {
    let response = await axiosInstance.post(
      `${endpoints.cancelCustomerPolicy}`,
      payloadObject,
    );
    return response;
  } catch (error) {
    return error;
  }
};
export const cancelCustomerSubscription = async payloadObject => {
  try {
    let response = await axiosInstance.post(
      `${endpoints.cancelCustomerSubscription}`,
      payloadObject,
    );
    return response;
  } catch (error) {
    return error;
  }
};
export const getPaymentDefaultCards = async () => {
  try {
    let result = await axiosInstance.get(`${endpoints.getCustomerCards}`);
    return result;
  } catch (error) {
    return error;
  }
};
export const getQRCode = async baseUrl => {
  try {
    let result = await axios.get(
      `https://api.scanova.io/v2/qrcode/url?url=${baseUrl}&size=m&error_correction=M&data_pattern=RECT&eye_pattern=RECT_RECT&data_gradient_style=None&data_gradient_start_color=%23000000&data_gradient_end_color=%23000000&eye_color_inner=%23000000&eye_color_outer=%23000000&background_color=%23FFFFFF&logo.url=${LOGO_URL}&logo.size=5&logo.excavated=true&logo.angle=0&poster.left=50&poster.top=50&poster.size=40&poster.eyeshape=ROUND_RECT&poster.dataPattern=ROUND&format=png&apikey=${API_QR_KEY}`,
      {
        responseType: 'blob',
        'Content-Type': 'application/zip',
      },
    );
    return result;
  } catch (error) {
    return error;
  }
};

export const downloadStaffDocuments = async id => {
  try {
    let result = await axiosInstance.get(
      `${endpoints.downloadStaffDocument}${id}`,
      {
        responseType: 'blob',
      },
    );
    return result;
  } catch (error) {
    return error;
  }
};

export const getCarrierMakerByType = async serviceTypeId => {
  try {
    return await (
      await axiosInstance.get(`${endpoints.carrierMakerByType}${serviceTypeId}`)
    ).data;
  } catch (error) {
    return error;
  }
};

export const getCustomerSubscription = async payload => {
  try {
    return await (
      await axiosInstance.post(`${endpoints.getCustomerSubscription}`, payload)
    ).data;
  } catch (error) {
    return error;
  }
};
export const getInvoiceDetails = async (invoiceId, signupId) => {
  try {
    return await (
      await axiosInstance.get(
        `${endpoints.getInvoiceDetails}?invId=${invoiceId}&signupId=${signupId}`,
      )
    ).data;
  } catch (error) {
    return error;
  }
};
export const getCustomerClaimsDetail = async (claimId, signupId) => {
  try {
    return await (
      await axiosInstance.get(
        `${endpoints.getCustomerClaimsDetail}?claimId=${claimId}&signupId=${signupId}`,
      )
    ).data;
  } catch (error) {
    return error;
  }
};

export const getSusbcriptionDetails = async (subId, signupId) => {
  try {
    return await (
      await axiosInstance.get(
        `${endpoints.getSubscriptionDetails}?subId=${subId}&signupId=${signupId}`,
      )
    ).data;
  } catch (error) {
    return error;
  }
};
export const getDeductibleList = async isDisaster => {
  try {
    return await (
      await axiosInstance.get(
        `${endpoints.deductibleList}?isDisaster=${isDisaster || false}`,
      )
    ).data;
  } catch ({response: {data}}) {
    return data;
  }
};
export const addNewClaims = async data => {
  try {
    let result = await axiosMultiPartInstance.post(
      `${endpoints.addNewClaim}`,
      data,
    );
    return result;
  } catch (error) {
    return error;
  }
};
export const getVehicleModelListById = async (serviceTypeId, makeId) => {
  try {
    return await (
      await axiosInstance.get(
        `${endpoints.vehicleModelListById}${serviceTypeId}&makeId=${makeId}`,
      )
    ).data;
  } catch (error) {
    return error;
  }
};
export const getCustommerInvoices = async payload => {
  try {
    return await (
      await axiosInstance.post(`${endpoints.getCustomerInvoices}`, payload)
    ).data;
  } catch (error) {
    return error;
  }
};
export const getCartItemsList = async id => {
  try {
    return await (
      await axiosInstance.get(`${endpoints.getCartItemList}${id}`)
    ).data;
  } catch (error) {
    return error;
  }
};
export const removeAllCartItems = async id => {
  try {
    return await (
      await axiosInstance.get(`${endpoints.emptyCart}${id}`)
    ).data;
  } catch (error) {
    return error;
  }
};
export const getSingleCartItem = async (id, signupId) => {
  try {
    return await (
      await axiosInstance.get(
        `${endpoints.getCartItem}${id}&signupId=${signupId}`,
      )
    ).data;
  } catch (error) {
    return error;
  }
};
export const removeSingleCartItem = async id => {
  try {
    return await (
      await axiosInstance.get(`${endpoints.removeCartItem}${id}`)
    ).data;
  } catch (error) {
    return error;
  }
};
export const getPaymentPlans = async id => {
  try {
    return await (
      await axiosInstance.get(`${endpoints.paymentPlan}${id}`)
    ).data;
  } catch (error) {
    return error;
  }
};
export const getCheckoutCompleteObject = async id => {
  try {
    return await (
      await axiosInstance.get(`${endpoints.checkoutCompleteObject}${id}`)
    ).data;
  } catch (error) {
    return error;
  }
};
export const getBillingCards = async id => {
  try {
    return await (
      await axiosInstance.get(`${endpoints.getBillingCards}${id}`)
    ).data;
  } catch (error) {
    return error;
  }
};
export const getCheckoutDetails = async id => {
  try {
    return await (
      await axiosInstance.get(`${endpoints.getCheckoutDetails}${id}`)
    ).data;
  } catch (error) {
    return error;
  }
};
export const removeCarrier = async id => {
  try {
    return await (
      await axiosInstance.post(`${endpoints.removeCarrier}${id}`)
    ).data;
  } catch (error) {
    return error;
  }
};

export const addItemToCart = async payloadObject => {
  try {
    return await (
      await axiosInstance.post(`${endpoints.addCartItem}`, payloadObject)
    ).data;
  } catch (error) {
    return error;
  }
};
export const CreateOderSubscription = async payloadObject => {
  try {
    return await (
      await axiosInstance.post(`${endpoints.createOrder}`, payloadObject)
    ).data;
  } catch (error) {
    return error;
  }
};
export const AddCustomerStripeSubId = async payloadObject => {
  try {
    return await (
      await axiosInstance.post(`${endpoints.addStripSubsId}`, payloadObject)
    ).data;
  } catch (error) {
    return error;
  }
};
export const getUserDropdownValues = async payloadObject => {
  try {
    return await (
      await axiosInstance.post(`${endpoints.getUserDropdown}`, payloadObject)
    ).data;
  } catch (error) {
    return error;
  }
};

export const getRiskLevelList = async () => {
  try {
    return await (
      await axiosInstance.get(`${endpoints.getRiskLevel}`)
    ).data;
  } catch (error) {
    return error;
  }
};

// export const getRiskLevelList = async () => {
//   try {
//     let result = await axiosInstance.get(`${endpoints.getRiskLevel}`);
//     console.log(result);
//     return result;
//   } catch (error) {
//     console.log(error);
//   }
// };
