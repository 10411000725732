import {createSlice} from '@reduxjs/toolkit';
import {ENUMS} from '../../common';

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: {
    selectedPlanId: ENUMS.PLANS_ID.MONTHLY,
    selectedCard: null,
    totalCost: 0,
    usersList: [],
    selectedUser: {},
    isUserChange: false,
    promoCode: '',
    couponCodeText: '',
    couponType: 0,
    minimumDiscountAmount: 0,
    paymentdetails: {},
    discounts: {
      amount: 0,
      percentage: 0,
    },
    duration: '',
    processingFee: 1,
    taxPercent: 2.9,
    cartItems: [],
    monthlyPlanData: null,
    biAnnualPlanData: null,
    annualPlanData: null,
    selectedPlanRecords: null,
    totalDeductibles: 0,
  },
  reducers: {
    setSelectedPlanID(state, {payload}) {
      state.selectedPlanId = payload.id;
      state.totalCost = payload.cost;
    },
    setTotalCost(state, {payload}) {
      state.totalCost = payload;
    },
    setCartItems(state, {payload}) {
      state.cartItems = payload;
    },
    setMonthlyPlanData(state, {payload}) {
      state.monthlyPlanData = payload;
    },
    setBiannualPlanData(state, {payload}) {
      state.biAnnualPlanData = payload;
    },
    setAnnualPlanData(state, {payload}) {
      state.annualPlanData = payload;
    },
    setSelectedPlanRecords(state, {payload}) {
      state.selectedPlanRecords = payload;
    },
    setTotalDeductibles(state, {payload}) {
      state.totalDeductibles = payload;
    },
    setSelectedCard(state, {payload}) {
      state.selectedCard = payload;
    },
    setCouponType(state, {payload}) {
      state.couponType = payload;
    },
    setUserList(state, {payload}) {
      state.usersList = payload;
    },
    setSelectedUser(state, {payload}) {
      state.selectedUser = payload;
    },
    setIsUserChange(state, {payload}) {
      state.isUserChange = payload;
    },
    setPromoCode(state, {payload}) {
      state.promoCode = payload;
    },
    setPaymentdetails(state, {payload}) {
      state.paymentdetails = payload;
    },
    setCouponCodeText(state, {payload}) {
      state.couponCodeText = payload;
    },
    setDuration(state, {payload}) {
      state.duration = payload;
    },
    setProcessingFee(state, {payload}) {
      state.processingFee = payload;
    },
    setTaxPercentage(state, {payload}) {
      state.taxPercent = payload;
    },

    setDiscounts(state, {payload}) {
      state.discounts.amount = payload.amount;
      state.discounts.percentage = payload.percentage;
    },
    setMinimumDiscountAmount(state, {payload}) {
      state.minimumDiscountAmount = payload;
    },
  },
});

export const {
  setTotalDeductibles,
  setMonthlyPlanData,
  setBiannualPlanData,
  setAnnualPlanData,
  setSelectedPlanRecords,
  setCartItems,
  setSelectedPlanID,
  setSelectedCard,
  setUserList,
  setSelectedUser,
  setTotalCost,
  setIsUserChange,
  setPromoCode,
  setCouponCodeText,
  setDiscounts,
  setMinimumDiscountAmount,
  setPaymentdetails,
  setDuration,
  setCouponType,
  setProcessingFee,
  setTaxPercentage,
} = checkoutSlice.actions;
