import React from 'react';
import {CircularProgress} from '@mui/material';
import './styles.scss';

export const GenericButton = ({
  loading,
  onPress,
  icon,
  buttonText,
  disable,
  grayButton,
  id,
  customStyle,
}) => {
  return (
    <>
      <div className="generic-btn-container" id={id}>
        <button
          type="button"
          disabled={loading || disable}
          className={
            loading || disable || grayButton
              ? `disable-generic-btn ${customStyle}`
              : ` ${customStyle}`
          }
          onClick={onPress}>
          {loading ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <>
              {icon && <span style={{marginRight: '5px'}}>{icon}</span>}
              {buttonText}
            </>
          )}
        </button>
      </div>
    </>
  );
};
