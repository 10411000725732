import {Grid} from '@mui/material';
import React from 'react';
import {ALL_TEXT, ENUMS} from '../../../common';

export function DashboardCards({data, logginInUserId}) {
  let cardsArr = [
    {
      icon: <span class="icon-customers"></span>,
      title: ALL_TEXT.CUSTOMERS,
      count: data?.customers,
    },
    {
      icon: <span class="icon-registrations"></span>,
      title: ALL_TEXT.REGISTRATION,
      count: data?.registered,
    },
    {
      icon: <span class="icon-claims"></span>,
      title: ALL_TEXT.OPENED_CLAIMS,
      count: data?.openClaims,
    },
    {
      icon: <span class="icon-claims"></span>,
      title: ALL_TEXT.CLOSED_CLAIMS__,
      count: data?.closedClaims,
    },
  ];
  let agentsCards = [
    {
      icon: <span class="icon-agents"></span>,
      title: ALL_TEXT.AGENTS,
      count: data?.agents,
    },
    {
      icon: <span class="icon-customers"></span>,
      title: ALL_TEXT.CUSTOMERS,
      count: data?.customers,
    },
    {
      icon: <span class="icon-registrations"></span>,
      title: ALL_TEXT.REGISTRATION,
      count: data?.registered,
    },
    {
      icon: <span class="icon-claims"></span>,
      title: ALL_TEXT.CLOSED_CLAIMS__,
      count: data?.closedClaims,
    },
  ];
  let dashboardCards =
    logginInUserId === ENUMS.STAFF.ROLE.INDEPANDANT_AGENT.VALUE ||
    logginInUserId === ENUMS.STAFF.ROLE.AGENT.VALUE ||
    logginInUserId === ENUMS.STAFF.ROLE.SALES_REP.VALUE
      ? cardsArr
      : agentsCards;
  return (
    <div className="dashboard-card-container">
      <Grid container spacing={2}>
        {dashboardCards.map((i, index) => {
          return (
            <Grid item key={index} xs={12} sm={4} md={4} lg={3} xl={3}>
              <Card icon={i.icon} title={i.title} count={i.count} />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
const Card = ({icon, title, count}) => {
  return (
    <div className="dashboard-card">
      <div className="dashboard-icon-container">{icon}</div>
      <div className="title-card-dashboard">
        <span>{title}</span>
        <span>{count}</span>
      </div>
    </div>
  );
};
